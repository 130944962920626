import React from "react";
import { observer } from "mobx-react-lite";

interface Props {
	title: string;
	subtitle?: string;
	children?: React.ReactNode;
}

export const PageHeader = observer(function({ title, children, subtitle }: Props) {
	return (
		<div className="content-toolbar">
			<div className="content-header">
				<div className="content-header__main">{title}</div>
				{subtitle != null && <div className="content-header__sub">{subtitle}</div>}
			</div>
			{children}
		</div>
	);
});
