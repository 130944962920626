import { computed, observable, runInAction } from "mobx";

import { actions } from "@actions";
import { DataSourceSession, Filters, InfiniteDataSource } from "@app-models/data-source";
import { XScopeChildInfo } from "@external-types/scope";

import { ReferenceScopeColumns, referenceScopeColumnsByCode } from "../../constants/reference-scope-code";
import { NewReferenceScope } from "./new-reference-scope";
import { ReferenceScopeItem } from "./reference-scope-item";
import { XFileRef } from "../../../../../src/types/external-data/file";
import { IUploadFile } from "../../../../../src/types/models";

export class ReferenceList extends InfiniteDataSource<ReferenceScopeItem> {
	public readonly session = new DataSourceSession<ReferenceScopeItem>();
	@observable public typeSchemaFetched = false;
	public typeSchema: NewReferenceScope;
	private _filter: Filters = new Filters(true);
	@observable private scope: XScopeChildInfo;

	public columns: ReferenceScopeColumns;

	public constructor(scope: XScopeChildInfo) {
		super();

		this.scope = scope;
		this.typeSchema = new NewReferenceScope(scope);
		this.columns = referenceScopeColumnsByCode.get(scope.id) ?? [];
	}

	public get id() {
		return this.scope.id;
	}

	public get filter() {
		return this._filter;
	}

	public get name() {
		return this.scope.name;
	}

	@computed
	public get files() {
		return this.scope.files;
	}

	public deleteFile = async (file: XFileRef) => {
		if (confirm(`Удалить файл ${file.filename}?`)) {
			await actions.deleteScopeFile(this.scope.id, file.id);

			await this.refetchScope();
		}
	};

	public uploadFiles = async (files: IUploadFile[]) => {
		if (files.length > 0) {
			for (const file of files) {
				await actions.uploadScopeFile(this.scope.id, file);
			}

			await this.refetchScope()
		}
	};

	private async refetchScope() {
		const scope = await actions.getScope(this.scope.id);

		runInAction(() => {
			this.scope = scope;
		});
	}

	protected async fetch(): Promise<ReferenceScopeItem[]> {
		const descendants = await actions.getScopeChildrenPaged(this.scope.id, this.pagination, this.filter);

		this.updatePageInfo(descendants);

		return descendants.list.map(item => new ReferenceScopeItem(item));
	}

	public async fetchTypeSchema() {}
}
