import React from "react";
import cn from "classnames";

export function Column({ children, borderless = false }: { children?: React.ReactNode; borderless?: boolean }) {
	return (
		<div className={cn("org-panel-info__column", { "org-panel-info__column--borderless": borderless })}>
			{children}
		</div>
	);
}
