import { actions } from "@actions";
import { XScopeCard, XScopeChildInfo } from "@external-types/scope";
import { SchemaForm } from "@app-models/schema-form";
import { getScopeAttributeValue } from "@helpers/scope-attributes";
import { fullName } from "@helpers/user";

import { WardAttributes } from "../constants/ward-attributes";
import { mapAttrToScopeAttrId } from "../stores/reference-store/reference-scope";

export function getWardFullName(scope: XScopeCard | XScopeChildInfo, username: string = "Не определено") {
	return fullName({
		firstName: getScopeAttributeValue(scope, WardAttributes.FirstName),
		lastName: getScopeAttributeValue(scope, WardAttributes.LastName),
		middleName: getScopeAttributeValue(scope, WardAttributes.MiddleName),
		username,
	});
}

export function getWardScopeName(form: SchemaForm) {
	return [
		form.getField(WardAttributes.LastName),
		form.getField(WardAttributes.FirstName),
		form.getField(WardAttributes.MiddleName),
		form.getField(WardAttributes.Phone),
		form.getField(WardAttributes.MobilePhone),
		form.getField(WardAttributes.HomePhone),
		form.getField(WardAttributes.AdditionalPhone),
	]
		.filter(field => field != null && field.value != null && field.value.trim() !== "")
		.map(field => field!.value.trim())
		.join(" ")
		.trim()
		.substr(0, 400);
}

export async function saveWardInfo(scope: XScopeCard | XScopeChildInfo, form: SchemaForm) {
	const attrs = form.values.map(x => ({ attr: x.id, value: x.value }));

	const name = getWardScopeName(form) || scope.name;

	await actions.updateScope(scope.id, {
		name,
		attrs: mapAttrToScopeAttrId(attrs, scope),
	});
}

export async function setWardDefaults(form: SchemaForm) {
	const destinationPhoneField = form.getField(WardAttributes.DestinationPhone);

	if (destinationPhoneField && !destinationPhoneField.value) {
		destinationPhoneField.value = destinationPhoneField.options[0].value;
	}
}
