import { action, observable, runInAction } from "mobx";
import { actions } from "@actions";
import { XScopeChildInfo } from "@external-types/scope";
import { SubReferenceItem } from "./sub-reference-item";
import { XAppTypeInfo } from "@external-types/type";
import { IReferenceScopeSection } from "./types";

export class SubReferenceCollection {
	@observable.ref public children: SubReferenceItem[] = [];
	@observable public index: number = -1;

	public constructor(
		private parentSection: IReferenceScopeSection,
		private appTypes: XAppTypeInfo[],
		scopes: XScopeChildInfo[] = [],
	) {
		if (parentSection.scopeId !== null) {
			const scopeId = parentSection.scopeId;

			this.children = scopes.map(ch => new SubReferenceItem(scopeId, this.findAppTypeById(ch.type.id), ch));
		}

		if (this.children.length > 0) {
			this.index = 0;
		}
	}

	private findAppTypeById(appTypeId: number) {
		return this.appTypes.find(appType => appType.id === appTypeId)!;
	}

	public async fetch() {
		const parentScopeId = this.parentSection.scopeId;

		if (parentScopeId !== null) {
			const children = await actions.getScopeChildren(parentScopeId);

			runInAction(() => {
				this.children = children.map(
					ch => new SubReferenceItem(parentScopeId, this.findAppTypeById(ch.type.id), ch),
				);

				if (children.length > 0) {
					this.index = 0;
				}
			});
		}
	}

	@action
	public add = async () => {
		const children = this.children;
		const parentScopeId = this.parentSection.scopeId;

		if (parentScopeId == null) {
			const updateResult = await this.parentSection.update();

			if (!updateResult) {
				return;
			}
		}

		if (!this.parentSection.scopeId) {
			throw new Error(`Parent scope id is not defined`);
		}

		runInAction(() => {
			this.children = children.concat(new SubReferenceItem(this.parentSection.scopeId!, this.appTypes[0]));
		});

		this.updateIndex(this.children.length - 1);
	};

	@action
	public updateIndex(index: number) {
		if (this.validate()) {
			this.submitActiveScope();
			this.index = index;
		}
	}

	public validate() {
		if (this.index >= 0) {
			return this.children[this.index].validate();
		}

		return true;
	}

	public submitActiveScope() {
		if (this.index >= 0) {
			const childScope = this.children[this.index];

			childScope.update();
		}

	}
}
