import React from "react";
import ReactSelect, { ValueType, FormatOptionLabelMeta } from "react-select";
import cn from "classnames";
import { OptionItem } from "@app-types/models";

interface Props<T> {
	items: Array<OptionItem<T>>;
	value: T;
	className?: string;
	disabled?: boolean;
	onChange?(value: T | null): void;
	placeholder?: string;
	formatOptionLabel?: FormatOptionCallback<T>;
}
export type FormatOptionCallback<T> = (
	option: OptionItem<T>,
	labelMeta: FormatOptionLabelMeta<OptionItem<T>, boolean>,
) => React.ReactNode;

export function Select<T extends number | string>({
	items,
	value,
	disabled,
	onChange,
	className,
	placeholder,
	formatOptionLabel,
}: Props<T>) {
	function handleChange(item: ValueType<OptionItem<T>, false>) {
		if (item) {
			onChange?.(item.value);
		}
	}

	const selectedItem = items.find(item => item.value === value);

	return (
		<ReactSelect
			className={cn("ui-kit-select", className)}
			classNamePrefix="select"
			value={selectedItem}
			isDisabled={disabled}
			options={items}
			onChange={handleChange}
			placeholder={placeholder ?? "Не выбрано"}
			formatOptionLabel={formatOptionLabel}
		/>
	);
}
