import React from "react";

import { VolunteerAttributes } from "../../../../constants/volunteer-attributes";
import { SchemaFormFieldRow } from "@app-components/schema-form";
import { SchemaForm } from "@app-models/schema-form";

interface Props {
	form: SchemaForm;
}

export const InterviewSection = ({ form }: Props) => {
	return (
		<div className="scope-card-content__row">
			<div className="scope-card-content__column">
				<div className="scope-card-heading">Информация о собеседовании</div>
				<div>
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.InterviewDate)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.Interviewer)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.DateAndResultsOfTheCall)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.WhereFromDidYouKnowAboutUs)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.WhereDidKnow)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.DateOfQuestionnaireAdded)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.DateOfQuestionnaireUpdated)!} />
				</div>
			</div>
		</div>
	);
};
