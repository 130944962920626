import React from "react";
import { observer } from "mobx-react";
import { IFilterItem, IFilters } from "@app-types/data";
import { FilterType } from "@app-constants/filter-type";
import { Select } from "@app-ui";
import { SearchTextbox } from "@app-components/search-textbox";

interface Props {
	filters: IFilters;
	onApply: (id: string, value: any) => void;
}

function FilterComponent({ filter, onApply }: { filter: IFilterItem; onApply: (id: string, value: any) => void }) {
	function handleFilterChange(value: any) {
		onApply(filter.name, value);
	}

	switch (filter.type) {
		case FilterType.Select:
			return (
				<Select
					key={filter.name}
					items={filter.options}
					value={filter.value || ""}
					disabled={filter.options.length <= 1}
					onChange={handleFilterChange}
				/>
			);
		case FilterType.Search:
			return (
				<SearchTextbox
					onChange={handleFilterChange}
					value={filter.value as string}
					className="filter-search-input"
					placeholder={filter.label}
				/>
			);
		default:
			return null;
	}
}

export const FilterSidebar = observer(({ filters, onApply }: Props) => {
	const attrFilter = filters.items.find(filter => filter.name === "attr");
	const valueFilter = filters.items.find(filter => filter.name === "value");

	function handleFilterChange(filterName: string) {
		return function(value: any) {
			onApply(filterName, value);
		};
	}

	return (
		<div className="filter-sidebar">
			<div className="filter-sidebar__header">Фильтры</div>
			{attrFilter && (
				<div className="filter-sidebar__item">
					<Select
						items={attrFilter.options}
						value={attrFilter.value || ""}
						disabled={attrFilter.options.length <= 1}
						onChange={handleFilterChange(attrFilter.name)}
					/>
				</div>
			)}
			{valueFilter && (
				<div className="filter-sidebar_item">
					<FilterComponent filter={valueFilter} onApply={onApply} />
				</div>
			)}
		</div>
	);
});
