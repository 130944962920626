import React from "react";
import { SvgIcon } from "@app-types/models";

export function MortarBoard({ className }: SvgIcon) {
	return (
		<svg viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
			<g>
				<path
					d="M30.396 9.48356C30.7585 9.35472 31.0004 9.01148 31 8.62676C30.9995 8.24205 30.7567 7.89941 30.394 7.77142L15.8022 2.62306C15.6067 2.55403 15.3934 2.55403 15.1979 2.62306L0.606075 7.77142C0.243278 7.89935 0.000485101 8.24205 7.25777e-07 8.62676C-0.000483649 9.01148 0.241522 9.35472 0.604016 9.48356L6.38437 11.5385V17.4629C6.38437 18.4406 7.4044 19.2675 9.41613 19.9205C11.1696 20.4896 13.3303 20.803 15.5001 20.803C17.6698 20.803 19.8304 20.4896 21.584 19.9205C23.5957 19.2675 24.6158 18.4407 24.6158 17.4629V11.5385L27.0023 10.6901V18.8993C25.941 19.2745 25.1784 20.2874 25.1784 21.4757C25.1784 22.6638 25.941 23.6768 27.0023 24.052V27.5205C27.0023 28.022 27.409 28.4287 27.9105 28.4287C28.4121 28.4287 28.8187 28.022 28.8187 27.5205V24.052C29.8801 23.6768 30.6427 22.6639 30.6427 21.4757C30.6427 20.2875 29.8801 19.2745 28.8187 18.8993V10.0443L30.396 9.48356ZM27.9105 22.3914C27.4055 22.3914 26.9947 21.9806 26.9947 21.4756C26.9947 20.9706 27.4055 20.5598 27.9105 20.5598C28.4154 20.5598 28.8262 20.9706 28.8262 21.4756C28.8262 21.9806 28.4154 22.3914 27.9105 22.3914ZM15.5 4.44255L27.3714 8.63112L15.5 12.8514L3.62864 8.63106L15.5 4.44255ZM22.7993 17.326C22.6252 17.5014 22.0021 17.9217 20.5956 18.3229C19.0952 18.7509 17.2855 18.9866 15.5 18.9866C13.7145 18.9866 11.9048 18.7509 10.4044 18.3229C8.99793 17.9217 8.37478 17.5015 8.20071 17.326V12.1843L15.1958 14.671C15.2941 14.706 15.3971 14.7235 15.4999 14.7235C15.6029 14.7235 15.7057 14.706 15.8041 14.671L22.7992 12.1843V17.326H22.7993Z"
					fill="currentColor"
				/>
			</g>
		</svg>
	);
}
