import React from "react";
import { SvgIcon } from "@app-types/models";

export default function({ className }: SvgIcon) {
	return (
		<svg
			width="24"
			height="30"
			viewBox="0 0 24 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M5.56824 11.7998C5.51544 11.7998 5.46792 11.7813 5.42568 11.7444C5.38872 11.7021 5.37024 11.6546 5.37024 11.6018V6.4538C5.37024 6.39572 5.38872 6.3482 5.42568 6.31124C5.46792 6.27428 5.51544 6.2558 5.56824 6.2558H7.78584C8.46168 6.2558 8.99232 6.41156 9.37776 6.72308C9.76848 7.0346 9.96384 7.48868 9.96384 8.08532C9.96384 8.67668 9.77112 9.12284 9.38568 9.4238C9.00024 9.71948 8.46696 9.86732 7.78584 9.86732H6.84336V11.6018C6.84336 11.6599 6.82224 11.7074 6.78 11.7444C6.74304 11.7813 6.69552 11.7998 6.63744 11.7998H5.56824ZM7.74624 8.77436C7.97856 8.77436 8.16072 8.71628 8.29272 8.60012C8.42472 8.47868 8.49072 8.3018 8.49072 8.06948C8.49072 7.853 8.42736 7.6814 8.30064 7.55468C8.1792 7.42268 7.9944 7.35668 7.74624 7.35668H6.8196V8.77436H7.74624Z"
				fill="#EE5D5D"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M23.7901 7.59986C23.7845 7.42425 23.7105 7.25292 23.5738 7.12361L16.2573 0.205723L16.2565 0.20502C16.122 0.07834 15.9367 0 15.7321 0H1.56399C0.701559 0 0 0.663283 0 1.47873V28.5213C0 29.3367 0.701497 30 1.56393 30H22.2274C23.0897 30 23.7913 29.3367 23.7913 28.5213V7.62025C23.7914 7.61524 23.791 7.61036 23.7905 7.60546C23.7904 7.6036 23.7902 7.60173 23.7901 7.59986ZM16.475 2.39831L21.255 6.91794H16.7885C16.6156 6.91794 16.475 6.78499 16.475 6.62152V2.39831ZM22.2274 28.5952C22.2705 28.5952 22.3056 28.562 22.3056 28.5214H22.3057V8.32279H16.7885C15.7963 8.32279 14.9892 7.55955 14.9892 6.62152V1.40485H1.56399C1.52092 1.40485 1.48584 1.43801 1.48584 1.47873V28.5213C1.48584 28.562 1.52092 28.5952 1.56399 28.5952H22.2274Z"
				fill="#EE5D5D"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.7827 21.6001H5.00879V22.8001H18.7827V21.6001ZM18.7827 24.0001H5.00879V25.2001H18.7827V24.0001Z"
				fill="#EE5D5D"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.3098 16.6061H16.2676C16.2676 18.7001 14.4949 20.3989 12.3098 20.3989V16.6061ZM13.0632 19.5897C14.2253 19.3211 15.1411 18.4418 15.4232 17.328H13.0632V19.5897Z"
				fill="#EE5D5D"
			/>
			<path
				d="M7.51306 15.8021C7.51306 17.8979 9.28574 19.5967 11.4708 19.5967V15.8038H15.4285C15.4285 13.7081 13.6559 12.011 11.4708 12.011C9.28574 12.011 7.51306 13.7081 7.51306 15.8021Z"
				fill="#EE5D5D"
			/>
		</svg>
	);
}
