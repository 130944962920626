export enum ReferenceScopeCode {
	Alco = "REG012",
	Homeless = "REG07",
	Hospitals = "REG5",
	HospitalsPriests = "REG501",
	MaterialAid = "REG08",
	HotLineOfMoscowDiocese = "REG02",
	StateInstitutions = "REG4",
	Contacts = "LREG1",
	ContactsInDiocese = "REG01",
	PrivateContactsInDiocese = "LREG4",
	WomenCrisisCenters = "REG06",
	ManualsAndDocuments = "REG010",
	PilgrimageHotels = "REG03",
	Projects = "REG9",
	PsychologicalHelp = "REG013",
	Priests = "LREG2",
	SocialInstitutions = "REG3",
	HomelessHandbook = "REG10",
	SocialWorkerHandbook = "REG09",
	Helpline = "REG04",
	Foundations = "REG05",
	TemplesAndMonasteries = "REG2",
	LegalAid = "REG014",
	Lawyers = "LREG3",
}

export enum ReferenceScopeId {
	AddressBook = 11027,
	Alco = 9995,
	Homeless = 8285,
	Hospitals = 8165,
	HospitalsPriests = 9676,
	MaterialAid = 8284,
	HotLineOfMoscowDiocese = 8288,
	StateInstitutions = 7823,
	ContactsInDiocese = 8291,
	ContactsMercy = 9940,
	PrivateContactsInDiocese = 9991,
	WomenCrisisCenters = 8286,
	ManualsAndDocuments = 9900,
	PilgrimageHotels = 8280,
	Projects = 8293,
	PsychologicalHelp = 9996,
	Priests = 9908,
	SocialInstitutions = 7822,
	HomelessHandbook = 8282,
	SocialWorkerHandbook = 8283,
	Helpline = 8287,
	Foundations = 8279,
	TemplesAndMonasteries = 9379,
	TemplesAndMonasteriesMO = 9990,
	TemplesAndMonasteriesMoscow = 7818,
	LegalAid = 9997,
	Lawyers = 9902,
}

export type ReferenceScopeColumns = Array<{
	caption: string;
	source: number | number[] | "@name";
	type?: "all" | "first";
	width?: number;
}>;

export const referenceScopeColumnsByCode: Map<ReferenceScopeId, ReferenceScopeColumns> = new Map([
	[
		ReferenceScopeId.AddressBook,
		[
			{
				caption: "Сан, ФИО",
				source: 25476,
			},
			{
				caption: "Зона ответственности",
				source: 25479,
			},
			{
				caption: "Телефон, Email",
				source: [25480, 25481],
			},
		],
	],
	[
		ReferenceScopeId.Alco,
		[
			{
				caption: "Название",
				source: "@name",
			},
			{
				caption: "Направление деятельности",
				source: 25310,
			},
			{
				caption: "Населенный пункт, Адрес",
				source: [25465, 25468],
			},
			{
				caption: "Телефон",
				source: 25471,
			},
		],
	],
	[
		ReferenceScopeId.Homeless,
		[
			{
				caption: "Название организации",
				source: 24469,
			},
			{
				caption: "Субъект РФ",
				source: 24470,
			},
			{
				caption: "Населенный пункт, Адрес",
				source: [25237, 24474],
			},
			{
				caption: "Телефон",
				source: [25241],
			},
		],
	],
	[
		ReferenceScopeId.Hospitals,
		[
			{
				caption: "Название больницы",
				source: 22323,
			},
			{
				caption: "Благочиние",
				source: 25243,
			},
			{
				caption: "Приписной храм",
				source: 25244,
			},
		],
	],
	[
		ReferenceScopeId.HospitalsPriests,
		[
			{
				caption: "Сан, ФИО",
				source: 25215,
			},
			{
				caption: "Телефон",
				source: 25216,
			},
			{
				caption: "Email",
				source: 25217,
			},
		],
	],
	[
		ReferenceScopeId.MaterialAid,
		[
			{
				caption: "Название организации",
				source: 24458,
			},
			{
				caption: "Субъект РФ",
				source: 24459,
			},
			{
				caption: "Населенный пункт, Адрес",
				source: [25246, 25247],
			},
			{
				caption: "Тип помощи",
				source: [25245],
			},
		],
	],
	[
		ReferenceScopeId.HotLineOfMoscowDiocese,
		[
			{
				caption: "Благочиние",
				source: "@name",
			},
			{
				caption: "Телефон",
				source: [25248, 24483, 24484],
				type: "first",
			},
		],
	],
	[
		ReferenceScopeId.StateInstitutions,
		[
			{
				caption: "Название организации",
				source: 22585,
			},
			{
				caption: "Субъект РФ",
				source: 22594,
			},
			{
				caption: "Населенный пункт, Адрес",
				source: [22596, 22600],
			},
			{
				caption: "Телефон",
				source: 22601,
			},
		],
	],
	[
		ReferenceScopeId.ContactsMercy,
		[
			{
				caption: "Сан, ФИО",
				source: 25476,
			},
			{
				caption: "Зона ответственности",
				source: 25479,
			},
			{
				caption: "Телефон, Email",
				source: [25480, 25481],
			},
		],
	],
	[
		ReferenceScopeId.ContactsInDiocese,
		[
			{
				caption: "Епархия",
				source: 24348,
			},
			{
				caption: "Номер церковно-социальной помощи",
				source: 24351,
			},
			{
				caption: "Куратор",
				source: 24352,
			},
		],
	],
	[
		ReferenceScopeId.PrivateContactsInDiocese,
		[
			{
				caption: "Епархия",
				source: 25452,
			},
			{
				caption: "Номер церковно-социальной помощи",
				source: 25454,
			},
			{
				caption: "Куратор",
				source: 25455,
			},
		],
	],
	[
		ReferenceScopeId.WomenCrisisCenters,
		[
			{
				caption: "Название организации",
				source: 24444,
			},
			{
				caption: "Субъект РФ",
				source: 24445,
			},
			{
				caption: "Населенный пункт, Адрес",
				source: [25249, 24448],
			},
			{
				caption: "Епархия",
				source: 24457,
			},
		],
	],
	[
		ReferenceScopeId.ManualsAndDocuments,
		[
			{
				caption: "Название",
				source: "@name",
			},
		],
	],
	[
		ReferenceScopeId.PilgrimageHotels,
		[
			{
				caption: "Название организации",
				source: 24414,
			},
			{
				caption: "Субъект РФ",
				source: 24415,
			},
			{
				caption: "Населенный пункт, Адрес",
				source: [25427, 24417],
			},
		],
	],
	[
		ReferenceScopeId.Projects,
		[
			{
				caption: "Название проекта",
				source: 24486,
			},
			{
				caption: "Тип проекта",
				source: 25446,
			},
			{
				caption: "Адрес",
				source: 24488,
			},
			{
				caption: "Телефон",
				source: 25447,
			},
		],
	],
	[
		ReferenceScopeId.PsychologicalHelp,
		[
			{
				caption: "Название организации",
				source: 25408,
			},
			{
				caption: "Направление деятельности",
				source: 25409,
			},
			{
				caption: "Населенный пункт, Адрес",
				source: [25411, 25412],
			},
			{
				caption: "Телефон, Часы работы",
				source: [25470, 25417],
			},
		],
	],
	[
		ReferenceScopeId.Priests,
		[
			{
				caption: "Сан, ФИО",
				source: 25279,
			},
			{
				caption: "Телефон (мобильный)",
				source: 25290,
			},
			{
				caption: "Приоритет направления звонков, Расписание",
				source: [25288, 25295],
			},
			{
				caption: "Комментарии",
				source: 25449,
			},
		],
	],
	[
		ReferenceScopeId.SocialInstitutions,
		[
			{
				caption: "Название организации",
				source: 22522,
			},
			{
				caption: "Субъект РФ",
				source: 22531,
			},
			{
				caption: "Населенный пункт, Адрес",
				source: [22533, 22536],
			},
			{
				caption: "Телефон",
				source: 22537,
			},
		],
	],
	[
		ReferenceScopeId.HomelessHandbook,
		[
			{
				caption: "Название организации",
				source: 24526,
			},
			{
				caption: "Субъект РФ",
				source: 24536,
			},
			{
				caption: "Направление деятельности",
				source: 24535,
			},
		],
	],
	//
	[
		ReferenceScopeId.SocialWorkerHandbook,
		[
			{
				caption: "Название организации",
				source: 24494,
			},
			{
				caption: "Субъект РФ",
				source: 24495,
			},
			{
				caption: "Населенный пункт",
				source: 25460,
			},
		],
	],
	[
		ReferenceScopeId.Helpline,
		[
			{
				caption: "Название организации",
				source: 24424,
			},
			{
				caption: "Направление деятельности",
				source: 24430,
			},
			{
				caption: "Телефон",
				source: 25437,
			},
		],
	],
	[
		ReferenceScopeId.Foundations,
		[
			{
				caption: "Название организации",
				source: 24434,
			},
			{
				caption: "Направление деятельности",
				source: 25440,
			},
			{
				caption: "Населенный пункт, Адрес",
				source: [25441, 24438],
			},
			{
				caption: "Телефон, Email",
				source: [25444, 25445],
				width: 20,
			},
		],
	],
	[
		ReferenceScopeId.TemplesAndMonasteries,
		[
			{
				caption: "Название храма",
				source: "@name",
			},
			{
				caption: "Субъект РФ",
				source: [22177, 25199],
			},
			{
				caption: "Населенный пункт, Адрес",
				source: [22179, 22181, 25203, 25205],
			},
			{
				caption: "Епархия",
				source: [22173, 25198],
			},
		],
	],
	[
		ReferenceScopeId.TemplesAndMonasteriesMO,
		[
			{
				caption: "Название храма",
				source: "@name",
			},
			{
				caption: "Благочиние",
				source: [22175],
			},
			{
				caption: "Населенный пункт, Адрес",
				source: [22179, 22181],
			},
		],
	],
	[
		ReferenceScopeId.TemplesAndMonasteriesMoscow,
		[
			{
				caption: "Название храма",
				source: "@name",
			},
			{
				caption: "Благочиние",
				source: [22175],
			},
			{
				caption: "Адрес",
				source: [22181],
			},
		],
	],
	[
		ReferenceScopeId.LegalAid,
		[
			{
				caption: "Название организации",
				source: 25393,
			},
			{
				caption: "Направление деятельности",
				source: 25394,
			},
			{
				caption: "Населенный пункт, Адрес",
				source: [25396, 25397],
			},
			{
				caption: "Телефон, Часы работы, Тип приема",
				source: [25469, 25403, 25401],
			},
		],
	],
	[
		ReferenceScopeId.Lawyers,
		[
			{
				caption: "ФИО",
				source: 25298,
			},
			{
				caption: "Направление деятельности",
				source: 25450,
			},
			{
				caption: "Вид консультаций",
				source: 25303,
			},
			{
				caption: "Расписание",
				source: 25309,
			},
		],
	],
]);
