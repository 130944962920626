import React from "react";
import cn from "classnames";

import { Button } from "@app-components/buttons/button";
import { WardForm } from "../../stores/models/ward-form";
import { SchemaFormFieldRow } from "@app-components/schema-form";
import { WardAttributes } from "../../constants/ward-attributes";

interface Props {
	form: WardForm;
	fields: WardAttributes[];
	onClose: () => void;
}

export const AdditionalFormFieldsDialog = ({ form, fields, onClose }: Props) => {
	function handleSave() {
		onClose();
	}

	return (
		<div className="additional-form-fields-dialog">
			<div className="additional-form-fields-dialog__header">Добавление информации</div>
			<div className="additional-form-fields-dialog__items">
				<div className="form">
					{fields.map(fieldId => {
						const field = form.getField(fieldId);

						if (field && !field.value) {
							return <SchemaFormFieldRow field={field} key={fieldId} />;
						}

						return null;
					})}
				</div>
			</div>
			<div className="additional-form-fields-dialog__actions">
				<Button className={cn("button button-light")} onClick={handleSave}>
					Добавить
				</Button>
			</div>
		</div>
	);
};
