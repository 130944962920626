import React from "react";
import { Ward } from "../../../../stores/wards-store";
import { AttrFormRowInfo } from "./base-info-section";
import { WardAttributes } from "../../../../constants/ward-attributes";

interface Props {
	entry: Ward;
}

export const HealthStatusSection = ({ entry }: Props) => {
	return (
		<>
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.DisabilityCategory)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.DisabilityCategoryLevel)} scope={entry.scope} />
			<AttrFormRowInfo
				attr={entry.getAttr(WardAttributes.DisabilityInformationAndAppointmentReason)}
				scope={entry.scope}
			/>
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.MentalDisorders)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.HasSocialWorker)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.SocialWorkerFrequency)} scope={entry.scope} />
		</>
	);
};
