import { action, observable } from "mobx";

import { FieldParameters, IField, IForm, IValidator } from ".";

export class Field implements IField {
	public label: string;
	public name: string;
	@observable public value?: string;
	@observable public isValid: boolean = true;
	@observable public validationMessage?: string;

	private validators?: IValidator[];

	public constructor({ name, value, label, validators }: FieldParameters, private readonly form: IForm) {
		this.name = name;
		this.label = label;
		this.validators = validators;
		this.value = value;
	}

	@action
	public updateValue(value: string) {
		this.value = value;
		this.validate();
	}

	@action
	public validate() {
		if (!this.validators) {
			return true;
		}

		for (const validator of this.validators) {
			const [success, message] = validator(this, this.form);

			if (!success) {
				this.isValid = false;
				this.validationMessage = message;
				return;
			}
		}

		this.isValid = true;
		this.validationMessage = undefined;
	}
}
