import React from "react";
import { SvgIcon } from "@app-types/models";

export function Pray({ className }: SvgIcon) {
	return (
		<svg viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
			<g clipPath="url(#clip0)">
				<g clipPath="url(#clip1)">
					<path
						d="M22.8634 20.1776V14.522C22.8634 14.4308 22.8467 14.3404 22.8141 14.2553L19.7332 6.21569C19.4031 5.35945 18.5646 4.78418 17.6468 4.78418C17.0746 4.78418 16.5521 5.0005 16.1563 5.35528C15.7604 5.0005 15.238 4.78418 14.6658 4.78418C13.748 4.78418 12.9094 5.35945 12.5787 6.21713L9.49846 14.2553C9.46587 14.3404 9.44913 14.4308 9.44913 14.522V20.1776L5.19306 24.481C4.98161 24.6947 4.91936 25.0146 5.03522 25.2921C5.15103 25.5695 5.42225 25.7502 5.72293 25.7502H13.1753C13.4141 25.7502 13.6384 25.6358 13.7786 25.4425C14.8254 23.999 15.6271 22.3772 16.1563 20.6664C16.6854 22.3772 17.4871 23.999 18.5339 25.4425C18.6741 25.6358 18.8984 25.7502 19.1372 25.7502H26.5896C26.8903 25.7502 27.1615 25.5695 27.2773 25.2921C27.3932 25.0146 27.3309 24.6947 27.1195 24.481L22.8634 20.1776ZM12.7891 24.2598H7.50812L10.7242 21.0079C10.8622 20.8684 10.9396 20.6801 10.9396 20.4839V14.6599L13.97 6.75196C14.08 6.46643 14.3597 6.27466 14.6658 6.27466C15.0767 6.27466 15.411 6.60897 15.411 7.0199V12.4139C15.1779 12.3313 14.927 12.2863 14.6658 12.2863C13.433 12.2863 12.4301 13.2892 12.4301 14.522V18.2482C12.4301 18.6597 12.7638 18.9934 13.1753 18.9934C13.5869 18.9934 13.9206 18.6597 13.9206 18.2482V14.522C13.9206 14.111 14.2549 13.7767 14.6658 13.7767C15.0767 13.7767 15.411 14.111 15.411 14.522V15.7673C15.411 18.791 14.4825 21.7913 12.7891 24.2598ZM19.5235 24.2598C17.8301 21.7913 16.9015 18.791 16.9015 15.7673V14.522C16.9015 14.111 17.2358 13.7767 17.6468 13.7767C18.0577 13.7767 18.392 14.111 18.392 14.522V18.2482C18.392 18.6597 18.7257 18.9934 19.1372 18.9934C19.5488 18.9934 19.8825 18.6597 19.8825 18.2482V14.522C19.8825 13.2892 18.8795 12.2863 17.6468 12.2863C17.3855 12.2863 17.1348 12.3317 16.9015 12.4144V7.0199C16.9015 6.60897 17.2358 6.27466 17.6468 6.27466C17.9529 6.27466 18.2325 6.46643 18.342 6.75052L21.373 14.6599V20.4839C21.373 20.6801 21.4504 20.8684 21.5883 21.0079L24.8044 24.2598H19.5235Z"
						fill="currentColor"
					/>
					<path
						d="M16.9015 2.54822V1.05774C16.9015 0.646168 16.5678 0.3125 16.1563 0.3125C15.7447 0.3125 15.411 0.646168 15.411 1.05774V2.54822C15.411 2.95979 15.7447 3.29346 16.1563 3.29346C16.5678 3.29346 16.9015 2.95984 16.9015 2.54822Z"
						fill="currentColor"
					/>
					<path
						d="M7.21338 9.30469C6.80181 9.30469 6.46814 9.63836 6.46814 10.0499C6.46814 10.4615 6.80181 10.7952 7.21338 10.7952H8.70386C9.11543 10.7952 9.4491 10.4615 9.4491 10.0499C9.4491 9.63836 9.11543 9.30469 8.70386 9.30469H7.21338Z"
						fill="currentColor"
					/>
					<path
						d="M23.6086 10.7952H25.0991C25.5107 10.7952 25.8444 10.4615 25.8444 10.0499C25.8444 9.63836 25.5107 9.30469 25.0991 9.30469H23.6086C23.1971 9.30469 22.8634 9.63836 22.8634 10.0499C22.8634 10.4615 23.1971 10.7952 23.6086 10.7952Z"
						fill="currentColor"
					/>
					<path
						d="M10.3622 5.26034C10.6532 5.55138 11.1251 5.55143 11.4162 5.26034C11.7072 4.9693 11.7072 4.49746 11.4162 4.20642L10.3597 3.14992C10.0687 2.85888 9.59678 2.85888 9.30574 3.14992C9.0147 3.44096 9.0147 3.9128 9.30574 4.20384L10.3622 5.26034Z"
						fill="currentColor"
					/>
					<path
						d="M21.9529 3.14992L20.8964 4.20642C20.6053 4.49746 20.6053 4.9693 20.8964 5.26034C21.1874 5.55133 21.6593 5.55138 21.9503 5.26034L23.0068 4.20384C23.2978 3.9128 23.2978 3.44096 23.0068 3.14992C22.7158 2.85888 22.2439 2.85888 21.9529 3.14992Z"
						fill="currentColor"
					/>
				</g>
			</g>
			<defs>
				<clipPath id="clip0">
					<rect width="25.4375" height="25.4375" fill="#fff" transform="translate(3.4375 0.3125)" />
				</clipPath>
				<clipPath id="clip1">
					<rect width="25.4375" height="25.4375" fill="#fff" transform="translate(3.4375 0.3125)" />
				</clipPath>
			</defs>
		</svg>
	);
}
