import { action, computed, observable, runInAction } from "mobx";

import { WardSectionId } from "./ward-sections";
import { SchemaForm } from "../../../../../src/models/schema-form";
import { actions } from "../../../../../src/actions";
import { getWardScopeName } from "../../helpers/ward-helper";
import { Scope } from "../../constants/scope";
import { ScopeAppType } from "../../constants/scope-app-type";
import { IWard } from "./ward-info";
import { IUploadFile } from "../../../../../src/types/models";

export class EmptyWardInfo implements IWard {
	@observable public currentSectionId: WardSectionId = WardSectionId.BaseInfo;
	@observable public form: SchemaForm | null = null;
	@observable public fetching: boolean = false;
	@observable file: IUploadFile | null = null;

	@action
	public updateCurrentSection(sectionId: number) {
		this.currentSectionId = sectionId;
	}

	@computed
	public get photoUrl() {
		return this.file !== null ? window.URL.createObjectURL(this.file) : null;
	}

	@action
	public uploadPhoto = (files: IUploadFile[]) => {
		if (files.length > 0) {
			this.file = files[0];
		}
	};

	@action
	public deletePhoto = () => {
		this.file = null;
	};

	@action
	public async save() {
		if (this.form!.validate()) {
			try {
				runInAction(() => {
					this.fetching = true;
				});

				const attrs = this.form!.values.map(x => ({ attr: x.id, value: x.value }));
				const name = getWardScopeName(this.form!);
				const scope = await actions.createScope(Scope.Wards, ScopeAppType.Ward, {
					name,
					attrs,
				});

				if (this.file !== null) {
					await actions.uploadScopeFile(scope.id, this.file);
				}

				return scope;
			} finally {
				runInAction(() => {
					this.fetching = false;
				});
			}
		}

		return null;
	}

	public async fetch() {
		const typeSchema = await actions.getScopeType(Scope.Wards, ScopeAppType.Ward);
		const form = new SchemaForm(typeSchema.attrs);

		runInAction(() => {
			this.form = form;
		});
	}
}
