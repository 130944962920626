import React from "react";
import { SvgIcon } from "@app-types/models";

export default function({ className }: SvgIcon) {
	return (
		<svg viewBox="0 0 12 7" fill="currentColor" className={className}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.6649 0.328989C11.2182 -0.109663 10.4941 -0.109663 10.0456 0.328989L5.99914 4.2944L1.95264 0.328989C1.50588 -0.109663 0.781827 -0.109663 0.335069 0.328989C-0.11169 0.76764 -0.11169 1.4765 0.335069 1.91515L5.19121 6.67364C5.63797 7.10879 6.36203 7.10879 6.80879 6.67364L11.6649 1.91515C12.1117 1.4765 12.1117 0.76764 11.6649 0.328989Z"
			/>
		</svg>
	);
}
