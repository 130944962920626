import { SvgIcon } from "@app-types/models";
import React from "react";

export default function({ className }: SvgIcon) {
	return (
		<svg className={className} viewBox="0 0 23 30" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M22.7988 7.59986C22.7935 7.42425 22.7226 7.25292 22.5915 7.12361L15.5799 0.205723L15.5791 0.20502C15.4503 0.07834 15.2727 0 15.0766 0H1.49883C0.672328 0 0 0.663283 0 1.47873V28.5213C0 29.3367 0.672268 30 1.49877 30H21.3012C22.1277 30 22.8 29.3367 22.8 28.5213V7.62025C22.8001 7.61523 22.7997 7.61034 22.7992 7.60543C22.7991 7.60358 22.7989 7.60172 22.7988 7.59986ZM15.7885 2.39831L20.3694 6.91794H16.0889C15.9233 6.91794 15.7885 6.78499 15.7885 6.62152V2.39831ZM21.3013 28.5952C21.3426 28.5952 21.3762 28.562 21.3762 28.5214H21.3763V8.32279H16.0889C15.1382 8.32279 14.3646 7.55955 14.3646 6.62152V1.40485H1.49883C1.45755 1.40485 1.42394 1.43801 1.42394 1.47873V28.5213C1.42394 28.562 1.45755 28.5952 1.49883 28.5952H21.3013Z"
				fill="#918D8D"
			/>
			<path
				d="M10.5577 17.12C10.5044 17.12 10.4577 17.1 10.4177 17.06C10.3777 17.02 10.3577 16.9733 10.3577 16.92V16.6C10.3911 16.2867 10.4877 16.0133 10.6477 15.78C10.8144 15.54 11.0477 15.2667 11.3477 14.96C11.5677 14.7467 11.7344 14.5633 11.8477 14.41C11.9611 14.2567 12.0277 14.1 12.0477 13.94C12.0744 13.6667 11.9844 13.45 11.7777 13.29C11.5711 13.1233 11.3211 13.04 11.0277 13.04C10.3744 13.04 9.98107 13.3633 9.84773 14.01C9.80107 14.1633 9.7044 14.24 9.55773 14.24H8.70773C8.64107 14.24 8.58773 14.22 8.54773 14.18C8.50773 14.1333 8.48773 14.0733 8.48773 14C8.50107 13.6333 8.61107 13.29 8.81773 12.97C9.0244 12.65 9.3244 12.3933 9.71773 12.2C10.1111 12 10.5711 11.9 11.0977 11.9C11.6511 11.9 12.1044 11.99 12.4577 12.17C12.8177 12.3433 13.0777 12.5667 13.2377 12.84C13.4044 13.1133 13.4877 13.3967 13.4877 13.69C13.4877 14.03 13.4111 14.3233 13.2577 14.57C13.1111 14.81 12.8877 15.09 12.5877 15.41C12.3744 15.6433 12.2077 15.8433 12.0877 16.01C11.9744 16.17 11.8977 16.3433 11.8577 16.53C11.8311 16.6967 11.8144 16.81 11.8077 16.87C11.7544 17.0367 11.6644 17.12 11.5377 17.12H10.5577ZM10.5277 19C10.4611 19 10.4044 18.9767 10.3577 18.93C10.3111 18.8833 10.2877 18.8267 10.2877 18.76V17.89C10.2877 17.8233 10.3111 17.7667 10.3577 17.72C10.4044 17.6667 10.4611 17.64 10.5277 17.64H11.4977C11.5711 17.64 11.6311 17.6633 11.6777 17.71C11.7244 17.7567 11.7477 17.8167 11.7477 17.89V18.76C11.7477 18.8267 11.7244 18.8833 11.6777 18.93C11.6311 18.9767 11.5711 19 11.4977 19H10.5277Z"
				fill="#918D8D"
			/>
		</svg>
	);
}
