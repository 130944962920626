import React from "react";
import { observer } from "mobx-react-lite";
import { AvatarIcon } from "@icons";
import { XUserProfile } from "@external-types/user";

const avatarVersion = Date.now();

interface Props {
	user: XUserProfile | null;
}

export const ProfileAvatar = observer(function({ user }: Props) {
	const hasAvatar = user?.has_avatar ?? false;

	return !hasAvatar ? (
		<div className="header-profile__avatar header-profile__avatar--default">
			<AvatarIcon />
		</div>
	) : (
		<img className="header-profile__avatar" src={`/api/profile/avatar.png?=${avatarVersion}`} />
	);
});
