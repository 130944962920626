import React from "react";
import { SvgIcon } from "@app-types/models";

export default function({ className }: SvgIcon) {
	return (
		<svg className={className} viewBox="0 0 31 13" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
			<path
				className="arrow-icon__edge"
				fillRule="evenodd"
				clipRule="evenodd"
				fill="currentColor"
				d="M7.17101 0.835069C7.60966 1.28183 7.60966 2.00588 7.17101 2.45435L3.2056 6.50086L7.17101 10.5474C7.60966 10.9941 7.60966 11.7182 7.17101 12.1649C6.73236 12.6117 6.0235 12.6117 5.58485 12.1649L0.826357 7.30879C0.391215 6.86203 0.391215 6.13797 0.826357 5.69121L5.58485 0.835069C6.0235 0.38831 6.73236 0.38831 7.17101 0.835069Z"
			/>
			<path
				className="arrow-icon__line"
				d="M4 6.5H29.0293"
				strokeWidth="2"
				strokeLinecap="round"
				stroke="currentColor"
			/>
		</svg>
	);
}
