export enum WardSectionId {
	BaseInfo = 0,
	Contacts = 1,
	HealthStatus = 2,
	Family = 3,
	Churchness = 4,
	Description = 5,
	Projects = 6,
}

interface WardSection {
	title: string;
	id: WardSectionId;
}

export const WardSections: WardSection[] = [
	{ id: WardSectionId.BaseInfo, title: "Общая информация" },
	{ id: WardSectionId.Contacts, title: "Контакты" },
	{ id: WardSectionId.HealthStatus, title: "Состояние здоровья" },
	{ id: WardSectionId.Family, title: "Семья и окружение" },
	{ id: WardSectionId.Churchness, title: "Воцерковленность" },
	{ id: WardSectionId.Description, title: "Характеристика человека и ситуации" },
	{ id: WardSectionId.Projects, title: "Проекты и добровольцы" },
];
