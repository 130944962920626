export enum ProcessFieldValue {
	PhoneCallId = 5986, // Идентификатор звонка
	PhoneCallSource = 5987, // Номер входящего звонка
	PhoneCallDestination = 5988, //Номер на который совершен звонок

	CallType = 5989, // Тип обращения
	AnswerMessage = 5992, // Текст ответа
	RequestMessage = 4438, // Текст просьбы // TODO: удалить?
	Eparchy = 6007, // Епархия
	CallTopic = 5990, // Тема звонка
	RequestTopic = 5997, // Тема просьбы
	AppealMessage = 5991, // Текст обращения
	HasRelatedRequest = 5998, // Есть ли связанная просьба
	UrgentRequest = 5996, // Срочная просьба,
	ChooseLawyer = 5993, // Юрист
	LawyerDate = 5994, // Дата консультации
	LawyerTime = 5995, // Время консультации
	AddressFrom = 5999, // Адрес (откуда)
	AddressTo = 6000, // Адрес (куда)
	Date = 6001, // Дата
	Time = 6002, // Время
}
