import React from "react";

import { XScopeAttrRef, XScopeChildInfo } from "@external-types/scope";
import { getAttributeValue } from "@helpers/scope-attributes";

import { Ward } from "../../../../stores/wards-store";
import { WardAttributes } from "../../../../constants/ward-attributes";
import { UserPhoto } from "../../../components/user-photo";

interface Props {
	entry: Ward;
}

export function AttrFormRowInfo({
	attr,
	label,
	scope,
}: {
	attr?: XScopeAttrRef;
	label?: string;
	scope: XScopeChildInfo;
}) {
	if (!attr || !attr.value) {
		return null;
	}

	return (
		<div className="form__row">
			<div className="form__label">{label ?? attr.name}</div>
			<div className="form__value">{getAttributeValue(attr, scope.type)}</div>
		</div>
	);
}

export const BaseInfoSection = ({ entry }: Props) => {
	return (
		<>
			{entry.photoUrl && (
				<div className="scope-card-user-photo">
					<UserPhoto fileUrl={entry.photoUrl} />
				</div>
			)}

			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.LastName)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.FirstName)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.MiddleName)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.Birthday)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.Age)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.Gender)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.DistrictOfAssistance)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.RegularityOfAssistance)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.InPrayList)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.ReasonOfInPrayList)} scope={entry.scope} />

			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.Dead)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.DateOfDeath)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.CoordinatorComment)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.CoordinatorAssistant)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.Comments)} scope={entry.scope} />
		</>
	);
};
