import { action, computed, observable, reaction } from "mobx";
import { actions, Proxy } from "@actions";

import { IUserSection } from "@app-types/models";
import { IAppState, IAppStore } from "@app-types/stores";
import { XUserInfo, XUserProfile } from "@external-types/user";
import { firebaseProxy } from "@shared/firebase-proxy";

export class AppStore implements IAppStore {
	@observable private user: XUserProfile | null = null;
	@observable public isAppLoaded = false;
	@observable public userSections: IUserSection[] = [];

	public constructor(private appState: IAppState, private availableSections: IUserSection[]) {
		Proxy.initAuthInteceptor(() => this.setAuth(null));

		reaction(
			() => this.authUser,
			(authUser: XUserProfile | null) => {
				if (authUser === null) {
					this.appState.clean();
					firebaseProxy.dispose();
				} else {
					firebaseProxy.init(authUser.firebase).then(token => actions.updateDeviceId(token!));
				}
			},
		);

		this.loadProfile();
	}

	public async updateProfile(user: Partial<XUserInfo>) {
		const updatedUser = await actions.updateProfile(user);

		this.setAuth({
			...this.user!,
			...updatedUser,
		});
	}

	public async updatePassword(payload: { old: string; new: string }) {
		await actions.updatePassword(payload);
	}

	@action
	public setAuth(authInfo: XUserProfile | null) {
		this.user = authInfo;
		if (authInfo) {
			this.appState.init();
			this.userSections = this.availableSections.filter(section => section.access(authInfo.roles));
		} else {
			this.userSections = [];
		}
	}

	@computed
	public get authUser(): XUserProfile | null {
		return this.user;
	}

	private async loadProfile() {
		try {
			const profile = await actions.profile();

			this.setAuth(profile);
		} catch (e) {
			this.setAuth(null);
			if (e.response && e.response.status === 401) {
				return;
			}
		} finally {
			this.setAppLoaded();
		}
	}

	@action
	private setAppLoaded = () => {
		this.isAppLoaded = true;
	};
}
