import React from "react";
import { SvgIcon } from "@app-types/models";

export function ActiveCall({ className }: SvgIcon) {
	return (
		<svg
			viewBox="0 0 100 100"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<circle opacity="0.7" cx="50" cy="50" r="38" fill="currentColor" />
			<circle opacity="0.5" cx="50" cy="50" r="50" fill="currentColor" />
			<circle cx="50" cy="50" r="25" fill="currentColor" />
			<path
				d="M59.9999 54.9201V57.9201C60.0011 58.1986 59.944 58.4743 59.8324 58.7294C59.7209 58.9846 59.5572 59.2137 59.352 59.402C59.1468 59.5902 58.9045 59.7336 58.6407 59.8228C58.3769 59.912 58.0973 59.9452 57.8199 59.9201C54.7428 59.5857 51.7869 58.5342 49.1899 56.8501C46.7738 55.3148 44.7253 53.2663 43.1899 50.8501C41.4999 48.2413 40.4482 45.2711 40.1199 42.1801C40.0949 41.9036 40.1278 41.6249 40.2164 41.3617C40.3051 41.0986 40.4475 40.8568 40.6347 40.6517C40.8219 40.4466 41.0497 40.2828 41.3037 40.1706C41.5577 40.0584 41.8323 40.0004 42.1099 40.0001H45.1099C45.5952 39.9953 46.0657 40.1672 46.4337 40.4836C46.8017 40.8001 47.042 41.2395 47.1099 41.7201C47.2366 42.6802 47.4714 43.6228 47.8099 44.5301C47.9445 44.888 47.9736 45.277 47.8938 45.651C47.8141 46.0249 47.6288 46.3682 47.3599 46.6401L46.0899 47.9101C47.5135 50.4136 49.5864 52.4865 52.0899 53.9101L53.3599 52.6401C53.6318 52.3712 53.9751 52.1859 54.3491 52.1062C54.723 52.0264 55.112 52.0556 55.4699 52.1901C56.3772 52.5286 57.3199 52.7635 58.2799 52.8901C58.7657 52.9586 59.2093 53.2033 59.5265 53.5776C59.8436 53.9519 60.0121 54.4297 59.9999 54.9201Z"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
