import React from "react";
import { observer } from "mobx-react-lite";
import cn from "classnames";

import {
	volunteerProjectFieldKeysByType,
	volunteerProjectNameByType,
	VolunteerProjects,
	VolunteerProjectTypes,
} from "../../../stores/volunteers-store/volunteer-projects";

import { SchemaFieldType, ISchemaField } from "@app-models/schema-form";
import { SchemaFormFieldRow } from "@app-components/schema-form";
import { Select } from "@app-ui";
import { Button } from "@app-components/buttons/button";

import { VolunteerAttributes } from "../../../constants/volunteer-attributes";

interface Props {
	volunteerProjects: VolunteerProjects;
	onClose: () => void;
}

const FieldRowActivity = observer(({ field }: { field: ISchemaField }) => {
	function handleChange(e: React.FormEvent<HTMLInputElement>) {
		field.updateValue(e.currentTarget.value);
	}

	return (
		<div className="form__row">
			<div className="form__label">Активность добровольца в проекте</div>
			<div className="form__value">
				<div className="form-radio-group">
					<label className="form-radio">
						<input
							type="radio"
							name="activity"
							value="2"
							checked={field.value === "2"}
							onChange={handleChange}
						/>
						Разовая помощь
					</label>
					<label className="form-radio">
						<input
							type="radio"
							name="activity"
							value="3"
							checked={field.value === "3"}
							onChange={handleChange}
						/>
						Активное участие
					</label>
				</div>
			</div>
		</div>
	);
});

export const VolunteerProjectsDialog = observer(({ volunteerProjects, onClose }: Props) => {
	const [selectedProjectType, setSelectedProjectType] = React.useState(VolunteerProjectTypes.Target);

	const getProjects = (projType: VolunteerProjectTypes) =>
		volunteerProjectFieldKeysByType[projType].filter(key => !volunteerProjects.form.fields.find(f => f.id === key));

	const [selectedProject, setSelectedProject] = React.useState(
		getProjects(selectedProjectType).length > 0 ? getProjects(selectedProjectType)[0] : null,
	);

	function handleSave() {
		if (volunteerProjects.field) {
			volunteerProjects.form.addField(volunteerProjects.field);
		}

		onClose();
	}

	React.useEffect(() => {
		volunteerProjects.selectProject(selectedProject);
	}, [selectedProject]);

	function handleProjectTypeChange(value: VolunteerProjectTypes) {
		setSelectedProjectType(value);
		setSelectedProject(getProjects(value)[0]);
	}

	function handleProjectChange(value: VolunteerAttributes) {
		setSelectedProject(value);
	}

	return (
		<div>
			<div className="volunteer-projects-dialog__header">Добавление проекта</div>
			<div className="volunteer-projects-dialog__content">
				<div className="form__row">
					<div className="form__label">Тип проекта</div>
					<div className="form__value">
						<Select
							items={volunteerProjectNameByType.map(([value, label]) => ({
								label,
								value,
							}))}
							value={selectedProjectType}
							onChange={handleProjectTypeChange}
						/>
					</div>
				</div>
				<div className="form__row">
					<div className="form__label">Название проекта</div>
					<div className="form__value">
						<Select
							items={getProjects(selectedProjectType).map(proj => ({
								label: volunteerProjects.getProjectLabel(proj),
								value: proj,
							}))}
							value={selectedProject ?? ""}
							onChange={handleProjectChange}
						/>
					</div>
				</div>

				{volunteerProjects.field != null &&
					(volunteerProjects.field.type === SchemaFieldType.Select ? (
						<FieldRowActivity field={volunteerProjects.field} />
					) : (
						<SchemaFormFieldRow field={volunteerProjects.field} />
					))}
			</div>
			<div className="volunteer-projects-dialog__actions">
				<Button className={cn("button button-light")} onClick={handleSave}>
					Добавить
				</Button>
			</div>
		</div>
	);
});
