import React from "react";

export function InifiniteDataListHeader({ captions }: { captions: string[] }) {
	return (
		<thead>
			<tr className="data-grid__header">
				{captions.map((caption, index) => (
					<td className="data-grid__header-cell" key={index}>
						{caption}
					</td>
				))}
			</tr>
		</thead>
	);
}
