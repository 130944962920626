import React from "react";
import { observer } from "mobx-react-lite";
import { generatePath, RouteComponentProps, withRouter } from "react-router";
import { Button } from "@app-components/buttons/button";

import { RequestItem } from "../../stores/request-store/request-item";
import { Paths } from "../../routes/paths";
import { CallTaskFormType } from "../../stores/call-center-store/task";
import { ProcessActions, ProcessActions_15_1 } from "../../constants/process";

interface Props extends RouteComponentProps {
	item: RequestItem;
}

export const TaskActions = withRouter(
	observer(function({ item, history }: Props) {
		function handleChange(actionId: number) {
			return function() {
				item.runAction(actionId, nextTaskRef => {
					if (nextTaskRef.next_id) {
						document.location.replace(generatePath(Paths.RequestsItem, { requestId: nextTaskRef.next_id }));
					} else {
						history.push(Paths.ActiveRequests);
					}
				});
			};
		}

		const task = item.task!;

		return (
			<div className="task-form-actions">
				<div className="task-form-actions__intermediate" />
				<div className="task-form-actions__finish">
					{task.actions
						.filter(
							action =>
								task.form.type === CallTaskFormType.Request ||
								![
									ProcessActions.SendToCoordinator,
									ProcessActions.Respond,
									ProcessActions.CancelRespond,
									ProcessActions_15_1.Respond,
								].includes(action.actionID),
						)
						.map(actionItem => (
							<Button
								className="button button--primary"
								key={actionItem.actionID}
								onClick={handleChange(actionItem.actionID)}
								// disabled={!task.submitAllowed}
							>
								{actionItem.action}
							</Button>
						))}
				</div>
			</div>
		);
	}),
);
