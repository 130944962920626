import React from "react";
import { Route, Switch } from "react-router-dom";

import { PrivateRoute } from "@app-components/routes";
import * as Pages from "../pages";
import { Paths } from "./paths";

export function Routes() {
	return (
		<Switch>
			<PrivateRoute exact path={Paths.CallCenter} component={Pages.ProcessedCalls} />
			<PrivateRoute exact path={Paths.CallCenterUnhandled} component={Pages.IncomingCalls} />
			<PrivateRoute exact path={Paths.CallCenterItem} component={Pages.CallCenterItem} />
			<PrivateRoute exact path={Paths.Requests} component={Pages.ProcessedRequests} />
			<PrivateRoute exact path={Paths.ActiveRequests} component={Pages.Requests} />
			<PrivateRoute exact path={Paths.RequestsItem} component={Pages.RequestItem} />
			<PrivateRoute exact path={Paths.Wards} component={Pages.Wards} />
			<PrivateRoute exact path={Paths.WardItemCreate} component={Pages.WardItemCreate} />
			<PrivateRoute exact path={Paths.WardItem} component={Pages.WardItem} />

			<PrivateRoute exact path={Paths.Volunteers} component={Pages.Volunteers} />
			<PrivateRoute exact path={Paths.VolunteerCreate} component={Pages.VolunteerCreate} />
			<PrivateRoute exact path={Paths.Volunteer} component={Pages.Volunteer} />

			<PrivateRoute exact path={Paths.Reference} component={Pages.Reference} />
			<PrivateRoute exact path={Paths.ReferenceItemCreation} component={Pages.ReferenceItemCreation} />
			<PrivateRoute exact path={Paths.ReferenceItem} component={Pages.ReferenceItem} />
			<PrivateRoute exact path={Paths.ReferenceList} component={Pages.ReferenceList} />
			<PrivateRoute exact path={Paths.Reports} component={Pages.Reports} />
			<PrivateRoute exact path={Paths.Profile} component={Pages.Profile} />

			<PrivateRoute exact path={Paths.Home} component={Pages.Home} />
			<PrivateRoute exact path={Paths.Map} component={Pages.Map} />
			<Route path={Paths.Policy} component={Pages.Policy} />
			<Route path={Paths.Login} component={Pages.Login} />
			<Route path={Paths.CompleteAuth} component={Pages.CompleteAuth} />
			<Route path={Paths.AutoAuth} component={Pages.AutoAuth} />
			<Route path="*" component={Pages.NotFound} />
		</Switch>
	);
}
