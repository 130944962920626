import React from "react";
import { SchemaFormFieldRow } from "@app-components/schema-form";
import { SchemaForm } from "@app-models/schema-form";

import { WardAttributes } from "../../../../constants/ward-attributes";

interface Props {
	form: SchemaForm;
}

export const FamilyEditSection = ({ form }: Props) => {
	return (
		<>
			<SchemaFormFieldRow field={form.getField(WardAttributes.MaritalStatus)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.SpouseFullName)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.HasChildren)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.RelativesInfo)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.IsAlone)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.ColivingInfo)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.ColivingWithAlcoholAddictionOrMentalIllness)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.NeighborsHelp)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.AnybodyHelp)!} />
		</>
	);
};
