export enum XShemaFormItemType {
	String = "string",
	Text = "text",
	Number = "number",
	Enum = "enum",
	Media = "media",
	Label = "label",

	Masked = "masked", // ? scope attr
}
