import { computed } from "mobx";

import { FieldParameters, IField, IForm } from "@app-models/form/index";
import { Field } from "./field";

interface FormParameters {
	fields: FieldParameters[];
}

export class Form implements IForm {
	private mapFieldByName: Map<string, IField>;
	public fields: IField[];

	public constructor({ fields }: FormParameters) {
		this.fields = fields.map(declaration => new Field(declaration, this));
		this.mapFieldByName = new Map<string, IField>(this.fields.map(field => [field.name, field]));
	}

	public get(fieldName: string) {
		const field = this.mapFieldByName.get(fieldName);

		if (!field) {
			throw new Error(`${fieldName} not found`);
		}

		return field;
	}

	public validate() {
		this.fields.forEach(field => field.validate());

		return this.isValid;
	}

	@computed
	public get isValid() {
		return this.fields.every(field => field.isValid);
	}

	public values() {
		return Object.assign(
			{},
			...this.fields.filter(field => field.value != null).map(field => ({ [field.name]: field.value })),
		);
	}
}
