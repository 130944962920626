import { action, computed, observable, runInAction } from "mobx";

import { XScopeAttrRef, XScopeCard, XScopeChildInfo } from "@external-types/scope";
import { getAttributeValue, getScopeAttribute, getScopeAttributeValue } from "@helpers/scope-attributes";

import { WardAttributes } from "../../constants/ward-attributes";
import { WardSectionId } from "./ward-sections";
import { SchemaForm } from "../../../../../src/models/schema-form";
import { actions } from "../../../../../src/actions";
import { XAppTypeInfo } from "../../../../../src/types/external-data/type";
import { mapAttrValueToFormField } from "../reference-store/reference-scope";
import { getWardFullName, saveWardInfo } from "../../helpers/ward-helper";
import { Scope } from "../../constants/scope";
import { DataSourceSession, InfiniteDataSource, Filters } from "../../../../../src/models/data-source";
import { XTaskExtRef } from "../../../../../src/types/external-data/process";
import { ProcessFieldValue } from "../../constants/process-field-value";
import { XFileRef } from "../../../../../src/types/external-data/file";
import { IUploadFile } from "../../../../../src/types/models";

export enum DisplayMode {
	View = 0,
	Edit = 1,
}

export interface IWard {
	deletePhoto: () => void;
	photoUrl: string | null;
	uploadPhoto: (files: IUploadFile[]) => void;
}

export class RequestsHistoryFilters extends Filters {
	protected createQuery() {
		const query = super.createQuery();

		return {
			...query,
			vid: [
				ProcessFieldValue.RequestMessage,
				ProcessFieldValue.RequestTopic,
				ProcessFieldValue.CallTopic,
				ProcessFieldValue.AppealMessage,
			],
			pvid: [
				ProcessFieldValue.RequestMessage,
				ProcessFieldValue.RequestTopic,
				ProcessFieldValue.CallTopic,
				ProcessFieldValue.AppealMessage,
			],
			tree: true,
			all: true,
			type: "root",
			orderBy: "date_desc",
		};
	}
}

class WardActivityHistoryDataSourceSession extends DataSourceSession<XTaskExtRef> {
	public filter = new RequestsHistoryFilters();
}

class WardActivityHistoryDataSource extends InfiniteDataSource<XTaskExtRef> {
	private _session: WardActivityHistoryDataSourceSession;

	public constructor(private scopeId: number) {
		super();

		this._session = new WardActivityHistoryDataSourceSession();
	}

	protected async fetch(): Promise<XTaskExtRef[]> {
		const result = await actions.getScopeProcesses(this.scopeId, this._session.pagination, this._session.filter);

		this.updatePageInfo(result);

		return result.list;
	}

	protected get session() {
		return this._session;
	}
}

export class Ward implements IWard {
	@observable public currentSectionId: WardSectionId = WardSectionId.BaseInfo;
	@observable public mode: DisplayMode = DisplayMode.View;
	@observable public form: SchemaForm | null = null;
	@observable public scope: XScopeChildInfo | XScopeCard;
	@observable public fetching: boolean = false;
	@observable private photo: XFileRef | null = null;
	private typeSchema: XAppTypeInfo | null = null;
	public datasource: WardActivityHistoryDataSource;

	public constructor(scope: XScopeChildInfo) {
		this.scope = scope;
		this.datasource = new WardActivityHistoryDataSource(scope.id);
		this.photo = scope.files.length ? scope.files[0] : null;
	}

	@computed
	public get fullName() {
		return getWardFullName(this.scope);
	}

	@computed
	public get photoUrl() {
		return this.photo !== null ? actions.getScopeFileUrl(this.scope.id, this.photo.id) : null;
	}

	@computed
	public get id() {
		return this.scope.id;
	}

	public get phone() {
		return [
			getScopeAttributeValue(this.scope, WardAttributes.Phone),
			getScopeAttributeValue(this.scope, WardAttributes.AdditionalPhone),
			getScopeAttributeValue(this.scope, WardAttributes.HomePhone),
			getScopeAttributeValue(this.scope, WardAttributes.MobilePhone),
		]
			.filter(phone => phone != null && phone !== "")
			.join(", ");
	}

	public get address() {
		const district = getScopeAttributeValue(this.scope, WardAttributes.DistrictOfMoscow);
		const city = getScopeAttributeValue(this.scope, WardAttributes.Settlement);
		const subway = getScopeAttributeValue(this.scope, WardAttributes.Subway);
		const street = getScopeAttributeValue(this.scope, WardAttributes.Address);

		return [city, district, subway, street].filter(val => val && val.trim()).join(", ");
	}

	public get helpFrequency() {
		return getScopeAttributeValue(this.scope, WardAttributes.HelpRequiredFrequency) ?? "";
	}

	public get isInPrayList() {
		return getScopeAttributeValue(this.scope, WardAttributes.InPrayList) === "да";
	}

	public get isDead() {
		return getScopeAttributeValue(this.scope, WardAttributes.InPrayList) === "да";
	}

	public get projects() {
		return [
			WardAttributes.TargetedAssistanceSao,
			WardAttributes.TargetedAssistanceSvao,
			WardAttributes.TargetedAssistanceVao,
			WardAttributes.TargetedAssistanceUvao,
			WardAttributes.TargetedAssistanceUao,
			WardAttributes.TargetedAssistanceUzao,
			WardAttributes.TargetedAssistanceZao,
			WardAttributes.TargetedAssistanceSzao,
			WardAttributes.MMO,
			WardAttributes.MO,
			WardAttributes.InquiryOffice,
			WardAttributes.Patronage,
			WardAttributes.TransportationServices,
			WardAttributes.WashingRoom,
			WardAttributes.WashingRoomOurs,
			WardAttributes.HouseForMother,
		]
			.filter(attr => getScopeAttributeValue(this.scope, attr) === "да")
			.map(attr => this.getAttr(attr)!.name)
			.join(", ");
	}

	public getAttr(attr: WardAttributes) {
		return getScopeAttribute(this.scope, attr);
	}

	public getAttrValue(attr: XScopeAttrRef) {
		return getAttributeValue(attr, this.scope.type);
	}

	@action
	public updateCurrentSection(sectionId: number) {
		this.currentSectionId = sectionId;
	}

	public uploadPhoto = async (files: IUploadFile[]) => {
		if (files.length > 0) {
			const photo = await actions.uploadScopeFile(this.scope.id, files[0]);

			runInAction(() => {
				this.photo = photo;
			});
		}
	};

	public deletePhoto = async () => {
		if (this.photo) {
			await actions.deleteScopeFile(this.scope.id, this.photo.id);

			runInAction(() => {
				this.photo = null;
			});
		}
	};

	@action
	public async save() {
		let scope = this.scope;

		if (this.form!.validate()) {
			try {
				runInAction(() => {
					this.fetching = true;
				});

				await saveWardInfo(this.scope, this.form!);

				scope = await actions.getScope(this.scope.id);

				runInAction(() => {
					this.scope = scope;
					this.photo = scope.files.length > 0 ? scope.files[0] : null;
				});
			} finally {
				runInAction(() => {
					this.fetching = false;
				});
			}
		}

		return scope;
	}

	public async toggleMode(mode: DisplayMode) {
		if (mode === DisplayMode.Edit) {
			try {
				runInAction(() => {
					this.fetching = true;
				});

				if (this.typeSchema === null) {
					await this.fetchTypeSchema();
				}

				runInAction(() => {
					const form = new SchemaForm(this.typeSchema!.attrs);
					mapAttrValueToFormField(this.scope, form);

					this.form = form;
				});
			} finally {
				runInAction(() => {
					this.fetching = false;
				});
			}
		}

		runInAction(() => {
			this.mode = mode;
		});
	}

	public deleteScope = async () => {
		if (
			confirm(
				"Удаление карточки. Если Вы удалите карточку подопечного, все данные по подопечному будут удалены из системы и восстановить их будет невозможно.",
			)
		) {
			try {
				runInAction(() => {
					this.fetching = true;
				});
				await actions.deleteScope(this.scope.id, true);
			} finally {
				runInAction(() => {
					this.fetching = false;
				});
			}
		}
	};

	private async fetchTypeSchema() {
		const typeSchema = await actions.getScopeType(Scope.Wards, this.scope.type.id);

		runInAction(() => {
			this.typeSchema = typeSchema;
		});
	}
}
