import { observable } from "mobx";
import { DataSourceSession, Filters } from "@shared/models/data-source";
import { IncomingCall } from "../call-center-store";
import { WardAttributes } from "../../constants/ward-attributes";
import { ProcessFieldValue } from "../../constants/process-field-value";
import { RequestTask } from "../request-store";
import { ProcessSchema } from "../../constants/process-schema";
import { ProcessedRequestItem } from "../request-store/processed-request-item";

class ProcessedRequestsFilters extends Filters {
	protected createQuery() {
		const query = super.createQuery();

		return {
			...query,
			schema: ProcessSchema.SubProcess,
			// aid: [WardAttributes.Phone, WardAttributes.FirstName, WardAttributes.LastName, WardAttributes.MiddleName],
			paid: [WardAttributes.Phone, WardAttributes.FirstName, WardAttributes.LastName, WardAttributes.MiddleName],
			// vid: [
			// 	ProcessFieldValue.RequestMessage,
			// 	ProcessFieldValue.RequestTopic,
			// 	ProcessFieldValue.CallTopic,
			// 	ProcessFieldValue.AppealMessage,
			// ],
			pvid: [
				ProcessFieldValue.RequestMessage,
				ProcessFieldValue.RequestTopic,
				ProcessFieldValue.CallTopic,
				ProcessFieldValue.AppealMessage,
			],
			tree: true,
			all: true,
			type: "all",
			orderBy: "date_desc",
		};
	}
}

export class ProcessedRequestsSession extends DataSourceSession<RequestTask> {
	public filter: Filters = new ProcessedRequestsFilters(true);
	@observable public currentItem: ProcessedRequestItem | null = null;
	@observable public incomingCall: IncomingCall | null = null;
}
