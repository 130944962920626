import React from "react";
import cn from "classnames";
import { SvgIcon } from "@app-types/models";

interface Props {
	Icon: React.FunctionComponent<SvgIcon>;
	iconClassName?: string;
	onClick: () => void;
}

export function IconButton({ iconClassName, onClick, Icon }: Props) {
	function handleClick(e: React.FormEvent) {
		e.stopPropagation();

		onClick();
	}

	return (
		<button onClick={handleClick} className="icon-button">
			<Icon className={cn("icon-button__icon", iconClassName)} />
		</button>
	);
}
