import { computed, observable, runInAction } from "mobx";
import { actions } from "@actions";

import { XScopeChildInfo } from "@app-types/external-data/scope";
import { ReferenceRootSection } from "./reference-root-section";
import { ReferenceChildSection } from "./reference-child-section";

import { IReferenceScopeSection } from "./types";

export class NewReferenceScope {
	@observable public activeSectionId: number = 0;
	@observable.ref public sections: IReferenceScopeSection[] = [];
	@observable public fetched: boolean = false;

	public constructor(private rootScope: XScopeChildInfo) {}

	public async fetch() {
		const sectionTypes = await actions.getTypeSchema(this.rootScope.type.id);

		if (sectionTypes.types.length === 0) {
			return;
		}

		if (sectionTypes.types.length > 1) {
			throw new Error(`Single root type supported but was ${sectionTypes.types.length}`);
		}

		const rootType = await actions.getTypeSchema(sectionTypes.types[0].id);
		const rootSection = new ReferenceRootSection(rootType, this.rootScope.id);

		await rootSection.fetch();

		runInAction(() => {
			const sections: IReferenceScopeSection[] = [rootSection];

			this.sections = sections.concat(
				rootType.types.map(appTypeRef => new ReferenceChildSection(rootSection, appTypeRef)),
			);
			this.activeSectionId = rootSection.id;
			this.fetched = true;
		});
	}

	@computed
	public get activeSection() {
		return this.findSectionById(this.activeSectionId);
	}

	public updateActiveSectionId = async (sectionId: number) => {
		const activeSection = this.activeSection;

		if (activeSection !== null && activeSection.validate()) {
			await activeSection.update();

			const nextActiveSection = this.findSectionById(sectionId);

			if (nextActiveSection !== null && !nextActiveSection.fetched) {
				await nextActiveSection.fetch();
			}
			runInAction(() => {
				this.activeSectionId = sectionId;
			});
		}
	};

	private findSectionById(sectionId: number) {
		const activeSection = this.sections.find(section => section.id === sectionId);

		return activeSection ?? null;
	}
}