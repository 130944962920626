import React from "react";
import { SchemaFormFieldRow } from "@app-components/schema-form";
import { SchemaForm } from "@app-models/schema-form";

import { WardAttributes } from "../../../../constants/ward-attributes";

interface Props {
	form: SchemaForm;
}

export const HealthStatusEditSection = ({ form }: Props) => {
	return (
		<>
			<SchemaFormFieldRow field={form.getField(WardAttributes.DisabilityCategory)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.DisabilityCategoryLevel)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.DisabilityInformationAndAppointmentReason)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.MentalDisorders)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.HasSocialWorker)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.SocialWorkerFrequency)!} />
		</>
	);
};
