import React from "react";
import { SchemaFormFieldRow } from "@app-components/schema-form";
import { SchemaForm } from "@app-models/schema-form";

import { WardAttributes } from "../../../../constants/ward-attributes";

interface Props {
	form: SchemaForm;
}

export const BaseInfoEditSection = ({ form }: Props) => {
	return (
		<>
			<SchemaFormFieldRow field={form.getField(WardAttributes.LastName)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.FirstName)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.MiddleName)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.Birthday)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.Age)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.Gender)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.DistrictOfAssistance)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.RegularityOfAssistance)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.InPrayList)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.ReasonOfInPrayList)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.Dead)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.DateOfDeath)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.CoordinatorComment)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.CoordinatorAssistant)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.Comments)!} />
		</>
	);
};
