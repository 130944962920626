import React, { useContext } from "react";
import { generatePath, RouteComponentProps, useParams, withRouter } from "react-router";
import { observer } from "mobx-react";
import cn from "classnames";

import { FilterToolbar } from "@app-components/filters/filter-toolbar";
import { Button } from "@app-components/buttons/button";

import { StoreContext } from "../../context";
import { PageHeader } from "../../components/page-header";
import { Paths } from "../../routes/paths";

import { ReferenceDataGrid } from "./reference-data-grid";
import { ReferenceWrapper } from "./reference-wrapper";
import { ReferenceNavigationItem } from "../../stores/reference-store";
import { CaretIcon } from "../../../../../src/components/icons";
import { ReferenceScopeId } from "../../constants/reference-scope-code";
import { ReferenceFileList } from "./reference-file-list";

interface Props extends RouteComponentProps {
	children?: React.ReactNode;
}

const SidebarGroupLink = observer(
	({ item, onClick }: { item: ReferenceNavigationItem; onClick: (item: ReferenceNavigationItem) => void }) => {
		const { referenceStore } = useContext(StoreContext);
		const Icon = item.Icon;
		const isExpanded = item.id === referenceStore.referenceNavigation.expandedId;

		function handleClick() {
			onClick(item);
		}

		return (
			<div
				className={cn("sidebar__link", "sidebar-link-group", {
					"sidebar-link-group--active": isExpanded,
				})}
				onClick={handleClick}
			>
				<div className="sidebar-link-group__name">
					{Icon && <Icon className="sidebar-link-icon" />}
					{item.name}
					<CaretIcon
						className={cn("sidebar-link-group__caret", {
							"sidebar-link-group__caret--expanded": isExpanded,
						})}
					/>
				</div>

				{isExpanded && item.children.map(ch => <SidebarSubLink key={ch.id} item={ch} onClick={onClick} />)}
			</div>
		);
	},
);

const SidebarLink = ({
	item,
	onClick,
}: {
	item: ReferenceNavigationItem;
	onClick: (item: ReferenceNavigationItem) => void;
}) => {
	const { referenceStore } = useContext(StoreContext);
	const Icon = item.Icon;

	function handleClick() {
		onClick(item);
	}

	return (
		<div
			className={cn("sidebar__link", "sidebar-link", {
				"sidebar__link--active": referenceStore.activeScope?.id === item.id,
			})}
			onClick={handleClick}
		>
			{Icon && <Icon className="sidebar-link-icon" />}
			{item.name}
		</div>
	);
};

const SidebarSubLink = ({
	item,
	onClick,
}: {
	item: ReferenceNavigationItem;
	onClick: (item: ReferenceNavigationItem) => void;
}) => {
	const { referenceStore } = useContext(StoreContext);

	function handleClick(e: React.FormEvent) {
		e.preventDefault();
		e.stopPropagation();

		onClick(item);
	}

	return (
		<div
			onClick={handleClick}
			className={cn("sidebar-sub-link", {
				"sidebar-sub-link--active": referenceStore.activeScope?.id === item.id,
			})}
		>
			{item.name}
		</div>
	);
};

const ReferenceWrapper1 = withRouter(
	observer(({ children, history }: Props) => {
		const { referenceStore } = useContext(StoreContext);
		const { referenceId } = useParams<{ referenceId: string }>();

		React.useEffect(() => {
			referenceStore.updateActiveScope(parseInt(referenceId, 10));
		}, [referenceId]);

		function handleScopeChange(scope: ReferenceNavigationItem) {
			if (scope.children.length === 0) {
				history.push(generatePath(Paths.ReferenceList, { referenceId: scope.id }));
			} else {
				referenceStore.referenceNavigation.expand(scope.id);
			}
		}

		return (
			<>
				<div className="page__sidebar">
					<div className="sidebar">
						{referenceStore.referenceNavigation.items.map(scope =>
							scope.children.length > 0 ? (
								<SidebarGroupLink item={scope} onClick={handleScopeChange} key={scope.id} />
							) : (
								<SidebarLink item={scope} onClick={handleScopeChange} key={scope.id} />
							),
						)}
					</div>
				</div>
				<div className="page__content">{children}</div>
			</>
		);
	}),
);

export const ReferenceList = withRouter(
	observer(({ history }: RouteComponentProps) => {
		const { referenceStore } = useContext(StoreContext);
		const activeScope = referenceStore.activeScope;

		const { referenceId } = useParams<{ referenceId: string }>();

		function handleCreate() {
			history.push(generatePath(Paths.ReferenceItemCreation, { referenceId }));
		}

		function handleFilterApply(filterId: string, value: any) {
			if (activeScope) {
				activeScope.applyFilter(filterId, value);
			}
		}

		return (
			<ReferenceWrapper>
				<ReferenceWrapper1>
					{activeScope !== null &&
						(activeScope.id === ReferenceScopeId.ManualsAndDocuments ? (
							<ReferenceFileList />
						) : (
							<>
								<PageHeader title={activeScope.name}>
									<div className="filter-toolbar">
										<FilterToolbar
											filters={activeScope.filter}
											onApply={handleFilterApply}
											hiddenFilters={["type", "orderBy", "attr"]}
										/>
									</div>

									<div>
										<Button className="button button--primary" onClick={handleCreate}>
											Добавить
										</Button>
									</div>
								</PageHeader>

								<ReferenceDataGrid store={activeScope} />
							</>
						))}
				</ReferenceWrapper1>
			</ReferenceWrapper>
		);
	}),
);
