import { observable, action, runInAction } from "mobx";
import { AppStore } from "./app-store";

import { actions } from "@actions";
import { Credentials } from "@app-types/models";
import { XExtAuthRef } from "@external-types/user/ext-auth-ref";
import { IAuthStore } from "@app-types/stores";

export class AuthStore implements IAuthStore {
	@observable
	public inProgress = false;
	@observable
	public errorMessage: string | null = null;
	@observable
	public externalAuths: XExtAuthRef[] = [];

	private appStore: AppStore;

	public constructor(appStore: AppStore) {
		this.appStore = appStore;
	}

	@action
	public reset() {}

	@action
	public async externalLogin(state: string, payload: { code: string }) {
		try {
			const authToken = await actions.completeAuth(state, payload);
			this.appStore.setAuth(authToken);

			runInAction(() => {
				this.errorMessage = null;
			});
		} catch (error) {
			this.handleError(error);
		}
	}

	@action
	public async login(credentials: Credentials) {
		try {
			const authToken = await actions.loginApp(credentials);
			this.appStore.setAuth(authToken);

			runInAction(() => {
				this.errorMessage = null;
			});
		} catch (error) {
			this.handleError(error);
		}
	}

	@action
	public logout() {
		return actions.logout().finally(() => {
			this.appStore.setAuth(null);
		});
	}

	@action
	public async fetchExternalAuths() {
		const externalAuths = await actions.listAuths();

		runInAction(() => {
			this.externalAuths = externalAuths;
		});

		return externalAuths;
	}

	@action
	private handleError = (err: any) => {
		if (err.response.status === 401) {
			this.errorMessage = "Неверный логин или пароль";
		} else {
			this.errorMessage = "Произошла неизвестная ошибка";
		}
	};
}
