import React from "react";
import Pager from "rc-pagination";
//@ts-ignore
import localeInfo from "rc-pagination/lib/locale/ru_RU";
// import { PageSizeSelect } from "./page-size-select";
import { ArrowIcon } from "@icons";
import { IPagination } from "@app-types/data";

interface Props {
	pageSize?: number;
	total?: number;
	currentPage?: number;
	className?: string;

	pagination?: IPagination;

	onChange(current: number, pageSize: number): void;
}

// const pageSizeOptions = ["10", "20", "30"];

function PrevIcon() {
	return <ArrowIcon className="pagination-arrow" />;
}

function NextIcon() {
	return <ArrowIcon className="pagination-arrow pagination-arrow--right" />;
}

export class Pagination extends React.Component<Props> {
	private handleChange = (current: number, pageSize: number) => {
		this.props.onChange(current, pageSize);
	};

	public render() {
		let { pageSize, currentPage, total, pagination, className } = this.props;

		if (pagination) {
			pageSize = pagination.pageSize;
			currentPage = pagination.currentPage;
			total = pagination.total;
		}

		return (
			//@ts-ignore
			<Pager
				showLessItems
				pageSize={pageSize}
				current={currentPage}
				total={total}
				locale={localeInfo}
				onChange={this.handleChange}
				className={className}
				//onShowSizeChange={this.handleChange}
				//selectComponentClass={PageSizeSelect}
				//showSizeChanger
				//pageSizeOptions={pageSizeOptions}
				prevIcon={PrevIcon}
				nextIcon={NextIcon}
			/>
		);
	}
}
