import React from "react";
import { SvgIcon } from "@app-types/models";

export default function({ className }: SvgIcon) {
	return (
		<svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M23.6 3.80001H19.2V0.600012C19.2 0.379112 19.0209 0.200012 18.8 0.200012H5.2C4.9791 0.200012 4.8 0.379112 4.8 0.600012V6.20001H0.4C0.1791 6.20001 0 6.37911 0 6.60001V23C0 23.2209 0.1791 23.4 0.4 23.4C0.6209 23.4 0.8 23.2209 0.8 23V7.00001H4.8V23.4C4.8 23.6209 4.9791 23.8 5.2 23.8H18.8C19.0209 23.8 19.2 23.6209 19.2 23.4V4.60001H23.2V23C23.2 23.2209 23.3791 23.4 23.6 23.4C23.8209 23.4 24 23.2209 24 23V4.20001C24 3.97911 23.8209 3.80001 23.6 3.80001ZM11.6 23H10V19.4H11.6V23ZM14 23H12.4V19.4H14V23ZM18.4 23H14.8V19C14.8 18.7791 14.6209 18.6 14.4 18.6H9.6C9.3791 18.6 9.2 18.7791 9.2 19V23H5.6V1.00001H18.4V23Z"
				fill="black"
			/>
			<path
				d="M9.1999 1.79999H6.7999C6.579 1.79999 6.3999 1.97909 6.3999 2.19999V4.59999C6.3999 4.82089 6.579 4.99999 6.7999 4.99999H9.1999C9.4208 4.99999 9.5999 4.82089 9.5999 4.59999V2.19999C9.5999 1.97909 9.4208 1.79999 9.1999 1.79999ZM8.7999 4.19999H7.1999V2.59999H8.7999V4.19999Z"
				fill="black"
			/>
			<path
				d="M13.1999 1.79999H10.7999C10.579 1.79999 10.3999 1.97909 10.3999 2.19999V4.59999C10.3999 4.82089 10.579 4.99999 10.7999 4.99999H13.1999C13.4208 4.99999 13.5999 4.82089 13.5999 4.59999V2.19999C13.5999 1.97909 13.4208 1.79999 13.1999 1.79999ZM12.7999 4.19999H11.1999V2.59999H12.7999V4.19999Z"
				fill="black"
			/>
			<path
				d="M17.1999 1.79999H14.7999C14.579 1.79999 14.3999 1.97909 14.3999 2.19999V4.59999C14.3999 4.82089 14.579 4.99999 14.7999 4.99999H17.1999C17.4208 4.99999 17.5999 4.82089 17.5999 4.59999V2.19999C17.5999 1.97909 17.4208 1.79999 17.1999 1.79999ZM16.7999 4.19999H15.1999V2.59999H16.7999V4.19999Z"
				fill="black"
			/>
			<path
				d="M9.1999 5.79999H6.7999C6.579 5.79999 6.3999 5.97909 6.3999 6.19999V8.59999C6.3999 8.82089 6.579 8.99999 6.7999 8.99999H9.1999C9.4208 8.99999 9.5999 8.82089 9.5999 8.59999V6.19999C9.5999 5.97909 9.4208 5.79999 9.1999 5.79999ZM8.7999 8.19999H7.1999V6.59999H8.7999V8.19999Z"
				fill="black"
			/>
			<path
				d="M13.1999 5.79999H10.7999C10.579 5.79999 10.3999 5.97909 10.3999 6.19999V8.59999C10.3999 8.82089 10.579 8.99999 10.7999 8.99999H13.1999C13.4208 8.99999 13.5999 8.82089 13.5999 8.59999V6.19999C13.5999 5.97909 13.4208 5.79999 13.1999 5.79999ZM12.7999 8.19999H11.1999V6.59999H12.7999V8.19999Z"
				fill="black"
			/>
			<path
				d="M17.1999 5.79999H14.7999C14.579 5.79999 14.3999 5.97909 14.3999 6.19999V8.59999C14.3999 8.82089 14.579 8.99999 14.7999 8.99999H17.1999C17.4208 8.99999 17.5999 8.82089 17.5999 8.59999V6.19999C17.5999 5.97909 17.4208 5.79999 17.1999 5.79999ZM16.7999 8.19999H15.1999V6.59999H16.7999V8.19999Z"
				fill="black"
			/>
			<path
				d="M9.1999 9.79999H6.7999C6.579 9.79999 6.3999 9.97909 6.3999 10.2V12.6C6.3999 12.8209 6.579 13 6.7999 13H9.1999C9.4208 13 9.5999 12.8209 9.5999 12.6V10.2C9.5999 9.97909 9.4208 9.79999 9.1999 9.79999ZM8.7999 12.2H7.1999V10.6H8.7999V12.2Z"
				fill="black"
			/>
			<path
				d="M13.1999 9.79999H10.7999C10.579 9.79999 10.3999 9.97909 10.3999 10.2V12.6C10.3999 12.8209 10.579 13 10.7999 13H13.1999C13.4208 13 13.5999 12.8209 13.5999 12.6V10.2C13.5999 9.97909 13.4208 9.79999 13.1999 9.79999ZM12.7999 12.2H11.1999V10.6H12.7999V12.2Z"
				fill="black"
			/>
			<path
				d="M17.1999 9.79999H14.7999C14.579 9.79999 14.3999 9.97909 14.3999 10.2V12.6C14.3999 12.8209 14.579 13 14.7999 13H17.1999C17.4208 13 17.5999 12.8209 17.5999 12.6V10.2C17.5999 9.97909 17.4208 9.79999 17.1999 9.79999ZM16.7999 12.2H15.1999V10.6H16.7999V12.2Z"
				fill="black"
			/>
			<path
				d="M9.1999 13.8H6.7999C6.579 13.8 6.3999 13.9791 6.3999 14.2V16.6C6.3999 16.8209 6.579 17 6.7999 17H9.1999C9.4208 17 9.5999 16.8209 9.5999 16.6V14.2C9.5999 13.9791 9.4208 13.8 9.1999 13.8ZM8.7999 16.2H7.1999V14.6H8.7999V16.2Z"
				fill="black"
			/>
			<path
				d="M13.1999 13.8H10.7999C10.579 13.8 10.3999 13.9791 10.3999 14.2V16.6C10.3999 16.8209 10.579 17 10.7999 17H13.1999C13.4208 17 13.5999 16.8209 13.5999 16.6V14.2C13.5999 13.9791 13.4208 13.8 13.1999 13.8ZM12.7999 16.2H11.1999V14.6H12.7999V16.2Z"
				fill="black"
			/>
			<path
				d="M17.1999 13.8H14.7999C14.579 13.8 14.3999 13.9791 14.3999 14.2V16.6C14.3999 16.8209 14.579 17 14.7999 17H17.1999C17.4208 17 17.5999 16.8209 17.5999 16.6V14.2C17.5999 13.9791 17.4208 13.8 17.1999 13.8ZM16.7999 16.2H15.1999V14.6H16.7999V16.2Z"
				fill="black"
			/>
			<path
				d="M2.4001 7.79999H2.0001C1.7792 7.79999 1.6001 7.97909 1.6001 8.19999V8.59999C1.6001 8.82089 1.7792 8.99999 2.0001 8.99999H2.4001C2.621 8.99999 2.8001 8.82089 2.8001 8.59999V8.19999C2.8001 7.97909 2.621 7.79999 2.4001 7.79999Z"
				fill="black"
			/>
			<path
				d="M3.99995 7.79999H3.59995C3.37905 7.79999 3.19995 7.97909 3.19995 8.19999V8.59999C3.19995 8.82089 3.37905 8.99999 3.59995 8.99999H3.99995C4.22085 8.99999 4.39995 8.82089 4.39995 8.59999V8.19999C4.39995 7.97909 4.22085 7.79999 3.99995 7.79999Z"
				fill="black"
			/>
			<path
				d="M2.4001 9.40002H2.0001C1.7792 9.40002 1.6001 9.57912 1.6001 9.80002V10.2C1.6001 10.4209 1.7792 10.6 2.0001 10.6H2.4001C2.621 10.6 2.8001 10.4209 2.8001 10.2V9.80002C2.8001 9.57912 2.621 9.40002 2.4001 9.40002Z"
				fill="black"
			/>
			<path
				d="M3.99995 9.40002H3.59995C3.37905 9.40002 3.19995 9.57912 3.19995 9.80002V10.2C3.19995 10.4209 3.37905 10.6 3.59995 10.6H3.99995C4.22085 10.6 4.39995 10.4209 4.39995 10.2V9.80002C4.39995 9.57912 4.22085 9.40002 3.99995 9.40002Z"
				fill="black"
			/>
			<path
				d="M2.4001 11H2.0001C1.7792 11 1.6001 11.1791 1.6001 11.4V11.8C1.6001 12.0209 1.7792 12.2 2.0001 12.2H2.4001C2.621 12.2 2.8001 12.0209 2.8001 11.8V11.4C2.8001 11.1791 2.621 11 2.4001 11Z"
				fill="black"
			/>
			<path
				d="M3.99995 11H3.59995C3.37905 11 3.19995 11.1791 3.19995 11.4V11.8C3.19995 12.0209 3.37905 12.2 3.59995 12.2H3.99995C4.22085 12.2 4.39995 12.0209 4.39995 11.8V11.4C4.39995 11.1791 4.22085 11 3.99995 11Z"
				fill="black"
			/>
			<path
				d="M2.4001 12.6H2.0001C1.7792 12.6 1.6001 12.7791 1.6001 13V13.4C1.6001 13.6209 1.7792 13.8 2.0001 13.8H2.4001C2.621 13.8 2.8001 13.6209 2.8001 13.4V13C2.8001 12.7791 2.621 12.6 2.4001 12.6Z"
				fill="black"
			/>
			<path
				d="M3.99995 12.6H3.59995C3.37905 12.6 3.19995 12.7791 3.19995 13V13.4C3.19995 13.6209 3.37905 13.8 3.59995 13.8H3.99995C4.22085 13.8 4.39995 13.6209 4.39995 13.4V13C4.39995 12.7791 4.22085 12.6 3.99995 12.6Z"
				fill="black"
			/>
			<path
				d="M2.4001 14.2H2.0001C1.7792 14.2 1.6001 14.3791 1.6001 14.6V15C1.6001 15.2209 1.7792 15.4 2.0001 15.4H2.4001C2.621 15.4 2.8001 15.2209 2.8001 15V14.6C2.8001 14.3791 2.621 14.2 2.4001 14.2Z"
				fill="black"
			/>
			<path
				d="M3.99995 14.2H3.59995C3.37905 14.2 3.19995 14.3791 3.19995 14.6V15C3.19995 15.2209 3.37905 15.4 3.59995 15.4H3.99995C4.22085 15.4 4.39995 15.2209 4.39995 15V14.6C4.39995 14.3791 4.22085 14.2 3.99995 14.2Z"
				fill="black"
			/>
			<path
				d="M2.4001 15.8H2.0001C1.7792 15.8 1.6001 15.9791 1.6001 16.2V16.6C1.6001 16.8209 1.7792 17 2.0001 17H2.4001C2.621 17 2.8001 16.8209 2.8001 16.6V16.2C2.8001 15.9791 2.621 15.8 2.4001 15.8Z"
				fill="black"
			/>
			<path
				d="M3.99995 15.8H3.59995C3.37905 15.8 3.19995 15.9791 3.19995 16.2V16.6C3.19995 16.8209 3.37905 17 3.59995 17H3.99995C4.22085 17 4.39995 16.8209 4.39995 16.6V16.2C4.39995 15.9791 4.22085 15.8 3.99995 15.8Z"
				fill="black"
			/>
			<path
				d="M2.4001 17.4H2.0001C1.7792 17.4 1.6001 17.5791 1.6001 17.8V18.2C1.6001 18.4209 1.7792 18.6 2.0001 18.6H2.4001C2.621 18.6 2.8001 18.4209 2.8001 18.2V17.8C2.8001 17.5791 2.621 17.4 2.4001 17.4Z"
				fill="black"
			/>
			<path
				d="M3.99995 17.4H3.59995C3.37905 17.4 3.19995 17.5791 3.19995 17.8V18.2C3.19995 18.4209 3.37905 18.6 3.59995 18.6H3.99995C4.22085 18.6 4.39995 18.4209 4.39995 18.2V17.8C4.39995 17.5791 4.22085 17.4 3.99995 17.4Z"
				fill="black"
			/>
			<path
				d="M2.4001 19H2.0001C1.7792 19 1.6001 19.1791 1.6001 19.4V19.8C1.6001 20.0209 1.7792 20.2 2.0001 20.2H2.4001C2.621 20.2 2.8001 20.0209 2.8001 19.8V19.4C2.8001 19.1791 2.621 19 2.4001 19Z"
				fill="black"
			/>
			<path
				d="M3.99995 19H3.59995C3.37905 19 3.19995 19.1791 3.19995 19.4V19.8C3.19995 20.0209 3.37905 20.2 3.59995 20.2H3.99995C4.22085 20.2 4.39995 20.0209 4.39995 19.8V19.4C4.39995 19.1791 4.22085 19 3.99995 19Z"
				fill="black"
			/>
			<path
				d="M2.4001 20.6H2.0001C1.7792 20.6 1.6001 20.7791 1.6001 21V21.4C1.6001 21.6209 1.7792 21.8 2.0001 21.8H2.4001C2.621 21.8 2.8001 21.6209 2.8001 21.4V21C2.8001 20.7791 2.621 20.6 2.4001 20.6Z"
				fill="black"
			/>
			<path
				d="M3.99995 20.6H3.59995C3.37905 20.6 3.19995 20.7791 3.19995 21V21.4C3.19995 21.6209 3.37905 21.8 3.59995 21.8H3.99995C4.22085 21.8 4.39995 21.6209 4.39995 21.4V21C4.39995 20.7791 4.22085 20.6 3.99995 20.6Z"
				fill="black"
			/>
			<path
				d="M22 5.40002H20.4C20.1791 5.40002 20 5.57912 20 5.80002V7.40002C20 7.62092 20.1791 7.80002 20.4 7.80002H22C22.2209 7.80002 22.4 7.62092 22.4 7.40002V5.80002C22.4 5.57912 22.2209 5.40002 22 5.40002ZM21.6 7.00002H20.8V6.20002H21.6V7.00002Z"
				fill="black"
			/>
			<path
				d="M22 8.59998H20.4C20.1791 8.59998 20 8.77908 20 8.99998V10.6C20 10.8209 20.1791 11 20.4 11H22C22.2209 11 22.4 10.8209 22.4 10.6V8.99998C22.4 8.77908 22.2209 8.59998 22 8.59998ZM21.6 10.2H20.8V9.39998H21.6V10.2Z"
				fill="black"
			/>
			<path
				d="M22 11.8H20.4C20.1791 11.8 20 11.9791 20 12.2V13.8C20 14.0209 20.1791 14.2 20.4 14.2H22C22.2209 14.2 22.4 14.0209 22.4 13.8V12.2C22.4 11.9791 22.2209 11.8 22 11.8ZM21.6 13.4H20.8V12.6H21.6V13.4Z"
				fill="black"
			/>
			<path
				d="M22 15H20.4C20.1791 15 20 15.1791 20 15.4V17C20 17.2209 20.1791 17.4 20.4 17.4H22C22.2209 17.4 22.4 17.2209 22.4 17V15.4C22.4 15.1791 22.2209 15 22 15ZM21.6 16.6H20.8V15.8H21.6V16.6Z"
				fill="black"
			/>
			<path
				d="M22 18.2H20.4C20.1791 18.2 20 18.3791 20 18.6V20.2C20 20.4209 20.1791 20.6 20.4 20.6H22C22.2209 20.6 22.4 20.4209 22.4 20.2V18.6C22.4 18.3791 22.2209 18.2 22 18.2ZM21.6 19.8H20.8V19H21.6V19.8Z"
				fill="black"
			/>
		</svg>
	);
}
