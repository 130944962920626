export enum WardAttributes {
	LastName = 22405, // Фамилия
	FirstName = 22406, // Имя
	MiddleName = 22407, // Отчество
	Birthday = 22408, // Дата рождения
	Age = 25547, // Возраст
	Gender = 22410, // Пол
	RegularityOfAssistance = 25549, // Регулярность помощи
	InPrayList = 22431, // В списке для молитв
	ReasonOfInPrayList = 24050, // Причина добавления в список для молитв
	Dead = 22419, // Скончался
	DateOfDeath = 22434, // Дата смерти
	Phone = 22424, // Контактный телефон
	MobilePhone = 25550, // Мобильный телефон
	HomePhone = 22425, // Домашний телефон
	AdditionalPhone = 23756, // Дополнительный телефон
	DistrictOfAssistance = 25552, // Район адресной помощи
	Settlement = 22426, // Населенный пункт
	Subway = 22427, // Метро
	Address = 22428, // Адрес (улица, дом, строение, корпус)
	Apartment = 24051, // Квартира, код подъезда
	Comments = 25679, // Комментарии для операторов
	DestinationPhone = 25747, // Номер, на который поступил звонок

	Email = 24589, // Email
	Region = 25701, // Регион
	DistrictOfMoscow = 25648, // Округ г. Москвы
	DisabilityCategory = 25554, // Группа инвалидности
	DisabilityCategoryLevel = 25555, // Степень (инвалидности)
	DisabilityInformationAndAppointmentReason = 25556, // Сведения об инвалидности и основании назначения
	MentalDisorders = 25557, // Наличие психических отклонений (есть ли неадекватность в словах, поступках и т.п.)
	HasSocialWorker = 22421, // Есть социальный работник?
	SocialWorkerFrequency = 25558, // Как часто приходит? Если НЕТ, то почему?
	MaritalStatus = 24626, // Семейное положение
	SpouseFullName = 22415, // ФИО мужа/жены
	HasChildren = 22414, // Наличие детей
	RelativesInfo = 22433, // Сведения о родственниках (ФИО, степень родства, как можно связаться)
	IsAlone = 25560, // Человек живет один?
	ColivingInfo = 25561, // С кем? (живет)
	ColivingWithAlcoholAddictionOrMentalIllness = 25562, // Живут ли с ним люди, страдающие алкогольной зависимостью или психическими заболеваниями?
	NeighborsHelp = 25563, // Помогают ли соседи? Как зовут и как можно связаться при необходимости?
	AnybodyHelp = 22422, // Есть ли ещё люди, которые помогают? Как зовут и как можно связаться при необходимости?
	Religion = 25692, // Вероисповедание
	OtherReligion = 25693, // Другое вероисповедание
	NameInChristening = 22409, // Имя в крещении
	AngelDate = 22413, // День ангела
	ParishChurch = 22416, // Приходской храм
	PriestRequired = 25694, // Нужно ли пригласить священника?
	ChurchinessInfo = 22423, // Комментарии о воцерковленности
	CanDoSanitary = 25657, // Что может делать самостоятельно - сангигиенические процедуры
	CanDoCook = 25658, // Что может делать самостоятельно - приготовление пищи
	CanDoCleaning = 25659, // Что может делать самостоятельно - уборка квартиры
	CanDoFlatMovement = 25660, // Что может делать самостоятельно - передвижение по квартире
	CanDoCityMovement = 25661, // Что может делать самостоятельно - передвижение по городу
	HouseholdSelfService = 25662, // Комментарии о степени бытового самообслуживания
	HelpRequiredVolunteers = 25666, // Чья помощь нужна - Добровольцев
	HelpRequiredNurse = 25667, // Чья помощь нужна - Сиделки
	HelpRequiredMedicalStaff = 25668, // Чья помощь нужна - Мед. персонала
	HelpRequiredPriest = 25669, // Чья помощь нужна - Священника
	HelpRequiredSocialWorker = 26034, // Чья помощь нужна - Социального работника
	HelpRequiredSpecialist = 26035, // Чья помощь нужна - специалиста (юрист, психолог и др.)
	HelpTypeCleaningCook = 25671, // Какая помощь необходима - убираться, готовить
	HelpTypeBuyDrugs = 25672, // Какая помощь необходима - покупать продукты, лекарства
	HelpTypeSanitary = 25673, // Какая помощь необходима - сангигиенические процедуры
	HelpTypeChangingDiapers = 25674, // Какая помощь необходима - замена памперсов
	HelpTypeTreatmentOfBedsores = 25675, // Какая помощь необходима - обработка пролежней
	HelpTypeTalk = 25676, // Какая помощь необходима - пообщаться
	HelpTypeRenovation = 26022, // Какая помощь необходима - ремонт помещения
	HelpTypeHomeSpecialist = 26023, // Какая помощь необходима - помощь сантехника, электрика, специалиста по компьютерам
	HelpTypeEscort = 26024, // Какая помощь необходима - сопровождение (прогулка, храм, больница и т.д.)
	HelpTypeTransportation = 26025, // Какая помощь необходима - транспорт: такси/ автоволонтер/ спецтранспорт
	HelpTypeSocialWorker = 26026, // Какая помощь необходима - работа соцработника со случаем
	HelpTypeSpecialist = 26027, // Какая помощь необходима - запрос на специалиста (врач, если не предоставляет поликлиника бесплатно; нотариус и др.)
	HelpTypeEscortChildren = 26028, // Какая помощь необходима - сопровождение детей
	HelpTypeChildrenClasses = 26029, // Какая помощь необходима - занятия с детьми
	HelpTypeDonate = 26030, // Какая помощь необходима - пожертвовать технику/мебель/вещи
	HelpTypeOnlineTalk = 26031, // Какая помощь необходима - онлайн-общение
	HelpTypeAdditional = 25677, // Какая ещё, кроме вышеперечисленной (нужна помощь)
	HelpRequiredFrequency = 25678, // Как часто (нужна помощь)
	HelpMonthStart = 26036, // Месяц начала помощи (ММ.ГГГГ)
	HelpMonthEnd = 26037, // Месяц окончания помощи (ММ.ГГГГ)
	FinancialSituation = 25690, // Финансовое положение
	FinancialSituationInfo = 25691, // Комментарии о финансовом положении
	CommonComment = 25695, // Общий комментарий о человеке и ситуации
	FullNameofVolunteer = 25696, // ФИО опросившего добровольца
	FillingDate = 25697, // Дата заполнения
	CoordinatorAssistant = 25702, // Помощник координатора
	CoordinatorComment = 25703, // Комментарии координатора
	AdditionalInfo = 25704, // Иная дополнительная информация
	RegularVolunteers = 25705, // Постоянные добровольцы (архив)
	Projects = 25706, // Проекты (архив)
	PB_s = 25707, // ПБ_с
	TargetedAssistanceSao = 25708, // Адресная помощь: САО
	TargetedAssistanceSvao = 25709, // Адресная помощь: СВАО
	TargetedAssistanceVao = 25710, // Адресная помощь: ВАО
	TargetedAssistanceUvao = 25711, // Адресная помощь: ЮВАО
	TargetedAssistanceUao = 25712, // Адресная помощь: ЮАО
	TargetedAssistanceUzao = 25713, // Адресная помощь: ЮЗАО
	TargetedAssistanceZao = 25714, // Адресная помощь: ЗАО
	TargetedAssistanceSzao = 25715, // Адресная помощь: СЗАО
	MMO = 25716, // ММО
	MO = 25717, // МО
	InquiryOffice = 25718, // Справочная
	Patronage = 25719, // Патронаж
	TransportationServices = 25720, // Перевозки
	WashingRoom = 25721, // Помывочная
	WashingRoomOurs = 25722, // Помывочная наша
	HouseForMother = 25723, // Дом для мамы
	OtherProject = 25724, // Другой проект
}
