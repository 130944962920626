import React from "react";
import { observer } from "mobx-react";
import cn from "classnames";

import { XHistoryItem } from "@external-types/process";

import { formatDateTime } from "@helpers/datetime";
import { fullName } from "@helpers/user";

import { CaretIcon } from "@icons";
import { Row } from "../../../../../src/components/panel-test/row";
import { Column } from "../../../../../src/components/panel-test/column";
import { TaskFieldValue } from "./task-field-value";

interface Props {
	title: string;
	executionTime?: string;
	children?: React.ReactNode;
	executor?: React.ReactNode;
}

export const TaskStep = observer(({ children, executor, executionTime, title }: Props) => {
	const [collapsed, setCollapsed] = React.useState(true);

	function handleClick() {
		setCollapsed(!collapsed);
	}

	return (
		<div>
			<div className="task-step-summary" onClick={handleClick}>
				<div className="task-step-summary__state">
					<div className="task-step-summary-state">{title}</div>
					<div className="task-step-summary-execution-time">{executionTime}</div>
				</div>
				{executor && (
					<div className="task-step-summary__executor task-step-summary-executor">
						<div className="task-step-summary-executor__label">Выполнил</div>
						<div className="task-step-summary-executor__value">{executor}</div>
					</div>
				)}
				<div className={cn("task-step-summary__caret", { "task-step-summary__caret--expanded": !collapsed })}>
					<CaretIcon />
				</div>
			</div>

			{!collapsed && <div className="task-step-fields">{children}</div>}
		</div>
	);
});

export const HistoryTaskStep = observer(({ historyItem }: { historyItem: XHistoryItem }) => {
	const executionTime = historyItem.tasks.length > 0 ? formatDateTime(historyItem.tasks[0].execute) : "";
	const executor = historyItem.tasks.map(task => (
		<React.Fragment key={task.id}>
			{fullName(task.executor)}
			<br />
		</React.Fragment>
	));

	return (
		<TaskStep executionTime={executionTime} title={historyItem.state.state} executor={executor}>
			<div className="task-step-fields">
				{historyItem.tasks.map(task => (
					<Row key={task.id}>
						<Column borderless>
							{task.values.map(field => (
								<TaskFieldValue key={field.id} field={field} taskId={task.id} />
							))}
						</Column>
					</Row>
				))}
			</div>
		</TaskStep>
	);
});
