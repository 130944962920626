import React, { useContext } from "react";

import { VolunteerAttributes } from "../../../../constants/volunteer-attributes";
import { Volunteer } from "../../../../stores/volunteers-store";
import { ScopeCardInfo } from "./base-info-section";
import { AttrRating } from "../../components/rating";
import { StoreContext } from "../../../../context";

interface Props {
	entry: Volunteer;
}

export const CharacteristicsSection = ({ entry }: Props) => {
	const { volunteersStore } = useContext(StoreContext);
	const volunteer = volunteersStore.currentItem!;

	return (
		<div className="scope-card-content__row">
			<div className="scope-card-content__column">
				<div className="scope-card-heading">Характеристика</div>
				<div>
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.HasAuto)} scope={entry.scope} />
					<ScopeCardInfo
						attr={entry.getAttr(VolunteerAttributes.HasMedicalExamination)}
						scope={entry.scope}
					/>
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.TrainingCompleted)} scope={entry.scope} />
					<ScopeCardInfo
						attr={entry.getAttr(VolunteerAttributes.DateTrainingCompleted)}
						scope={entry.scope}
					/>
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.CoordinatorComments)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.PriestComments)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.ImportantNotes)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.AdditionalNotes)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.ReasonOfHelping)} scope={entry.scope} />
				</div>
				<div className="scope-card-heading">Кому и как хочет помогать</div>
				<div>
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.HelpToAdults)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.HelpToChildren)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.HelpRemote)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.HelpByAuto)} scope={entry.scope} />

					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.WhenCanHelp)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.MoreThanOncePerWeek)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.OncePerWeek)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.OncePerMonth)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.TwicePerMonth)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.WhenCanHelpComments)} scope={entry.scope} />
				</div>
			</div>
			<div className="scope-card-content__column">
				<div className="scope-card-heading rating-heading">Рейтинг {volunteer.averageRating}</div>
				<div>
					<AttrRating attr={entry.getAttr(VolunteerAttributes.HowIsGood)} />
					<AttrRating attr={entry.getAttr(VolunteerAttributes.AlwaysCompletesRequests)} />
					<AttrRating attr={entry.getAttr(VolunteerAttributes.CanWarkHard)} />
					<AttrRating attr={entry.getAttr(VolunteerAttributes.Responsibility)} />
					<AttrRating attr={entry.getAttr(VolunteerAttributes.Diligence)} />
					<AttrRating attr={entry.getAttr(VolunteerAttributes.Mobility)} />
				</div>
			</div>
		</div>
	);
};
