import React, { useContext } from "react";
import { InfiniteDataList } from "@app-components/infinite-data-list";
import { XTaskExtRef } from "@external-types/process";
import { formatDateTime } from "@helpers/datetime";

import { StoreContext } from "../../../context";
import { getTaskExtAttributeValue } from "../../../helpers/attributes";
import { ProcessFieldValue } from "../../../constants/process-field-value";

function getTopic(taskRef: XTaskExtRef) {
	return (
		getTaskExtAttributeValue(taskRef, ProcessFieldValue.RequestTopic) ||
		getTaskExtAttributeValue(taskRef, ProcessFieldValue.CallTopic)
	);
}

function getMessage(taskRef: XTaskExtRef) {
	return (
		getTaskExtAttributeValue(taskRef, ProcessFieldValue.RequestMessage) ||
		getTaskExtAttributeValue(taskRef, ProcessFieldValue.AppealMessage)
	);
}

export function WardRequestHistory() {
	const { wardsStore } = useContext(StoreContext);

	React.useEffect(() => {
		wardsStore.currentItem!.datasource.fetchPage(1, 3);
	}, []);

	return (
		<>
			<div className="scope-card-heading">История обращений</div>
			<InfiniteDataList
				captions={["Дата обращения", "Тема просьбы", "Текст обращения", "Статус"]}
				store={wardsStore.currentItem!.datasource}
				className="history-grid"
				EntryComponent={({ entry }) => (
					<tr className="history-grid__row">
						<td className="history-grid__cell">{formatDateTime(entry.create, "YYYY.MM.DD HH:mm")}</td>
						<td className="history-grid__cell">{getTopic(entry)}</td>
						<td className="history-grid__cell">{getMessage(entry)}</td>
						<td className="history-grid__cell">{entry.state}</td>
					</tr>
				)}
				createKey={entry => entry.id}
				useInfiniteFetch={false}
			/>
		</>
	);
}
