import React from "react";
import { SvgIcon } from "@app-types/models";

export function AddUser({ className }: SvgIcon) {
	return (
		<svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
			<path
				d="M2.41699 25C1.87764 25 1.44043 24.5628 1.44043 24.0234C1.44043 19.1233 5.427 15.1367 10.3271 15.1367H11.792C12.7948 15.1367 13.7794 15.3027 14.7186 15.6301C15.2279 15.8077 15.4968 16.3645 15.3192 16.8737C15.1417 17.383 14.5849 17.652 14.0756 17.4744C13.3437 17.2192 12.5753 17.0898 11.792 17.0898H10.3271C6.50395 17.0898 3.39355 20.2002 3.39355 24.0234C3.39355 24.5628 2.95635 25 2.41699 25ZM17.5537 6.5918C17.5537 2.95708 14.5966 0 10.9619 0C7.3272 0 4.37012 2.95708 4.37012 6.5918C4.37012 10.2265 7.3272 13.1836 10.9619 13.1836C14.5966 13.1836 17.5537 10.2265 17.5537 6.5918ZM15.6006 6.5918C15.6006 9.14956 13.5197 11.2305 10.9619 11.2305C8.40415 11.2305 6.32324 9.14956 6.32324 6.5918C6.32324 4.03403 8.40415 1.95312 10.9619 1.95312C13.5197 1.95312 15.6006 4.03403 15.6006 6.5918ZM22.583 19.1406H19.6533V16.2109C19.6533 15.6716 19.2161 15.2344 18.6768 15.2344C18.1374 15.2344 17.7002 15.6716 17.7002 16.2109V19.1406H14.7705C14.2312 19.1406 13.7939 19.5778 13.7939 20.1172C13.7939 20.6565 14.2312 21.0938 14.7705 21.0938H17.7002V24.0234C17.7002 24.5628 18.1374 25 18.6768 25C19.2161 25 19.6533 24.5628 19.6533 24.0234V21.0938H22.583C23.1224 21.0938 23.5596 20.6565 23.5596 20.1172C23.5596 19.5778 23.1224 19.1406 22.583 19.1406Z"
				fill="currentColor"
			/>
		</svg>
	);
}
