import React, { Component } from "react";
import cn from "classnames";
import onClickOutside from "react-onclickoutside";
import { CrossIcon } from "@icons";
import { Overlay } from "@app-components/controls/overlay";

export interface Props {
	className?: string;
	onClose: () => void;
	children: React.ReactNode;
}

class DialogComponent extends Component<Props> {
	public handleClickOutside = () => {
		this.props.onClose();
	};

	public render() {
		const { className, onClose } = this.props;

		return (
			<div className={cn("dialog", className)}>
				<button className="dialog__close-button" onClick={onClose}>
					<CrossIcon />
				</button>
				{this.props.children}
			</div>
		);
	}
}

const DialogWithClickOutside = onClickOutside(DialogComponent);

export function Dialog(props: Props) {
	return (
		<>
			<div className="dialog-container">
				<DialogWithClickOutside {...props} />
			</div>
			<Overlay global showLoader={false} />
		</>
	);
}
