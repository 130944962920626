import React from "react";
import cn from "classnames";

import { fullName } from "@helpers/user";
import { formatDateTime } from "@helpers/datetime";
import { XProcessAdminInfo } from "@external-types/process";

import { HistoryTaskStep, TaskStep } from "./task-step";

import { XUserRef } from "@external-types/user";
import {
	IRequestHistoryItem,
	NewRequestStepHistoryItem,
	RequestHistoryItem,
	RequestHistoryItemType,
	VolunteersExecutionStepHistoryItem,
	VolunteersRequiredStepHistoryItem,
} from "../../stores/request-store/request-history";
// import { RequestState } from "../requests/request-item";
import { RequestItem } from "../../stores/request-store/request-item";
import { ProcessedRequestItem } from "../../stores/request-store/processed-request-item";
import { Row } from "../../../../../src/components/panel-test/row";
import { Column } from "../../../../../src/components/panel-test/column";
import { TaskFieldValue } from "./task-field-value";

interface Props {
	requestItem: RequestItem | ProcessedRequestItem;
	Actions?: React.ReactNode;
}

const VolunteersRequiredStep = ({ history }: { history: VolunteersRequiredStepHistoryItem }) => {
	const [activeExecutor, setActiveExecutor] = React.useState(() =>
		history.executors.length === 0 ? null : history.executors[0],
	);

	function handleChangeExecutor(activeExecutor: XUserRef) {
		return function() {
			setActiveExecutor(activeExecutor);
		};
	}

	return (
		<div className="help-request-volunteers">
			{history.executors.length === 1 && (
				<div className="help-request-volunteer">{fullName(history.executors[0])}</div>
			)}

			<div className="help-request-volunteers-panel">
				{history.executors.length > 1 && (
					<div className="help-request-volunteers__items">
						{history.executors.map(e => (
							<div
								key={e.id}
								onClick={handleChangeExecutor(e)}
								className={cn("help-request-volunteers__item", {
									"help-request-volunteers__item--active": e.id === activeExecutor?.id,
								})}
							>
								{fullName(e)}
							</div>
						))}
					</div>
				)}

				<div className="help-request-volunteers__content">
					{history.getActiveHistory(activeExecutor!.id)!.map(x => (
						<div key={x.state.id} className="help-request-volunteers-description">
							<div className="help-request-volunteers-description__header">{x.state.state}</div>
							<div className="help-request-volunteers-description__status">
								<div className="help-request-volunteers-description__state">{x.tasks[0].action}</div>
								<div className="help-request-volunteers-description__time">
									{formatDateTime(x.tasks[0].execute)}
								</div>
							</div>
							<div className="help-request-volunteers-description__content">
								{x.tasks[0].values.map(v => v.value)}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

const VolunteersExecutionStep = ({ historyItem }: { historyItem: VolunteersExecutionStepHistoryItem }) => {
	const items = historyItem.items;
	const mainItem = historyItem.historyItem;
	const [activeItem, setActiveItem] = React.useState(() => (items.length === 0 ? null : items[0]));

	function handleActiveChange(activeItem: XProcessAdminInfo) {
		return function() {
			setActiveItem(activeItem);
		};
	}

	return (
		<>
			<div className="help-request-volunteers">
				{items.length === 1 && <div className="help-request-volunteer">{items[0].scope.name}</div>}

				<div className="help-request-volunteers-panel">
					{items.length > 1 && (
						<div className="help-request-volunteers__items">
							{items.map(e => (
								<div
									key={e.id}
									onClick={handleActiveChange(e)}
									className={cn("help-request-volunteers__item", {
										"help-request-volunteers__item--active": e.id === activeItem?.id,
									})}
								>
									{e.scope.name}
								</div>
							))}
						</div>
					)}

					<div className="help-request-volunteers__content">
						{activeItem != null &&
							activeItem.history.map(x => (
								<div key={x.state.id} className="help-request-volunteers-description">
									<div className="help-request-volunteers-description__header">{x.state.state}</div>
									<div className="help-request-volunteers-description__status">
										{x.tasks.length > 0 && (
											<>
												<div className="help-request-volunteers-description__state">
													{x.tasks[0].action}
												</div>
												<div className="help-request-volunteers-description__time">
													{formatDateTime(x.tasks[0].execute)}
												</div>
											</>
										)}
									</div>
									<div className="help-request-volunteers-description__content">
										{x.tasks.length > 0 && x.tasks[0].values.map(v => v.value)}
									</div>
								</div>
							))}
					</div>
				</div>
			</div>
			<div>
				{mainItem.tasks.map(task => (
					<Row key={task.id}>
						<Column borderless>
							{task.values.map(field => (
								<TaskFieldValue key={field.id} field={field} taskId={task.id} />
							))}
						</Column>
					</Row>
				))}
			</div>
		</>
	);
};

const NewRequestHistoryStep = ({ historyItem }: { historyItem: NewRequestStepHistoryItem }) => {
	return (
		<>
			{historyItem.items.map(item => (
				<div key={item.tasks[0].id}>
					{item.tasks.map(task => (
						<Row key={task.id}>
							<Column borderless>
								{task.values.map(field => (
									<TaskFieldValue key={field.id} field={field} taskId={task.id} />
								))}
							</Column>
						</Row>
					))}
				</div>
			))}
		</>
	);
};

const HelpRequestHistoryItem = ({ requestHistoryItem }: { requestHistoryItem: IRequestHistoryItem }) => {
	switch (requestHistoryItem.type) {
		case RequestHistoryItemType.NewRequest:
			return (
				<div className="task-sub-item">
					<div className="task-sub-item-connector" />
					<div>
						<TaskStep title={requestHistoryItem.title}>
							<NewRequestHistoryStep historyItem={requestHistoryItem as NewRequestStepHistoryItem} />
						</TaskStep>
					</div>
				</div>
			);
		case RequestHistoryItemType.VolunteersRequired:
			return (
				<div className="task-sub-item">
					<div className="task-sub-item-connector" />
					<div>
						<TaskStep title={requestHistoryItem.title}>
							<VolunteersRequiredStep history={requestHistoryItem as VolunteersRequiredStepHistoryItem} />
						</TaskStep>
					</div>
				</div>
			);
		case RequestHistoryItemType.Common:
			const historyItem = requestHistoryItem as RequestHistoryItem;

			return (
				<div className="task-sub-item">
					<div className="task-sub-item-connector" />
					<div>
						<HistoryTaskStep historyItem={historyItem.historyItem} />
					</div>
				</div>
			);
		case RequestHistoryItemType.VolunteersExecution: {
			return (
				<div className="task-sub-item">
					<div className="task-sub-item-connector" />
					<div>
						<TaskStep title={requestHistoryItem.title}>
							<VolunteersExecutionStep
								historyItem={requestHistoryItem as VolunteersExecutionStepHistoryItem}
							/>
						</TaskStep>
					</div>
				</div>
			);
		}
	}
};

export function HelpRequestHistory({ requestItem, Actions }: Props) {
	const history = requestItem.requestHistory!;

	return (
		<div className="help-request-history">
			<div className="help-request__header">Обработка просьбы</div>
			{Actions}
			{/*<RequestState item={requestItem} />*/}
			{history.items.length > 0 &&
				history.items.map(historyItem => (
					<HelpRequestHistoryItem requestHistoryItem={historyItem} key={historyItem.id} />
				))}
		</div>
	);
}
