export enum VolunteerSectionId {
	BaseInfo = 0,
	Characteristics = 1,
	Contacts = 2,
	Projects = 3,
	ProjectsArchive = 4,
	Health = 5,
	WorkAndFamily = 6,
	Churchness = 7,
	Interview = 8,
}

interface VolunteerSection {
	title: string;
	id: VolunteerSectionId;
}

export const VolunteerSections: VolunteerSection[] = [
	{ id: VolunteerSectionId.BaseInfo, title: "Основное" },
	{ id: VolunteerSectionId.Characteristics, title: "Характеристика" },
	{ id: VolunteerSectionId.Contacts, title: "Контакты" },
	{ id: VolunteerSectionId.Projects, title: "Проекты" },
	{ id: VolunteerSectionId.ProjectsArchive, title: "Архив проектов" },
	{ id: VolunteerSectionId.Health, title: "Здоровье" },
	{ id: VolunteerSectionId.WorkAndFamily, title: "Семья и работа" },
	{ id: VolunteerSectionId.Churchness, title: "Воцерковленность" },
	{ id: VolunteerSectionId.Interview, title: "Информация о собеседовании" },
];
