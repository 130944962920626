import React, { useContext, useState } from "react";
import { observer } from "mobx-react";

import { Form, IForm } from "@app-models/form";
import { IField } from "@app-models/form";
import { TextField, PasswordField, FormRow } from "@app-components/form";
import { LightButton } from "@app-components/buttons/light-button";
import { EditIcon } from "@icons";
import { CircleButton } from "@app-components/buttons/circle-button";

import { Paths } from "../../routes/paths";
import { PageHeader } from "../../components/page-header";
import { withFetching } from "../../components/with-fetching";
import { StoreContext } from "../../context";
import { createProfileFormStore } from "./profile-form-store";

interface UserState {
	username: string;
	password?: string;
	newPassword?: string;
	newPasswordValidation?: string;
	lastName?: string;
	firstName?: string;
	middleName?: string;
	phone: string;
	email: string;
}

function withFormRow(Component: React.ComponentType<{ field: IField; disabled?: boolean }>) {
	const Editor = ({ field, disabled }: { field: IField; disabled?: boolean }) => {
		return (
			<FormRow field={field}>
				<Component field={field} disabled={disabled} />
			</FormRow>
		);
	};

	return Editor;
}

function EditForm({ form, onCloseEdit }: { form: IForm; onCloseEdit: () => void }) {
	const { appStore } = useContext(StoreContext);

	async function handleSave() {
		if (form.validate()) {
			const { password, newPassword, newPasswordValidation, ...payload } = form.values<UserState>();

			await appStore.updateProfile(payload);

			if (password && newPassword && newPasswordValidation) {
				await appStore.updatePassword({
					old: password,
					new: newPassword,
				});
			}

			onCloseEdit();
		}
	}

	return (
		<div className="profile-content">
			<div className="profile-forms">
				<div className="profile-content__column">
					<div className="profile-content__header">Логин/пароль</div>

					<div className="form">
						<TextFieldFormRow field={form.get("username")} disabled />
						<PasswordFieldFormRow field={form.get("password")} />
						<PasswordFieldFormRow field={form.get("newPassword")} />
						<PasswordFieldFormRow field={form.get("newPasswordValidation")} />
					</div>
				</div>
				<div className="profile-content__column">
					<div className="profile-content__header">Личные данные</div>

					<div className="form">
						<TextFieldFormRow field={form.get("lastName")} />
						<TextFieldFormRow field={form.get("firstName")} />
						<TextFieldFormRow field={form.get("middleName")} />
					</div>
				</div>
				<div className="profile-content__column">
					<div className="profile-content__header">Контакты</div>

					<div className="form">
						<div className="form__row form__row--string">
							<TextFieldFormRow field={form.get("phone")} />
							<TextFieldFormRow field={form.get("email")} />
						</div>
					</div>
				</div>
			</div>
			<div className="profile-content__actions">
				<button className="button button--primary" onClick={handleSave}>
					Сохранить
				</button>
				<LightButton onClick={onCloseEdit}>Отмена</LightButton>
			</div>
		</div>
	);
}

function ViewFormRow({ field }: { field: IField }) {
	return (
		<div className="form__row">
			<label className="form__label">{field.label}</label>
			<div className="form__value">{field.value}</div>
		</div>
	);
}

function ViewForm({ form, onEdit }: { form: IForm; onEdit: () => void }) {
	return (
		<div className="profile-content">
			<div className="profile-content__toolbar">
				<CircleButton Icon={EditIcon} onClick={onEdit} />
			</div>
			<div className="profile-forms">
				<div className="profile-content__column">
					<div className="profile-content__header">Логин</div>

					<div className="form">
						<ViewFormRow field={form.get("username")} />
					</div>
				</div>
				<div className="profile-content__column">
					<div className="profile-content__header">Личные данные</div>

					<div className="form">
						<ViewFormRow field={form.get("lastName")} />
						<ViewFormRow field={form.get("firstName")} />
						<ViewFormRow field={form.get("middleName")} />
					</div>
				</div>
				<div className="profile-content__column">
					<div className="profile-content__header">Контакты</div>

					<div className="form">
						<div className="form__row form__row--string">
							<ViewFormRow field={form.get("phone")} />
							<ViewFormRow field={form.get("email")} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const TextFieldFormRow = withFormRow(TextField);
const PasswordFieldFormRow = withFormRow(PasswordField);

function ProfileContent() {
	const { appStore } = useContext(StoreContext);
	const [isEditMode, setEditMode] = useState(false);

	const [form] = useState<Form>(() => {
		return createProfileFormStore(appStore.authUser!);
	});

	function handleStartEdit() {
		setEditMode(true);
	}

	function handleCloseEdit() {
		setEditMode(false);
	}

	return (
		<div className="page__content">
			<PageHeader title="Личный кабинет" />

			{isEditMode ? (
				<EditForm form={form} onCloseEdit={handleCloseEdit} />
			) : (
				<ViewForm form={form} onEdit={handleStartEdit} />
			)}
		</div>
	);
}

const ContentWithFetching = withFetching(ProfileContent);

export const Profile = observer(function() {
	const [initialized, setInitialized] = React.useState(false);

	async function fetch() {
		setInitialized(true);
	}

	return <ContentWithFetching fetch={fetch} initialized={initialized} path={Paths.Profile} />;
});
