import React, { useContext } from "react";
import { observer } from "mobx-react";
import { generatePath } from "react-router";

import { StoreContext } from "../../context";
import { Paths } from "../../routes/paths";
import { Page } from "../../components/page";
import { PageHeader } from "../../components/page-header";
import { FilterToolbar } from "../../../../../src/components/filters/filter-toolbar";
import { EmptyCalls } from "./components/empty-calls";
import { InfiniteDataList } from "../../../../../src/components/infinite-data-list";
import { CallTask } from "../../stores/call-center-store";
import { GridEntry } from "./components/grid-entry";
import { IncomingCallDetails } from "./components/incoming-call-details";
import { Header } from "./components/header";

const captions = ["Время", "Телефон", "Подопечный", "Тема", "Текст", "Кто добавил", "Статус"];

const GridEntryWithStore = observer(({ entry }: { entry: CallTask }) => {
	const { unhandledCallsStore: store } = useContext(StoreContext);

	const isActive = store.currentItem ? store.currentItem.taskId === entry.taskId : false;
	const isNotActive = store.currentItem ? !isActive : false;

	function handleAttach(scopeId: number) {
		return store.moveToScope(entry, scopeId);
	}

	function handleClick() {
		if (store.currentItem === null || store.currentItem.taskId !== entry.taskId) {
			store.fetchTask(entry.taskId);
		} else {
			store.clearFocus();
		}
	}

	return (
		<GridEntry
			entry={entry}
			captions={captions}
			onClick={handleClick}
			isActive={isActive}
			isNotActive={isNotActive}
		>
			{store.currentItem != null && <IncomingCallDetails onAttach={handleAttach} details={store.currentItem} />}
		</GridEntry>
	);
});

export const IncomingCalls = observer(function() {
	const { unhandledCallsStore: store } = useContext(StoreContext);

	React.useEffect(() => {
		store.fetchPage(1, 50);
	}, [store]);

	const handleOpenNotification = (callId: number) => () => {
		window.open(generatePath(Paths.CallCenterItem, { callId }), "_blank");
		store.clearIncomingCall();
	};

	function handleFilterApply(filterId: string, value: any) {
		store.applyFilter(filterId, value);
	}

	return (
		<Page path={Paths.CallCenterUnhandled}>
			<div className="page__content">
				{store.incomingCall != null && (
					<div className="incoming-call-notification" onClick={handleOpenNotification(store.incomingCall.id)}>
						<div className="incoming-call-notification__header">Поступил звонок</div>
						<div className="incoming-call-notification__row">
							<div className="incoming-call-notification__label">Звонящий</div>
							<div className="incoming-call-notification__value">{store.incomingCall.phone}</div>
						</div>
						{/*<div className="incoming-call-notification__row">*/}
						{/*	<div className="incoming-call-notification__label">Номер обращения</div>*/}
						{/*	<div className="incoming-call-notification__value">444333334234</div>*/}
						{/*</div>*/}
					</div>
				)}

				<PageHeader title="Необработанные звонки">
					<div className="filter-toolbar">
						<FilterToolbar
							filters={store.filter}
							onApply={handleFilterApply}
							hiddenFilters={["type", "schema", "orderBy"]}
						/>
					</div>
				</PageHeader>

				{!store.loading && store.items.length === 0 ? (
					<EmptyCalls />
				) : (
					<InfiniteDataList
						store={store}
						EntryComponent={GridEntryWithStore}
						createKey={entry => entry.taskId}
						captions={captions}
						pagerPosition="top"
						Header={<Header />}
					/>
				)}
			</div>
		</Page>
	);
});
