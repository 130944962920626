import React from "react";
import { SvgIcon } from "@app-types/models";

export default function({ className }: SvgIcon) {
	return (
		<svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
			<g opacity="0.5">
				<path
					d="M1.51172 1.54016L18.4883 19.4599"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M18.4883 1.54016L1.51172 19.4599"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	);
}
