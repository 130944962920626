import { action, observable } from "mobx";
import { IUploadFile, OptionItem } from "@app-types/models";
import { XSchemaFormItemInfo, XShemaFormItemType } from "@external-types/schema";
import { XAppAttributeRef } from "@external-types/type";
// @ts-ignore
import isNumeric from "isnumeric";
import EventEmitter from "@helpers/event-emitter";
import { XTaskValueInfo } from "@external-types/process";

export enum SchemaFieldType {
	Label = "label",
	Masked = "masked",
	Media = "media",
	Number = "number",
	Select = "select",
	String = "string",
	Switch = "switch",
	Text = "text",
}

export interface ISchemaField {
	id: number;
	label: string;
	type: SchemaFieldType;
	order: number;
	required: boolean;
	options: Array<OptionItem<string>>;
	isValid: boolean;
	value: string;
	files: IUploadFile[];
	validationMessage: string;
	visible: boolean;
	mask: Array<RegExp | string>;

	subscribeChange: (callback: (data: { id: number; value: string }) => void) => void;
	updateValue(value: string): void;
	updateFiles(files: IUploadFile[]): void;
	reset(): void;
	validate(): void;
}

export class SchemaField implements ISchemaField {
	public id: number;
	public label: string;
	public readonly type: SchemaFieldType;
	public order: number;
	public required: boolean;
	public options: Array<OptionItem<string>> = [];
	public mask: Array<RegExp | string> = [];
	private changeEventEmitter = new EventEmitter();

	@observable public value: string = "";
	@observable.ref public files: IUploadFile[] = [];
	@observable public isValid: boolean = true;
	@observable public validationMessage: string = "";
	@observable public visible: boolean = true;

	public constructor(item: XSchemaFormItemInfo | XAppAttributeRef | XTaskValueInfo) {
		this.id = item.id;
		this.label = item.name;
		// @ts-ignore
		this.order = item.hasOwnProperty("order") ? item.order : 0;
		// @ts-ignore
		this.required = item.hasOwnProperty("is_required") ? item.is_required : false;

		if (item.type === XShemaFormItemType.Enum) {
			const parsedValue = JSON.parse(item.options);

			this.options = (parsedValue.options ?? parsedValue).map(({ id, name, code, text }: any) => ({
				value: (id ?? code).toString(),
				label: name ?? text,
			}));
		} else if (item.type === XShemaFormItemType.Label) {
			this.value = item.options;
		} else if (item.type === XShemaFormItemType.Masked) {
			const mask = item.options.split("");
			this.mask = mask.map(item => {
				return item === "0" ? /\d/ : item;
			});
		}

		this.type = this.getType(item.type);

		if (this.type === SchemaFieldType.Switch) {
			if (!this.value) {
				this.value = this.options[0].value;
			}
		}
	}

	public subscribeChange(callback: (data: { id: number; value: string }) => void) {
		this.changeEventEmitter.on(callback);
	}

	@action
	public updateValue(value: string) {
		this.value = this.type === SchemaFieldType.Number ? value.replace(/\s/g, "").replace(/\,/g, ".") : value;

		this.changeEventEmitter.emit({ id: this.id, value });

		this.validate();
	}

	@action
	public updateFiles(files: IUploadFile[]) {
		this.files = files;

		this.validate();
	}

	@action
	public reset() {
		switch (this.type) {
			case SchemaFieldType.Switch: {
				if (!this.value && this.options.length > 0) {
					this.value = this.options[0].value;
				}
				break;
			}
			default: {
				this.value = "";
				break;
			}
		}
	}

	@action
	public validate() {
		if (this.required) {
			if (this.type === SchemaFieldType.Media && this.files.length === 0) {
				this.validationMessage = "Это поле обязательно к заполнению";
				this.isValid = false;
				return;
			} else if (this.type !== SchemaFieldType.Media && this.value === "") {
				this.isValid = false;
				this.validationMessage = "Это поле обязательно к заполнению";
				return;
			}
		}

		if (this.type === SchemaFieldType.Number && this.value !== "" && !isNumeric(this.value)) {
			this.isValid = false;
			this.validationMessage = "Это поле должно быть числом";
			return;
		}

		this.isValid = true;
		this.validationMessage = "";
	}

	private getType(fieldType: XShemaFormItemType) {
		switch (fieldType) {
			case XShemaFormItemType.String:
				return SchemaFieldType.String;
			case XShemaFormItemType.Text:
				return SchemaFieldType.Text;
			case XShemaFormItemType.Number:
				return SchemaFieldType.Number;
			case XShemaFormItemType.Enum:
				return this.options.find(option => option.label.toLowerCase() === "да") && this.options.length === 2
					? SchemaFieldType.Switch
					: SchemaFieldType.Select;
			case XShemaFormItemType.Media:
				return SchemaFieldType.Media;
			case XShemaFormItemType.Label:
				return SchemaFieldType.Label;
			case XShemaFormItemType.Masked:
				return SchemaFieldType.Masked;
		}
	}
}
