import React from "react";
import { Ward } from "../../../../stores/wards-store";
import { AttrFormRowInfo } from "./base-info-section";
import { WardAttributes } from "../../../../constants/ward-attributes";

interface Props {
	entry: Ward;
}

export const FamilySection = ({ entry }: Props) => {
	return (
		<>
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.MaritalStatus)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.SpouseFullName)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.HasChildren)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.RelativesInfo)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.IsAlone)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.ColivingInfo)} scope={entry.scope} />
			<AttrFormRowInfo
				attr={entry.getAttr(WardAttributes.ColivingWithAlcoholAddictionOrMentalIllness)}
				scope={entry.scope}
			/>
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.NeighborsHelp)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.AnybodyHelp)} scope={entry.scope} />
		</>
	);
};
