import React from "react";
import { XScopeAttrRef, XScopeChildInfo } from "@external-types/scope";
import { getAttributeValue } from "@helpers/scope-attributes";
import { LightButton } from "@app-components/buttons/light-button";

import { Ward } from "../../stores/wards-store";
import { WardAttributes } from "../../constants/ward-attributes";
import { generatePath } from "react-router";
import { Paths } from "../../routes/paths";

interface Props {
	entry: Ward;
}

function AttrFormRowInfo({ attr, label, scope }: { attr?: XScopeAttrRef; label?: string; scope: XScopeChildInfo }) {
	if (!attr || !attr.value) {
		return null;
	}

	return (
		<div className="form__row">
			<div className="form__label">{label ?? attr.name}</div>
			<div className="form__value">{getAttributeValue(attr, scope.type)}</div>
		</div>
	);
}

function FormRowInfo({ label, value }: { label: string; value: string }) {
	return (
		<div className="form__row">
			<div className="form__label">{label}</div>
			<div className="form__value">{value}</div>
		</div>
	);
}

export const WardBaseDetails = ({ entry }: Props) => {
	function handleClick() {
		Object.assign(document.createElement("a"), {
			target: "_blank",
			href: generatePath(Paths.WardItem, { id: entry.id }),
		}).click();
	}

	return (
		<div className="ward-base-details">
			<div className="ward-base-details__content">
				<div className="ward-base-details__column">
					<div className="ward-base-details__header">Подопечный</div>
					<FormRowInfo label="Подопечный" value={entry.fullName} />
					<AttrFormRowInfo attr={entry.getAttr(WardAttributes.Birthday)} scope={entry.scope} />
					<AttrFormRowInfo attr={entry.getAttr(WardAttributes.Age)} scope={entry.scope} />
					<AttrFormRowInfo attr={entry.getAttr(WardAttributes.Gender)} scope={entry.scope} />
				</div>
				<div className="ward-base-details__column">
					<div className="ward-base-details__header">Контакты</div>
					<AttrFormRowInfo attr={entry.getAttr(WardAttributes.Phone)} scope={entry.scope} />
					<AttrFormRowInfo attr={entry.getAttr(WardAttributes.MobilePhone)} scope={entry.scope} />
					<AttrFormRowInfo attr={entry.getAttr(WardAttributes.HomePhone)} scope={entry.scope} />
					<AttrFormRowInfo attr={entry.getAttr(WardAttributes.AdditionalInfo)} scope={entry.scope} />
					<AttrFormRowInfo attr={entry.getAttr(WardAttributes.Email)} scope={entry.scope} />
					<AttrFormRowInfo attr={entry.getAttr(WardAttributes.Region)} scope={entry.scope} />
					<AttrFormRowInfo attr={entry.getAttr(WardAttributes.Settlement)} scope={entry.scope} />
					<AttrFormRowInfo attr={entry.getAttr(WardAttributes.DistrictOfMoscow)} scope={entry.scope} />
					<AttrFormRowInfo attr={entry.getAttr(WardAttributes.Subway)} scope={entry.scope} />
					<AttrFormRowInfo attr={entry.getAttr(WardAttributes.Address)} scope={entry.scope} />
					<AttrFormRowInfo attr={entry.getAttr(WardAttributes.Apartment)} scope={entry.scope} />
				</div>
				<div className="ward-base-details__column">
					<div className="ward-base-details__header">Состояние</div>
					<AttrFormRowInfo attr={entry.getAttr(WardAttributes.DistrictOfAssistance)} scope={entry.scope} />
					<AttrFormRowInfo attr={entry.getAttr(WardAttributes.RegularityOfAssistance)} scope={entry.scope} />
					<AttrFormRowInfo attr={entry.getAttr(WardAttributes.InPrayList)} scope={entry.scope} />
					<AttrFormRowInfo attr={entry.getAttr(WardAttributes.ReasonOfInPrayList)} scope={entry.scope} />
					<AttrFormRowInfo attr={entry.getAttr(WardAttributes.Dead)} scope={entry.scope} />
					<AttrFormRowInfo attr={entry.getAttr(WardAttributes.DateOfDeath)} scope={entry.scope} />
					<AttrFormRowInfo attr={entry.getAttr(WardAttributes.CoordinatorComment)} scope={entry.scope} />
				</div>
			</div>
			<div className="ward-base-details__actions">
				<LightButton onClick={handleClick}>Подробнее</LightButton>
			</div>
		</div>
	);
};
