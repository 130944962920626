import React from "react";
import cn from "classnames";
import { firebaseProxy } from "@shared/firebase-proxy";
import { DialogPresenter } from "@app-components/dialog";
import { Button } from "@app-components/buttons/button";

import { NotificationMessage } from "../types";
import { ActiveCallIcon } from "./icons";
import { generatePath } from "react-router";
import { Paths } from "../routes/paths";
import { actions } from "../../../../src/actions";
import { getWardFullName } from "../helpers/ward-helper";
import { ProcessFieldValue } from "../constants/process-field-value";

interface Props {
	taskId: number;
	callerName: string;
	phoneNumber: string;
	onClose: () => void;
}

const CallNotificationDialog = ({ onClose, callerName, phoneNumber, taskId }: Props) => {
	function handleProcess() {
		window.open(generatePath(Paths.CallCenterItem, { callId: taskId }), "_blank");
		onClose();
	}

	return (
		<div className="call-notification">
			<div className="call-notification__header">Поступил новый звонок</div>
			<div className="call-notification__content">
				<div className="call-notification-source">{phoneNumber}</div>
				<div className="call-notification-description">{callerName}</div>
				<ActiveCallIcon className="call-notification-icon" />
			</div>
			<div className="call-notification__actions">
				<Button className={cn("button button-light")} onClick={handleProcess}>
					Начать оформление звонка
				</Button>
			</div>
		</div>
	);
};

export function CallNotification() {
	React.useEffect(() => {
		const lid = firebaseProxy.subscribe<NotificationMessage>(async message => {
			const taskId = parseInt(message.data.taskID, 10);

			const task = await actions.getTask(taskId);
			const scope = await actions.getScope(task.scope.id);
			const phoneNumber =
				task.history[0]?.tasks[0]?.values.find(v => v.item === ProcessFieldValue.PhoneCallSource)?.value ??
				"Неизвестный номер";
			const callerName = getWardFullName(scope, "Неизвестный проситель");

			const presenter = new DialogPresenter();

			presenter.show(
				<CallNotificationDialog
					taskId={taskId}
					phoneNumber={phoneNumber}
					callerName={callerName}
					onClose={() => presenter.close()}
				/>,
				"call-notification-dialog",
			);
		});

		return () => firebaseProxy.unsubscribe(lid);
	}, []);

	return <div />;
}
