import React, { useContext } from "react";
import { observer } from "mobx-react";
import { generatePath } from "react-router";
import cn from "classnames";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { Overlay } from "@app-components/controls/overlay";
import { Button } from "@app-components/buttons/button";
import { LightButton } from "@app-components/buttons/light-button";

import { StoreContext } from "../../context";
import { Paths } from "../../routes/paths";
import { withFetching } from "../../components/with-fetching";
import { VolunteerEditForm } from "./volunteer-edit-form";
import { VolunteerSections } from "../../stores/volunteers-store/volunteer-sections";
import { EmptyVolunteer } from "../../stores/volunteers-store/empty-volunteer";
import { BackButton } from "../components/back-button";

interface VolunteerEntryProps extends RouteComponentProps {
	item: EmptyVolunteer;
}

const VolunteerEntryContent = withRouter(
	observer(({ item, history }: VolunteerEntryProps) => {
		function handleCancel() {
			history.push(Paths.Volunteers);
		}

		async function handleSave() {
			const scope = await item.save();
			if (scope) {
				history.push(generatePath(Paths.Volunteer, { id: scope.id }));
			}
		}

		return (
			<>
				<div className="scope-card-content">
					<VolunteerEditForm entry={item} />
				</div>
				<div className="scope-card__actions">
					<Button className="button button--primary" onClick={handleSave}>
						Сохранить
					</Button>
					<LightButton onClick={handleCancel}>Отмена</LightButton>
				</div>
			</>
		);
	}),
);

const VolunteerEntry = withRouter(
	observer(({ item, history }: VolunteerEntryProps) => {
		function onBack() {
			history.push(Paths.Volunteers);
		}

		const handleChangeSection = (sectionId: number) => () => {
			item.updateCurrentSection(sectionId);
		};

		return (
			<>
				<div className="scope-card__section scope-card-section">
					<div className="scope-card-section__nav">
						<BackButton onClick={onBack} />
					</div>
					<div className="scope-card-section__menu scope-card-menu">
						{VolunteerSections.map(section => (
							<div
								className={cn("scope-card-menu__link", {
									"scope-card-menu__link--active": section.id === item.currentSectionId,
								})}
								onClick={handleChangeSection(section.id)}
								key={section.id}
							>
								{section.title}
							</div>
						))}
					</div>
				</div>
				<div className="scope-card__content">
					<div className="scope-card__header scope-card-header">
						<div className="scope-card-header__title">
							<header className="scope-card-header-title__main">Новый доброволец</header>
							<header className="scope-card-header-title__sub-title">Доброволец</header>
						</div>
					</div>
					<VolunteerEntryContent item={item} />
				</div>
				{item.fetching && <Overlay global={false} globalWait />}
			</>
		);
	}),
);

export const VolunteerContent = observer(function() {
	const { volunteersStore: store } = useContext(StoreContext);

	return (
		<div className="page__content scope-card">
			{store.emptyVolunteer !== null && <VolunteerEntry item={store.emptyVolunteer} />}
		</div>
	);
});

const ContentWithFetching = withFetching(VolunteerContent);

export const VolunteerCreate = observer(function({ children }: { children?: React.ReactNode }) {
	const { volunteersStore } = useContext(StoreContext);
	const [initialized, setInitialized] = React.useState(false);

	async function fetch() {
		await volunteersStore.fetchEmptyVolunteer();
		setInitialized(true);
	}

	return (
		<ContentWithFetching fetch={fetch} initialized={initialized} path={Paths.Volunteers}>
			{children}
		</ContentWithFetching>
	);
});
