import React from "react";
import { SchemaFormFieldRow } from "@app-components/schema-form";
import { SchemaForm } from "@app-models/schema-form";

import { WardAttributes } from "../../../../constants/ward-attributes";

interface Props {
	form: SchemaForm;
}

export const DescriptionEditSection = ({ form }: Props) => {
	return (
		<>
			<h3>Что может делать самостоятельно</h3>
			<SchemaFormFieldRow field={form.getField(WardAttributes.CanDoSanitary)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.CanDoCook)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.CanDoCleaning)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.CanDoFlatMovement)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.CanDoCityMovement)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.HouseholdSelfService)!} />

			<h3>Чья помощь нужна</h3>
			<SchemaFormFieldRow field={form.getField(WardAttributes.HelpRequiredVolunteers)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.HelpRequiredNurse)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.HelpRequiredMedicalStaff)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.HelpRequiredPriest)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.HelpRequiredSocialWorker)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.HelpRequiredSpecialist)!} />

			<h3>Какая помощь необходима</h3>
			<SchemaFormFieldRow field={form.getField(WardAttributes.HelpTypeCleaningCook)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.HelpTypeBuyDrugs)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.HelpTypeSanitary)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.HelpTypeChangingDiapers)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.HelpTypeTreatmentOfBedsores)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.HelpTypeTalk)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.HelpTypeAdditional)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.HelpRequiredFrequency)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.HelpMonthStart)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.HelpMonthEnd)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.HelpTypeRenovation)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.HelpTypeHomeSpecialist)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.HelpTypeEscort)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.HelpTypeTransportation)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.HelpTypeSocialWorker)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.HelpTypeSpecialist)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.HelpTypeEscortChildren)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.HelpTypeChildrenClasses)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.HelpTypeDonate)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.HelpTypeOnlineTalk)!} />

			<h3>Финансовое положение</h3>
			<SchemaFormFieldRow field={form.getField(WardAttributes.FinancialSituation)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.FinancialSituationInfo)!} />

			<h3>Дополнительная информация</h3>
			<SchemaFormFieldRow field={form.getField(WardAttributes.CommonComment)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.AdditionalInfo)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.FullNameofVolunteer)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.FillingDate)!} />
		</>
	);
};
