import React from "react";
import { SvgIcon } from "@app-types/models";

export function BlockUser({ className }: SvgIcon) {
	return (
		<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
			<path
				d="M2.91016 12.8657V11.8359C2.91016 10.5436 3.96156 9.49219 5.25391 9.49219C6.54625 9.49219 7.59766 10.5436 7.59766 11.8359V12.8524C8.84383 13.2635 9.74609 14.4382 9.74609 15.8203V16.875C9.74609 18.5981 8.34422 20 6.62109 20H3.92578C2.20266 20 0.800781 18.5981 0.800781 16.875V15.8203C0.800781 14.4527 1.68426 13.2883 2.91016 12.8657ZM5.25391 11.0547C4.82312 11.0547 4.47266 11.4052 4.47266 11.8359V12.6953H6.03516V11.8359C6.03516 11.4052 5.68469 11.0547 5.25391 11.0547ZM2.36328 16.875C2.36328 17.7366 3.06422 18.4375 3.92578 18.4375H6.62109C7.48266 18.4375 8.18359 17.7366 8.18359 16.875V15.8203C8.18359 14.9587 7.48266 14.2578 6.62109 14.2578H3.92578C3.06422 14.2578 2.36328 14.9587 2.36328 15.8203V16.875ZM11.582 10.5469C8.67426 10.5469 6.30859 8.18121 6.30859 5.27344C6.30859 2.36566 8.67426 0 11.582 0C14.4898 0 16.8555 2.36566 16.8555 5.27344C16.8555 8.18121 14.4898 10.5469 11.582 10.5469ZM11.582 1.5625C9.53582 1.5625 7.87109 3.22723 7.87109 5.27344C7.87109 7.31965 9.53582 8.98438 11.582 8.98438C13.6282 8.98438 15.293 7.31965 15.293 5.27344C15.293 3.22723 13.6282 1.5625 11.582 1.5625ZM4.25129 16.3281C4.25129 16.8675 4.68852 17.3047 5.22785 17.3047C5.76719 17.3047 6.20441 16.8675 6.20441 16.3281C6.20441 15.7888 5.76719 15.3516 5.22785 15.3516C4.68852 15.3516 4.25129 15.7888 4.25129 16.3281ZM10.5273 12.8906C10.5273 13.3221 10.8771 13.6719 11.3086 13.6719H12.0898C15.1484 13.6719 17.6367 16.1602 17.6367 19.2188C17.6367 19.6502 17.9865 20 18.418 20C18.8495 20 19.1992 19.6502 19.1992 19.2188C19.1992 15.2986 16.01 12.1094 12.0898 12.1094H11.3086C10.8771 12.1094 10.5273 12.4591 10.5273 12.8906Z"
				fill="currentColor"
			/>
		</svg>
	);
}
