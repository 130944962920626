import React from "react";
import { observer } from "mobx-react";

import { CallItemHistory } from "../../../stores/call-center-store/call-item-history";
import { HelpRequestInfo } from "../../components/help-request-info";
// import { RequestState } from "../../requests/request-item";
import { CallHistory } from "../../components/call-history";
import { ProcessedCallsDetailsHistory } from "./processed-call-details-history";

interface Props {
	details: CallItemHistory;
	onAttach: (scopeId: number) => Promise<void>;
}

export const ProcessedCallDetails = observer(({ onAttach, details }: Props) => {
	const [forcedToShowHistory, setForcedToShowHistory] = React.useState(false);

	function handleFetchSubprocess() {
		details.fetchSubprocessHistory();
		setForcedToShowHistory(true);
	}

	return (
		<div className="call-details">
			<HelpRequestInfo
				processId={details.projectSubprocessId!}
				wardInfo={details.ward!}
				baseTask={details.baseTask}
				editable
				audioRecordUrl={details.audioRecordUrl}
				onFetchSubprocess={details.hasSubprocesses ? handleFetchSubprocess : undefined}
				showProcessMoveButton={details.projectSubprocessId !== null}
				onAttach={onAttach}
				onDelete={details.delete}
			/>
			{(details.history.length > 0 || forcedToShowHistory) && (
				<ProcessedCallsDetailsHistory
					history={details.history}
					// Actions={<RequestState item={details} />}
				/>
			)}
			{details.scopeProcesses.length > 0 && <CallHistory scopeProcesses={details.scopeProcesses} />}
		</div>
	);
});
