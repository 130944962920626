import { observer } from "mobx-react";
import React, { useContext } from "react";

import { FilterToolbar } from "@app-components/filters/filter-toolbar";
import { InfiniteDataList } from "@app-components/infinite-data-list";
import { Select } from "@app-ui";

import { StoreContext } from "../../context";
import { Paths } from "../../routes/paths";
import { Page } from "../../components/page";
import { PageHeader } from "../../components/page-header";
import { EmptyCalls } from "../call-center/components/empty-calls";

import { GridEntry } from "./grid-entry";
import { Header } from "./header";
import { RequestTask } from "../../stores/request-store";
import { ProcessedRequestDetails } from "./processed-request-details";

const captions = ["Время", "Телефон", "Подопечный", "Тема", "Раздел", "Текст", "Кто добавил", "Статус"];

function getEntryCss(entry: RequestTask) {
	switch (entry.state) {
		case "Новый звонок":
			return "grid-entry-state-1";
		case "Принят":
			return "grid-entry-state-2";
		case "Оформление просьбы":
		case "Новая просьба":
			return "grid-entry-state-3";
		case "Нужен доброволец":
			return "grid-entry-state-4";
		case "Назначен доброволец":
			return "grid-entry-state-5";
		case "В работе":
			return "grid-entry-state-6";
		case "Выполнено":
			return "grid-entry-state-7";
		case "Не выполнено":
			return "grid-entry-state-8";
		case "Недозвон":
			return "grid-entry-state-9";
		case "Отменено":
			return "grid-entry-state-10";
		case "Отказ их":
			return "grid-entry-state-11";
		case "Отказ наш":
			return "grid-entry-state-12";
		default:
			return "";
	}
}

const GridEntryWithStore = observer(({ entry }: { entry: RequestTask }) => {
	const { processedRequests: store } = useContext(StoreContext);
	const isActive = store.currentItem ? store.currentItem.processId === entry.pid : false;
	const isNotActive = store.currentItem ? !isActive : false;

	function handleClick() {
		if (store.currentItem === null || store.currentItem.processId !== entry.pid) {
			store.fetchProcess(entry.pid);
		} else {
			store.clearFocus();
		}
	}

	return (
		<GridEntry
			entry={entry}
			captions={captions}
			onClick={handleClick}
			isActive={isActive}
			isNotActive={isNotActive}
			className={`call-grid-item ${getEntryCss(entry)}`}
		>
			{store.currentItem != null && <ProcessedRequestDetails details={store.currentItem} />}
		</GridEntry>
	);
});

export const ProcessedRequests = observer(function() {
	const { processedRequests: store } = useContext(StoreContext);

	React.useEffect(() => {
		store.fetchScopes();
		store.fetchPage(1, 50);
	}, [store]);

	function handleFilterApply(filterId: string, value: any) {
		store.applyFilter(filterId, value);
	}

	function handleScopeChange(value: number) {
		store.changeScope(value);
	}

	return (
		<Page path={Paths.Requests}>
			<div className="page__content">
				<PageHeader title="Полный перечень просьб">
					<div className="filter-toolbar">
						{store.scopes.length > 1 && store.selectedScope !== null && (
							<Select
								items={store.scopes.map(s => ({ label: s.name, value: s.id }))}
								value={store.selectedScope!.id}
								onChange={handleScopeChange}
							/>
						)}
						<FilterToolbar
							filters={store.filter}
							onApply={handleFilterApply}
							hiddenFilters={["type", "schema", "orderBy", "all", "tree"]}
						/>
					</div>
				</PageHeader>

				{!store.loading && store.items.length === 0 ? (
					<EmptyCalls />
				) : (
					<InfiniteDataList
						store={store}
						EntryComponent={GridEntryWithStore}
						Header={<Header />}
						createKey={entry => entry.taskId}
						captions={captions}
						pagerPosition="top"
					/>
				)}
			</div>
		</Page>
	);
});
