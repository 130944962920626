import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import Calendar from "react-calendar";
import DateTimePicker from "react-datetime";
import { useDownloadExcel } from "@shared/hooks/use-download-excel";

import { Select } from "@app-ui";
import { XProjectInfoValue } from "@external-types/project";
import { formatDate } from "@helpers/datetime";
import { Overlay } from "@app-components/controls/overlay";
import Pie from "@app-components/controls/pie-donut";

import { PageHeader } from "../components/page-header";
import { StoreContext } from "../context";
import { withFetching } from "../components/with-fetching";
import { Paths } from "../routes/paths";
import { FormatOptionCallback } from "../../../../src/ui-kit/select";

import { actions } from "../../../../src/actions";
import { Scope } from "../constants/scope";

// import { Switch } from "@app-components/switch";

function FormRowInfo({ label, value }: { label: string; value: number }) {
	return (
		<div className="form__row reports-form-row">
			<div className="form__label">{label}:</div>&nbsp;<div className="form__value">{value}</div>
		</div>
	);
}

function ThemeFormInfo({ themes }: { themes: XProjectInfoValue[] }) {
	const [selectedThemes, setThemes] = useState<string[]>([]);

	function handleThemeChange(value: string) {
		const selectedTheme = selectedThemes.find(theme => theme === value);
		const result =
			selectedTheme == null ? selectedThemes.concat(value) : selectedThemes.filter(theme => theme !== value);

		result.sort();

		setThemes(result);
	}
	const formatSelection: FormatOptionCallback<string> = option => {
		return <span>{`${selectedThemes.includes(option.value) ? "✓ " : ""}${option.label}`}</span>;
	};

	if (!themes) {
		return null;
	}

	return (
		<>
			<div className="form__row">
				<div className="form__label">Тема звонка</div>
				<div className="form__value">
					<Select
						items={themes.map(x => ({
							label: x.name,
							value: x.name,
						}))}
						value=""
						onChange={handleThemeChange}
						formatOptionLabel={formatSelection}
					/>
				</div>
				{selectedThemes.map(themeName => {
					const theme = themes.find(t => t.name === themeName);

					return <FormRowInfo key={themeName} value={theme?.number ?? 0} label={themeName} />;
				})}
			</div>
			<div className="pie-chart">
				<Pie data={themes.map(theme => ({ label: theme.name, value: theme.number, color: null }))} />
			</div>
		</>
	);
}

// enum FilterType {
// 	Category = "category",
// 	Theme = "theme",
// }

// const FilterForm = observer(() => {
// 	const [filter, setFilter] = React.useState(FilterType.Category);
//
// 	function handleFilterChange(value: string) {
// 		setFilter(value as FilterType);
// 	}
//
// 	return (
// 		<Switch
// 			on={{ label: "Тема", value: FilterType.Theme }}
// 			off={{ label: "Категория", value: FilterType.Category }}
// 			value={filter}
// 			onChange={handleFilterChange}
// 		></Switch>
// 	);
// });

const RequestsContent = observer(() => {
	const { reportsStore } = useContext(StoreContext);
	const reportInfo = reportsStore.reportInfo!;
	const [downloadInProgress, download] = useDownloadExcel();

	const [reportPeriodStart, setReportPeriodStart] = React.useState(Date.now() - 1000 * 60 * 60 * 24 * 30);
	const [reportPeriodEnd, setReportPeriodEnd] = React.useState(Date.now());

	function handleChange(date: Date | Date[]) {
		if (date instanceof Date) {
			reportsStore.fetch(date);
		}
	}

	async function handleDownload() {
		download(() => actions.getScopeExportContent(Scope.Root));
	}

	async function handleDownloadByDays() {
		download(() => actions.getProjectExportContent("mercy", reportPeriodStart, reportPeriodEnd));
	}

	function handleUpdatePeriodStart(e: any) {
		setReportPeriodStart(e.valueOf());
	}

	function handleUpdatePeriodEnd(e: any) {
		setReportPeriodEnd(e.valueOf());
	}

	return (
		<div className="page__content">
			<PageHeader title="Статистика" />

			<div className="reports">
				<div className="reports__calendar">
					<Calendar locale="ru-RU" value={reportsStore.currentDay} onChange={handleChange} />

					<div>
						<div className="report-actions-row">
							<button onClick={handleDownload} type="button" className="button button--primary">
								Скачать годовой отчет
							</button>
						</div>

						<div className="report-actions-row">
							<div style={{ display: "flex", alignItems: "center" }}>
								<DateTimePicker
									value={new Date(reportPeriodStart)}
									locale="ru-Ru"
									inputProps={{ className: "form__input" }}
									onChange={handleUpdatePeriodStart}
									timeConstraints={{ minutes: { min: 0, max: 59, step: 15 } }}
								/>
								&nbsp;&nbsp;-&nbsp;&nbsp;
								<DateTimePicker
									value={new Date(reportPeriodEnd)}
									locale="ru-Ru"
									inputProps={{ className: "form__input" }}
									onChange={handleUpdatePeriodEnd}
									timeConstraints={{ minutes: { min: 0, max: 59, step: 15 } }}
								/>
							</div>

							<div style={{ marginTop: 10 }}>
								<button onClick={handleDownloadByDays} type="button" className="button button--primary">
									Скачать отчет по дням
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="reports__form">
					<h3>{formatDate(reportInfo.values.currday.number)}</h3>

					<FormRowInfo label={reportInfo.values.day.name} value={reportInfo.values.day.number} />
					<FormRowInfo label={reportInfo.values.callsOut.name} value={reportInfo.values.callsOut.number} />
					<FormRowInfo label={reportInfo.values.callsIn.name} value={reportInfo.values.callsIn.number} />
					<FormRowInfo
						label={reportInfo.values.callsRegions.name}
						value={reportInfo.values.callsRegions.number}
					/>
					<FormRowInfo label={reportInfo.values.plea.name} value={reportInfo.values.plea.number} />
				</div>
				<div className="reports__themes">
					{/*<div>*/}
					{/*	<FilterForm />*/}

					{/*</div>*/}

					<ThemeFormInfo themes={reportInfo.lists.theme} />
				</div>
			</div>
			{(reportsStore.fetching || downloadInProgress) && <Overlay global={false} />}
		</div>
	);
});

const ContentWithFetching = withFetching(RequestsContent);

export const Reports = observer(function() {
	const { reportsStore } = useContext(StoreContext);
	const [initialized, setInitialized] = React.useState(false);

	async function fetch() {
		await reportsStore.fetch();
		setInitialized(true);
	}

	return <ContentWithFetching fetch={fetch} initialized={initialized} path={Paths.Reports} />;
});
