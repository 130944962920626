import React from "react";
import { SchemaFormFieldRow } from "@app-components/schema-form";
import { SchemaForm } from "@app-models/schema-form";

import { WardAttributes } from "../../../../constants/ward-attributes";

interface Props {
	form: SchemaForm;
}

export const ContactsEditSection = ({ form }: Props) => {
	return (
		<>
			<SchemaFormFieldRow field={form.getField(WardAttributes.Phone)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.MobilePhone)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.HomePhone)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.AdditionalInfo)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.Email)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.Region)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.Settlement)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.DistrictOfMoscow)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.Subway)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.Address)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.Apartment)!} />
		</>
	);
};
