import React from "react";

import { formatDateTime } from "@helpers/datetime";
import { fullName } from "@helpers/user";
import { XTaskExtRef } from "@external-types/process";

import { getTaskExtAttributeValue } from "../../helpers/attributes";
import { ProcessFieldValue } from "../../constants/process-field-value";

interface Props {
	scopeProcesses: XTaskExtRef[];
}

function getTopic(taskRef: XTaskExtRef) {
	return (
		getTaskExtAttributeValue(taskRef, ProcessFieldValue.RequestTopic) ||
		getTaskExtAttributeValue(taskRef, ProcessFieldValue.CallTopic)
	);
}

function getMessage(taskRef: XTaskExtRef) {
	return (
		getTaskExtAttributeValue(taskRef, ProcessFieldValue.RequestMessage) ||
		getTaskExtAttributeValue(taskRef, ProcessFieldValue.AppealMessage)
	);
}

export function CallHistory({ scopeProcesses }: Props) {
	return (
		<div className="call-details__history">
			<div className="call-details__header call-details__header--underlined">История обращений</div>
			<table className="call-history">
				<thead>
					<tr>
						<td className="call-history__header-cell">Дата обращения</td>
						<td className="call-history__header-cell">Тема просьбы</td>
						<td className="call-history__header-cell">Текст обращения</td>
						<td className="call-history__header-cell">Кто добавил</td>
						<td className="call-history__header-cell">Статус</td>
					</tr>
				</thead>
				<tbody>
					{scopeProcesses.map(s => (
						<tr key={s.id}>
							<td className="call-history__cell">{formatDateTime(s.create, "YYYY.MM.DD HH:mm")}</td>
							<td className="call-history__cell">{getTopic(s)}</td>
							<td className="call-history__cell">{getMessage(s)}</td>
							<td className="call-history__cell">{fullName(s.initiator)}</td>
							<td className="call-history__cell">{s.state}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}
