import React from "react";
import { Ward } from "../../../../stores/wards-store";
import { AttrFormRowInfo } from "./base-info-section";
import { WardAttributes } from "../../../../constants/ward-attributes";

interface Props {
	entry: Ward;
}

export const ChurchnessSection = ({ entry }: Props) => {
	return (
		<>
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.Religion)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.OtherReligion)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.NameInChristening)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.AngelDate)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.ParishChurch)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.PriestRequired)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.ChurchinessInfo)} scope={entry.scope} />
		</>
	);
};
