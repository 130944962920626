import React from "react";
import { observer } from "mobx-react-lite";

import { IDataSource } from "@app-types/data";
import { OptionItem } from "@app-types/models";
import { CheckBoxListWithPaging } from "@app-components/checkbox-list-wth-paging";
import { SearchTextbox } from "@app-components/search-textbox";

interface Props {
	dataSource: IDataSource<OptionItem<number>>;
	selected: number[];
	onSelect: (selected: number[]) => void;
}

export const TaskScopes = observer(({ dataSource, selected, onSelect }: Props) => {
	function handleSearch(value: string) {
		dataSource.applyFilter("search", value);
	}

	return (
		<>
			<div>
				<SearchTextbox onChange={handleSearch} />
			</div>
			<div style={{ margin: "2rem 0" }}>
				<CheckBoxListWithPaging dataSource={dataSource} onChange={onSelect} selected={selected} />
			</div>
		</>
	);
});
