export enum VolunteerAttributes {
	Id = 25749, // "Id"
	DateOfQuestionnaireAdded = 25757, // "Дата добавления анкеты"
	DateOfQuestionnaireUpdated = 25856, // "Дата последнего обновления анкеты"
	DateAndResultsOfTheCall = 25750, // "Дата и результаты обзвона"
	LastName = 22438, // "Фамилия"
	FirstName = 22439, // "Имя"
	MiddleName = 22440, // "Отчество"
	Gender = 22443, // "Пол"
	Birthday = 22442, // "Дата рождения"
	MobilePhone = 22453, // "Мобильный телефон"
	HomePhone = 22455, // "Домашний телефон"
	AdditionalPhone = 22456, // "Дополнительный телефон"
	ContactPhones = 25844, // "Контактные телефоны"
	Email = 22454, // "Email"
	FederalDistrict = 25771, // "Федеральный округ"
	Region = 25784, // "Регион"
	DistrictOfMoscow = 22496, // "Округ (для г.Москвы)"
	Settlement = 22457, // "Населенный пункт"
	Subway = 25785, // "Метро"
	Address = 22459, // "Адрес"
	// Street = 25788, // "Улица"
	// House = 25786, // "Дом"
	// HouseBuilding = 25787, // "Корпус"
	Apartment = 24214, // "Квартира"
	Profession = 25803, // "Профессия"
	MaritalStatus = 22446, // "Семейное положение"
	ChildrenInfo = 25800, // "Информация о детях"
	ReasonOfHelping = 25802, // "Почему помогает"
	AdultsAtHome = 25767, // "Взрослые на дому"
	HasAuto = 25766, // "Есть авто"
	OnlineHelping = 25768, // "Онлайн помощь"
	ConfessorsBlessing = 22464, // "Благословление духовника"
	InterviewDate = 22465, // "Дата собеседования"
	CoordinatorComments = 25840, // "Комментарии координаторов"
	ImportantNotes = 25841, // "Важные примечания"
	AdditionalNotes = 25842, // "Дополнительные комментарии"
	NameDay = 25845, // "Именины"
	NameInChristening = 25846, // "Имя в крещении"
	FrequencyOfHowCommunion = 25849, // "Как часто причащается"
	TrainingCompleted = 25850, // "Прошел тренинг"
	DateTrainingCompleted = 25851, // "Дата прохождения тренинга"
	ReceiveEmail = 25852, // "Получать Email"
	ReceiveWhatsapp = 25853, // "Получать Whatsapp"
	Churchiness = 25855, // "Воцерковленность"
	WhereDidKnow = 25860, // "Откуда узнали (комментарии)"
	HealthRestrictions = 25861, // "Ограничения по здоровью"
	ParishChurch = 25862, // "Приходской храм"
	WhenCanHelp = 25863, // "Когда может помогать"
	WhenCanHelpComments = 25864, // "Когда может помогать (комментарии)"
	OncePerMonth = 25866, // "Один раз в месяц"
	TwicePerMonth = 25865, // "Два раза в месяц"
	OncePerWeek = 25867, // "Один раз в неделю"
	MoreThanOncePerWeek = 25868, // "Чаще одного раза в неделю"
	RegularWards = 25870, // "Постоянные подопечные"
	TargetedAssistanceSao = 25871, // "Адресная помощь: САО"
	TargetedAssistanceSvao = 25872, // "Адресная помощь: СВАО"
	TargetedAssistanceVao = 25873, // "Адресная помощь: ВАО"
	TargetedAssistanceUvao = 25874, // "Адресная помощь: ЮВАО"
	TargetedAssistanceUao = 25875, // "Адресная помощь: ЮАО"
	TargetedAssistanceUzao = 25876, // "Адресная помощь: ЮЗАО"
	TargetedAssistanceZao = 25877, // "Адресная помощь: ЗАО"
	TargetedAssistanceSzao = 25878, // "Адресная помощь: СЗАО"
	AdditionalRegion = 25879, // "Район дополнительный"
	LargeFamily = 25880, // "Многодетным"
	Actions = 25881, // "Акции"
	RescueHangar = 25882, // "Ангар спасения"
	BAS = 25883, // "БАС"
	Almshouse = 25884, // "Богадельня"
	HIVCenterIKB2 = 25885, // "ВИЧ-центр (ИКБ 2)"
	GKB1 = 25886, // "ГКБ 1"
	GKB1PSO = 25887, // "ГКБ 1 (ПСО)"
	GKB12 = 25888, // "ГКБ 12"
	GNC = 25889, // "ГНЦ"
	GCP = 25890, // "ГЦП"
	HouseForMother = 25891, // "Дом для мамы"
	CSSV_Kuncevsky = 25892, // "ЦССВ Кунцевский"
	CSSV_Mayak = 25893, // "ЦССВ Маяк"
	MMO = 25894, // "ММО"
	Morozovskaya_DKB = 25895, // "Морозовская ДКБ"
	NII_Gelmgoltsa = 25896, // "НИИ Гельмгольца"
	NPC = 25897, // "НПЦ"
	PB = 25898, // "ПБ"
	Transportation = 25899, // "Перевозки"
	PNI11 = 25900, // "ПНИ11"
	PNI25 = 25901, // "ПНИ25"
	RDKB = 25902, // "РДКБ"
	Repairing = 25903, // "Ремонт"
	VolunteerSchool = 25904, // "Школа добровольцев"
	Website = 25905, // "Сайт"
	Roshal = 25906, // "Рошаль"
	StAlexey = 25907, // "Свят. Алексия"
	SSSD = 25908, // "СССД"
	HelpForGroups = 25909, // "Помощь группам"
	Lawyers = 25910, // "Юристы"
	BusMercy = 25911, // "Автобус милосердие"
	Actions2 = 25912, // "Акции"
	Homeless = 25913, // "Бездомным"
	Sanitary = 25914, // "Гигиенический уход"
	RapidResponseTeam = 25915, // "Группа быстрого реагирования"
	ProvideProfessionalAdvice = 25916, // "Дать профессиональную консультацию"
	WatchForOrphansInHospitals = 25917, // "Дежурить у детей сирот в больницах"
	ForOrphans = 25918, // "Детям сиротам"
	Disabled = 25919, // "Инвалидам"
	BuyProducts = 25920, // "Купить продукты"
	LargeFamilyProject = 25921, // "Многодетным семьям"
	VisitReadProject = 25922, // "Навестить, почитать"
	NeedyInHospitalsProject = 25923, // "Нуждающимся в больницах"
	LoneOldProject = 25924, // "Одиноким, пожилым"
	TakeAwayThingsProject = 25925, // "Отвезти вещи"
	MoveThingsProject = 25926, // "Перевезти на машине"
	PhoneTalkProject = 25927, // "Побеседовать по телефону"
	ChildWalkProject = 25928, // "Погулять с ребенком, с больным"
	LiturgyHelpProject = 25929, // "Помочь на литургии, крещении"
	BringToChurchProject = 25930, // "Привезти в храм"
	PrepareFoodProject = 25931, // "Приготовить еду"
	SmallRepairProject = 25932, // "Сделать мелкий ремонт"
	CleanProject = 25933, // "Убраться"
	HospiceProject = 25934, // "Хосписы"
	HumanitarianAidCenterProject = 25935, // "Центр гуманитарной помощи"
	OtherProject = 25936, // "Прочее"
	OtherWhoProject = 25937, // "Прочее кому"
	// InPrayList = 25956, // "В списке для молитв "
	// ReasonOfInPrayList = 25957, // "Причины добавления в список для молитв "
	VolunteerStatus = 25958, // "Статус добровольца "
	Dead = 25959, // "Скончался "
	DateOfDeath = 25960, // "Дата смерти "
	VolunteerPersonalCharacteristics = 25961, // "Личностная характеристика добровольца "
	HowIsGood = 25962, // "как хорошо выполняет просьбы "
	AlwaysCompletesRequests = 25963, // "всегда ли выполняет взятые задачи "
	CanWarkHard = 25964, // "готовность/возможность физической работы "
	Responsibility = 25965, // "ответственность "
	Diligence = 25966, // "исполнительность "
	Mobility = 25967, // "мобильность "
	HasMedicalExamination = 25968, // "Наличие анализов/медкнижки "
	PriestComments = 25969, // "Комментарии священника "
	HelpToAdults = 25970, // "Взрослым "
	HelpToChildren = 25971, // "Детям "
	HelpRemote = 25972, // "Удаленно, онлайн"
	HelpByAuto = 25973, // "На авто "
	Messengers = 25974, // "Использование мессенджеров "
	PreferredCommunicationWay = 25975, // "Предпочтительный способ связи"
	DGKBStVladimir = 25976, // "ДГКБ св.Владимира"
	NMICBlochina = 25977, // "НМИЦ им.Блохина"
	SocialDepartment = 25978, // "Соц. Отдел"
	MO = 25979, // "МО"
	Barbershop = 25980, // "Парикмахерская"
	MusicalTherapy = 25981, // "Муз. Терапия"
	WashingRoom = 25982, // "Помывочная"
	HelpToCoordinator = 25983, // "Помощь координатору"
	HasChronicDiseases = 25985, // "Есть ли хронические заболевания?"
	// ChronicDiseases = 25985, // "Если Да - укажите какие "
	DisabilityCategory = 25986, // "Группа инвалидности "
	DisabilityCategoryLevel = 25987, // "Степень "
	DisabilityAppointmentReason = 25988, // "Основание для назначения инвалидности"
	SpouseFullName = 25989, // "ФИО супруга/супруги"
	Education = 25990, // "Образование "
	WorkPlace = 25991, // "Место работы "
	Interviewer = 25992, // "ФИО интервьюера "
	WhereFromDidYouKnowAboutUs = 25993, // "Откуда о нас узнали"
}
