import React from "react";

import { VolunteerAttributes } from "../../../../constants/volunteer-attributes";
import { SchemaFormFieldRow } from "@app-components/schema-form";
import { SchemaForm } from "@app-models/schema-form";

interface Props {
	form: SchemaForm;
}

export const HealthSection = ({ form }: Props) => {
	return (
		<div className="scope-card-content__row">
			<div className="scope-card-content__column">
				<div className="scope-card-heading">Здоровье</div>
				<div>
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.HasChronicDiseases)!} />
					{/*<FormFieldRow field={form.getField(VolunteerAttributes.ChronicDiseases)!} />*/}
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.HealthRestrictions)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.DisabilityCategory)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.DisabilityCategoryLevel)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.DisabilityAppointmentReason)!} />
				</div>
			</div>
		</div>
	);
};
