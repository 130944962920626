import React from "react";

import { SchemaForm } from "@app-models/schema-form";
import { VolunteerAttributes } from "../../../../constants/volunteer-attributes";
import { SchemaFormFieldRow } from "@app-components/schema-form";

interface Props {
	form: SchemaForm;
}

export const ProjectsArchiveSection = ({ form }: Props) => {
	return (
		<div className="scope-card-content__row">
			<div className="scope-card-content__column">
				<div className="scope-card-heading">Архив проектов</div>
				<div>
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.BusMercy)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.Actions2)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.Homeless)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.Sanitary)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.RapidResponseTeam)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.ProvideProfessionalAdvice)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.WatchForOrphansInHospitals)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.ForOrphans)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.Disabled)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.BuyProducts)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.LargeFamilyProject)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.VisitReadProject)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.NeedyInHospitalsProject)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.LoneOldProject)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.TakeAwayThingsProject)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.MoveThingsProject)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.PhoneTalkProject)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.ChildWalkProject)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.LiturgyHelpProject)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.BringToChurchProject)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.PrepareFoodProject)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.SmallRepairProject)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.CleanProject)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.HospiceProject)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.HumanitarianAidCenterProject)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.OtherProject)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.OtherWhoProject)!} />
				</div>
			</div>
		</div>
	);
};
