import { observable } from "mobx";
import { DataSourceSession, FilterItem, Filters } from "@shared/models/data-source";
import { CallTask, IncomingCall } from "../call-center-store";
import { CallItem } from "../call-center-store/call-item";
import { WardAttributes } from "../../constants/ward-attributes";
import { ProcessFieldValue } from "../../constants/process-field-value";
import { ProcessSchema } from "../../constants/process-schema";
import { ProcessState } from "../../constants/process-state";
import { FilterType } from "../../../../../src/constants/filter-type";

class UnhandledCallsViewFilters extends Filters {
	public constructor(searchFilter: boolean = false, stateNameFilter: boolean = false) {
		super(searchFilter, stateNameFilter);

		this.items.push(
			new FilterItem({
				name: "state",
				value: ProcessState.NewCall,
				type: FilterType.Select,
			}),
		);
	}

	protected createQuery() {
		const query = super.createQuery();

		return {
			...query,
			schema: ProcessSchema.MainProcess,
			aid: [WardAttributes.Phone, WardAttributes.FirstName, WardAttributes.LastName, WardAttributes.MiddleName],
			paid: [WardAttributes.Phone, WardAttributes.FirstName, WardAttributes.LastName, WardAttributes.MiddleName],
			vid: [
				ProcessFieldValue.RequestMessage,
				ProcessFieldValue.RequestTopic,
				ProcessFieldValue.CallTopic,
				ProcessFieldValue.AppealMessage,
			],
			pvid: [
				ProcessFieldValue.RequestMessage,
				ProcessFieldValue.RequestTopic,
				ProcessFieldValue.CallTopic,
				ProcessFieldValue.AppealMessage,
			],
			orderBy: "date_desc",
		};
	}
}

export class UnhandledCallsSession extends DataSourceSession<CallTask> {
	public filter: Filters = new UnhandledCallsViewFilters(true);
	@observable public currentItem: CallItem | null = null;
	@observable public incomingCall: IncomingCall | null = null;
}
