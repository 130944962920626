import { action, observable, runInAction } from "mobx";
import { actions } from "@actions";

import { InfiniteDataSource } from "@app-models/data-source";
import { AppStore } from "@shared/stores";

import { AppState } from "../app-state";
import { RequestTask } from "./index";
import { ProcessedRequestItem } from "./processed-request-item";
import { XScopeRef } from "../../../../../src/types/external-data/scope";

export class ProcessedRequestsStore extends InfiniteDataSource<RequestTask> {
	public scopes: XScopeRef[] = [];
	@observable public selectedScope: XScopeRef | null = null;

	public constructor(private appStore: AppStore, private appState: AppState) {
		super();
	}

	public get currentItem() {
		return this.session.currentItem;
	}

	@action
	public clearFocus() {
		this.session.currentItem = null;
	}

	protected get session() {
		return this.appState.processedRequests;
	}

	protected async fetch() {
		if (!this.selectedScope) {
			throw new Error("User has no role permissions");
		}

		const result = await actions.getScopeProcesses(this.selectedScope.id, this.session.pagination, this.filter);

		this.updatePageInfo(result);

		return result.list.map(x => new RequestTask(x));
	}

	@action
	public fetchScopes() {
		this.scopes = this.appStore
			.authUser!.roles.filter(role => role.pviewer && role.scope.typename === "Проект")
			.map(role => role.scope);

		if (this.scopes.length > 0) {
			this.selectedScope = this.scopes[0];
		}
	}

	@action
	public changeScope(scopeId: number) {
		this.selectedScope = this.scopes.find(scope => scope.id === scopeId)!;
		this.fetchPage(1);
	}

	public async fetchProcess(processId: number) {
		runInAction(() => {
			this.session.loading = true;
			this.session.currentItem = null;
		});

		const currentItem = new ProcessedRequestItem(processId);
		await currentItem.fetch();

		runInAction(() => {
			this.session.loading = false;
			this.session.currentItem = currentItem;
		});
	}
}
