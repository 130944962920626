import { observable, runInAction } from "mobx";

import { actions } from "@actions";
import { XProcessAdminInfo, XProcessRef, XTaskExtRef } from "@external-types/process";

import { WardInfo } from "../models/ward-info";
import { ProcessState } from "../../constants/process-state";
import { BaseTaskForm } from "../models/base-task-form";
import { Filters } from "../../../../../src/models/data-source";
import { ProcessHistoryFilters } from "../call-center-store/call-item";
import { RequestHistory } from "./request-history";
import { getCallDestinationNumber } from "../../helpers/process-helper";

export class ProcessedRequestItem {
	public ward: WardInfo | null = null;
	public baseTask: BaseTaskForm | null = null;
	@observable public processInfo: XProcessAdminInfo | null = null;
	@observable public taskReports: XProcessRef[] = [];
	@observable public scopeProcesses: XTaskExtRef[] = [];
	@observable public audioRecordUrl: string | null = null;
	private filters: Filters = new ProcessHistoryFilters();

	public requestHistory: RequestHistory | null = null;

	public constructor(public readonly processId: number) {}

	public async fetch() {
		const processTaskInfo = await actions.getProcessAdminInfo(this.processId);
		const scopeRef = processTaskInfo.base ? processTaskInfo.base.scope : processTaskInfo.scope;
		const scope = await actions.getScope(scopeRef.id);

		const scopeProcesses = await actions.getScopeProcesses(scope.id, undefined, this.filters);
		const baseHistory = processTaskInfo.base ? processTaskInfo.base.history : [];
		const history = baseHistory.concat(processTaskInfo.history);
		const baseTask = history.find(item => item.formID === ProcessState.NewCall) ?? null;

		// const subprocesses = await actions.getSubprocesses(this.processId, processTaskInfo.id);
		// let hasSubprocesses = false;
		// let subprocessHistory: XHistoryItem[] = [];
		//
		// if (subprocesses.list.length > 0) {
		// 	const subprocess = await actions.getProcessAdminInfo(subprocesses.list[0].pid);
		//
		// 	hasSubprocesses = true;
		// 	subprocessHistory = subprocess.history;
		// }

		const requestHistory = await RequestHistory.create(processTaskInfo);

		runInAction(() => {
			if (baseTask != null) {
				this.baseTask = new BaseTaskForm(processTaskInfo.pid, baseTask);
			}
			this.audioRecordUrl =
				processTaskInfo.links && processTaskInfo.links.length > 0
					? actions.getProcessLinkUrl(processTaskInfo.id, processTaskInfo.links[0].id)
					: null;

			this.requestHistory = requestHistory;

			// this.taskReports = this.requestHistory.volunteersExecutionHistory.items;
			this.processInfo = processTaskInfo;
			//			this.history = history.filter(item => item.formID !== ProcessState.InProgressCall) ?? [];
			this.ward = new WardInfo(scope, { destinationPhoneNumber: getCallDestinationNumber(history) });
			// this.hasSubprocesses = hasSubprocesses;
			// this.subprocessHistory = subprocessHistory;
			this.scopeProcesses = scopeProcesses.list;
		});
	}

	public delete = async () => {
		return actions.deleteProcess(this.processId);
	};

	// @action
	// public fetchSubprocessHistory() {
	// 	this.history = this.subprocessHistory;
	// }
}
