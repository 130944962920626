import React from "react";
import { observer } from "mobx-react";

import { VolunteerSectionId } from "../../stores/volunteers-store/volunteer-sections";
import {
	BaseInfoEditSection,
	CharacteristicsEditSection,
	ChurchnessEditSection,
	ContactsEditSection,
	HealthEditSection,
	InterviewEditSection,
	ProjectsArchiveEditSection,
	ProjectsEditSection,
	WorkAndFamilyEditSection,
} from "./volunteer-sections/edit-sections";
import { IVolunteer } from "../../stores/volunteers-store/volunteer";

interface Props {
	entry: IVolunteer;
}

export const VolunteerEditForm = observer(({ entry }: Props) => {
	const form = entry.form!;
	const currentSectionId = entry.currentSectionId;
	const volunteerProjects = entry.volunteerProjects;

	switch (currentSectionId) {
		case VolunteerSectionId.BaseInfo:
			return <BaseInfoEditSection form={form} entry={entry} />;
		case VolunteerSectionId.Contacts:
			return <ContactsEditSection form={form} />;
		case VolunteerSectionId.Interview:
			return <InterviewEditSection form={form} />;
		case VolunteerSectionId.Churchness:
			return <ChurchnessEditSection form={form} />;
		case VolunteerSectionId.Projects:
			return <ProjectsEditSection form={form} volunteerProjects={volunteerProjects} />;
		case VolunteerSectionId.ProjectsArchive:
			return <ProjectsArchiveEditSection form={form} />;
		case VolunteerSectionId.Health:
			return <HealthEditSection form={form} />;
		case VolunteerSectionId.Characteristics:
			return <CharacteristicsEditSection form={form} />;
		case VolunteerSectionId.WorkAndFamily:
			return <WorkAndFamilyEditSection form={form} />;
		default:
			return null;
	}
});
