import { XSchemaFormInfo, XSchemaFormRef, XSubProcessConfig } from "../schema";

export enum XActionType {
	Simple = "simple",
	Parallel = "parallel",
	Assigned = "assigned",
	Subprocess = "subprocess",
}

export interface XTaskAction extends XSchemaFormInfo {
	actionID: number;
	action: string;
	subprocess: { count: number; forms: XSchemaFormRef[] } & XSubProcessConfig;
}
