export enum ProcessActions {
	ProcessCall = 2959,
	SendToCoordinator = 2958,

	Respond = 2965,
	CancelRespond = 2966,
}

export enum ProcessActions_15_1 {
	SaveComment = 3449, // Сохранить комментарий
	AssignVolunteer = 3450, // Назначить добровольца
	RefuseExternal = 3451, // Отказ их
	RefuseInternal = 2530, // Отказ наш
	RequestCompleted = 3453, // Просьба выполнена
	Respond = 3454, // Откликнуться
}
