import React, { useState } from "react";
import cn from "classnames";

import { Button } from "@app-components/buttons/button";
import { SearchTextbox } from "@app-components/search-textbox";
import { Loader } from "@app-components/controls/loader";
import { getScopeAttributeValue } from "@helpers/scope-attributes";
import { Filters, FilterItem } from "@app-models/data-source";
import { FilterType } from "@app-constants/filter-type";
import { actions } from "@actions";
import { XScopeChildInfo } from "@external-types/scope";

import { Scope } from "../../../constants/scope";
import { WardAttributes } from "../../../constants/ward-attributes";
import { getWardFullName } from "../../../helpers/ward-helper";

export const TaskAttachDialog = ({
	onAttach,
	onClose,
}: {
	onAttach: (scopeId: number) => Promise<void>;
	onClose: () => void;
}) => {
	const [wardId, setWardId] = useState<number | null>(null);
	const [pattern, setPattern] = useState<string>();
	const [items, setItems] = useState<XScopeChildInfo[]>([]);
	const [fetching, setFetching] = useState(false);

	React.useEffect(() => {
		fetch();
	}, [pattern]);

	async function fetch() {
		try {
			setFetching(true);

			const filters = new Filters(true);
			filters.items = [
				new FilterItem({
					name: "search",
					label: "search",
					options: [],
					type: FilterType.Search,
					value: pattern,
				}),
			];

			const items = await actions.getScopeChildrenPaged(Scope.Wards, { offset: 0, pageSize: 5 }, filters);
			if (wardId != null && !items.list.find(item => item.id === wardId)) {
				setWardId(null);
			}

			setItems(items.list);
		} finally {
			setFetching(false);
		}
	}

	const handleWardClick = (wardId: number) => () => {
		setWardId(wardId);
	};

	async function handleChange(value: string) {
		setPattern(value);
	}

	const disabled = wardId == null;

	async function handleAttach() {
		await onAttach(wardId!);
		onClose();
	}

	return (
		<div className="attach-task-dialog">
			<div className="attach-task-dialog__items">
				<SearchTextbox onChange={handleChange} value={pattern} />
				{fetching && (
					<div className="attach-task-dialog__loader">
						<Loader />
					</div>
				)}

				{!fetching &&
					items.length > 0 &&
					items.map(item => (
						<div
							className={cn("ward-item", { "ward-item--selected": wardId === item.id })}
							key={item.id}
							onClick={handleWardClick(item.id)}
						>
							<div className="ward-item__name">{getWardFullName(item, item.name)}</div>
							<div className="ward-item__info">
								{getScopeAttributeValue(item, WardAttributes.Phone)}
								{getScopeAttributeValue(item, WardAttributes.Address)}
							</div>
						</div>
					))}

				{!fetching && items.length == 0 && (
					<div className="attach-task-dialog__loader">Нет записей, удовлетворяющих поиску</div>
				)}
			</div>
			<div className="attach-task-dialog__actions">
				<Button
					className={cn("button button--primary", { "button--disabled": disabled })}
					disabled={disabled}
					onClick={handleAttach}
				>
					Привязать
				</Button>
			</div>
		</div>
	);
};
