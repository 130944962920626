import { action, computed, observable, runInAction } from "mobx";
import { actions } from "@actions";

import { Scope } from "../../constants/scope";
import { AppState } from "../app-state";
import { ReferenceList } from "./reference-list";
import { ReferenceScope } from "./reference-scope";
import { NewReferenceScope } from "./new-reference-scope";
import { SubReferenceCollection } from "./sub-reference-collection";
import { ReferenceScopeId } from "../../constants/reference-scope-code";
import { SvgIcon } from "../../../../../src/types/models";
import {
	CallIcon,
	CalmIcon,
	ChurchIcon,
	ClothesIcon,
	ContactIcon,
	HomelessIcon,
	HospitalIcon,
	HotelIcon,
	LawIcon,
	MotherIcon,
	PaperIcon,
	ProjectIcon,
	WhiskeyIcon,
} from "../../pages/reference/icons";

export { ReferenceList, NewReferenceScope, SubReferenceCollection };

export class ReferenceNavigationItem {
	public id: ReferenceNavigationGroup | ReferenceScopeId;
	public name: string;
	public children: ReferenceNavigationItem[];
	@observable public expanded: boolean = false;

	public constructor(
		id: ReferenceNavigationGroup | ReferenceScopeId,
		name: string,
		children: ReferenceNavigationItem[] = [],
		public Icon?: React.FunctionComponent<SvgIcon>,
	) {
		this.id = id;
		this.name = name;
		this.children = children;
	}

	@action
	public setExpanded(expanded: boolean) {
		this.expanded = expanded;
	}
}

enum ReferenceNavigationGroup {
	Contacts = "contacts",
	HotLines = "hot-lines",
	Homeless = "homeless",
	Hospitals = "hospitals",
	Lawyers = "lawyers",
	Temples = "temples",
	Organizations = "organizations",
}

const nav = [
	new ReferenceNavigationItem(
		ReferenceNavigationGroup.Contacts,
		"Контакты",
		[
			new ReferenceNavigationItem(ReferenceScopeId.AddressBook, "Адресная книга"),
			new ReferenceNavigationItem(ReferenceScopeId.PrivateContactsInDiocese, "Контакты в епархиях"),
			new ReferenceNavigationItem(ReferenceScopeId.ContactsMercy, "Контакты “Милосердия”"),
			new ReferenceNavigationItem(ReferenceScopeId.Priests, "Милосердие: Священники"),
		],
		ContactIcon,
	),
	new ReferenceNavigationItem(
		ReferenceNavigationGroup.HotLines,
		"Горячие линии",
		[
			new ReferenceNavigationItem(ReferenceScopeId.HotLineOfMoscowDiocese, "Горячие линии Московской епархии"),
			new ReferenceNavigationItem(ReferenceScopeId.Helpline, "Телефон доверия"),
		],
		CallIcon,
	),
	new ReferenceNavigationItem(ReferenceScopeId.Projects, "Проекты", [], ProjectIcon),
	new ReferenceNavigationItem(
		ReferenceNavigationGroup.Homeless,
		"Бездомные",
		[
			new ReferenceNavigationItem(ReferenceScopeId.Homeless, "Помощь бездомным"),
			new ReferenceNavigationItem(ReferenceScopeId.SocialWorkerHandbook, "Справочник соц. работника"),
			new ReferenceNavigationItem(ReferenceScopeId.HomelessHandbook, "Справочник бездомного"),
		],
		HomelessIcon,
	),
	new ReferenceNavigationItem(ReferenceScopeId.MaterialAid, "Вещевая помощь", [], ClothesIcon),
	new ReferenceNavigationItem(ReferenceScopeId.WomenCrisisCenters, "Кризисные центры для женщин", [], MotherIcon),
	new ReferenceNavigationItem(ReferenceScopeId.Alco, "Помощь зависимым", [], WhiskeyIcon),
	new ReferenceNavigationItem(
		ReferenceNavigationGroup.Hospitals,
		"Больничное служение",
		[
			new ReferenceNavigationItem(ReferenceScopeId.HospitalsPriests, "Больничное служение: Священники"),
			new ReferenceNavigationItem(ReferenceScopeId.Hospitals, "Больничное служение: Больницы"),
		],
		HospitalIcon,
	),
	new ReferenceNavigationItem(
		ReferenceNavigationGroup.Lawyers,
		"Юристы",
		[
			new ReferenceNavigationItem(ReferenceScopeId.LegalAid, "Юридическая помощь"),
			new ReferenceNavigationItem(ReferenceScopeId.Lawyers, "Милосердие: Юристы"),
		],
		LawIcon,
	),
	new ReferenceNavigationItem(ReferenceScopeId.PsychologicalHelp, "Психологическая помощь", [], CalmIcon),
	new ReferenceNavigationItem(
		ReferenceNavigationGroup.Temples,
		"Храмы и монастыри",
		[
			new ReferenceNavigationItem(ReferenceScopeId.TemplesAndMonasteriesMoscow, "Храмы и монастыри: Москва"),
			new ReferenceNavigationItem(
				ReferenceScopeId.TemplesAndMonasteriesMO,
				"Храмы и монастыри: Московская область",
			),
			new ReferenceNavigationItem(ReferenceScopeId.TemplesAndMonasteries, "Храмы и монастыри: Россия"),
		],
		ChurchIcon,
	),
	new ReferenceNavigationItem(
		ReferenceNavigationGroup.Organizations,
		"Организации и фонды",
		[
			new ReferenceNavigationItem(ReferenceScopeId.Foundations, "Благотворительные фонды"),
			new ReferenceNavigationItem(ReferenceScopeId.PilgrimageHotels, "Паломнические гостиницы"),
			new ReferenceNavigationItem(ReferenceScopeId.StateInstitutions, "Гос. учреждения"),
			new ReferenceNavigationItem(ReferenceScopeId.SocialInstitutions, "Соц. учреждения"),
		],
		HotelIcon,
	),
	new ReferenceNavigationItem(ReferenceScopeId.ManualsAndDocuments, "Справочные материалы", [], PaperIcon),
];

class ReferenceNavigation {
	public items = nav;

	@observable public expandedId: number | string | null = null;
	@observable public activeId: number | null = null;

	@action
	public expand(itemId: ReferenceScopeId | ReferenceNavigationGroup) {
		if (this.expandedId === itemId) {
			this.expandedId = null;
		} else {
			this.expandedId = itemId;
		}
	}

	@action
	public setActive(itemId: number) {
		this.activeId = itemId;

		for (const group of this.items) {
			if (group.id === itemId) {
				this.expandedId = null;
				return;
			}

			if (group.children.length > 0) {
				for (const item of group.children) {
					if (item.id === itemId) {
						this.expandedId = group.id;
						return;
					}
				}
			}
		}
	}

	public filter(ids: number[]) {
		const set = new Set(ids);

		const root = this.items;

		this.items = root.filter(x => typeof x.id === "string" || set.has(x.id));

		for (const group of this.items) {
			if (group.children.length > 0) {
				group.children = group.children.filter(ch => set.has(ch.id as number));
			}
		}
	}

	public setActiveFirst() {
		let id: number = 0;

		if (this.items.length > 0) {
			if (this.items[0].children.length > 0) {
				id = this.items[0].children[0].id as number;
			} else {
				id = this.items[0].id as number;
			}
		}

		if (id) {
			this.setActive(id);
		}

		return id;
	}
}

export class ReferenceStore {
	@observable currentScope: ReferenceScope | null = null;
	public referenceNavigation = new ReferenceNavigation();

	public constructor(private appState: AppState) {}

	protected get session() {
		return this.appState.reference;
	}

	public fetch = async () => {
		runInAction(() => {
			this.session.isFetching = true;
		});

		let referenceLists = await actions.getScopeChildren(Scope.Reference);
		try {
			const privateReferenceLists = await actions.getScopeChildren(Scope.PrivateReference);

			referenceLists = referenceLists.concat(privateReferenceLists);
		} catch {}

		this.referenceNavigation.filter(referenceLists.map(ch => ch.id));

		runInAction(() => {
			const newReferenceLists = referenceLists
				.filter(scope => !this.session.lists.find(rf => rf.id === scope.id))
				.map(scope => new ReferenceList(scope));

			if (newReferenceLists.length > 0) {
				this.session.lists = this.session.lists.concat(newReferenceLists);
			}

			if (this.session.lists.length > 0 && this.session.activeScope === null) {
				const activeId = this.referenceNavigation.setActiveFirst();
				const activeScope = this.session.lists.find(x => x.id === activeId);

				if (activeScope) {
					this.session.activeScope = activeScope;
				}
			}

			this.session.initialized = true;
			this.session.isFetching = false;
		});
	};

	public deleteScope = async (scopeId: number) => {
		if (confirm("Удалить запись?")) {
			await actions.deleteScope(scopeId, true);
		}
	};

	public fetchItem = async (scopeId: number) => {
		const scope = new ReferenceScope(scopeId);

		await scope.fetch();

		runInAction(() => {
			this.currentScope = scope;
		});
	};

	@computed
	public get initialized() {
		return this.session.initialized;
	}

	@computed
	public get scopes() {
		return this.session.lists;
	}

	@computed
	public get activeScope() {
		return this.session.activeScope;
	}

	@action
	public updateActiveScope(scopeId: number) {
		const scope = this.scopes.find(scope => scopeId === scope.id);

		if (scope) {
			this.session.activeScope = scope;
		}

		this.referenceNavigation.setActive(scopeId);
	}
}
