import { DataSourceSession, Filters } from "@shared/models/data-source";

import { ProcessSchema } from "../../constants/process-schema";
import { WardAttributes } from "../../constants/ward-attributes";
import { RequestTask } from "../request-store";
import { RequestItem } from "../request-store/request-item";
import { ProcessFieldValue } from "../../constants/process-field-value";

class RequestViewFilters extends Filters {
	protected createQuery() {
		const query = super.createQuery();

		return {
			...query,
			schema: ProcessSchema.SubProcess,
			aid: [WardAttributes.Phone, WardAttributes.FirstName, WardAttributes.LastName, WardAttributes.MiddleName],
			paid: [WardAttributes.Phone, WardAttributes.FirstName, WardAttributes.LastName, WardAttributes.MiddleName],
			vid: [
				ProcessFieldValue.RequestMessage,
				ProcessFieldValue.RequestTopic,
				ProcessFieldValue.CallTopic,
				ProcessFieldValue.AppealMessage,
			],
			pvid: [
				ProcessFieldValue.RequestMessage,
				ProcessFieldValue.RequestTopic,
				ProcessFieldValue.CallTopic,
				ProcessFieldValue.AppealMessage,
			],
			orderBy: "date_desc",
		};
	}
}

export class RequestSession extends DataSourceSession<RequestTask> {
	public filter: Filters = new RequestViewFilters(true);
	public currentItem: RequestItem | null = null;
}
