export enum Paths {
	Home = "/",
	Login = "/login",
	CompleteAuth = "/complete-auth",
	AutoAuth = "/auto-auth",
	Policy = "/policy",
	Profile = "/profile",

	CallCenter = "/call-center",
	CallCenterUnhandled = "/call-center/unhandled",
	CallCenterItem = "/call-center/:callId",

	Requests = "/requests",
	ActiveRequests = "/requests/active",
	RequestsItem = "/requests/:requestId",

	Wards = "/wards",
	WardItem = "/wards/:id",
	WardItemCreate = "/wards/new",

	Volunteers = "/volunteers",
	Volunteer = "/volunteers/:id",
	VolunteerCreate = "/volunteers/new",

	Reference = "/reference",
	ReferenceList = "/reference/:referenceId",
	ReferenceItem = "/reference/:referenceId/:itemId",
	ReferenceItemCreation = "/reference/:referenceId/create",
	Reports = "/reports",

	Map = "/map",
}
