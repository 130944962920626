import { computed, observable, runInAction } from "mobx";

import { actions } from "@actions";
import { XProjectInfo } from "@external-types/project";
import { IAppStore } from "@app-types/stores";

export class ReportsStore {
	@observable public reportInfo: XProjectInfo | null = null;
	@observable public fetching: boolean = false;

	public constructor(private appStore: IAppStore) {}

	public async fetch(date?: Date) {
		const authUser = this.appStore.authUser;

		if (authUser) {
			try {
				runInAction(() => {
					this.fetching = true;
				});

				const reportInfo = await actions.getProjectReport("mercy", date?.getTime());

				runInAction(() => {
					this.reportInfo = reportInfo;
				});
			} finally {
				runInAction(() => {
					this.fetching = false;
				});
			}
		}
	}

	@computed
	public get currentDay() {
		return new Date(this.reportInfo!.values.currday.number);
	}
}
