import React from "react";
import { SvgIcon } from "@app-types/models";

export default function({ className }: SvgIcon) {
	return (
		<svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
			<path d="M7.50012 1.28223V13.7178" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M13.4619 7.49988H1.53809" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
}
