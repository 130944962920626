import React from "react";
import { unmountComponentAtNode, render } from "react-dom";
import { Dialog } from "./dialog";

export class DialogPresenter {
	private rootNode: HTMLDivElement | null = null;

	public show(Content: React.ReactNode, className?: string) {
		if (!this.rootNode) {
			this.rootNode = document.createElement("div");
			document.body.append(this.rootNode);
			render(<Dialog onClose={this.close} className={className} children={Content} />, this.rootNode);
		}
	}

	public close = () => {
		if (this.rootNode) {
			unmountComponentAtNode(this.rootNode);
			document.body.removeChild(this.rootNode);
			this.rootNode = null;
		}
	};
}
