import React from "react";
import { bem } from "@helpers/bem";
import { SvgIcon } from "@app-types/models";
import { Button, ButtonProps } from "./button";

interface Props extends ButtonProps {
	Icon: React.FunctionComponent<SvgIcon>;
}

export const CircleButton = function({ className, onClick, disabled = false, Icon, title }: Props) {
	const cnb = bem("circle-button", { disabled });

	return (
		<Button className={cnb.classNames(className)} onClick={onClick} disabled={disabled} title={title}>
			<Icon className={cnb.em("icon")} />
		</Button>
	);
};
