import React from "react";
import { observer } from "mobx-react-lite";

import { Select } from "@app-ui";
import { Overlay } from "@app-components/controls/overlay";
import { SchemaFieldType, ISchemaField, ISchemaForm } from "@app-models/schema-form";
import { SchemaFormFieldRow } from "@app-components/schema-form/schema-form-field-row";
import { SchemaFormRow } from "@app-components/schema-form/schema-form-row";
import { Switch } from "@app-components/switch";
import { TaskScopes } from "@app-components/task/task-scopes";

import { ITask } from "../../../stores/call-center-store/task";
import { ProcessFieldValue } from "../../../constants/process-field-value";
import MaskedInput, { conformToMask } from "react-text-mask";

const TaskFormRowType = observer(({ field }: { field: ISchemaField }) => {
	function handleSelect(value: string) {
		field.updateValue(value);
	}

	return (
		<SchemaFormRow key={field.id} field={field} className="task-form-row-type">
			<Switch on={field.options[0]} off={field.options[1]} value={field.value} onChange={handleSelect} />
		</SchemaFormRow>
	);
});

const TaskFormRowAppealMessage = observer(({ field }: { field: ISchemaField }) => {
	const defaultMessages = ["Со священником", "Поговорить", "Сорвалось", "Молчат", "Вещи отдам"];

	function handleFormFieldChange(e: React.FormEvent<HTMLTextAreaElement>) {
		field.updateValue(e.currentTarget.value);
	}

	const handleSelectAppeal = (appealMessage: string) => () => {
		field.updateValue(appealMessage);
	};

	return (
		<SchemaFormRow key={field.id} field={field}>
			<textarea
				className="form__input form__input--textarea"
				rows={3}
				value={field.value}
				onChange={handleFormFieldChange}
			/>
			<div className="default-appeal-message-list">
				{defaultMessages.map(m => (
					<span key={m} className="default-appeal-message" onClick={handleSelectAppeal(m)}>
						{m}
					</span>
				))}
			</div>
		</SchemaFormRow>
	);
});

const TaskDateFormRow = observer(({ field }: { field: ISchemaField }) => {
	const mask = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/];

	function handleFormFieldChange(e: React.FormEvent<HTMLInputElement>) {
		field.updateValue(e.currentTarget.value);
	}

	return (
		<SchemaFormRow field={field}>
			<MaskedInput
				className="form__input"
				mask={mask}
				value={field.value}
				onChange={handleFormFieldChange}
				placeholder={conformToMask("", mask, {}).conformedValue}
			/>
		</SchemaFormRow>
	);
});

const TaskTimeFormRow = observer(({ field }: { field: ISchemaField }) => {
	const mask = [/\d/, /\d/, ":", /\d/, /\d/];

	function handleFormFieldChange(e: React.FormEvent<HTMLInputElement>) {
		field.updateValue(e.currentTarget.value);
	}

	return (
		<SchemaFormRow field={field}>
			<MaskedInput
				className="form__input"
				mask={mask}
				value={field.value}
				onChange={handleFormFieldChange}
				placeholder={conformToMask("", mask, {}).conformedValue}
			/>
		</SchemaFormRow>
	);
});

const SelectFormRow = observer(({ field }: { field: ISchemaField }) => {
	function handleSelect(value: string) {
		field.updateValue(value);
	}

	return (
		<SchemaFormRow key={field.id} field={field}>
			<Select items={field.options} value={field.value} onChange={handleSelect} placeholder="Выберите тему" />
		</SchemaFormRow>
	);
});

export function TaskForm({ form }: { form: ISchemaForm }) {
	return (
		<>
			{form.fields
				.filter(field => field.visible)
				.map(field => {
					if (field.id === ProcessFieldValue.CallType) {
						return <TaskFormRowType key={field.id} field={field} />;
					}

					if (field.id === ProcessFieldValue.Date) {
						return <TaskDateFormRow key={field.id} field={field} />;
					}

					if (field.id === ProcessFieldValue.Time) {
						return <TaskTimeFormRow key={field.id} field={field} />;
					}

					if (field.id === ProcessFieldValue.AppealMessage) {
						return <TaskFormRowAppealMessage key={field.id} field={field} />;
					}

					if (field.type === SchemaFieldType.Select) {
						return <SelectFormRow key={field.id} field={field} />;
					}

					return <SchemaFormFieldRow key={field.id} field={field} />;
				})}
		</>
	);
}

export const TaskItemAction = observer(function({ item }: { item: ITask }) {
	// TODO: track changes in stores
	item.form.fields.forEach(f => {
		f.visible = item.form.visibleFields.includes(f.id);
	});

	function handleSelectScopes(selected: number[]) {
		item.updateActionScopes(selected);
	}

	return (
		<div className="task-form">
			{item.actionIsExecuting && <Overlay inline />}

			<div className="form">
				<TaskForm form={item.form} />
			</div>

			{item.isActionSubprocessRequired(item.actionId) && (
				<TaskScopes
					dataSource={item.scopeDataSource}
					selected={item.selectedActionScopes}
					onSelect={handleSelectScopes}
				/>
			)}
		</div>
	);
});
