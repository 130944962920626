import { XScopeAttrRef, XScopeCard, XScopeChildInfo } from "@external-types/scope";
import { getAttributeValue, getScopeAttribute, getScopeAttributeValue } from "@helpers/scope-attributes";
import { fullName } from "@helpers/user";

import { VolunteerAttributes } from "../../constants/volunteer-attributes";
import { action, computed, observable, runInAction } from "mobx";
import { VolunteerSectionId } from "./volunteer-sections";
import { SchemaForm } from "../../../../../src/models/schema-form";
import { mapAttrValueToFormField } from "../reference-store/reference-scope";
import { XAppTypeInfo } from "@external-types/type";
import { actions } from "@actions";
import { allProjectFieldKeysSet, VolunteerProjects } from "./volunteer-projects";
import { DataSourceSession, Filters, InfiniteDataSource } from "../../../../../src/models/data-source";
import { XTaskExtRef } from "@external-types/process";
import { Scope } from "../../constants/scope";
import { saveVolunteerInfo } from "../../helpers/volunteer-helper";
import { ProcessFieldValue } from "../../constants/process-field-value";
import { IUploadFile } from "../../../../../src/types/models";
import { XFileRef } from "../../../../../src/types/external-data/file";

export enum DisplayMode {
	View = 0,
	Edit = 1,
}

export interface IVolunteer {
	currentSectionId: VolunteerSectionId;
	deletePhoto: () => void;
	form: SchemaForm | null;
	photoUrl: string | null;
	uploadPhoto: (files: IUploadFile[]) => void;
	volunteerProjects: VolunteerProjects | null;
}

class VolunteerActivityFilters extends Filters {
	protected createQuery() {
		const query = super.createQuery();

		return {
			...query,
			vid: [
				ProcessFieldValue.RequestMessage,
				ProcessFieldValue.RequestTopic,
				ProcessFieldValue.CallTopic,
				ProcessFieldValue.AppealMessage,
			],
			pvid: [
				ProcessFieldValue.RequestMessage,
				ProcessFieldValue.RequestTopic,
				ProcessFieldValue.CallTopic,
				ProcessFieldValue.AppealMessage,
			],
			tree: true,
			all: true,
			type: "all",
			orderBy: "date_desc",
		};
	}
}

class VolunteerActivityDataSourceSession extends DataSourceSession<XTaskExtRef> {
	public filter = new VolunteerActivityFilters();
}

class VolunteerActivityDataSource extends InfiniteDataSource<XTaskExtRef> {
	private _session: VolunteerActivityDataSourceSession;

	public constructor(private scopeId: number) {
		super();

		this._session = new VolunteerActivityDataSourceSession();
	}

	protected async fetch(): Promise<XTaskExtRef[]> {
		const result = await actions.getScopeProcesses(this.scopeId, this._session.pagination, this._session.filter);
		this.updatePageInfo(result);

		return result.list;
	}

	protected get session() {
		return this._session;
	}
}

export class Volunteer implements IVolunteer {
	@observable public currentSectionId: VolunteerSectionId = VolunteerSectionId.BaseInfo;
	@observable public mode: DisplayMode = DisplayMode.View;
	@observable public form: SchemaForm | null = null;
	@observable public scope: XScopeChildInfo | XScopeCard;
	@observable public fetching: boolean = false;
	@observable public volunteerProjects: VolunteerProjects | null = null;
	@observable private photo: XFileRef | null = null;
	private typeSchema: XAppTypeInfo | null = null;
	public datasource: VolunteerActivityDataSource;

	public constructor(scope: XScopeChildInfo) {
		this.scope = scope;
		this.datasource = new VolunteerActivityDataSource(scope.id);
		this.photo = scope.files.length ? scope.files[0] : null;
	}

	public get fullName() {
		return fullName({
			firstName: getScopeAttributeValue(this.scope, VolunteerAttributes.FirstName),
			lastName: getScopeAttributeValue(this.scope, VolunteerAttributes.LastName),
			middleName: getScopeAttributeValue(this.scope, VolunteerAttributes.MiddleName),
			username: "Новый доброволец",
		});
	}

	@computed
	public get id() {
		return this.scope.id;
	}

	@computed
	public get phone() {
		return [
			getScopeAttributeValue(this.scope, VolunteerAttributes.AdditionalPhone),
			getScopeAttributeValue(this.scope, VolunteerAttributes.HomePhone),
			getScopeAttributeValue(this.scope, VolunteerAttributes.MobilePhone),
		]
			.filter(phone => phone != null && phone !== "")
			.join(", ");
	}

	@computed
	public get address() {
		const city = getScopeAttributeValue(this.scope, VolunteerAttributes.Settlement);
		const street = getScopeAttributeValue(this.scope, VolunteerAttributes.Address);
		const hasBoth = city && street;

		return `${city}${hasBoth ? ", " : " "}${street}`;
	}

	@computed
	public get projects() {
		return this.scope.attrs
			.filter(attr => allProjectFieldKeysSet.has(attr.attr))
			.map(attr => attr.name)
			.join(", ");
	}

	@computed
	public get email() {
		return getScopeAttributeValue(this.scope, VolunteerAttributes.Email);
	}

	@computed
	public get location() {
		return [
			getScopeAttributeValue(this.scope, VolunteerAttributes.DistrictOfMoscow),
			getScopeAttributeValue(this.scope, VolunteerAttributes.Subway),
		]
			.filter(val => val)
			.join(", ");
	}

	@computed
	public get status() {
		return getScopeAttributeValue(this.scope, VolunteerAttributes.VolunteerStatus);
	}

	@computed
	public get photoUrl() {
		return this.photo !== null ? actions.getScopeFileUrl(this.scope.id, this.photo.id) : null;
	}

	@computed
	public get averageRating() {
		const attrs = [
			VolunteerAttributes.HowIsGood,
			VolunteerAttributes.AlwaysCompletesRequests,
			VolunteerAttributes.CanWarkHard,
			VolunteerAttributes.Responsibility,
			VolunteerAttributes.Diligence,
			VolunteerAttributes.Mobility,
		];

		let size = 0;

		const total = attrs.reduce((accumulator, val) => {
			const value = getScopeAttributeValue(this.scope, val);
			const res = parseInt(value, 10);

			if (Number.isFinite(res)) {
				size++;
				return accumulator + res;
			}

			return accumulator;
		}, 0);

		return new Intl.NumberFormat("ru", {
			maximumFractionDigits: 1,
			minimumFractionDigits: 1,
		}).format(total / size);
	}

	public getAttr(attr: VolunteerAttributes) {
		return getScopeAttribute(this.scope, attr);
	}

	public getAttrValue(attr: XScopeAttrRef) {
		return getAttributeValue(attr, this.scope.type);
	}

	@action
	public updateCurrentSection(sectionId: number) {
		this.currentSectionId = sectionId;
	}

	public async toggleMode(mode: DisplayMode) {
		if (mode === DisplayMode.Edit) {
			try {
				runInAction(() => {
					this.fetching = true;
				});

				if (this.typeSchema === null) {
					await this.fetchTypeSchema();
				}

				runInAction(() => {
					const form = new SchemaForm(
						this.typeSchema!.attrs.filter(attr => !allProjectFieldKeysSet.has(attr.id)),
					);
					mapAttrValueToFormField(this.scope, form);

					this.form = form;
					this.volunteerProjects = new VolunteerProjects(this.scope, this.typeSchema!);
				});
			} finally {
				runInAction(() => {
					this.fetching = false;
				});
			}
		}

		runInAction(() => {
			this.mode = mode;
		});
	}

	private async fetchTypeSchema() {
		const typeSchema = await actions.getScopeType(Scope.ProjectsAndVolunteers, this.scope.type.id);

		runInAction(() => {
			this.typeSchema = typeSchema;
		});
	}

	public uploadPhoto = async (files: IUploadFile[]) => {
		if (files.length > 0) {
			const photo = await actions.uploadScopeFile(this.scope.id, files[0]);

			runInAction(() => {
				this.photo = photo;
			});
		}
	};

	public deletePhoto = async () => {
		if (this.photo) {
			await actions.deleteScopeFile(this.scope.id, this.photo.id);

			runInAction(() => {
				this.photo = null;
			});
		}
	};

	@action
	public async save() {
		let scope = this.scope;

		if (this.form!.validate()) {
			try {
				runInAction(() => {
					this.fetching = true;
				});

				this.volunteerProjects!.form.fields.forEach(field => {
					this.form!.addField(field);
				});

				await saveVolunteerInfo(this.scope, this.form!);

				scope = await actions.getScope(this.scope.id);

				runInAction(() => {
					this.scope = scope;
					this.form = null;
					this.volunteerProjects = null;
					this.photo = scope.files.length > 0 ? scope.files[0] : null;
				});
			} finally {
				runInAction(() => {
					this.fetching = false;
				});
			}
		}

		return scope;
	}

	public fetchActivity() {}

	public deleteScope = async () => {
		if (
			confirm(
				"Удаление карточки. Если Вы удалите карточку подопечного, все данные по подопечному будут удалены из системы и восстановить их будет невозможно.",
			)
		) {
			try {
				runInAction(() => {
					this.fetching = true;
				});
				await actions.deleteScope(this.scope.id, true);
			} finally {
				runInAction(() => {
					this.fetching = false;
				});
			}
		}
	};
}
