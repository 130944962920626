import axios, { AxiosResponse } from "axios";
import qs from "qs";
import {
	Credentials,
	IUploadFile,
	ProcessRefList,
	RefreshTokenRequest,
	ScopePayload,
	ScopeReportList,
	UserStateList,
	XProcessList,
	XScopeChildInfoList,
	XScopeRefList,
	XUserRefList,
} from "@app-types/models";
import { IFilters } from "@app-types/data";
import {
	XProcessAdminInfo,
	XProcessInfo,
	XProcessRef,
	XProcessReport,
	XProcessTaskInfo,
	XStartProcessInfo,
	XTaskList,
} from "@external-types/process";
import { XToken, XUserInfo, XUserProfile, XUserRef } from "@external-types/user";
import { XScopeCard, XScopeChildInfo, XScopeRef } from "@external-types/scope";
import { XAppTypeInfo, XAppTypeRef, XAppTypeRoleRef } from "@external-types/type";
import { XFileRef } from "@external-types/file";
import { XTaskCompletePayload, XTaskValuePayload } from "@external-types/payload";
import { XNextTaskRef } from "@external-types/process/next-task-ref";
import { XExtAuthRef } from "@external-types/user/ext-auth-ref";
import { XScopeTaskList } from "@external-types/process/scope-task-list";
import { ProjectName, XProjectInfo } from "@external-types/project";

const TOKEN_KEY = "app_token";
const REFRESH_KEY = "refresh_token";
const USERNAME_KEY = "username";
const DEVICE_ID_KEY = "device";
const APP_NAME = "web";

function getRefreshToken() {
	return localStorage.getItem(REFRESH_KEY);
}

function getUserName() {
	return localStorage.getItem(USERNAME_KEY);
}

function getDeviceId() {
	return localStorage.getItem(DEVICE_ID_KEY);
}

// Функции работы с токенам аутенфикации
function storeToken(username: string | null, token: string | null, refresh: string | null, deviceId: string | null) {
	api.defaults.headers.common.AUTHTOKEN = token;

	if (username !== null) {
		localStorage.setItem(USERNAME_KEY, username);
	}

	if (token !== null) {
		localStorage.setItem(TOKEN_KEY, token);
	}

	if (refresh !== null) {
		localStorage.setItem(REFRESH_KEY, refresh);
	}

	if (deviceId !== null) {
		localStorage.setItem(DEVICE_ID_KEY, deviceId);
	}
}

function loadToken(response: AxiosResponse) {
	const { username, token, refresh_token, device_id } = response.data;

	storeToken(username, token, refresh_token, device_id);
	return response;
}

function resetToken() {
	delete api.defaults.headers.AUTHTOKEN;
	localStorage.removeItem(TOKEN_KEY);
	localStorage.removeItem(REFRESH_KEY);
	localStorage.removeItem(USERNAME_KEY);
	localStorage.removeItem(DEVICE_ID_KEY);
}

const api = axios.create({
	baseURL: "/api",
	paramsSerializer: params => qs.stringify(params, { arrayFormat: "repeat" }),
});

// Инициализация из localStorage
storeToken(
	localStorage.getItem(USERNAME_KEY),
	localStorage.getItem(TOKEN_KEY),
	localStorage.getItem(REFRESH_KEY),
	localStorage.getItem(DEVICE_ID_KEY),
);

export const Proxy = {
	initAuthInteceptor(resetCallback: () => void) {
		api.interceptors.response.use(undefined, error => {
			const { response, config } = error;
			const refreshToken = getRefreshToken();
			const userName = getUserName();
			// Обработка ошибки аутенцикации
			if (response && config && response.status === 401 && refreshToken && userName && !config.skipAuthRefresh) {
				// Пытаемся обновить токен

				const refreshRequest: RefreshTokenRequest = {
					app: APP_NAME,
					refresh_token: refreshToken,
					username: userName,
				};

				return (
					api
						// @ts-ignore skipAuthRefresh
						.post("/login/app", refreshRequest, { skipAuthRefresh: true })
						.then(loadToken)
						.then(resp => {
							// В случае успеха повторяем запрос c новым токеном
							config.skipAuthRefresh = true;
							config.headers.AUTHTOKEN = resp.data.token;

							return axios.request({
								...config,
								baseURL: "",
							});
						})
						.catch(err => {
							// В случае неудачи очищаем все токены
							resetCallback();
							return Promise.reject(err);
						})
				);
			}

			if (response.status === 401) {
				resetCallback();
			}

			return Promise.reject(error);
		});
	},
};

interface PaginationQuery {
	offset?: number;
	pageSize?: number;
}

function formatFilterPageRequest<T>(uri: string, pagination?: PaginationQuery, filter?: IFilters): Promise<T> {
	let offset = null;
	let pageSize = null;
	if (pagination) {
		offset = pagination.offset;
		pageSize = pagination.pageSize;
	}

	return api
		.get(uri, {
			params: {
				...(filter ? filter.query : {}),
				offset: offset,
				size: pageSize,
			},
		})
		.then(response => response.data);
}

const actions = {
	loginApp(credentials: Credentials): Promise<XToken> {
		return api

			.post(
				"/login/app",
				{
					...credentials,
					app: APP_NAME,
				},
				// @ts-ignore skipAuthRefresh
				{ skipAuthRefresh: true },
			)
			.then(loadToken)
			.then(response => response.data);
	},

	loginRefresh(deviceId: string) {
		api.post(
			"login/app",
			{
				username: getUserName(),
				app: APP_NAME,
				refresh_token: getRefreshToken(),
				device_id: deviceId,
			}, // @ts-ignore skipAuthRefresh
			{ skipAuthRefresh: true },
		).then(loadToken);
	},

	// login(credentials: Credentials): Promise<XToken> {
	// 	return api.post("/login", credentials).then(response => response.data);
	// },

	logout() {
		return api.post("/logout").finally(resetToken);
	},

	getReports(scopeId: number, pagination?: PaginationQuery, filter?: IFilters): Promise<ProcessRefList> {
		return formatFilterPageRequest(`/scopes/${scopeId}/reports`, pagination, filter);
	},

	getMyReports(pagination?: PaginationQuery, filter?: IFilters): Promise<XProcessList> {
		return formatFilterPageRequest("/reports", pagination, filter);
	},

	getMyReport(processId: number): Promise<XProcessReport> {
		return api.get(`/reports/s/${processId}`).then(response => response.data);
	},

	getReport(processId: number): Promise<XProcessReport> {
		return api.get(`/reports/m/${processId}`).then(response => response.data);
	},

	getScope(scopeId: number): Promise<XScopeCard> {
		return api.get(`/scopes/${scopeId}`).then(response => response.data);
	},

	getScopeUsers(scopeId: number): Promise<UserStateList> {
		return formatFilterPageRequest<UserStateList>(`/scopes/${scopeId}/users`);
	},

	getScopeUsersByRole(scopeId: number, roleId: number): Promise<UserStateList> {
		return api.get(`/scopes/${scopeId}/role/${roleId}/users`).then(response => response.data);
	},

	getScopeRoles(scopeId: number): Promise<XAppTypeRoleRef[]> {
		return api.get(`/scopes/${scopeId}/roles`).then(response => response.data);
	},

	getUser(userId: number): Promise<XUserInfo> {
		return api.get(`/users/${userId}`).then(response => response.data);
	},

	getFileContent(fileId: number): Promise<Blob> {
		return api.get(`/files/${fileId}/content`, { responseType: "blob" }).then(response => response.data);
	},

	getScopeExportContent(scopeId: number): Promise<Blob> {
		return api.get(`/export/scope/${scopeId}`, { responseType: "blob" }).then(response => response.data);
	},

	getProjectExportContent(project: string, from: number, to: number) {
		return api
			.get(`/projects/${project}/export?from=${from}&to=${to}`, { responseType: "blob" })
			.then(response => response.data);
	},

	getFileUrlContent(url: string): Promise<Blob> {
		return api.get(url, { responseType: "blob" }).then(response => response.data);
	},

	getTaskFileContent(taskId: number, fileId: number): Promise<Blob> {
		return this.getFileUrlContent(`/files/t/${taskId}/${fileId}/content`);
	},

	getScopeFileContent(scopeId: number, fileId: number): Promise<Blob> {
		return this.getFileUrlContent(`/files/s/${scopeId}/${fileId}/content`);
	},

	getScopeTypes(scopeId: number): Promise<XAppTypeRef[]> {
		return api.get(`/scopes/${scopeId}/types`).then(response => response.data);
	},

	getScopeType(scopeId: number, typeId: number): Promise<XAppTypeInfo> {
		return api.get(`/scopes/${scopeId}/types/${typeId}`).then(response => response.data);
	},

	getTypeSchema(typeId: number): Promise<XAppTypeInfo> {
		return api.get(`/types/${typeId}`).then(response => response.data);
	},

	createScope(scopeId: number, typeId: number, payload: ScopePayload): Promise<XScopeRef> {
		return api.post(`/scopes/${scopeId}/scopes/${typeId}`, payload).then(response => response.data);
	},

	updateScope(scopeId: number, payload: ScopePayload): Promise<XScopeCard> {
		return api.put(`/scopes/${scopeId}`, payload).then(response => response.data);
	},

	uploadScopeFile(scopeId: number, file: IUploadFile): Promise<XFileRef> {
		const formData = new FormData();
		formData.append("file", new Blob([file], { type: file.type }), file.name || "file");

		return api.post(`/scopes/${scopeId}/files`, formData).then(response => response.data);
	},

	async createScopeDeep(
		scopeId: number,
		typeId: number,
		payload: ScopePayload,
		children: Array<{ typeId: number; payload: ScopePayload }> = [],
		files: IUploadFile[] = [],
	): Promise<XScopeRef> {
		const scopeRef: XScopeRef = await this.createScope(scopeId, typeId, payload);
		for (let child of children) {
			await this.createScope(scopeRef.id, child.typeId, child.payload);
		}
		await Promise.all(files.map(file => this.uploadScopeFile(scopeRef.id, file)));

		return scopeRef;
	},

	getScopeChildren(scopeId: number, pagination?: PaginationQuery): Promise<XScopeChildInfo[]> {
		return formatFilterPageRequest<XScopeChildInfo[]>(`/scopes/${scopeId}/childs`, pagination);
	},

	getScopeRefChildren(scopeId: number, pagination?: PaginationQuery, filter?: IFilters) {
		return formatFilterPageRequest<XScopeRefList>(`/scopes/${scopeId}/scopes`, pagination, filter);
	},

	getScopeChildrenPaged(scopeId: number, pagination?: PaginationQuery, filter?: IFilters) {
		return formatFilterPageRequest<XScopeChildInfoList>(`/scopes/${scopeId}/scopes2`, pagination, filter);
	},

	getScopeLastProcessList(
		scopeId: number,
		pagination?: PaginationQuery,
		filter?: IFilters,
	): Promise<ScopeReportList> {
		return formatFilterPageRequest<ScopeReportList>(`/scopes/${scopeId}/pchilds`, pagination, filter);
	},

	getScopeReports(scopeId: number): Promise<XProcessRef[]> {
		return api.get(`/scopes/${scopeId}/my-reports`).then(response => response.data.list);
	},

	getScopeProcesses(scopeId: number, pagination?: PaginationQuery, filter?: IFilters): Promise<XTaskList> {
		return formatFilterPageRequest(`/scopes/${scopeId}/processes`, pagination, filter);
	},

	getProcessByManager(processId: number): Promise<XProcessAdminInfo> {
		return api.get(`/processes/m/${processId}`).then(response => response.data);
	},

	getCuratorProcess(processId: number): Promise<XProcessInfo> {
		return api.get(`/processes/c/${processId}`).then(response => response.data);
	},

	getMyProcesses(pagination?: PaginationQuery, filter?: IFilters): Promise<XProcessList> {
		return formatFilterPageRequest<XProcessList>(`/processes`, pagination, filter);
	},

	getMyTasks(pagination?: PaginationQuery, filter?: IFilters): Promise<XTaskList> {
		return formatFilterPageRequest("/tasks", pagination, filter);
	},

	getScopeTasks(scopeId: number, pagination?: PaginationQuery, filter?: IFilters): Promise<XScopeTaskList> {
		return formatFilterPageRequest(`/scopes/${scopeId}/tasks`, pagination, filter);
	},

	getTask(taskId: number): Promise<XProcessTaskInfo> {
		return api.get(`/tasks/${taskId}`).then(response => response.data);
	},

	getTaskReports(
		taskId: number,
		stateId: number,
		pagination?: PaginationQuery,
		filter?: IFilters,
	): Promise<ProcessRefList> {
		return formatFilterPageRequest(`tasks/${taskId}/${stateId}/reports`, pagination, filter);
	},

	uploadFile(file: IUploadFile): Promise<XFileRef> {
		const formData = new FormData();
		formData.append("file", new Blob([file], { type: file.type }), file.name || "file");

		return api.post("/files", formData).then(response => response.data);
	},

	async runTaskAction(
		taskId: number,
		actionId: number,
		data: {
			items: Array<{ id: number; value?: string; files?: IUploadFile[] }>;
			accounts?: number[];
			scopes?: number[];
			expire?: number;
			subform?: number;
		},
	): Promise<XNextTaskRef> {
		const payload: XTaskCompletePayload = {
			values: [],
		};

		if (data.accounts) {
			payload.accounts = data.accounts;
		}

		if (data.expire) {
			payload.expire = data.expire;
		}

		if (data.scopes) {
			payload.scopes = data.scopes;
		}

		if (data.subform) {
			payload.subform = data.subform;
		}

		for (const { id, value, files } of data.items) {
			const item: XTaskValuePayload = { item: id, value };

			if (files && files.length > 0) {
				const fileIds: number[] = [];

				for (const file of files) {
					const fileRef = await this.uploadFile(file);
					fileIds.push(fileRef.id);
				}

				item.files = fileIds;
			}

			payload.values.push(item);
		}

		return api.post(`/tasks/${taskId}/${actionId}`, payload).then(response => response.data);
	},

	getUserTasks(): Promise<XStartProcessInfo[]> {
		return api.get("/tasks/refs").then(response => response.data);
	},

	getTaskActionExecutors(taskId: number, actionId: number, pagination?: PaginationQuery): Promise<XUserRefList> {
		return formatFilterPageRequest<XUserRefList>(`/tasks/${taskId}/${actionId}/users`, pagination);
	},

	async getTaskScopes(
		taskId: number,
		actionId: number,
		pagination?: PaginationQuery,
		filter?: IFilters,
	): Promise<XScopeRefList> {
		return formatFilterPageRequest<XScopeRefList>(`/tasks/${taskId}/${actionId}/scopes`, pagination, filter);
	},

	async getAllTaskActionExecutors(taskId: number, actionId: number): Promise<XUserRef[]> {
		let result: XUserRef[] = [];
		let hasMore = true;
		const pageSize = 100;
		let pagination = {
			offset: 0,
			pageSize,
		};

		while (hasMore) {
			const res = await formatFilterPageRequest<XUserRefList>(`/tasks/${taskId}/${actionId}/users`, pagination);
			result = result.concat(res.list);

			hasMore = res.pagination.hasMore;
			pagination = {
				offset: pagination.offset + pageSize,
				pageSize,
			};
		}
		return result;
	},

	getProcessAdminInfo(processId: number): Promise<XProcessAdminInfo> {
		return api.get(`/mprocesses/${processId}`).then(response => response.data);
	},

	deleteProcess(processId: number) {
		return api.delete(`/mprocesses/${processId}`).then(response => response.data);
	},

	moveProcessToScope(processId: number, scopeId: number): Promise<XProcessAdminInfo> {
		return api.post(`/mprocesses/${processId}/move/${scopeId}`).then(response => response.data);
	},

	getScopesForProcessMove(
		processId: number,
		pagination?: PaginationQuery,
		filter?: IFilters,
	): Promise<XScopeRefList> {
		return formatFilterPageRequest<XScopeRefList>(`/mprocesses/${processId}/move/scopes`, pagination, filter);
	},

	updateProcess(
		processId: number,
		taskId: number,
		payload: { values: Array<{ id: number; value: string }> },
	): Promise<XProcessAdminInfo> {
		return api.put(`/mprocesses/${processId}/tasks/${taskId}`, payload).then(response => response.data);
	},

	getSubprocesses(processId: number, taskId: number, pagination?: PaginationQuery): Promise<ProcessRefList> {
		return formatFilterPageRequest<ProcessRefList>(`/mprocesses/${processId}/${taskId}/processes`, pagination);
	},

	createScopeProcess(scopeId: number, taskId: number, name: string, expire: number): Promise<XProcessRef> {
		return api
			.post(`/scopes/${scopeId}/processes/${taskId}`, {
				name,
				expire,
			})
			.then(response => response.data);
	},

	deleteScope(scopeId: number, force: boolean = false) {
		return api.delete(`/scopes/${scopeId}?force=${force}`);
	},

	deleteScopeFile(scopeId: number, fileId: number) {
		return api.delete(`/files/s/${scopeId}/${fileId}`);
	},

	profile(): Promise<XUserProfile> {
		return api.get("/profile").then(response => response.data);
	},

	updateProfile(payload: Partial<XUserProfile>): Promise<XUserRef> {
		return api.put("/profile", payload).then(response => response.data);
	},

	updatePassword(payload: { old: string; new: string }): Promise<XUserRef> {
		return api.post("/profile/password", payload).then(response => response.data);
	},

	listAuths(): Promise<XExtAuthRef[]> {
		return api.get("/auths").then(response => response.data);
	},

	completeAuth(auth: any, payload: any): Promise<XToken> {
		return api.post(`/auths/${auth}/complete`, payload).then(response => response.data);
	},

	getProjectReport(name: ProjectName, date: number | null = null): Promise<XProjectInfo> {
		return api.get(`/projects/${name}/info?day=${date}`).then(response => response.data);
	},

	getScopeFileUrl(scopeId: number, fileId: number) {
		return `/api/files/s/${scopeId}/${fileId}/content`;
	},

	getTaskProcessLinkUrl(taskId: number, linkId: number) {
		return `/api/tasks/${taskId}/link/${linkId}`;
	},

	getProcessLinkUrl(processId: number, linkId: number) {
		return `/api/processes/m/${processId}/link/${linkId}`;
	},

	updateDeviceId(deviceId: string) {
		if (deviceId !== getDeviceId()) {
			actions.loginRefresh(deviceId);
		}
	},

	addErrorFilter(filter: (error: any) => void) {
		api.interceptors.response.use(
			resp => resp,
			error => filter(error),
		);
	},
};

export { actions };
