import React from "react";
import cn from "classnames";
import { XFileRef } from "@external-types/file";
import { fileIcon } from "@app-components/files/file-icon";
import { downloadTaskFile } from "@helpers/downloader";

interface Props {
	file: XFileRef;
	taskId: number;
	className?: string;
}

export function TaskFileItem({ file, taskId, className }: Props) {
	const Icon = fileIcon(file.filename, file.mimetype);

	function handleDownload() {
		downloadTaskFile(taskId, file);
	}

	return (
		<div className={cn("file-item", className)} onClick={handleDownload}>
			<Icon className="file-preview-list__file-icon" />
			<span className="file-item__text" title={file.filename}>
				{file.filename}
			</span>
		</div>
	);
}
