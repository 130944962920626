import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { RouteComponentProps, withRouter } from "react-router";
import { AppHeader } from "../components/app-header";
import { StoreContext } from "../context";

export const Home = withRouter(
	observer(function({ history }: RouteComponentProps) {
		const { appStore } = useContext(StoreContext);

		useEffect(() => {
			if (appStore.authUser) {
				history.replace(appStore.userSections[0].path);
			}
		}, [appStore.authUser]);

		return (
			<>
				<AppHeader />
				<div className="app__content" />
			</>
		);
	}),
);
