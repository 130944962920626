import React from "react";
import { SvgIcon } from "@app-types/models";

export function User({ className }: SvgIcon) {
	return (
		<svg viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
			<path
				d="M21.6365 14.9921C19.8652 14.9858 18.1708 15.7153 16.9581 17.0065C15.2139 15.615 13.0469 14.8608 10.8156 14.8692H10.7981C8.11636 14.8749 5.62784 15.9173 3.78346 17.8144C1.90961 19.7293 0.884972 22.3465 0.890648 25.1864C0.891747 25.3419 0.95272 25.4905 1.06057 25.602C1.17061 25.7123 1.32039 25.7736 1.47621 25.772L16.8468 25.7427C16.882 25.7837 16.9169 25.8188 16.9523 25.8598C18.9702 27.9955 22.178 28.495 24.75 27.0744C27.322 25.6535 28.607 22.6726 27.8737 19.8272C27.1404 16.9818 24.5749 14.993 21.6365 14.9921ZM2.07934 24.595C2.20238 22.2938 3.09244 20.1918 4.6206 18.6285C6.24269 16.9597 8.43844 16.0403 10.7981 16.0403H10.8156C12.7851 16.0326 14.6981 16.6977 16.2378 17.9257C14.9405 19.9294 14.855 22.4857 16.0153 24.5717L2.07934 24.595ZM21.6365 26.703C18.726 26.703 16.3667 24.3436 16.3667 21.433C16.3667 18.5228 18.726 16.1632 21.6365 16.1632C24.5471 16.1632 26.9064 18.5228 26.9064 21.433C26.9064 24.3436 24.5471 26.703 21.6365 26.703Z"
				fill="currentColor"
				stroke="currentColor"
			/>
			<path
				d="M10.8039 13.4752C14.222 13.4752 16.993 10.7042 16.993 7.28604C16.993 3.86773 14.222 1.09668 10.8039 1.09668C7.38573 1.09668 4.61469 3.86773 4.61469 7.28604C4.61798 10.7027 7.38702 13.4719 10.8039 13.4752ZM10.8039 2.26779C13.5755 2.26779 15.8219 4.51462 15.8219 7.28604C15.8219 10.0573 13.5755 12.3041 10.8039 12.3041C8.03245 12.3041 5.7858 10.0573 5.7858 7.28604C5.78909 4.51591 8.03373 2.27127 10.8039 2.26779Z"
				fill="currentColor"
				stroke="currentColor"
			/>
			<path
				d="M23.6098 19.6473L20.7814 21.9485L19.7451 20.7364C19.5349 20.4905 19.1654 20.4617 18.9196 20.6719C18.6737 20.8821 18.645 21.2518 18.8552 21.4977L20.2605 23.1429C20.372 23.2725 20.5344 23.3474 20.7056 23.3478C20.8396 23.3492 20.97 23.3034 21.0742 23.219L24.3477 20.5547C24.5949 20.3504 24.6309 19.9846 24.4286 19.7355C24.2259 19.4863 23.8606 19.4472 23.6098 19.6473Z"
				fill="currentColor"
				stroke="currentColor"
			/>
		</svg>
	);
}
