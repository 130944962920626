import React from "react";
import { SvgIcon } from "@app-types/models";
import { bem } from "@helpers/bem";

const cnb = bem("state-path");

export default function({ className }: SvgIcon) {
	return (
		<svg className={cnb.classNames(className)} viewBox="0 0 17 47" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				className={cnb.em("midpoint")}
				d="M8.49487 27C10.4279 27 11.9949 25.433 11.9949 23.5C11.9949 21.567 10.4279 20 8.49487 20C6.56188 20 4.99487 21.567 4.99487 23.5C4.99487 25.433 6.56188 27 8.49487 27Z"
			/>
			<path
				className={cnb.em("point")}
				d="M8.49487 30C12.0847 30 14.9949 27.0899 14.9949 23.5C14.9949 19.9101 12.0847 17 8.49487 17C4.90502 17 1.99487 19.9101 1.99487 23.5C1.99487 27.0899 4.90502 30 8.49487 30Z"
				strokeWidth="3"
			/>
			<rect className={cnb.em("next")} x="6.99487" width="3" height="14" rx="1.5" />
			<rect className={cnb.em("prev")} x="6.99487" y="33" width="3" height="14" rx="1.5" />
		</svg>
	);
}
