import React from "react";
import { observer } from "mobx-react-lite";
import cn from "classnames";

import { Select } from "@app-ui";
import { YesNoButton } from "@app-components/buttons/yesno-button";
import { IFilters, IFilterItem } from "@app-types/data";
import { FilterType } from "@app-constants/filter-type";
import { SearchTextbox } from "@app-components/search-textbox";

interface S {
	label: string;
	value: boolean;
	onChange(value: boolean): void;
}

function RadioFilter({ label, value, onChange }: S) {
	function handleClick() {
		onChange(!value);
	}

	return (
		<div className={cn("filter-radio", { "filter-radio--active": value })} onClick={handleClick}>
			<YesNoButton active={value}>{label}</YesNoButton>
		</div>
	);
}

function FilterComponent({ filter, onApply }: { filter: IFilterItem; onApply: (id: string, value: any) => void }) {
	function handleFilterChange(value: any) {
		onApply(filter.name, value);
	}

	switch (filter.type) {
		case FilterType.Select:
			return (
				<Select
					key={filter.name}
					items={filter.options}
					value={filter.value || ""}
					disabled={filter.options.length <= 1}
					onChange={handleFilterChange}
				/>
			);
		case FilterType.YesNo:
			return (
				<RadioFilter
					key={filter.name}
					label={filter.label}
					value={Boolean(filter.value)}
					onChange={handleFilterChange}
				/>
			);
		case FilterType.Search:
			return (
				<SearchTextbox
					onChange={handleFilterChange}
					value={filter.value as string}
					className="filter-search-input"
					placeholder={filter.label}
				/>
			);
		default:
			return null;
	}
}

export const FilterToolbar = observer(function({
	filters,
	onApply,
	additionalFilters,
	hiddenFilters = [],
}: {
	filters: IFilters;
	onApply: (id: string, value: any) => void;
	additionalFilters?: IFilterItem[];
	hiddenFilters?: string[];
}) {
	return (
		<div className="content-toolbar__actions">
			{filters.items
				.filter(item => !hiddenFilters.includes(item.name))
				.map(filter => (
					<FilterComponent filter={filter} onApply={onApply} key={filter.name} />
				))}
			{additionalFilters != null &&
				additionalFilters.map(filter => (
					<FilterComponent filter={filter} onApply={onApply} key={filter.name} />
				))}
		</div>
	);
});
