import React, { forwardRef, Ref } from "react";
import cn from "classnames";
import { Textbox } from "@app-ui";
import { SearchIcon } from "@icons";
import { useDebouncedEffect } from "@shared/effects/use-debounce-effect";

interface Props {
	value?: string;
	className?: string;
	delay?: number;
	placeholder?: string;
	onChange(value: string): void;
}

export const SearchTextbox = forwardRef(
	({ onChange, className, value = "", delay = 1000, placeholder }: Props, ref: Ref<HTMLInputElement>) => {
		const [val, setValue] = React.useState(value);

		function handleChange(value: string) {
			setValue(value);
		}

		useDebouncedEffect(
			() => {
				onChange(val);
			},
			delay,
			[val],
		);

		return (
			<span className="search-textbox">
				<Textbox
					placeholder={placeholder || "Поиск..."}
					Icon={<SearchIcon className="search-icon" />}
					onChange={handleChange}
					value={val}
					className={cn(className)}
					ref={ref}
				/>
			</span>
		);
	},
);
