import React from "react";
import { observer } from "mobx-react";
import AudioPlayer from "react-h5-audio-player";

import { Overlay } from "@app-components/controls/overlay";

import { Task as TaskContent } from "../task";
import { CallItem } from "../../../stores/call-center-store/call-item";
import { WardDetailsForm } from "../../components/ward-details-form";
import { IncomingCallDetailsHistory } from "./incoming-call-details-history";
import { CallHistory } from "../../components/call-history";

interface Props {
	onAttach: (scopeId: number) => Promise<void>;
	details: CallItem;
}

export const IncomingCallDetails = observer(({ onAttach, details }: Props) => {
	function handleWardSave() {
		details.updateWardForm();
	}

	return (
		<div className="call-details">
			{details.inProgress && <Overlay inline />}
			<div className="call-details__forms">
				<div className="call-details__ward-form">
					<div className="call-details__header">Звонящий</div>
					<WardDetailsForm
						form={details.form!}
						onAttach={onAttach}
						onSave={handleWardSave}
						additionalInfo={details.additionalInfo}
					/>
				</div>
				<div className="call-details__process-form">
					<div className="call-details__header">Обращение</div>
					{details.audioRecordUrl != null && (
						<AudioPlayer
							src={details.audioRecordUrl}
							layout="horizontal-reverse"
							showJumpControls={false}
							showSkipControls={false}
							showFilledVolume={false}
							customAdditionalControls={[]}
							customVolumeControls={[]}
						/>
					)}

					<IncomingCallDetailsHistory details={details} />
					<TaskContent item={details} />
				</div>
			</div>
			{details.scopeProcesses.length > 0 && <CallHistory scopeProcesses={details.scopeProcesses} />}
		</div>
	);
});
