import React from "react";

import { VolunteerAttributes } from "../../../../constants/volunteer-attributes";
import { Volunteer } from "../../../../stores/volunteers-store";
import { AttrFormRowInfo } from "./base-info-section";

interface Props {
	entry: Volunteer;
}

export const InterviewSection = ({ entry }: Props) => {
	return (
		<div className="scope-card-content__row">
			<div className="scope-card-content__column">
				<div className="scope-card-heading">Информация о собеседовании</div>
				<div>
					<AttrFormRowInfo attr={entry.getAttr(VolunteerAttributes.InterviewDate)} scope={entry.scope} />
					<AttrFormRowInfo attr={entry.getAttr(VolunteerAttributes.Interviewer)} scope={entry.scope} />
					<AttrFormRowInfo
						attr={entry.getAttr(VolunteerAttributes.DateAndResultsOfTheCall)}
						scope={entry.scope}
					/>
					<AttrFormRowInfo
						attr={entry.getAttr(VolunteerAttributes.WhereFromDidYouKnowAboutUs)}
						scope={entry.scope}
					/>
					<AttrFormRowInfo attr={entry.getAttr(VolunteerAttributes.WhereDidKnow)} scope={entry.scope} />
					<AttrFormRowInfo
						attr={entry.getAttr(VolunteerAttributes.DateOfQuestionnaireAdded)}
						scope={entry.scope}
					/>
					<AttrFormRowInfo
						attr={entry.getAttr(VolunteerAttributes.DateOfQuestionnaireUpdated)}
						scope={entry.scope}
					/>
				</div>
			</div>
		</div>
	);
};
