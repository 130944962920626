import React from "react";
import { SvgIcon } from "@app-types/models";

export default function({ className }: SvgIcon) {
	return (
		<svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12 12.7031C10.8369 12.7031 9.89062 13.6494 9.89062 14.8125H11.2969C11.2969 14.4248 11.6123 14.1094 12 14.1094C12.3877 14.1094 12.7031 14.4248 12.7031 14.8125H14.1094C14.1094 13.6494 13.1631 12.7031 12 12.7031Z"
				fill="black"
			/>
			<path
				d="M2.85938 21.8906V24H21.1406V21.8906C21.1406 19.4844 19.7732 17.3072 17.6664 16.242C18.0895 15.3874 18.3281 14.4244 18.3281 13.4062C18.3281 12.45 18.3281 9.41175 18.3281 8.48438H19.7344V4.26562H18.2786C17.9392 1.85766 15.8838 0 13.4062 0H10.5938C8.11622 0 6.06075 1.85766 5.72137 4.26562H4.26562V8.48438H5.67188V13.4062C5.67188 14.4244 5.91047 15.3874 6.33361 16.242C4.22681 17.3072 2.85938 19.4844 2.85938 21.8906ZM5.59842 20.1018L4.40217 20.7401C4.57673 20.0153 4.9133 19.3451 5.37755 18.7762L5.59842 20.1018ZM16.3729 22.5938H14.9671L15.2483 21.1875H16.7245L16.3729 22.5938ZM12 18.375C9.28608 18.375 7.07812 16.146 7.07812 13.4062V12.5559C10.3097 11.8322 13.6905 11.8322 16.9219 12.5559V13.4062C16.9219 16.146 14.7139 18.375 12 18.375ZM12 19.7812C12.763 19.7812 13.4949 19.6445 14.1729 19.3941L13.533 22.5938H10.4671L9.82716 19.3941C10.5051 19.6445 11.237 19.7812 12 19.7812ZM19.7344 21.8906V22.5938H17.8224L18.5255 19.7812H15.5295L15.783 18.5137C16.1982 18.2008 16.5739 17.8376 16.9016 17.4335C18.6141 18.2365 19.7344 19.9697 19.7344 21.8906ZM10.5938 1.40625H13.4062C15.1073 1.40625 16.5297 2.63686 16.8528 4.26562H7.14722C7.47028 2.63686 8.8927 1.40625 10.5938 1.40625ZM5.67188 5.67188H18.3281V7.07812H5.67188V5.67188ZM8.48438 8.48438V9.89062H9.89062V8.48438H14.1094V9.89062H15.5156V8.48438H16.9219V11.1177C13.6845 10.4377 10.3156 10.4377 7.07812 11.1177V8.48438H8.48438ZM8.21691 18.5137L9.03295 22.5938H4.26562V22.407L7.15158 20.8669L6.62203 17.6897C6.77503 17.5961 6.93389 17.5105 7.09828 17.4335C7.42608 17.8376 7.80178 18.2008 8.21691 18.5137Z"
				fill="black"
			/>
		</svg>
	);
}
