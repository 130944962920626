import { SchemaForm } from "@app-models/schema-form";
import { SubReferenceCollection } from ".";

export interface IReferenceScopeSection {
	id: number;
	scopeId: number | null;
	name: string;
	fetched: boolean;
	form: SchemaForm | null;
	children: SubReferenceCollection | null;
	fetch: () => Promise<void>;
	update: () => Promise<boolean>;
	validate(): boolean;
}

export const NameFieldId = -1;