import React from "react";
import { observer } from "mobx-react";

import { XScopeAttrRef, XScopeChildInfo } from "@external-types/scope";
import { getAttributeValue } from "@helpers/scope-attributes";
import { breakByLine } from "@helpers/text";

import { VolunteerAttributes } from "../../../../constants/volunteer-attributes";
import { Volunteer } from "../../../../stores/volunteers-store";
import { UserPhoto } from "../../../components/user-photo";

interface Props {
	entry: Volunteer;
}

export function AttrFormRowInfo({
	attr,
	label,
	scope,
}: {
	attr?: XScopeAttrRef;
	label?: string;
	scope: XScopeChildInfo;
}) {
	if (!attr || !attr.value) {
		return null;
	}

	return (
		<div className="form__row">
			<div className="form__label">{label ?? attr.name}</div>
			<div className="form__value">{getAttributeValue(attr, scope.type)}</div>
		</div>
	);
}

export function ScopeCardInfo({
	attr,
	label,
	scope,
	breakNewLine,
}: {
	attr?: XScopeAttrRef;
	label?: string;
	scope: XScopeChildInfo;
	breakNewLine?: boolean;
}) {
	if (!attr || !attr.value) {
		return null;
	}

	const val = breakNewLine ? breakByLine(getAttributeValue(attr, scope.type)) : [getAttributeValue(attr, scope.type)];

	return (
		<div className="scope-card-info">
			<div className="scope-card-info__label">{label ?? attr.name}</div>
			<div className="scope-card-info__value">
				{val.map((str, index) => (
					<React.Fragment key={index}>
						{str}
						<br />
					</React.Fragment>
				))}
			</div>
		</div>
	);
}

export const BaseInfoSection = observer(({ entry }: Props) => {
	return (
		<div className="scope-card-content__row">
			<div className="scope-card-content__column">
				<div className="scope-card-heading">Личные данные</div>
				{entry.photoUrl && (
					<div className="scope-card-user-photo">
						<UserPhoto fileUrl={entry.photoUrl} />
					</div>
				)}
				<div>
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.LastName)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.FirstName)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.MiddleName)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.Birthday)} scope={entry.scope} />
					{/*<AttrFormRowInfo attr={entry.getAttr(VolunteerAttributes.Age)} scope={entry.scope} />*/}
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.Gender)} scope={entry.scope} />
				</div>
			</div>

			<div className="scope-card-content__column">
				<div className="scope-card-heading">Состояние</div>
				<div>
					{/*<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.InPrayList)} scope={entry.scope} />*/}
					{/*<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.ReasonOfInPrayList)} scope={entry.scope} />*/}
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.VolunteerStatus)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.Dead)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.DateOfDeath)} scope={entry.scope} />
				</div>
			</div>
		</div>
	);
});
