import React from "react";
import { Ward } from "../../../../stores/wards-store";
import { AttrFormRowInfo } from "./base-info-section";
import { WardAttributes } from "../../../../constants/ward-attributes";
import { WardRequestHistory } from "../ward-request-history";

interface Props {
	entry: Ward;
}

export const ProjectsSection = ({ entry }: Props) => {
	return (
		<>
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.RegularVolunteers)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.Projects)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.PB_s)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.TargetedAssistanceSao)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.TargetedAssistanceSvao)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.TargetedAssistanceVao)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.TargetedAssistanceUvao)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.TargetedAssistanceUao)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.TargetedAssistanceUzao)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.TargetedAssistanceZao)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.TargetedAssistanceSzao)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.MMO)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.MO)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.InquiryOffice)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.Patronage)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.TransportationServices)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.WashingRoom)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.WashingRoomOurs)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.HouseForMother)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.OtherProject)} scope={entry.scope} />
			<WardRequestHistory />
		</>
	);
};
