import React, { useContext } from "react";

import { Volunteer } from "../../../../stores/volunteers-store";
import { StoreContext } from "../../../../context";
import { allProjectFieldKeysSet } from "../../../../stores/volunteers-store/volunteer-projects";
import { VolunteerAttributes } from "../../../../constants/volunteer-attributes";
import { ScopeCardInfo } from "./base-info-section";
import { VolunteerActivity } from "../../volunteer-activity";

interface Props {
	entry: Volunteer;
}

export const ProjectsSection = ({ entry }: Props) => {
	const { volunteersStore } = useContext(StoreContext);

	return (
		<>
			<div className="scope-card-content__row">
				<div className="scope-card-content__column">
					<div className="scope-card-heading">Проекты</div>
					<ul className="volunteer-projects-list">
						{volunteersStore
							.currentItem!.scope.attrs.filter(attr => allProjectFieldKeysSet.has(attr.attr))
							.map(attr => (
								<li key={attr.id}>{attr.name}</li>
							))}
					</ul>
				</div>
				<div className="scope-card-content__column">
					<div className="scope-card-heading">Постоянные подопечные</div>
					<ScopeCardInfo
						attr={entry.getAttr(VolunteerAttributes.RegularWards)}
						breakNewLine
						scope={entry.scope}
					/>
				</div>
			</div>
			<VolunteerActivity />
		</>
	);
};
