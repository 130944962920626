import React, { useContext } from "react";
import { observer } from "mobx-react";

import { withFetching } from "../../components/with-fetching";
import { StoreContext } from "../../context";

import { Paths } from "../../routes/paths";

export { ReferenceItem } from "./reference-item";
export { ReferenceItemCreation } from "./reference-item-creation";
export { ReferenceList } from "./reference-list";

interface Props {
	children?: React.ReactNode;
}

const ReferenceContent = ({ children }: Props) => {
	return <>{children}</>;
};

//@ts-ignore
const ContentWithFetching = withFetching(ReferenceContent);

export const ReferenceWrapper = observer(function({ children }: { children?: React.ReactNode }) {
	const { referenceStore } = useContext(StoreContext);

	return (
		<ContentWithFetching
			fetch={referenceStore.fetch}
			initialized={referenceStore.initialized}
			path={Paths.Reference}
		>
			{children}
		</ContentWithFetching>
	);
});
