import React, { useContext } from "react";
import { RouteComponentProps, useParams, withRouter } from "react-router";
import { observer } from "mobx-react";

import { ArrowIcon } from "@icons";

import { StoreContext } from "../../context";
import { Paths } from "../../routes/paths";
import { withFetching } from "../../components/with-fetching";

import { TaskItemAction } from "../call-center/task/task-item-action";
import { TaskActions } from "./task-actions";
import { HelpRequestInfo } from "../components/help-request-info";
import { HelpRequestHistory } from "../components/help-request-history";

const BackButton = withRouter(({ history }: RouteComponentProps) => {
	function handleBack() {
		history.push(Paths.ActiveRequests);
	}

	return (
		<button className="back-button" onClick={handleBack}>
			<ArrowIcon className="back-button__icon" />
		</button>
	);
});
//
// export const RequestState = observer(
// 	({ item }: { item: RequestItemStore | CallItemHistory | ProcessedRequestItem }) => {
// 		const [collapsed, setCollapsed] = React.useState(true);
//
// 		function handleClick() {
// 			setCollapsed(!collapsed);
// 		}
//
// 		if (!item.processInfo) {
// 			return null;
// 		}
//
// 		return (
// 			<div className="task-sub-item">
// 				<div className="task-sub-item-connector" />
// 				<div>
// 					<div className="task-step-summary" onClick={handleClick}>
// 						<div className="task-step-summary__state">
// 							<div className="task-step-summary-state">{item.processInfo.state}</div>
// 						</div>
// 						<div
// 							className={cn("task-step-summary__caret", {
// 								"task-step-summary__caret--expanded": !collapsed,
// 							})}
// 						>
// 							<CaretIcon />
// 						</div>
// 					</div>
// 					{!collapsed && (
// 						<>
// 							{item.processInfo.state === "Назначен доброволец" ? (
// 								<div>
// 									{item.requestHistory.volunteersExecutionHistory.items.length > 0 && (
// 										<VolunteersExecutionStep
// 											items={item.requestHistory.volunteersExecutionHistory.items}
// 										/>
// 									)}
// 								</div>
// 							) : (
// 								<div className="task-step-fields">
// 									{item.processInfo.processes &&
// 										item.taskReports.map(x => (
// 											<div key={x.id} className="form__value">
// 												{x.scope.name}
// 											</div>
// 										))}
// 								</div>
// 							)}
// 						</>
// 					)}
// 				</div>
// 			</div>
// 		);
// 	},
// );

export const RequestItemContent = observer(() => {
	const { requestStore } = useContext(StoreContext);
	const requestItem = requestStore.currentItem!;
	const wardInfo = requestItem.ward!;

	return (
		<div className="page__content">
			<div className="help-request-header">
				<div className="help-request-header__back-action">
					<BackButton />
				</div>
				<div className="help-request-header__title">
					<div className="help-request-header__name">{wardInfo.fullName}</div>
					<div className="help-request-header__info">{`${wardInfo.gender}${
						wardInfo.age ? `, ${wardInfo.age} лет` : ""
					}`}</div>
				</div>
				<div className="help-request-header__state help-request-header-state">
					<div className="help-request-header-state__label">Статус</div>
					<div className="help-request-header-state__value">{requestItem.task!.state}</div>
				</div>
			</div>

			<HelpRequestInfo
				wardInfo={requestItem.ward!}
				baseTask={requestItem.baseTask}
				audioRecordUrl={requestItem.audioRecordUrl}
				processId={requestItem.processInfo!.pid}
			/>
			<HelpRequestHistory
				requestItem={requestItem!}
				Actions={
					<div className="task-sub-item">
						<div className="task-sub-item-connector" />
						<div className="task-sub-item-form">
							<div>
								<TaskItemAction item={requestItem.task!} />
								<TaskActions item={requestItem} />
							</div>
						</div>
					</div>
				}
			/>
		</div>
	);
});

const ContentWithFetching = withFetching(RequestItemContent);

interface Props {
	children?: React.ReactNode;
}

export const RequestItem = observer(function({ children }: Props) {
	const { requestStore } = useContext(StoreContext);
	const [initialized, setInitialized] = React.useState(false);
	const { requestId } = useParams<{ requestId: string }>();
	const requestIdParam = parseInt(requestId, 10);

	async function fetch() {
		await requestStore.fetchTask(requestIdParam);
		setInitialized(true);
	}

	return (
		<ContentWithFetching fetch={fetch} initialized={initialized} path={Paths.ActiveRequests}>
			{children}
		</ContentWithFetching>
	);
});
