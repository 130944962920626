import React from "react";
import { SvgIcon } from "@app-types/models";

export function ChristianCross({ className }: SvgIcon) {
	return (
		<svg viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
			<circle cx="16.5" cy="16.5" r="16.5" fill="#EE5D5D" />
			<path
				d="M23.2664 10.4131H18.5271V5.67383C18.5271 5.3017 18.2254 5 17.8533 5H15.1467C14.7746 5 14.4729 5.3017 14.4729 5.67383V10.4131H9.73364C9.36151 10.4131 9.05981 10.7148 9.05981 11.0869V13.7935C9.05981 14.1656 9.36151 14.4673 9.73364 14.4673H14.4729V27.3262C14.4729 27.6983 14.7746 28 15.1467 28H17.8533C18.2254 28 18.5271 27.6983 18.5271 27.3262V14.4673H23.2664C23.6385 14.4673 23.9402 14.1656 23.9402 13.7935V11.0869C23.9402 10.7148 23.6385 10.4131 23.2664 10.4131ZM22.5925 13.1196H17.8533C17.4811 13.1196 17.1794 13.4213 17.1794 13.7935V26.6523H15.8206V13.7935C15.8206 13.4213 15.5189 13.1196 15.1467 13.1196H10.4075V11.7607H15.1467C15.5189 11.7607 15.8206 11.459 15.8206 11.0869V6.34766H17.1794V11.0869C17.1794 11.459 17.4811 11.7607 17.8533 11.7607H22.5925V13.1196Z"
				fill="white"
			/>
		</svg>
	);
}
