import React from "react";
import { observer } from "mobx-react";
import { PanelSection } from "@app-components/panel-test/panel-section";
import { Row } from "@app-components/panel-test/row";
import { Column } from "@app-components/panel-test/column";
import { TabGroupItem } from "./tab-group-item";
import { TabbedControl, TabbedControlData } from "@app-models/tabbed-control";

interface Props {
	data: TabbedControlData;
	onTabChange: (key: number) => void;
	children?: React.ReactNode;
}

export const PanelTabsContent = observer(({ data, onTabChange, children }: Props) => {
	const [controlRef, setControl] = React.useState<TabbedControl>();

	React.useEffect(() => {
		const control = new TabbedControl(data);
		setControl(control);

		onTabChange(control.activeTabKey as number);
	}, [data]);

	function handleClick(key: number | string) {
		controlRef!.updateKey(key);

		onTabChange(key as number);
	}

	const control = controlRef;

	if (!control) {
		return null;
	}

	return (
		<PanelSection title={data.title} padding={false}>
			<Row>
				<Column>
					<PanelSection>
						{control.data.items.map(tab => (
							<TabGroupItem
								activeKey={control.activeTabKey}
								onClick={handleClick}
								tab={tab}
								key={tab.key}
							/>
						))}
					</PanelSection>
				</Column>
				<Column>
					<PanelSection>{children}</PanelSection>
				</Column>
			</Row>
		</PanelSection>
	);
});
