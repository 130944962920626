import { XScopeAttrRef, XScopeCard, XScopeChildInfo } from "@external-types/scope";
import { XAppTypeCard } from "@external-types/type";

export interface AttributeEntry {
	value: string;
	label: string;
	field: number;
	key: number;
}

export type AttrMapper = Array<[number | string, string | null] | number | string>;

export function getScopeAttribute(scope: XScopeCard | XScopeChildInfo, attrId: number) {
	return scope.attrs.find(item => item.attr === attrId);
}

export function getScopeAttributeByName(scope: XScopeCard | XScopeChildInfo, attrName: string) {
	return scope.attrs.find(item => item.name.trim() == attrName);
}

export function getAttributeValue(attr: XScopeAttrRef, type: XAppTypeCard) {
	if (attr.type === "enum") {
		const attrTypeDef = type.attrs.find(a => a.id === attr.attr);

		if (attrTypeDef) {
			const options = JSON.parse(attrTypeDef.options) as Array<{ code: string; text: string }>;
			const option = options.find(opt => opt.code == attr.value);

			if (option) {
				return option.text;
			}
		}
	}

	return attr.value;
}

export function getScopeAttributeValue(scope: XScopeCard | XScopeChildInfo, attrId: number) {
	const res = getScopeAttribute(scope, attrId);

	if (res) {
		return getAttributeValue(res, scope.type);
	}

	return "";
}

export function getScopeAttributeValueByName(scope: XScopeCard | XScopeChildInfo, attrName: string) {
	const res = getScopeAttributeByName(scope, attrName);

	if (res) {
		return getAttributeValue(res, scope.type);
	}

	return "";
}

export function mapAttributes(
	attrs: XScopeAttrRef[],
	type: XAppTypeCard,
	mapper: AttrMapper | null = null,
): AttributeEntry[] {
	if (mapper === null) {
		return attrs.map(attr => ({
			value: getAttributeValue(attr, type),
			label: attr.name,
			field: attr.attr,
			key: attr.attr,
		}));
	}

	const result: AttributeEntry[] = [];

	mapper.forEach(mapperItem => {
		let attr: XScopeAttrRef | undefined;
		let label: string | null = null;
		let attrKey: number | string;

		if (Array.isArray(mapperItem)) {
			attrKey = mapperItem[0];
			label = mapperItem[1];
		} else {
			attrKey = mapperItem;
		}

		if (typeof attrKey === "string") {
			attr = attrs.find(item => item.name.trim() == attrKey);
		} else {
			attr = attrs.find(item => item.attr === attrKey);
		}

		if (attr) {
			result.push({
				field: attr.attr,
				key: attr.attr,
				label: label ?? attr.name,
				value: getAttributeValue(attr, type),
			});
		}
	});

	return result;
}
