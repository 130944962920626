import React from "react";
import cn from "classnames";

export interface ButtonProps {
	onClick?(): void;
	children?: React.ReactNode;
	disabled?: boolean;
	Icon?: React.ReactNode;
	iconPosition?: "left" | "right";
	primary?: boolean;
}

export function LightButton({
	onClick,
	children,
	disabled,
	Icon,
	iconPosition = "left",
	primary = false,
}: ButtonProps) {
	return (
		<button
			className={cn("button-light", { "button-light--primary": primary })}
			onClick={onClick}
			disabled={disabled}
		>
			{Icon != null && iconPosition === "left" && (
				<span className="button-light__icon button-light__icon--left">{Icon}</span>
			)}
			<span>{children}</span>
			{Icon != null && iconPosition === "right" && (
				<span className="button-light__icon button-light__icon--right">{Icon}</span>
			)}
		</button>
	);
}
