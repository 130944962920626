import React from "react";
import { observer } from "mobx-react";

import { Overlay } from "@app-components/controls/overlay";
import { Button } from "@app-components/buttons/button";
import { EditIcon } from "@icons";
import { CircleButton } from "@app-components/buttons/circle-button";

import { TaskForm } from "../task/task-item-action";
import { CallItem } from "../../../stores/call-center-store/call-item";
import { BaseTaskForm } from "../../../stores/models/base-task-form";
import { TaskFieldValue } from "../../components/task-field-value";
import { ProcessState } from "../../../constants/process-state";

interface Props {
	details: CallItem;
}

const IncomingCallDetailsForm = observer(({ taskForm }: { taskForm: BaseTaskForm }) => {
	function handleEdit() {
		taskForm.startEdit();
	}

	function handleSave() {
		taskForm.save();
	}

	return (
		<>
			<div style={{ display: "flex" }}>
				<span style={{ marginLeft: "auto" }}>
					{taskForm.isEdit ? (
						<Button className="button button--primary" onClick={handleSave}>
							Сохранить
						</Button>
					) : (
						<CircleButton onClick={handleEdit} Icon={EditIcon} className="reference-card-edit-action" />
					)}
				</span>
			</div>

			{taskForm.inProgress && <Overlay inline />}
			{taskForm.isEdit && taskForm.form !== null ? (
				<TaskForm form={taskForm.form!} />
			) : (
				taskForm.info.tasks.map(t =>
					t.values.map(val => <TaskFieldValue field={val} taskId={t.id} key={val.id} />),
				)
			)}
		</>
	);
});

export const IncomingCallDetailsHistory = observer(({ details }: Props) => {
	const history = details.history.filter(h => h.info.formID !== ProcessState.Registration);

	if (history.length === 0) {
		return null;
	}

	return (
		<>
			{history.map(h => (
				<IncomingCallDetailsForm taskForm={h} key={h.info.formID} />
			))}
		</>
	);
});
