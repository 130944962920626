import React from "react";
import { SvgIcon } from "@app-types/models";

export default function({ className }: SvgIcon) {
	return (
		<svg viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
			<path
				d="M5.98188 10.4004C5.88668 10.4004 5.81108 10.3752 5.75508 10.3248C5.69908 10.2688 5.66548 10.2016 5.65428 10.1232L4.73868 4.75559C4.73308 4.73879 4.73028 4.71919 4.73028 4.69679C4.73028 4.65199 4.74708 4.61279 4.78068 4.57919C4.81988 4.53999 4.86188 4.52039 4.90668 4.52039H5.94828C6.10508 4.52039 6.19188 4.58199 6.20868 4.70519L6.75468 7.98119L7.35948 6.04919C7.42108 5.89799 7.51348 5.82239 7.63668 5.82239H8.24148C8.38148 5.82239 8.47388 5.89799 8.51868 6.04919L9.12348 7.98119L9.66948 4.70519C9.68628 4.58199 9.77308 4.52039 9.92988 4.52039H10.9715C11.0219 4.52039 11.0639 4.53999 11.0975 4.57919C11.1367 4.61279 11.1563 4.65199 11.1563 4.69679L11.1479 4.75559L10.2323 10.1232C10.2211 10.2016 10.1847 10.2688 10.1231 10.3248C10.0671 10.3752 9.99428 10.4004 9.90468 10.4004H9.10668C9.01708 10.4004 8.94708 10.378 8.89668 10.3332C8.84628 10.2884 8.80988 10.238 8.78748 10.182L7.93908 7.77959L7.09068 10.182C7.04028 10.3276 6.93668 10.4004 6.77988 10.4004H5.98188Z"
				fill="#4B6ED6"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M23.7901 7.59986C23.7845 7.42425 23.7105 7.25292 23.5738 7.12361L16.2573 0.205723L16.2565 0.20502C16.122 0.07834 15.9367 0 15.7321 0H1.56399C0.701559 0 0 0.663283 0 1.47873V28.5213C0 29.3367 0.701497 30 1.56393 30H22.2274C23.0897 30 23.7913 29.3367 23.7913 28.5213V7.62025C23.7914 7.61523 23.7909 7.61034 23.7905 7.60544L23.7901 7.59986ZM16.475 2.39831L21.255 6.91794H16.7885C16.6156 6.91794 16.475 6.78499 16.475 6.62152V2.39831ZM22.2274 28.5952C22.2705 28.5952 22.3056 28.562 22.3056 28.5214H22.3057V8.32279H16.7885C15.7963 8.32279 14.9892 7.55955 14.9892 6.62152V1.40485H1.56399C1.52092 1.40485 1.48584 1.43801 1.48584 1.47873V28.5213C1.48584 28.562 1.52092 28.5952 1.56399 28.5952H22.2274Z"
				fill="#4B6ED6"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.00873 13.2002H18.7826V14.4002H5.00873V13.2002ZM5.00873 15.6002H18.7826V16.8002H5.00873V15.6002ZM18.7826 18.0002H5.00873V19.2002H18.7826V18.0002ZM5.00873 20.4002H18.7826V21.6002H5.00873V20.4002ZM18.7826 22.8002H5.00873V24.0002H18.7826V22.8002Z"
				fill="#4B6ED6"
			/>
			<path
				d="M18.7826 13.2002H19.0326V12.9502H18.7826V13.2002ZM5.00873 13.2002V12.9502H4.75873V13.2002H5.00873ZM18.7826 14.4002V14.6502H19.0326V14.4002H18.7826ZM5.00873 14.4002H4.75873V14.6502H5.00873V14.4002ZM18.7826 15.6002H19.0326V15.3502H18.7826V15.6002ZM5.00873 15.6002V15.3502H4.75873V15.6002H5.00873ZM18.7826 16.8002V17.0502H19.0326V16.8002H18.7826ZM5.00873 16.8002H4.75873V17.0502H5.00873V16.8002ZM5.00873 18.0002V17.7502H4.75873V18.0002H5.00873ZM18.7826 18.0002H19.0326V17.7502H18.7826V18.0002ZM5.00873 19.2002H4.75873V19.4502H5.00873V19.2002ZM18.7826 19.2002V19.4502H19.0326V19.2002H18.7826ZM18.7826 20.4002H19.0326V20.1502H18.7826V20.4002ZM5.00873 20.4002V20.1502H4.75873V20.4002H5.00873ZM18.7826 21.6002V21.8502H19.0326V21.6002H18.7826ZM5.00873 21.6002H4.75873V21.8502H5.00873V21.6002ZM5.00873 22.8002V22.5502H4.75873V22.8002H5.00873ZM18.7826 22.8002H19.0326V22.5502H18.7826V22.8002ZM5.00873 24.0002H4.75873V24.2502H5.00873V24.0002ZM18.7826 24.0002V24.2502H19.0326V24.0002H18.7826ZM18.7826 12.9502H5.00873V13.4502H18.7826V12.9502ZM19.0326 14.4002V13.2002H18.5326V14.4002H19.0326ZM5.00873 14.6502H18.7826V14.1502H5.00873V14.6502ZM4.75873 13.2002V14.4002H5.25873V13.2002H4.75873ZM18.7826 15.3502H5.00873V15.8502H18.7826V15.3502ZM19.0326 16.8002V15.6002H18.5326V16.8002H19.0326ZM5.00873 17.0502H18.7826V16.5502H5.00873V17.0502ZM4.75873 15.6002V16.8002H5.25873V15.6002H4.75873ZM5.00873 18.2502H18.7826V17.7502H5.00873V18.2502ZM5.25873 19.2002V18.0002H4.75873V19.2002H5.25873ZM18.7826 18.9502H5.00873V19.4502H18.7826V18.9502ZM18.5326 18.0002V19.2002H19.0326V18.0002H18.5326ZM18.7826 20.1502H5.00873V20.6502H18.7826V20.1502ZM19.0326 21.6002V20.4002H18.5326V21.6002H19.0326ZM5.00873 21.8502H18.7826V21.3502H5.00873V21.8502ZM4.75873 20.4002V21.6002H5.25873V20.4002H4.75873ZM5.00873 23.0502H18.7826V22.5502H5.00873V23.0502ZM5.25873 24.0002V22.8002H4.75873V24.0002H5.25873ZM18.7826 23.7502H5.00873V24.2502H18.7826V23.7502ZM18.5326 22.8002V24.0002H19.0326V22.8002H18.5326Z"
				fill="#4B6ED6"
			/>
		</svg>
	);
}
