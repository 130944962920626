import React from "react";
import { Avatar1Icon } from "@icons";

export function EmptyCalls() {
	return (
		<div className="empty-calls">
			<Avatar1Icon className="empty-calls__icon" />
			Ещё никто не звонил...
		</div>
	);
}
