import React from "react";

import { Volunteer } from "../../../../stores/volunteers-store";
import { VolunteerAttributes } from "../../../../constants/volunteer-attributes";
import { ScopeCardInfo } from "./base-info-section";

interface Props {
	entry: Volunteer;
}

export const ProjectsArchiveSection = ({ entry }: Props) => {
	return (
		<div className="scope-card-content__row">
			<div className="scope-card-content__column">
				<div className="scope-card-heading">Архив проектов</div>
				<div>
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.BusMercy)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.Actions2)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.Homeless)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.Sanitary)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.RapidResponseTeam)} scope={entry.scope} />
					<ScopeCardInfo
						attr={entry.getAttr(VolunteerAttributes.ProvideProfessionalAdvice)}
						scope={entry.scope}
					/>
					<ScopeCardInfo
						attr={entry.getAttr(VolunteerAttributes.WatchForOrphansInHospitals)}
						scope={entry.scope}
					/>
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.ForOrphans)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.Disabled)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.BuyProducts)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.LargeFamilyProject)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.VisitReadProject)} scope={entry.scope} />
					<ScopeCardInfo
						attr={entry.getAttr(VolunteerAttributes.NeedyInHospitalsProject)}
						scope={entry.scope}
					/>
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.LoneOldProject)} scope={entry.scope} />
					<ScopeCardInfo
						attr={entry.getAttr(VolunteerAttributes.TakeAwayThingsProject)}
						scope={entry.scope}
					/>
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.MoveThingsProject)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.PhoneTalkProject)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.ChildWalkProject)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.LiturgyHelpProject)} scope={entry.scope} />
					<ScopeCardInfo
						attr={entry.getAttr(VolunteerAttributes.BringToChurchProject)}
						scope={entry.scope}
					/>
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.PrepareFoodProject)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.SmallRepairProject)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.CleanProject)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.HospiceProject)} scope={entry.scope} />
					<ScopeCardInfo
						attr={entry.getAttr(VolunteerAttributes.HumanitarianAidCenterProject)}
						scope={entry.scope}
					/>
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.OtherProject)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.OtherWhoProject)} scope={entry.scope} />
				</div>
			</div>
		</div>
	);
};
