import { XSchemaFormRef } from "./schema-form-ref";
import { XSchemaRef } from "./schema-ref";
import { XActionType } from "../process";

export interface XParallelConfig {
	count: number;
}

export enum XSubprocessType {
	All = "all",
	One = "one",
	Selected = "selected",
}

export interface XSubProcessConfig {
	type: XSubprocessType;
	schema: XSchemaRef;
}

export interface XSchemaFormInfo extends XSchemaFormRef {
	type: XActionType;
	duration: number;
	parallel: XParallelConfig;
	subprocess: XSubProcessConfig;
}
