import React from "react";
import { observer } from "mobx-react-lite";
import { FileUpload } from "@app-components/files/file-upload";
import { IUploadFile } from "@app-types/models";
import { toFixedSize } from "@helpers/file";
import { fileIcon } from "@app-components/files/file-icon";

interface Props {
	onChange?(files: IUploadFile[]): void;
	onError?(error: string, file: IUploadFile): void;
	maxFiles?: number;
	maxFileSize?: number;
	files: IUploadFile[];
}

enum ErrorCode {
	InvalidFileType = 1,
	FileIsTooLarge = 2,
	FileIsTooSmall = 3,
	MaximumFileReached = 4,
}

const MAX_FILE_SIZE = 104857600;

function getErrorMessage(error: any, file: IUploadFile) {
	switch (error.code) {
		case ErrorCode.InvalidFileType:
			return `Тип файла ${file.name} не поддерживается`;
		case ErrorCode.FileIsTooLarge:
			return `Размер файла не должен преышать ${toFixedSize(MAX_FILE_SIZE)}`;
		case ErrorCode.FileIsTooSmall:
			return `Файл не должен быть пустым`;
		case ErrorCode.MaximumFileReached:
			return `Превышено максимальное количество файлов (10)`;
		default:
			return `Неизвестная ошибка (${error.code})`;
	}
}

export const UploadFiles = observer(({ onChange, onError, maxFiles, files }: Props) => {
	const fileRef = React.useRef<any>(null);
	const [errorMessage, setError] = React.useState<string | null>(null);
	const [recentError, setRecentError] = React.useState<boolean>(false);

	function handleError(error: any, file: IUploadFile) {
		const message = getErrorMessage(error, file);

		setError(message);
		setRecentError(true);

		if (onError) {
			onError(message, file);
		}
	}

	function handleRemove(file: IUploadFile) {
		return function() {
			fileRef.current.removeFile(file);
		};
	}

	function handleChange(files: IUploadFile[]) {
		if (recentError) {
			setRecentError(false);
		} else {
			setError(null);
		}

		if (onChange) {
			onChange(files);
		}
	}

	return (
		<>
			{errorMessage != null && <div className="form__error-message">{errorMessage}</div>}
			<FileUpload
				ref={fileRef}
				onChange={handleChange}
				onError={handleError}
				maxFiles={maxFiles}
				maxFileSize={MAX_FILE_SIZE}
			/>
			<div className="files-upload">
				{files.length > 0 &&
					files.map(file => {
						const Icon = fileIcon(file.name, file.type);
						return (
							<div key={file.id} className="file-item" onClick={handleRemove(file)}>
								<Icon className="file-preview-list__file-icon" />
								<div className="file-item__content">
									<div className="file-item__name">{file.name}</div>
									<div className="file-item__size">{file.sizeReadable}</div>
								</div>
								<button className="file-item__delete-btn">x</button>
							</div>
						);
					})}
			</div>
		</>
	);
});
