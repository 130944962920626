import React from "react";
import { observer } from "mobx-react";
import { IField } from "@app-models/form";

interface Props {
	field: IField;
	disabled?: boolean;
}

export const TextField = observer(({ field, disabled }: Props) => {
	function handleFormFieldChange(e: React.FormEvent<HTMLInputElement>) {
		field.updateValue(e.currentTarget.value);
	}

	return (
		<input
			type="text"
			className="form__input"
			value={field.value ?? ""}
			onChange={handleFormFieldChange}
			disabled={disabled}
		/>
	);
});
