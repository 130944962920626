import React from "react";

import { VolunteerAttributes } from "../../../../constants/volunteer-attributes";
import { Volunteer } from "../../../../stores/volunteers-store";
import { AttrFormRowInfo } from "./base-info-section";

interface Props {
	entry: Volunteer;
}

export const ChurchnessSection = ({ entry }: Props) => {
	return (
		<div className="scope-card-content__row">
			<div className="scope-card-content__column">
				<div className="scope-card-heading">Воцерковленность</div>
				<div>
					<AttrFormRowInfo attr={entry.getAttr(VolunteerAttributes.NameInChristening)} scope={entry.scope} />
					<AttrFormRowInfo attr={entry.getAttr(VolunteerAttributes.NameDay)} scope={entry.scope} />
					<AttrFormRowInfo attr={entry.getAttr(VolunteerAttributes.ParishChurch)} scope={entry.scope} />
					<AttrFormRowInfo attr={entry.getAttr(VolunteerAttributes.Churchiness)} scope={entry.scope} />
					<AttrFormRowInfo
						attr={entry.getAttr(VolunteerAttributes.FrequencyOfHowCommunion)}
						scope={entry.scope}
					/>
					<AttrFormRowInfo attr={entry.getAttr(VolunteerAttributes.ConfessorsBlessing)} scope={entry.scope} />
				</div>
			</div>
		</div>
	);
};
