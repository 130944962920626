import { observable } from "mobx";
import { SchemaField, SchemaForm } from "@app-models/schema-form";
import { XAppTypeInfo } from "@external-types/type";
import { XScopeCard } from "@external-types/scope";
import { XShemaFormItemType } from "@external-types/schema";
import { actions } from "@actions";

import { mapAttrToScopeAttrId, mapAttrValueToFormField } from "./reference-scope";
import { SubReferenceCollection } from "./sub-reference-collection";
import { IReferenceScopeSection, NameFieldId } from "./types";

export class ReferenceRootSection implements IReferenceScopeSection {
	public fetched: boolean = false;
	@observable public form: SchemaForm | null = null;
	public readonly name = "Основная информация";
	public children: SubReferenceCollection | null = null;

	public constructor(
		private appType: XAppTypeInfo,
		private rootScopeId: number | null = null,
		private scope: XScopeCard | null = null,
	) {
		const form = new SchemaForm(appType.attrs);

		form.fields.unshift(
			new SchemaField({
				id: NameFieldId,
				name: "Название",
				type: XShemaFormItemType.String,
				order: 0,
				is_required: true,
				description: "",
				is_report: false,
				options: "",
			}),
		);

		this.form = form;

		if (scope !== null) {
			mapAttrValueToFormField(scope, this.form);
		}
	}

	public get id() {
		return this.appType.id;
	}

	public get scopeId() {
		return this.scope?.id ?? null;
	}

	public async fetch() {}

	public async update() {
		if (!this.form) {
			return true;
		}

		if (this.form && !this.form.validate()) {
			return false;
		}

		const nameField = this.form.fields.find(f => f.id === NameFieldId);
		const name = nameField?.value ?? this.appType.name;
		const attrs = this.form.values.filter(f => f.id !== NameFieldId).map(x => ({ attr: x.id, value: x.value }));

		if (this.scope === null) {
			if (this.rootScopeId === null) {
				throw new Error("Unable to create scope with no parent");
			}

			const scopeRef = await actions.createScope(this.rootScopeId, this.appType.id, {
				name,
				attrs,
			});
			this.scope = await actions.getScope(scopeRef.id);
		} else {
			this.scope = await actions.updateScope(this.scope.id, {
				name,
				attrs: mapAttrToScopeAttrId(attrs, this.scope),
			});
		}

		return true;
	}

	public validate() {
		return this.form!.validate();
	}
}