import React from "react";

export interface ButtonProps {
	className?: string;
	onClick?(): void;
	disabled?: boolean;
	children?: React.ReactNode;
	title?: string;
}

export function Button({ className, onClick, disabled = false, children, title }: ButtonProps) {
	return (
		<button className={className} onClick={disabled ? undefined : onClick} title={title} disabled={disabled}>
			{children}
		</button>
	);
}
