import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { AppStoreContext } from "@shared/app-store-context";
import { Overlay } from "@app-components/controls/overlay";

export const App = observer(function({ children }: { children: React.ReactNode }) {
	const { appStore } = useContext(AppStoreContext);

	return appStore.isAppLoaded ? <>{children}</> : <Overlay />;
});
