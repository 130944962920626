import React from "react";
import cn from "classnames";

interface Props {
	children: React.ReactNode;
	topSeparated?: boolean;
	bottomIndent?: number;
}

export function Row({ children, topSeparated = false, bottomIndent }: Props) {
	return (
		<div
			className={cn(
				"org-panel-info__row",
				{ [`org-panel-info__row--bottom-indent-${bottomIndent}`]: Boolean(bottomIndent) },
				{ "org-panel-info__row--top-separated": topSeparated },
			)}
		>
			{children}
		</div>
	);
}
