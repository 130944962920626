import React from "react";
import cn from "classnames";

interface Props {
	active?: boolean;
	children?: React.ReactNode;
	onClick?(): void;
}

export function YesNoButton({ active, children, onClick }: Props) {
	return (
		<button className={cn("yesno-button", { "yesno-button--active": active })} type="button" onClick={onClick}>
			{children}
		</button>
	);
}
