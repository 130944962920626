import React from "react";
import { observer } from "mobx-react";
import AudioPlayer from "react-h5-audio-player";

import { EditIcon, Trash2Icon } from "@app-components/icons";
import { CircleButton } from "@app-components/buttons/circle-button";
import { Button } from "@app-components/buttons/button";
import { Overlay } from "@app-components/controls/overlay";
import { DialogPresenter } from "@app-components/dialog";

import { TaskFieldValue } from "./task-field-value";
import { WardInfo } from "../../stores/models/ward-info";
import { WardDetailsForm } from "./ward-details-form";
import { WardAttributes } from "../../constants/ward-attributes";
import { LocateLink } from "./locate-link";
import { BaseTaskForm } from "../../stores/models/base-task-form";
import { TaskForm } from "../call-center/task/task-item-action";
import { MoveProcessDialog } from "./move-process-dialog";
import { DeleteConfirmationDialog } from "./delete-confirmation-dialog";

interface Props {
	wardInfo: WardInfo;
	baseTask: BaseTaskForm | null;
	editable?: boolean;
	onFetchSubprocess?: () => void;
	audioRecordUrl: string | null;
	processId: number;
	showProcessMoveButton?: boolean;
	onAttach?: (scopeId: number) => Promise<void>;
	onDelete?: () => void;
}

function InfoField({ form, attr }: { form: WardInfo; attr: WardAttributes }) {
	const res = form.getAttributeValue(attr);

	if (res && res.value) {
		return (
			<div className="form__row">
				<div className="form__label">{res.label}</div>
				<div className="form__value">{res.value}</div>
			</div>
		);
	}

	return null;
}

function AddressInfoField({ form }: { form: WardInfo }) {
	const res = form.getAttributeValue(WardAttributes.Address);

	if (res && res.value) {
		return (
			<div className="form__row">
				<div className="form__label">{res.label}</div>
				<div className="form__value">{res.value}</div>
				<LocateLink city={form.city} street={form.street} />
			</div>
		);
	}

	return null;
}

function WardInfoForm({ wardInfo }: { wardInfo: WardInfo }) {
	return (
		<div className="help-request-ward">
			<div className="help-request-ward__column">
				<InfoField form={wardInfo} attr={WardAttributes.LastName} />
				<InfoField form={wardInfo} attr={WardAttributes.FirstName} />
				<InfoField form={wardInfo} attr={WardAttributes.MiddleName} />
				<InfoField form={wardInfo} attr={WardAttributes.Gender} />
				<InfoField form={wardInfo} attr={WardAttributes.Age} />
				<InfoField form={wardInfo} attr={WardAttributes.Birthday} />
				{/*<InfoField form={wardInfo} attr={WardAttributes.Dead} />*/}
				<InfoField form={wardInfo} attr={WardAttributes.InPrayList} />
				<InfoField form={wardInfo} attr={WardAttributes.DateOfDeath} />

				<InfoField form={wardInfo} attr={WardAttributes.ReasonOfInPrayList} />
				<InfoField form={wardInfo} attr={WardAttributes.Comments} />
			</div>
			<div className="help-request-ward__column">
				<InfoField form={wardInfo} attr={WardAttributes.Settlement} />
				<InfoField form={wardInfo} attr={WardAttributes.Subway} />
				<AddressInfoField form={wardInfo} />
				<InfoField form={wardInfo} attr={WardAttributes.Apartment} />
				{wardInfo.additionalInfo?.destinationPhoneNumber != null ? (
					<div className="form__row">
						<div className="form__label">Номер, на который совершен звонок</div>
						<div className="form__value">{wardInfo.additionalInfo?.destinationPhoneNumber}</div>
					</div>
				) : (
					<InfoField form={wardInfo} attr={WardAttributes.DestinationPhone} />
				)}

				<InfoField form={wardInfo} attr={WardAttributes.Phone} />
				<InfoField form={wardInfo} attr={WardAttributes.MobilePhone} />
				<InfoField form={wardInfo} attr={WardAttributes.HomePhone} />
				<InfoField form={wardInfo} attr={WardAttributes.AdditionalPhone} />
				<InfoField form={wardInfo} attr={WardAttributes.DistrictOfAssistance} />
				<InfoField form={wardInfo} attr={WardAttributes.RegularityOfAssistance} />
			</div>
		</div>
	);
}

function showMoveDialog(processId: number) {
	const presenter = new DialogPresenter();

	presenter.show(<MoveProcessDialog processId={processId} onClose={() => presenter.close()} />, "dialog-instance");
}

function showDeleteDialog(onDelete: () => void) {
	const presenter = new DialogPresenter();

	presenter.show(
		<DeleteConfirmationDialog onSubmit={onDelete} onClose={() => presenter.close()} />,
		"dialog-instance dialog-warning-confirmation",
	);
}

export const HelpRequestInfo = observer(
	({
		wardInfo,
		baseTask,
		editable = false,
		onFetchSubprocess,
		audioRecordUrl,
		processId,
		showProcessMoveButton = false,
		onAttach,
		onDelete,
	}: Props) => {
		function handleEditClick() {
			wardInfo.startEdit();

			if (baseTask) {
				baseTask.startEdit();
			}
		}

		function handleSave() {
			wardInfo.save();

			if (baseTask) {
				baseTask.save();
			}
		}

		async function handleDelete() {
			if (onDelete) {
				showDeleteDialog(async () => {
					await onDelete();

					document.location.reload();
				});
			}
		}

		return (
			<div className="help-request">
				<div className="help-request__ward">
					{wardInfo.inProgress && <Overlay inline />}
					{wardInfo.isEdit ? (
						<>
							<div className="help-request__header">
								<div>Звонящий</div>
							</div>
							<WardDetailsForm
								form={wardInfo.form!}
								onSave={handleSave}
								onAttach={onAttach}
								additionalInfo={wardInfo.additionalInfo}
								onProcessMove={showProcessMoveButton ? () => showMoveDialog(processId) : undefined}
							/>
						</>
					) : (
						<>
							<div className="help-request__header">
								<div>Звонящий</div>
								{editable && (
									<CircleButton
										onClick={handleEditClick}
										Icon={EditIcon}
										className="reference-card-edit-action"
									/>
								)}
							</div>
							<WardInfoForm wardInfo={wardInfo} />
						</>
					)}
				</div>
				<div className="help-request__ward help-request-info">
					<div className="help-request__header">
						Текущее обращение
						{onDelete && wardInfo.isEdit && (
							<CircleButton onClick={handleDelete} Icon={Trash2Icon} className="delete-button" />
						)}
					</div>

					{audioRecordUrl != null && (
						<AudioPlayer
							src={audioRecordUrl}
							layout="horizontal-reverse"
							showJumpControls={false}
							showSkipControls={false}
							showFilledVolume={false}
							customAdditionalControls={[]}
							customVolumeControls={[]}
						/>
					)}
					{baseTask != null && (
						<>
							{baseTask.inProgress && <Overlay inline />}
							{baseTask.isEdit ? (
								<>
									<TaskForm form={baseTask.form!} />
								</>
							) : (
								baseTask.info.tasks.map(t =>
									t.values.map(val => <TaskFieldValue field={val} taskId={t.id} key={val.id} />),
								)
							)}
						</>
					)}

					{onFetchSubprocess && (
						<div>
							<Button className="button button--primary" onClick={onFetchSubprocess}>
								История просьбы
							</Button>
						</div>
					)}
				</div>
			</div>
		);
	},
);
