import ExcelIcon from "../icons/docs/excel";
import GifIcon from "../icons/docs/gif";
import JpegIcon from "../icons/docs/jpeg";
import Mp4Icon from "../icons/docs/mp4";
import PdfIcon from "../icons/docs/pdf";
import PptIcon from "../icons/docs/ppt";
import RarIcon from "../icons/docs/rar";
import WordIcon from "../icons/docs/word";
import MovIcon from "../icons/docs/mov";
import PngIcon from "../icons/docs/png";
import UnsupportedIcon from "../icons/docs/unsupported";
import DocIcon from "../icons/doc";

import { MimeTypes } from "@helpers/file";

export function fileIcon(fileName: string, mimeType: string) {
	switch (mimeType) {
		case MimeTypes.Xls:
		case MimeTypes.Xlsx:
			return ExcelIcon;
		case MimeTypes.Jpeg:
			return JpegIcon;
		case MimeTypes.Gif:
			return GifIcon;
		case MimeTypes.Pdf:
			return PdfIcon;
		case MimeTypes.Png:
			return PngIcon;
		case MimeTypes.Ppt:
		case MimeTypes.Pptx:
			return PptIcon;
		case MimeTypes.Mp4:
			return Mp4Icon;
		case MimeTypes.Doc:
		case MimeTypes.Docx:
			return WordIcon;
		case MimeTypes.Mov:
			return MovIcon;
		case MimeTypes.OctetStream:
			return fileName.endsWith("rar") ? RarIcon : DocIcon;
		default:
			return UnsupportedIcon;
	}
}
