import { action, computed, observable, runInAction } from "mobx";

import { SchemaForm } from "@app-models/schema-form";
import { actions } from "@actions";

import { VolunteerSectionId } from "./volunteer-sections";
import { Scope } from "../../constants/scope";
import { ScopeAppType } from "../../constants/scope-app-type";
import { getVolunteerScopeName, setVolunteerDefaults } from "../../helpers/volunteer-helper";
import { VolunteerProjects } from "./volunteer-projects";
import { IVolunteer } from "./volunteer";
import { IUploadFile } from "../../../../../src/types/models";

export class EmptyVolunteer implements IVolunteer {
	@observable public currentSectionId: VolunteerSectionId = VolunteerSectionId.BaseInfo;
	@observable public form: SchemaForm | null = null;
	@observable public fetching: boolean = false;
	@observable public volunteerProjects: VolunteerProjects | null = null;
	@observable file: IUploadFile | null = null;

	@action
	public updateCurrentSection(sectionId: number) {
		this.currentSectionId = sectionId;
	}

	@computed
	public get photoUrl() {
		return this.file !== null ? window.URL.createObjectURL(this.file) : null;
	}

	@action
	public uploadPhoto = (files: IUploadFile[]) => {
		if (files.length > 0) {
			this.file = files[0];
		}
	};

	@action
	public deletePhoto = () => {
		this.file = null;
	};

	@action
	public async save() {
		if (this.form!.validate()) {
			try {
				runInAction(() => {
					this.fetching = true;
				});

				const attrs = this.form!.values.map(x => ({ attr: x.id, value: x.value }));
				const name = getVolunteerScopeName(this.form!);
				const scope = await actions.createScope(Scope.ProjectsAndVolunteers, ScopeAppType.Volunteer, {
					name,
					attrs,
				});

				if (this.file !== null) {
					await actions.uploadScopeFile(scope.id, this.file);
				}

				return scope;
			} finally {
				runInAction(() => {
					this.fetching = false;
				});
			}
		}

		return null;
	}

	public async fetch() {
		const typeSchema = await actions.getScopeType(Scope.ProjectsAndVolunteers, ScopeAppType.Volunteer);
		const form = new SchemaForm(typeSchema.attrs);

		setVolunteerDefaults(form);

		runInAction(() => {
			this.form = form;
			// @ts-ignore
			this.volunteerProjects = new VolunteerProjects({ attrs: [] }, typeSchema);
		});
	}
}
