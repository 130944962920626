import { action, computed, observable } from "mobx";

export interface TabGroup {
	groupName: string;
	disabled?: boolean;
	key: string | number;
	items: TabItem[] | TabGroup[];
}

export interface TabItem {
	key: string | number;
	label: string;
	value: string | number;
	items?: TabItem[];
}

export interface TabbedControlData {
	title: string;
	items: TabGroup[];
}

export class TabbedControl {
	@observable public data: TabbedControlData;
	@observable public activeTabKey: string | number = "";

	public constructor(data: TabbedControlData) {
		this.data = data;
		this.setActiveTabKey();
	}

	@action
	public update(data: TabbedControlData) {
		this.data = data;
		this.setActiveTabKey();
	}

	@action
	public updateKey(key: number | string) {
		this.activeTabKey = key;
	}

	@computed
	public get activeTab() {
		for (const tab of this.data.items) {
			if (tab.key === this.activeTabKey) {
				return tab;
			}

			const subTabs = this.getChildTabs(tab);
			for (const subTab of subTabs) {
				if (subTab.key === this.activeTabKey) {
					return subTab;
				}
			}
		}
	}

	public getChildTabs(tab: TabGroup) {
		return (tab.items as TabGroup[]).filter(sub => sub.groupName);
	}

	@action
	private setActiveTabKey() {
		for (const tab of this.data.items) {
			if (!tab.disabled) {
				this.activeTabKey = tab.key;
				return;
			}

			const subTabs = this.getChildTabs(tab);

			for (const subTab of subTabs) {
				if (!subTab.disabled) {
					this.activeTabKey = subTab.key;
					return;
				}
			}
		}
	}
}
