import firebase from "firebase/app";
import "firebase/messaging";
import EventEmitter from "@helpers/event-emitter";

class FirebaseProxy {
	private instance: firebase.app.App | null = null;
	private eventEmitter = new EventEmitter();

	public async init(config: Object) {
		await this.dispose();

		this.instance = firebase.initializeApp(config, "mercy-client-web");

		const messaging = firebase.messaging(this.instance);

		messaging.onMessage((payload: any) => {
			this.eventEmitter.emit(payload);
		});

		try {
			await Notification.requestPermission();
			return await messaging.getToken();
		} catch (error) {
			console.log("Unable to get permission to notify.", error.message);
		}
	}

	public subscribe<T>(listener: (data: T) => void) {
		return this.eventEmitter.on(listener);
	}

	public unsubscribe(lid: number) {
		this.eventEmitter.off(lid);
	}

	public async dispose() {
		if (this.instance) {
			await this.instance.delete();
			this.instance = null;
		}
	}
}

export const firebaseProxy = new FirebaseProxy();
