import React, { useState } from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import MaskedInput, { conformToMask } from "react-text-mask";
import { useDebouncedCallback } from "use-debounce";

import { LightButton } from "@app-components/buttons/light-button";
import { getScopeAttributeValue } from "@helpers/scope-attributes";
import { Loader } from "@app-components/controls/loader";
import { SchemaFormRow, SchemaFormFieldRow } from "@app-components/schema-form";
import { FilterItem, Filters } from "@app-models/data-source";
import { FilterType } from "@shared/constants/filter-type";
import { DialogPresenter } from "@app-components/dialog";
import { XScopeChildInfo } from "@external-types/scope";
import { Button } from "@app-components/buttons/button";

import { actions } from "@actions";

import { WardAttributes } from "../../constants/ward-attributes";
import { StreetFormField } from "./street-form-field";
import { WardForm } from "../../stores/models/ward-form";
import { AdditionalFormFieldsDialog } from "./additional-form-fields-dialog";
import { Scope } from "../../constants/scope";
import { TaskAttachDialog } from "../call-center/components/task-attach-dialog";
import { getWardFullName } from "../../helpers/ward-helper";

interface Props {
	form: WardForm;
	additionalInfo?: {
		destinationPhoneNumber: string | null;
	} | null;

	onAttach?: (scopeId: number) => Promise<void>;
	onSave?: () => void;
	onProcessMove?: () => void;
}

const WardFormField = observer(
	({
		form,
		id,
		showIfNotEmpty = false,
		children,
	}: {
		form: WardForm;
		id: number;
		showIfNotEmpty?: boolean;
		children?: React.ReactNode;
	}) => {
		const field = form.getField(id);

		if (field) {
			return showIfNotEmpty && field.value === "" ? null : (
				<SchemaFormFieldRow field={field}>{children}</SchemaFormFieldRow>
			);
		}

		return null;
	},
);

const PhoneField = observer(
	({ form, id, onAttach }: { form: WardForm; id: number; onAttach?: (scopeId: number) => Promise<void> }) => {
		const field = form.getField(id)!;
		const [items, setItems] = useState<XScopeChildInfo[]>([]);
		const [fetching, setFetching] = useState(false);

		const debounced = useDebouncedCallback(
			() => {
				if (field.value) {
					fetch();
				}
			},
			300,
			// The maximum time func is allowed to be delayed before it's invoked:
			{ maxWait: 2000 },
		);

		async function fetch() {
			try {
				setFetching(true);

				const filters = new Filters(true);
				filters.items = [
					new FilterItem({
						name: "search",
						label: "search",
						options: [],
						type: FilterType.Search,
						value: field.value,
					}),
				];

				const items = await actions.getScopeChildrenPaged(Scope.Wards, { offset: 0, pageSize: 5 }, filters);

				setItems(items.list);
			} finally {
				setFetching(false);
			}
		}

		function handleFormFieldChange(e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) {
			field.updateValue(e.currentTarget.value);

			debounced();
		}

		function handleAddPhoneDialog() {
			const presenter = new DialogPresenter();

			presenter.show(
				<AdditionalFormFieldsDialog
					onClose={() => presenter.close()}
					form={form}
					fields={[WardAttributes.MobilePhone, WardAttributes.HomePhone, WardAttributes.AdditionalPhone]}
				/>,
			);
		}

		const handleWardClick = (scope: XScopeChildInfo) => () => {
			if (!onAttach) {
				return;
			}

			// const name = fullName({
			// 	firstName: getScopeAttributeValue(scope, WardAttributes.FirstName),
			// 	lastName: getScopeAttributeValue(scope, WardAttributes.LastName),
			// 	username: scope.name,
			// 	middleName: getScopeAttributeValue(scope, WardAttributes.MiddleName),
			// });

			// if (
			// 	confirm(
			// 		`Привязать к существуещему подопечному?\n ${name} \n${getScopeAttributeValue(
			// 			scope,
			// 			WardAttributes.Phone,
			// 		)}\n ${getScopeAttributeValue(scope, WardAttributes.Address)}`,
			// 	)
			// ) {
			onAttach(scope.id);
			debounced.cancel();
			//}
		};

		function handleBlur() {
			setTimeout(() => {
				setItems([]);
			}, 500);
		}

		if (field) {
			return (
				<SchemaFormRow field={field}>
					<div style={{ display: "flex", position: "relative" }}>
						<MaskedInput
							className="form__input"
							style={{ flex: 1 }}
							mask={field.mask}
							value={field.value}
							onChange={handleFormFieldChange}
							onBlur={handleBlur}
							placeholder={conformToMask("", field.mask, {}).conformedValue}
						/>
						{fetching ||
							(items.length > 0 && (
								<div className="phone-field-dropdown">
									{fetching && (
										<div className="phone-field-dropdown__loader">
											<Loader />
										</div>
									)}
									{!fetching && items.length > 0 && (
										<div className="phone-field-dropdown__items">
											{items.map(item => (
												<div
													className={cn("ward-item")}
													key={item.id}
													onClick={handleWardClick(item)}
												>
													<div className="ward-item__name">{getWardFullName(item)}</div>
													<div className="ward-item__info">
														{getScopeAttributeValue(item, WardAttributes.Phone)}
														{getScopeAttributeValue(item, WardAttributes.Address)}
													</div>
												</div>
											))}
										</div>
									)}
								</div>
							))}
					</div>
					{form.hasAdditionalEmptyPhone && (
						<span className="link-button" onClick={handleAddPhoneDialog}>
							Добавить телефон
						</span>
					)}
				</SchemaFormRow>
			);
		}

		return null;
	},
);

export const WardDetailsForm = observer(({ form, onAttach, additionalInfo = null, onSave, onProcessMove }: Props) => {
	function handleAddAddressDialog() {
		const presenter = new DialogPresenter();

		presenter.show(
			<AdditionalFormFieldsDialog
				onClose={() => presenter.close()}
				form={form}
				fields={[WardAttributes.Settlement, WardAttributes.Subway]}
			/>,
		);
	}

	function handleAttachDialog() {
		const presenter = new DialogPresenter();

		presenter.show(<TaskAttachDialog onAttach={onAttach!} onClose={() => presenter.close()} />);
	}

	return (
		<div className="ward-details-form">
			<div className="ward-details-form__column">
				<WardFormField form={form} id={WardAttributes.LastName} />
				<WardFormField form={form} id={WardAttributes.FirstName} />
				<WardFormField form={form} id={WardAttributes.MiddleName} />
				<div className="form__row form__row--grouped">
					<WardFormField form={form} id={WardAttributes.Birthday} />
					<div className="form-row-group-separator" />
					<WardFormField form={form} id={WardAttributes.Age} />
				</div>
				<WardFormField form={form} id={WardAttributes.Gender} />
				<div className="form__row form__row--grouped">
					<WardFormField form={form} id={WardAttributes.InPrayList} />
					{/*<div className="form-row-group-separator" />*/}
					{/*<WardFormField form={form} id={WardAttributes.Dead} />*/}
				</div>
				{form.getField(WardAttributes.InPrayList)!.value === "2" && (
					<WardFormField form={form} id={WardAttributes.ReasonOfInPrayList} />
				)}
				{form.getField(WardAttributes.Dead)!.value === "2" && (
					<WardFormField form={form} id={WardAttributes.DateOfDeath} />
				)}
				<WardFormField form={form} id={WardAttributes.RegularityOfAssistance} />
				<WardFormField form={form} id={WardAttributes.Comments} />
			</div>
			<div className="ward-details-form__column">
				{additionalInfo != null && additionalInfo.destinationPhoneNumber ? (
					<div className="form__row">
						<label className="form__label">Номер, на который совершен звонок</label>
						<div>{additionalInfo.destinationPhoneNumber}</div>
					</div>
				) : (
					<WardFormField form={form} id={WardAttributes.DestinationPhone} />
				)}

				<PhoneField form={form} id={WardAttributes.Phone} onAttach={onAttach} />

				<WardFormField form={form} id={WardAttributes.MobilePhone} showIfNotEmpty />
				<WardFormField form={form} id={WardAttributes.HomePhone} showIfNotEmpty />
				<WardFormField form={form} id={WardAttributes.AdditionalPhone} showIfNotEmpty />

				<StreetFormField form={form} id={WardAttributes.Address} cityFieldId={WardAttributes.Settlement} />
				<WardFormField form={form} id={WardAttributes.Apartment}>
					{form.hasAdditionalEmptyAddress && (
						<span className="link-button" onClick={handleAddAddressDialog}>
							Добавить информацию
						</span>
					)}
				</WardFormField>
				<WardFormField form={form} id={WardAttributes.Settlement} showIfNotEmpty />
				<WardFormField form={form} id={WardAttributes.Subway} showIfNotEmpty />
				<WardFormField form={form} id={WardAttributes.DistrictOfAssistance} />

				{onAttach && (
					<div className="form__row">
						<LightButton onClick={handleAttachDialog}>Привязать к существующему</LightButton>
					</div>
				)}
				{onSave && (
					<div className="form__row">
						<Button className="button button--primary" onClick={onSave}>
							Сохранить
						</Button>
					</div>
				)}
				{onProcessMove && (
					<div className="form__row">
						<LightButton onClick={onProcessMove}>Переместить в другой проект</LightButton>
					</div>
				)}
			</div>
		</div>
	);
});
