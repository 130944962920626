import React, { useContext } from "react";
import { useParams } from "react-router";
import { observer } from "mobx-react";
import cn from "classnames";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { CircleButton } from "@app-components/buttons/circle-button";
import { EditIcon, Trash2Icon } from "@icons";

import { StoreContext } from "../../context";
import { Paths } from "../../routes/paths";
import { withFetching } from "../../components/with-fetching";
import { Volunteer } from "../../stores/volunteers-store";
import { VolunteerSectionId, VolunteerSections } from "../../stores/volunteers-store/volunteer-sections";

import {
	BaseInfoViewSection,
	ChurchnessViewSection,
	ContactsViewSection,
	InterviewViewSection,
	ProjectsViewSection,
	HealthViewSection,
	CharacteristicsViewSection,
	ProjectsArchiveViewSection,
	WorkAndFamilyViewSection,
} from "./volunteer-sections/view-sections";

import { Overlay } from "@app-components/controls/overlay";
import { DisplayMode } from "../../stores/volunteers-store/volunteer";
import { Button } from "../../../../../src/components/buttons/button";
import { LightButton } from "../../../../../src/components/buttons/light-button";
import { VolunteerEditForm } from "./volunteer-edit-form";
import { BackButton } from "../components/back-button";

const VolunteerViewForm = observer(({ item }: { item: Volunteer }) => {
	switch (item.currentSectionId) {
		case VolunteerSectionId.BaseInfo:
			return <BaseInfoViewSection entry={item} />;
		case VolunteerSectionId.Contacts:
			return <ContactsViewSection entry={item} />;
		case VolunteerSectionId.Interview:
			return <InterviewViewSection entry={item} />;
		case VolunteerSectionId.Churchness:
			return <ChurchnessViewSection entry={item} />;
		case VolunteerSectionId.Projects:
			return <ProjectsViewSection entry={item} />;
		case VolunteerSectionId.ProjectsArchive:
			return <ProjectsArchiveViewSection entry={item} />;
		case VolunteerSectionId.Health:
			return <HealthViewSection entry={item} />;
		case VolunteerSectionId.Characteristics:
			return <CharacteristicsViewSection entry={item} />;
		case VolunteerSectionId.WorkAndFamily:
			return <WorkAndFamilyViewSection entry={item} />;
		default:
			return null;
	}
});

const VolunteerEntryContent = observer(({ item }: { item: Volunteer }) => {
	function handleCancel() {
		item.toggleMode(DisplayMode.View);
	}

	async function handleSave() {
		await item.save();
		item.toggleMode(DisplayMode.View);
	}

	if (item.mode === DisplayMode.Edit && item.form) {
		return (
			<>
				<div className="scope-card-content">
					<VolunteerEditForm entry={item} />
				</div>
				<div className="scope-card__actions">
					<Button className="button button--primary" onClick={handleSave}>
						Сохранить
					</Button>
					<LightButton onClick={handleCancel}>Отмена</LightButton>
				</div>
			</>
		);
	}

	return (
		<div className="scope-card-content">
			<VolunteerViewForm item={item} />
		</div>
	);
});

interface VolunteerEntryProps extends RouteComponentProps {
	item: Volunteer;
}

const VolunteerEntry = withRouter(
	observer(({ item, history }: VolunteerEntryProps) => {
		function onBack() {
			history.push(Paths.Volunteers);
		}

		function editScope() {
			item.toggleMode(DisplayMode.Edit);
		}

		async function deleteScope() {
			await item.deleteScope();
			history.push(Paths.Volunteers);
		}

		const handleChangeSection = (sectionId: number) => () => {
			item.updateCurrentSection(sectionId);
		};

		return (
			<>
				<div className="scope-card__section scope-card-section">
					<div className="scope-card-section__nav">
						<BackButton onClick={onBack} />
					</div>
					<div className="scope-card-section__menu scope-card-menu">
						{VolunteerSections.map(section => (
							<div
								className={cn("scope-card-menu__link", {
									"scope-card-menu__link--active": section.id === item.currentSectionId,
								})}
								onClick={handleChangeSection(section.id)}
								key={section.id}
							>
								{section.title}
							</div>
						))}
					</div>
				</div>
				<div className="scope-card__content">
					<div className="scope-card__header scope-card-header">
						<div className="scope-card-header__title">
							<header className="scope-card-header-title__main">{item.fullName}</header>
							<header className="scope-card-header-title__sub-title">Доброволец</header>
						</div>
						<div className="scope-card-header__action">
							{item.mode === DisplayMode.View ? (
								<CircleButton Icon={EditIcon} onClick={editScope} />
							) : (
								<CircleButton Icon={Trash2Icon} onClick={deleteScope} />
							)}
						</div>
					</div>
					<VolunteerEntryContent item={item} />
				</div>
				{item.fetching && <Overlay global={false} globalWait />}
			</>
		);
	}),
);

export const VolunteerItemContent = observer(function() {
	const { volunteersStore: store } = useContext(StoreContext);

	return (
		<div className="page__content scope-card">
			{store.currentItem != null && <VolunteerEntry item={store.currentItem} />}
		</div>
	);
});

const ContentWithFetching = withFetching(VolunteerItemContent);

export const VolunteerItem = observer(function({ children }: { children?: React.ReactNode }) {
	const { volunteersStore } = useContext(StoreContext);
	const [initialized, setInitialized] = React.useState(false);
	const { id } = useParams<{ id: string }>();
	const idParam = parseInt(id, 10);

	async function fetch() {
		await volunteersStore.fetchVolunteer(idParam);
		setInitialized(true);
	}

	return (
		<ContentWithFetching fetch={fetch} initialized={initialized} path={Paths.Volunteers}>
			{children}
		</ContentWithFetching>
	);
});
