export type Listener = (data?: any) => void;

export default class EventEmitter {
	private readonly listeners = new Map<number, Listener>();

	public on(listener: Listener) {
		const lid = Date.now();
		this.listeners.set(lid, listener);

		return lid;
	}

	public off(lid: number) {
		this.listeners.delete(lid);
	}

	public emit(data?: any) {
		this.listeners.forEach(listener => {
			listener(data);
		});
	}
}
