import { XHistoryItem } from "@external-types/process";
import { ProcessState } from "../constants/process-state";
import { ProcessFieldValue } from "../constants/process-field-value";

export function getCallDestinationNumber(history: XHistoryItem[]) {
	const historyItem = history.find(h => h.formID === ProcessState.Registration);

	if (historyItem != null) {
		const phoneNumber = historyItem.tasks[0]?.values.find(v => v.item === ProcessFieldValue.PhoneCallDestination)
			?.value;

		if (phoneNumber) {
			return phoneNumber;
		}
	}

	return null;
}
