import React, { useContext } from "react";
import { generatePath, RouteComponentProps, withRouter } from "react-router";
import { observer } from "mobx-react";
import cn from "classnames";

import { GridCellContent, InfiniteDataList } from "@app-components/infinite-data-list";
import { Overlay } from "@app-components/controls/overlay";
import { useDownloadExcel } from "@shared/hooks/use-download-excel";
import { FilterIcon } from "@shared/components/icons";
import { Button } from "@app-components/buttons/button";
import { FilterToolbar } from "@app-components/filters/filter-toolbar";
import { IconButton } from "@app-components/buttons/icon-button";
import { actions } from "@actions";

import { PageHeader } from "../../components/page-header";
import { withFetching } from "../../components/with-fetching";
import { StoreContext } from "../../context";
import { PrayIcon, ChristianCrossIcon, AlmsIcon } from "../../components/icons";
import { Paths } from "../../routes/paths";
import { Ward } from "../../stores/wards-store";
import { WardBaseDetails } from "./ward-base-details";

import { FilterSidebar } from "../../components/filter-sidebar";
import { Scope } from "../../constants/scope";

export { WardItem } from "./ward-item";
export { WardItemCreate } from "./ward-create";

interface EntryComponentProps extends RouteComponentProps {
	entry: Ward;
	isActive: boolean;
	isNotActive: boolean;
	onClick: (entryId: number) => void;
	children?: React.ReactNode;
}

const captions = ["ФИО", "Проект", "Телефон", "Адрес", "Статус"];

const GridEntry = withRouter(
	observer(({ entry, isActive, isNotActive, children, onClick }: EntryComponentProps) => {
		function handleClick() {
			onClick(entry.id);
		}

		return (
			<>
				<tr
					className={cn("data-grid__row ward-row", {
						"ward-row--inactive": isNotActive,
						"ward-row--active": isActive,
					})}
					onClick={handleClick}
				>
					<GridCellContent>{entry.fullName}</GridCellContent>
					<GridCellContent>{entry.projects}</GridCellContent>
					<GridCellContent>{entry.phone}</GridCellContent>
					<GridCellContent>{entry.address}</GridCellContent>
					<GridCellContent>
						{entry.helpFrequency !== "" && (
							<span className="ward-status" title={`Регулятность помощи: ${entry.helpFrequency}`}>
								<AlmsIcon className="ward-status__icon" />
							</span>
						)}
						{entry.isInPrayList && (
							<span className="ward-status" title="В списке для молитв">
								<PrayIcon className="ward-status__icon" />
							</span>
						)}
						{entry.isDead && (
							<span className="ward-status" title="Скончался">
								<ChristianCrossIcon className="ward-status__icon" />
							</span>
						)}
					</GridCellContent>
				</tr>
				{isActive && (
					<tr className="call-center-row-details">
						<td colSpan={captions.length} className="ward-details-cell">
							{children}
						</td>
					</tr>
				)}
			</>
		);
	}),
);

const GridEntryWithStore = observer(({ entry }: { entry: Ward }) => {
	const { wardsStore } = useContext(StoreContext);

	function handleClick(entryId: number) {
		wardsStore.toggleSelect(entryId);
	}

	const isActive = entry.id === wardsStore.selectedItemId;
	const isNotActive = wardsStore.selectedItemId !== -1 && !isActive;

	return (
		<GridEntry entry={entry} isActive={isActive} isNotActive={isNotActive} onClick={handleClick}>
			<WardBaseDetails entry={entry} />
		</GridEntry>
	);
});

function WardsContent() {
	const { wardsStore: store } = useContext(StoreContext);
	const [sidebarFilterVisible, setSidebarFilterVisibility] = React.useState(false);
	const [downloadInProgress, download] = useDownloadExcel();

	function handleCreate() {
		window.open(generatePath(Paths.WardItemCreate), "_blank");
	}

	function handleSidebarFilter() {
		setSidebarFilterVisibility(!sidebarFilterVisible);
	}

	function handleFilterApply(filterId: string, value: any) {
		store.applyFilter(filterId, value);
	}

	function handleDownload() {
		download(() => actions.getScopeExportContent(Scope.Wards));
	}

	return (
		<>
			<div className="page__content">
				<PageHeader title="Подопечные">
					<div className="filter-toolbar">
						<FilterToolbar
							filters={store.filter}
							onApply={handleFilterApply}
							hiddenFilters={["type", "orderBy", "attr", "value"]}
						/>
					</div>
					<div className="page__actions">
						<button onClick={handleDownload} type="button" className="button button--primary">
							Выгрузить список
						</button>

						<Button className="button button--primary" onClick={handleCreate}>
							Добавить
						</Button>
					</div>
					<div className="filter-sidebar-action">
						<IconButton
							Icon={FilterIcon}
							onClick={handleSidebarFilter}
							iconClassName={cn("filter-sidebar-icon", {
								"filter-sidebar-icon--active": sidebarFilterVisible,
							})}
						/>
					</div>
				</PageHeader>
				<InfiniteDataList
					store={store}
					EntryComponent={GridEntryWithStore}
					createKey={entry => entry.id}
					captions={captions}
					pagerPosition="top"
				/>
			</div>
			{sidebarFilterVisible && <FilterSidebar filters={store.filter} onApply={handleFilterApply} />}
			{downloadInProgress && <Overlay global={false} />}
		</>
	);
}

const ContentWithFetching = withFetching(WardsContent);

export const Wards = observer(function() {
	const { wardsStore } = useContext(StoreContext);
	const [initialized, setInitialized] = React.useState(false);

	async function fetch() {
		await wardsStore.fetchPage(1, 50);
		setInitialized(true);
	}

	return <ContentWithFetching fetch={fetch} initialized={initialized} path={Paths.Wards} />;
});
