import { action, observable, runInAction } from "mobx";

import { XScopeCard, XScopeChildInfo } from "@external-types/scope";
import { WardForm } from "./ward-form";
import {
	getScopeAttributeValue,
	getScopeAttribute,
	getAttributeValue,
} from "../../../../../src/helpers/scope-attributes";
import { WardAttributes } from "../../constants/ward-attributes";
import { getWardFullName } from "../../helpers/ward-helper";

export class WardInfo {
	@observable public isEdit = false;
	@observable public inProgress = false;
	@observable private scope: XScopeCard | XScopeChildInfo;

	public additionalInfo: { destinationPhoneNumber: string | null } | null;

	public form: WardForm | null = null;

	public constructor(
		scope: XScopeCard | XScopeChildInfo,
		additionalInfo: { destinationPhoneNumber: string | null } | null = null,
	) {
		this.scope = scope;
		this.additionalInfo = additionalInfo;
	}

	public async startEdit() {
		try {
			this.setInProgress(true);
			const form = new WardForm(this.scope);
			await form.fetch();

			runInAction(() => {
				this.isEdit = true;
				this.form = form;
			});
		} finally {
			this.setInProgress(false);
		}
	}

	public async save() {
		try {
			this.setInProgress(true);

			const scope = await this.form!.save();

			runInAction(() => {
				this.form = null;
				this.isEdit = false;
				this.scope = scope;
			});
		} finally {
			this.setInProgress(false);
		}
	}

	public getAttributeValue(wardAttr: WardAttributes) {
		const attr = getScopeAttribute(this.scope, wardAttr);

		if (attr) {
			return {
				label: attr.name,
				value: getAttributeValue(attr, this.scope.type),
			};
		}

		return null;
	}

	public get fullName() {
		return getWardFullName(this.scope);
	}

	public get gender() {
		return getScopeAttributeValue(this.scope, WardAttributes.Gender);
	}

	public get age() {
		return getScopeAttributeValue(this.scope, WardAttributes.Age);
	}

	public get city() {
		return getScopeAttributeValue(this.scope, WardAttributes.Settlement);
	}

	public get street() {
		return getScopeAttributeValue(this.scope, WardAttributes.Address);
	}

	public get hasAddress() {
		return this.street != null && this.street !== "";
	}

	@action
	private setInProgress(inProgress: boolean) {
		this.inProgress = inProgress;
	}
}
