import React from "react";
import { Overlay } from "@app-components/controls/overlay";
import { Page } from "./page";

interface ExtendedProps {
	fetch: () => Promise<void>;
	initialized: boolean;
	path?: string;
	children?: React.ReactNode;
}

export function withFetching<T extends ExtendedProps>(
	Content: React.FunctionComponent<Omit<T, "fetch" | "initialized" | "path">>,
) {
	return ({ fetch, initialized, path, ...rest }: T) => {
		React.useEffect(() => {
			fetch();
		}, []);

		return <Page path={path}>{!initialized ? <Overlay global={false} globalWait /> : <Content {...rest} />}</Page>;
	};
}
