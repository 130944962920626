import React from "react";
import { AppHeader } from "./app-header";
import { Paths } from "../routes/paths";

interface Props {
	path?: string;
	children?: React.ReactNode;
}

export function Page({ children, path }: Props) {
	React.useEffect(() => {
		const root = document.documentElement;
		if (path && ![Paths.Reference].includes(path as Paths)) {
			root.className = "theme-blue";
		} else {
			root.className = "";
		}
	}, []);

	return (
		<>
			<AppHeader path={path} />
			<div className="page app__content">{children}</div>
		</>
	);
}
