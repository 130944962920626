import { observable } from "mobx";
import { actions } from "@actions";

import { SchemaForm } from "@app-models/schema-form";
import { XScopeChildInfo } from "@external-types/scope";
import { XAppTypeInfo } from "@external-types/type";
import { mapAttrToScopeAttrId, mapAttrValueToFormField } from "./reference-scope";

export class SubReferenceItem {
	public form: SchemaForm;
	@observable private scope: XScopeChildInfo | null = null;

	public constructor(
		private parentScopeId: number,
		private appType: XAppTypeInfo,
		scope: XScopeChildInfo | null = null,
	) {
		this.form = new SchemaForm(appType.attrs);
		this.scope = scope;

		if (scope) {
			mapAttrValueToFormField(scope, this.form);
		}
	}

	public async update() {
		if (!this.form) {
			return true;
		}

		if (this.form && !this.form.validate()) {
			return false;
		}

		const attrs = this.form.values.map(x => ({ attr: x.id, value: x.value }));
		const name = this.appType!.name;

		if (this.scope === null) {
			const parentScopeId = this.parentScopeId;

			const scopeRef = await actions.createScope(parentScopeId, this.appType!.id, {
				name,
				attrs,
			});

			this.scope = await actions.getScope(scopeRef.id);
		} else {
			this.scope = await actions.updateScope(this.scope.id, {
				name,
				attrs: mapAttrToScopeAttrId(attrs, this.scope),
			});
		}

		return true;
	}

	public validate() {
		return this.form?.validate() ?? true;
	}
}
