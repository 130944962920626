import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { DefaultArcObject } from "d3";

interface Props {
	data: DataItem[];
}

interface DataItem {
	value: number;
	color: string | null;
	label: string;
}

interface ArcItem extends DefaultArcObject {
	data: DataItem;
}

const innerRadius = 0;
const outerRadius = 75;

function getOffset(elem: HTMLElement) {
	let offsetLeft = 0;
	let offsetTop = 0;
	let currentElement: HTMLElement | null = elem;

	do {
		if (!isNaN(currentElement.offsetLeft)) {
			offsetLeft += currentElement.offsetLeft;
			offsetTop += currentElement.offsetTop;
		}
		currentElement = currentElement.offsetParent as HTMLElement;
	} while (currentElement != null);

	return {
		left: offsetLeft,
		top: offsetTop,
	};
}

interface Tooltip {
	left: number;
	top: number;
	data: any;
}

const Pie = ({ data }: Props) => {
	const ref = useRef(null);
	const divRef = React.useRef<HTMLDivElement>(null);
	const [legendTooltip, setLegendTooltip] = React.useState<Tooltip | null>(null);

	const createPie = d3
		.pie<DataItem>()
		.value(d => d.value)
		.sort(null);
	const createArc = d3
		.arc()
		.innerRadius(innerRadius)
		.outerRadius(outerRadius);

	// const outerArc = d3
	// 	.arc()
	// 	.innerRadius(outerRadius)
	// 	.outerRadius(outerRadius + 20);

	useEffect(() => {
		data.sort((a, b) => a.value - b.value);

		const pieData: any = createPie(data);
		const group = d3.select(ref.current);
		const groupWithData = group.selectAll("g.arc").data<ArcItem>(pieData);
		const color = d3
			.scaleOrdinal()
			.domain(data.map(d => d.label))
			.range(d3.quantize(t => d3.interpolateSpectral(t * 0.8 + 0.1), data.length).reverse());

		groupWithData.exit().remove();

		const groupWithUpdate = groupWithData
			.enter()
			.append("g")
			.attr("class", "arc");

		const path = groupWithUpdate.append("path").merge(groupWithData.select("path.arc"));

		path.attr("class", "arc")
			.attr("d", createArc)
			// @ts-ignore
			.attr("fill", (d: ArcItem) => {
				return d.data.color ?? color(d.data.label);
			});

		group
			.selectAll(".arc")
			.on("mouseover", data => {
				const offset = getOffset(divRef.current!);
				const left = d3.event.pageX - offset.left + 20;
				const top = d3.event.pageY - offset.top;

				setLegendTooltip({
					data,
					left,
					top,
				});
			})
			.on("mouseleave", () => {
				setLegendTooltip(null);
			});

		// const text = groupWithUpdate.append("text").merge(groupWithData.select("text"));
		//
		// text.attr("text-anchor", "middle")
		// 	.attr("alignment-baseline", "middle")
		// 	.attr("transform", (d: ArcItem) => {
		// 		var pos = createArc.centroid(d);
		// 		var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
		// 		pos[0] = 100 * 0.99 * (midangle < Math.PI ? 1 : -1);
		// 		return "translate(" + pos + ")";
		//
		// 		//`translate(${createArc.centroid(d)[0] + 30}, ${createArc.centroid(d)[1] + 30})`
		// 	})
		// 	.style("fill", (d: ArcItem) => d.data.color ?? color(d.data.label))
		// 	.style("font-size", 12)
		// 	.style("font-weight", 500)
		// 	.text((d: ArcItem) => d.data.label);
	}, [data]);

	return (
		<div style={{ position: "relative" }} ref={divRef}>
			<svg viewBox="0 0 150 150" style={{ overflow: "visible" }}>
				<g ref={ref} transform={`translate(${outerRadius} ${outerRadius})`} />
			</svg>

			{legendTooltip != null && (
				<div className="axis-legend-tooltip" style={{ left: legendTooltip.left, top: legendTooltip.top }}>
					{`${legendTooltip.data.data.label}: ${legendTooltip.data.data.value}`}
				</div>
			)}
		</div>
	);
};

export default Pie;
