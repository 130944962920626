import React from "react";

import { VolunteerAttributes } from "../../../../constants/volunteer-attributes";
import { Volunteer } from "../../../../stores/volunteers-store";
import { ScopeCardInfo } from "./base-info-section";

interface Props {
	entry: Volunteer;
}

export const WorkAndFamilySection = ({ entry }: Props) => {
	return (
		<div className="scope-card-content__row">
			<div className="scope-card-content__column">
				<div className="scope-card-heading">Семья и работа</div>
				<div>
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.MaritalStatus)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.SpouseFullName)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.ChildrenInfo)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.Education)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.Profession)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.WorkPlace)} scope={entry.scope} />
				</div>
			</div>
		</div>
	);
};
