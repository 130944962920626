import { action, observable, runInAction } from "mobx";

import { XHistoryItem } from "@external-types/process";
import { TaskForm } from "../call-center-store/task";
import { actions } from "../../../../../src/actions";

export class BaseTaskForm {
	@observable public isEdit = false;
	@observable public inProgress = false;
	@observable public info: XHistoryItem;
	public form: TaskForm | null = null;

	public constructor(public readonly processId: number, info: XHistoryItem) {
		this.info = info;
	}

	@action
	public startEdit() {
		this.isEdit = true;

		this.form = new TaskForm(this.info.tasks[0].values.map(v => ({ ...v, id: v.item })));

		this.form.fields.forEach(field => {
			const valInfo = this.info.tasks[0].values.find(v => v.item === field.id);

			if (valInfo) {
				field.value = valInfo.value;
			}
		});
	}

	public async save() {
		try {
			runInAction(() => {
				this.inProgress = true;
			});

			const values = this.form!.values.map(field => {
				const taskVals = this.info.tasks[0].values;

				return {
					id: taskVals.find(t => t.item === field.id)!.id,
					value: field.value,
				};
			});

			const processInfo = await actions.updateProcess(this.processId, this.info.tasks[0].id, { values });

			const baseTask =
				processInfo.history.find(h => h.formID === this.info.formID) ??
				processInfo.base?.history.find(h => h.formID === this.info.formID);

			runInAction(() => {
				this.isEdit = false;
				this.form = null;
				if (baseTask) {
					this.info = baseTask;
				}
			});
		} finally {
			runInAction(() => {
				this.inProgress = false;
			});
		}
	}
}
