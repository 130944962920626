import React, { useState } from "react";
import cn from "classnames";

import { Button } from "@app-components/buttons/button";
import { InfiniteDataSource, DataSourceSession } from "@app-models/data-source";
import { actions } from "@actions";
import { CheckBoxListWithPaging } from "../../../../../src/components/checkbox-list-wth-paging";
import { OptionItem } from "../../../../../src/types/models";
import { IDataSourceSession } from "../../../../../src/types/data";
import { action } from "mobx";

class ScopeDataSource extends InfiniteDataSource<OptionItem<number>> {
	protected session: IDataSourceSession<OptionItem<number>>;
	public constructor(private readonly processId: number) {
		super();

		this.session = new DataSourceSession();
	}

	protected async fetch(): Promise<Array<OptionItem<number>>> {
		const pageInfo = await actions.getScopesForProcessMove(this.processId, this.pagination);

		this.updatePageInfo(pageInfo);

		return pageInfo.list.map(x => ({ label: x.name, value: x.id }));
	}

	@action
	public clear() {
		this.session.items = [];
	}
}

export const MoveProcessDialog = ({ processId }: { processId: number; onClose: () => void }) => {
	const [dataSource] = useState(() => new ScopeDataSource(processId));
	const [scopeIds, setScopeIds] = useState<number[]>([]);

	React.useEffect(() => {
		dataSource.fetchPage();
	}, [dataSource]);

	async function handleMove() {
		if (scopeIds.length > 0) {
			if (confirm("Вы действительно хотите перенести процесс?")) {
				await actions.moveProcessToScope(processId, scopeIds[0]);

				window.location.reload();
				// onClose();
			}
		}
	}

	function handleScopeChange(values: number[]) {
		setScopeIds(values);
	}

	return (
		<div className="move-process-dialog">
			<div className="dialog-instance__header">Перемещение</div>
			<div>
				<CheckBoxListWithPaging
					dataSource={dataSource}
					single
					onChange={handleScopeChange}
					selected={scopeIds}
				/>
			</div>
			<div className="dialog-instance__actions">
				<Button
					className={cn("button button--primary", { "button--disabled": scopeIds.length === 0 })}
					onClick={handleMove}
				>
					Переместить
				</Button>
			</div>
		</div>
	);
};
