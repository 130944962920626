import React from "react";
import { SvgIcon } from "@app-types/models";

export default function({ className }: SvgIcon) {
	return (
		<svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g>
				<path
					d="M23.3702 18.5165L18.5125 15.277C17.8961 14.8692 17.0698 15.0034 16.614 15.5853L15.199 17.4047C15.0172 17.6444 14.6866 17.714 14.4235 17.5679L14.1544 17.4195C13.2621 16.9331 12.1519 16.3276 9.91502 14.09C7.67814 11.8523 7.07144 10.7413 6.58509 9.85062L6.4375 9.58146C6.28935 9.31843 6.35759 8.98647 6.59746 8.80317L8.41557 7.38859C8.99729 6.9328 9.13166 6.10671 8.72433 5.49006L5.48481 0.632377C5.06736 0.00443879 4.22983 -0.184065 3.58356 0.204425L1.55225 1.42463C0.914006 1.79986 0.445747 2.40755 0.245667 3.12037C-0.485802 5.78557 0.064475 10.3853 6.84028 17.1619C12.2303 22.5515 16.2429 24.0009 19.0009 24.0009C19.6356 24.0036 20.2679 23.921 20.8806 23.7553C21.5936 23.5555 22.2014 23.0872 22.5764 22.4488L23.7978 20.4187C24.1869 19.7723 23.9984 18.9342 23.3702 18.5165ZM23.1087 20.0083L21.89 22.0396C21.6208 22.5004 21.1836 22.8392 20.6702 22.9851C18.2098 23.6606 13.9112 23.1011 7.40617 16.596C0.901117 10.091 0.341653 5.79278 1.01716 3.33198C1.1632 2.81793 1.50243 2.38023 1.9638 2.11055L3.99511 0.891932C4.27552 0.723207 4.63903 0.804992 4.82018 1.07748L6.57993 3.71948L8.05693 5.93475C8.23381 6.20222 8.1757 6.56072 7.92336 6.75864L6.10483 8.17321C5.55141 8.59638 5.39337 9.36207 5.7341 9.96976L5.8785 10.2321C6.38965 11.1696 7.02513 12.3358 9.34519 14.6555C11.6653 16.9751 12.8311 17.6107 13.7681 18.1218L14.0309 18.2665C14.6385 18.6072 15.4042 18.4492 15.8274 17.8958L17.242 16.0773C17.44 15.8251 17.7983 15.767 18.0659 15.9437L22.9232 19.1832C23.1958 19.3642 23.2776 19.7279 23.1087 20.0083Z"
					fill="black"
				/>
				<path
					d="M13.5991 4.00206C17.3524 4.00623 20.3939 7.04783 20.3981 10.8011C20.3981 11.022 20.5772 11.201 20.7981 11.201C21.019 11.201 21.198 11.022 21.198 10.8011C21.1934 6.60623 17.794 3.2068 13.5991 3.20221C13.3783 3.20221 13.1992 3.38125 13.1992 3.60213C13.1992 3.82302 13.3783 4.00206 13.5991 4.00206Z"
					fill="black"
				/>
				<path
					d="M13.5991 6.40175C16.0277 6.40461 17.9956 8.3726 17.9985 10.8011C17.9985 11.022 18.1776 11.201 18.3984 11.201C18.6193 11.201 18.7984 11.022 18.7984 10.8011C18.795 7.93101 16.4692 5.60518 13.5991 5.6019C13.3783 5.6019 13.1992 5.78093 13.1992 6.00182C13.1992 6.22271 13.3783 6.40175 13.5991 6.40175Z"
					fill="black"
				/>
				<path
					d="M13.5991 8.80134C14.703 8.80266 15.5975 9.69718 15.5989 10.8011C15.5989 11.022 15.778 11.201 15.9988 11.201C16.2196 11.201 16.3987 11.022 16.3987 10.8011C16.3969 9.25564 15.1446 8.00323 13.5991 8.0015C13.3783 8.0015 13.1992 8.18053 13.1992 8.40142C13.1992 8.62231 13.3783 8.80134 13.5991 8.80134Z"
					fill="black"
				/>
			</g>
		</svg>
	);
}
