import React from "react";
import { XHistoryItem } from "@external-types/process";
import { HistoryTaskStep } from "../../components/task-step";

interface Props {
	history: XHistoryItem[];
	Actions?: React.ReactNode;
}

export function ProcessedCallsDetailsHistory({ history, Actions }: Props) {
	return (
		<div className="help-request-history">
			<div className="help-request__header">Обработка просьбы</div>
			{Actions}
			{history.length > 0 &&
				history.map(t => (
					<div className="task-sub-item" key={t.state.id}>
						<div className="task-sub-item-connector" />
						<div>
							<HistoryTaskStep historyItem={t} />
						</div>
					</div>
				))}
			{history.length === 0 && Actions == null && <div>Нет записей</div>}
		</div>
	);
}
