import React, { useContext } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { observer } from "mobx-react-lite";

import { StoreContext } from "../context";

export const AutoAuth = withRouter(
	observer(function(props: RouteComponentProps) {
		const { authStore } = useContext(StoreContext);
		const { history } = props;

		React.useEffect(() => {
			authStore.fetchExternalAuths().then(authList => {
				const query = new URLSearchParams(props.location.search);
				const auth = query.get("state");
				const prov = authList.find(a => a.code === auth);

				if (prov) {
					window.location.href = prov.url;
				} else {
					history.replace("/");
				}
			});
		}, []);

		return <div />;
	}),
);
