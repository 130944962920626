import { action, computed, observable, runInAction } from "mobx";
import { actions } from "@actions";

import { XScopeChildInfo } from "@external-types/scope";

export class ReferenceScopeItemSection {
	@observable public fetched: boolean = false;
	public title: string;
	@observable public children: XScopeChildInfo[] = [];
	@observable public pageIndex: number = -1;

	public constructor(private scope: XScopeChildInfo, title?: string) {
		this.title = title ?? scope.name;
	}

	public async fetch() {
		const children = await actions.getScopeChildren(this.scope.id);

		runInAction(() => {
			this.children = children;
			this.fetched = true;

			if (children.length > 0) {
				this.pageIndex = 0;
			}
		});
	}

	@computed
	public get activePage() {
		return this.children[this.pageIndex];
	}

	@action
	public updateIndex(pageIndex: number) {
		this.pageIndex = pageIndex;
	}

	public get id() {
		return this.scope.id;
	}

	public get attrs() {
		return this.scope.attrs;
	}

	public get type() {
		return this.scope.type;
	}
}

export class ReferenceScopeItem {
	@observable public fetched: boolean = false;
	@observable public children: ReferenceScopeItemSection[] = [];
	@observable public activeSectionId: number = -1;

	public constructor(public scope: XScopeChildInfo) {}

	public async fetch() {
		const children = await actions.getScopeChildren(this.scope.id);
		const result = new ReferenceScopeItemSection(this.scope, "Основная информация");

		runInAction(() => {
			result.fetched = true;
			this.children = [result].concat(children.map(ch => new ReferenceScopeItemSection(ch)));
			this.activeSectionId = this.scope.id;
			this.fetched = true;
		});
	}

	@computed
	public get activeSection() {
		return this.children.find(ch => ch.id === this.activeSectionId);
	}

	@action
	public updateSection(sectionId: number) {
		this.activeSectionId = sectionId;
	}
}
