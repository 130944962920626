import React from "react";
import { SchemaFormFieldRow } from "./schema-form-field-row";
import { ISchemaForm } from "@app-models/schema-form";

export { SchemaFormField } from "./schema-form-field";
export { SchemaFormFieldRow } from "./schema-form-field-row";
export { SchemaFormRow } from "./schema-form-row";

interface Props {
	form: ISchemaForm;
}

export function SchemaForm({ form }: Props) {
	return (
		<>
			{form.fields
				.filter(field => field.visible)
				.map(field => (
					<SchemaFormFieldRow key={field.id} field={field} />
				))}
		</>
	);
}
