import React from "react";

import { ArrowBackIcon } from "@icons";

interface Props {
	text?: string;
	onClick: () => void;
}

export function BackButton({ onClick, text = "Назад" }: Props) {
	return (
		<div className="back-button-wrapper">
			<button className="back-button" onClick={onClick}>
				<span className="back-button__icon">
					<ArrowBackIcon />
				</span>
				<span>{text}</span>
			</button>
		</div>
	);
}
