import React from "react";
import { CaretIcon } from "@icons";

interface Props {
	className?: string;
}

export class ExpandButton extends React.Component<Props> {
	public render() {
		const { className } = this.props;

		return (
			<div className={className}>
				<CaretIcon className="icon-caret" />
			</div>
		);
	}
}
