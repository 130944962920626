function buildModificators(baseClassName: string, modificators: { [key: string]: boolean }) {
	const res: string[] = [];

	res.push(baseClassName);
	for (let prop in modificators) {
		if (modificators[prop]) {
			res.push(`${baseClassName}--${prop}`);
		}
	}

	return res.join(" ");
}

class Block {
	private className: string;
	private modificators?: { [key: string]: boolean };

	public constructor(className: string, modificators?: { [key: string]: boolean }) {
		this.className = className;
		this.modificators = modificators;
	}

	public classNames(classNames: string = ""): string {
		return this.modificators
			? `${classNames} ${buildModificators(this.className, this.modificators)}`
			: `${classNames} ${this.className}`;
	}

	public em(element: string, modificators?: { [key: string]: boolean }) {
		const className = `${this.className}__${element}`;

		return modificators ? `${buildModificators(className, modificators)}` : className;
	}
}

export function bem(className: string, modificators?: { [key: string]: boolean }) {
	return new Block(className, modificators);
}
