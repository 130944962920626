import React, { useContext } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { observer } from "mobx-react-lite";

import { StoreContext } from "../context";

export const CompleteAuth = withRouter(
	observer(function(props: RouteComponentProps) {
		const { authStore } = useContext(StoreContext);
		const { history } = props;

		React.useEffect(() => {
			const query = new URLSearchParams(props.location.search);

			authStore
				.externalLogin(query.get("state") || "", {
					code: query.get("code") || "",
				})
				.then(() => {
					history.replace("/");
				});
		}, []);

		return <div />;
	}),
);
