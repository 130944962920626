import React from "react";
import { SvgIcon } from "@app-types/models";

export default function({ className }: SvgIcon) {
	return (
		<svg className={className} viewBox="0 0 19 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
			<g>
				<path d="M17.9011 12.704C17.5823 12.704 17.3236 12.9627 17.3236 13.2815V17.3236H1.15493V13.2815C1.15493 12.9627 0.896208 12.704 0.577488 12.704C0.258768 12.704 0 12.9627 0 13.2815V17.9011C0 18.2198 0.25872 18.4785 0.57744 18.4785H17.9011C18.2198 18.4785 18.4785 18.2198 18.4785 17.9011V13.2815C18.4786 12.9627 18.2198 12.704 17.9011 12.704Z" />
				<path d="M8.81773 13.6897C9.04237 13.912 9.41831 13.9143 9.6429 13.6897L13.6845 9.70526C13.9126 9.47947 13.912 9.11395 13.6845 8.88874C13.457 8.66294 13.0874 8.66294 12.8599 8.88874L9.81383 11.8915V0.57744C9.81383 0.258672 9.55281 0 9.23058 0C8.90836 0 8.64733 0.25872 8.64733 0.57744V11.8915L5.60125 8.88874C5.37316 8.66294 5.00418 8.66294 4.77666 8.88874C4.54857 9.11453 4.54857 9.48005 4.77666 9.70526L8.81773 13.6897Z" />
			</g>
		</svg>
	);
}
