import { DataSourceSession, Filters } from "@shared/models/data-source";
import { Ward } from "../wards-store";
import { ScopeAppType } from "../../constants/scope-app-type";

class WardsFilters extends Filters {
	protected createQuery() {
		const query = super.createQuery();

		return {
			...query,
			type: ScopeAppType.Ward,
		};
	}
}

export class WardsSession extends DataSourceSession<Ward> {
	public filter: Filters = new WardsFilters(true);
}
