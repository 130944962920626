import React, { useContext } from "react";
import { observer } from "mobx-react";
import { generatePath, RouteComponentProps, withRouter } from "react-router";

import { InfiniteDataList } from "@app-components/infinite-data-list";
import { FilterToolbar } from "@app-components/filters/filter-toolbar";

import { withFetching } from "../../components/with-fetching";
import { StoreContext } from "../../context";
import { Paths } from "../../routes/paths";
import { RequestTask } from "../../stores/request-store";
import { PageHeader } from "../../components/page-header";
import { Header } from "./header";
import { GridEntry } from "./grid-entry";

export { RequestItem } from "./request-item";
export { ProcessedRequests } from "./processed-requests";

const captions = ["Время", "Телефон", "Подопечный", "Тема", "Раздел", "Текст", "Кто добавил", "Статус"];

interface EntryComponentProps extends RouteComponentProps {
	entry: RequestTask;
}

function getEntryCss(entry: RequestTask) {
	switch (entry.state) {
		case "Новая просьба":
			return "grid-entry-state-1";
		case "Отправлено координатору":
			return "grid-entry-state-2";
		case "В работе":
			return "grid-entry-state-3";
		case "Недозвон":
			return "grid-entry-state-4";
		case "Отказ их":
			return "grid-entry-state-5";
		case "Отказ наш":
			return "grid-entry-state-6";
		case "Назначен доброволец":
			return "grid-entry-state-7";
		case "Выполнено":
			return "grid-entry-state-8";
		case "Завершено":
			return "grid-entry-state-9";
		default:
			return "";
	}
}

const EntryComponent = withRouter(
	observer(({ entry, history }: EntryComponentProps) => {
		function handleClick() {
			history.push(generatePath(Paths.RequestsItem, { requestId: entry.taskId }));
		}

		return (
			<GridEntry
				entry={entry}
				captions={captions}
				onClick={handleClick}
				isActive={false}
				isNotActive={false}
				className={`call-grid-item ${getEntryCss(entry)}`}
			/>
		);
	}),
);

const RequestsContent = observer(() => {
	const { requestStore: store } = React.useContext(StoreContext);

	function handleFilterApply(filterId: string, value: any) {
		store.applyFilter(filterId, value);
	}

	return (
		<div className="page__content">
			<PageHeader title="Просьбы">
				<div className="filter-toolbar">
					<FilterToolbar
						filters={store.filter}
						onApply={handleFilterApply}
						hiddenFilters={["type", "schema", "orderBy"]}
					/>
				</div>

				{/*<div>*/}
				{/*	<Button className="button button--primary" onClick={handleCreate}>*/}
				{/*		Добавить*/}
				{/*	</Button>*/}
				{/*</div>*/}
			</PageHeader>

			<InfiniteDataList
				store={store}
				EntryComponent={EntryComponent}
				createKey={entry => entry.taskId}
				captions={captions}
				pagerPosition="top"
				Header={<Header />}
			/>
		</div>
	);
});

const ContentWithFetching = withFetching(RequestsContent);

export const Requests = observer(function({ children }: { children?: React.ReactNode }) {
	const { requestStore } = useContext(StoreContext);
	const [initialized, setInitialized] = React.useState(false);

	async function fetch() {
		await requestStore.fetchPage(1, 50);
		setInitialized(true);
	}

	return (
		<ContentWithFetching fetch={fetch} initialized={initialized} path={Paths.ActiveRequests}>
			{children}
		</ContentWithFetching>
	);
});
