import React from "react";

import { VolunteerAttributes } from "../../../../constants/volunteer-attributes";
import { Volunteer } from "../../../../stores/volunteers-store";
import { ScopeCardInfo } from "./base-info-section";

interface Props {
	entry: Volunteer;
}

export const ContactsSection = ({ entry }: Props) => {
	return (
		<div className="scope-card-content__row">
			<div className="scope-card-content__column">
				<div className="scope-card-heading">Контакты</div>
				<div>
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.MobilePhone)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.HomePhone)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.AdditionalPhone)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.ContactPhones)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.Email)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.Messengers)} scope={entry.scope} />
					<ScopeCardInfo
						attr={entry.getAttr(VolunteerAttributes.PreferredCommunicationWay)}
						scope={entry.scope}
					/>
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.ReceiveEmail)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.ReceiveWhatsapp)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.Region)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.Settlement)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.DistrictOfMoscow)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.Subway)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.Address)} scope={entry.scope} />
					<ScopeCardInfo attr={entry.getAttr(VolunteerAttributes.Apartment)} scope={entry.scope} />
				</div>
			</div>
		</div>
	);
};
