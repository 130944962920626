import React, { useContext } from "react";
import { InfiniteDataList } from "@app-components/infinite-data-list";

import { StoreContext } from "../../context";
import { formatDateTime } from "../../../../../src/helpers/datetime";
import { XTaskExtRef } from "../../../../../src/types/external-data/process";
import { getTaskExtAttributeValue } from "../../helpers/attributes";
import { ProcessFieldValue } from "../../constants/process-field-value";

function getTopic(taskRef: XTaskExtRef) {
	return (
		getTaskExtAttributeValue(taskRef, ProcessFieldValue.RequestTopic) ||
		getTaskExtAttributeValue(taskRef, ProcessFieldValue.CallTopic)
	);
}

function getMessage(taskRef: XTaskExtRef) {
	return (
		getTaskExtAttributeValue(taskRef, ProcessFieldValue.RequestMessage) ||
		getTaskExtAttributeValue(taskRef, ProcessFieldValue.AppealMessage)
	);
}

export function VolunteerActivity() {
	const { volunteersStore } = useContext(StoreContext);

	React.useEffect(() => {
		volunteersStore.currentItem!.datasource.fetchPage();
	}, []);

	return (
		<>
			<div className="scope-card-heading">Просьбы</div>
			<InfiniteDataList
				captions={["Дата обращения", "Тема просьбы", "Текст обращения", "Статус"]}
				store={volunteersStore.currentItem!.datasource}
				className="history-grid"
				EntryComponent={({ entry }) => (
					<tr className="history-grid__row">
						<td className="history-grid__cell">{formatDateTime(entry.create, "YYYY.MM.DD HH:mm")}</td>
						<td className="history-grid__cell">{getTopic(entry)}</td>
						<td className="history-grid__cell">{getMessage(entry)}</td>
						<td className="history-grid__cell">{entry.state}</td>
					</tr>
				)}
				createKey={entry => entry.id}
				useInfiniteFetch={false}
			/>
		</>
	);
}
