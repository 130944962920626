import { observable } from "mobx";
import { IPagination, IDataSourceSession } from "@app-types/data";
import { Pagination } from "../pagination";
import { Filters } from "./filters";

export class DataSourceSession<TItem> implements IDataSourceSession<TItem> {
	@observable public loading: boolean = false;
	@observable public loadingNext: boolean = false;
	@observable.ref public items: TItem[] = [];

	public filter: Filters = new Filters(false);
	public pagination: IPagination = new Pagination();
}
