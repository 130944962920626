import React from "react";
import { SvgIcon } from "@app-types/models";

export default function({ className }: SvgIcon) {
	return (
		<svg viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
			<path
				d="M5.01932 11.8C4.9718 11.8 4.92956 11.7842 4.8926 11.7525C4.86092 11.7156 4.84508 11.676 4.84508 11.6337C4.84508 11.5968 4.85564 11.5651 4.87676 11.5387L6.60332 8.97261L5.00348 6.51741C4.98764 6.48573 4.97972 6.45405 4.97972 6.42237C4.97972 6.38013 4.99556 6.34317 5.02724 6.31149C5.05892 6.27453 5.09852 6.25605 5.14604 6.25605H6.29444C6.3578 6.25605 6.4106 6.27189 6.45284 6.30357C6.49508 6.33525 6.53468 6.37485 6.57164 6.42237L7.51412 7.85589L8.47244 6.42237C8.54108 6.31149 8.63348 6.25605 8.74964 6.25605H9.8426C9.88484 6.25605 9.9218 6.27453 9.95348 6.31149C9.99044 6.34317 10.0089 6.38013 10.0089 6.42237C10.0089 6.45933 9.99836 6.49101 9.97724 6.51741L8.36948 8.96469L10.1119 11.5387C10.1277 11.5598 10.1356 11.5915 10.1356 11.6337C10.1356 11.676 10.1198 11.7156 10.0881 11.7525C10.0564 11.7842 10.0168 11.8 9.96932 11.8H8.78924C8.67836 11.8 8.58596 11.7472 8.51204 11.6416L7.4666 10.121L6.42908 11.6416C6.371 11.7472 6.28124 11.8 6.1598 11.8H5.01932Z"
				fill="#0B9057"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M23.7901 7.59986C23.7845 7.42425 23.7105 7.25292 23.5738 7.12361L16.2573 0.205723L16.2565 0.20502C16.122 0.07834 15.9367 0 15.7321 0H1.56399C0.701559 0 0 0.663283 0 1.47873V28.5213C0 29.3367 0.701497 30 1.56393 30H22.2274C23.0897 30 23.7913 29.3367 23.7913 28.5213V7.62025C23.7914 7.61524 23.791 7.61036 23.7905 7.60546C23.7904 7.6036 23.7902 7.60173 23.7901 7.59986ZM16.475 2.39831L21.255 6.91794H16.7885C16.6156 6.91794 16.475 6.78499 16.475 6.62152V2.39831ZM22.2274 28.5952C22.2705 28.5952 22.3056 28.562 22.3056 28.5214H22.3057V8.32279H16.7885C15.7963 8.32279 14.9892 7.55955 14.9892 6.62152V1.40485H1.56399C1.52092 1.40485 1.48584 1.43801 1.48584 1.47873V28.5213C1.48584 28.562 1.52092 28.5952 1.56399 28.5952H22.2274Z"
				fill="#0B9057"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.00879 14.4H8.76531V15.6H5.00879V14.4ZM5.00879 16.8H8.76531V18H5.00879V16.8ZM8.76531 19.2H5.00879V20.4H8.76531V19.2ZM5.00879 21.6H8.76531V22.8H5.00879V21.6ZM8.76531 24H5.00879V25.2H8.76531V24Z"
				fill="#0B9057"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.0173 14.4H13.7739V15.6H10.0173V14.4ZM10.0173 16.8H13.7739V18H10.0173V16.8ZM13.7739 19.2H10.0173V20.4H13.7739V19.2ZM10.0173 21.6H13.7739V22.8H10.0173V21.6ZM13.7739 24H10.0173V25.2H13.7739V24Z"
				fill="#0B9057"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.0261 14.4H18.7826V15.6H15.0261V14.4ZM15.0261 16.8H18.7826V18H15.0261V16.8ZM18.7826 19.2H15.0261V20.4H18.7826V19.2ZM15.0261 21.6H18.7826V22.8H15.0261V21.6ZM18.7826 24H15.0261V25.2H18.7826V24Z"
				fill="#0B9057"
			/>
		</svg>
	);
}
