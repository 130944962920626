import { VolunteerAttributes } from "../../constants/volunteer-attributes";
import { ISchemaField, SchemaField, SchemaForm } from "@app-models/schema-form";
import { XAppTypeInfo } from "../../../../../src/types/external-data/type";
import { action, observable } from "mobx";
import { XScopeCard, XScopeChildInfo } from "../../../../../src/types/external-data/scope";
import { mapAttrValueToFormField } from "../reference-store/reference-scope";

export enum VolunteerProjectTypes {
	Target = "target-help",
	Organization = "organization-help",
	Other = "other-help",
}

export const volunteerProjectNameByType = [
	[VolunteerProjectTypes.Target, "Адресная помощь"],
	[VolunteerProjectTypes.Organization, "Помощь в учреждениях"],
	[VolunteerProjectTypes.Other, "Остальные проекты"],
];

export const volunteerProjectFieldKeysByType = {
	[VolunteerProjectTypes.Target]: [
		VolunteerAttributes.TargetedAssistanceSao,
		VolunteerAttributes.TargetedAssistanceSvao,
		VolunteerAttributes.TargetedAssistanceVao,
		VolunteerAttributes.TargetedAssistanceUvao,
		VolunteerAttributes.TargetedAssistanceUao,
		VolunteerAttributes.TargetedAssistanceUzao,
		VolunteerAttributes.TargetedAssistanceZao,
		VolunteerAttributes.TargetedAssistanceSzao,
		VolunteerAttributes.AdditionalRegion,
	],
	[VolunteerProjectTypes.Organization]: [
		VolunteerAttributes.BAS,
		VolunteerAttributes.HIVCenterIKB2,
		VolunteerAttributes.GKB1,
		VolunteerAttributes.GKB1PSO,
		VolunteerAttributes.GKB12,
		VolunteerAttributes.GNC,
		VolunteerAttributes.GCP,
		VolunteerAttributes.DGKBStVladimir,
		VolunteerAttributes.HouseForMother,
		VolunteerAttributes.Morozovskaya_DKB,
		VolunteerAttributes.NII_Gelmgoltsa,
		VolunteerAttributes.NMICBlochina,
		VolunteerAttributes.NPC,
		VolunteerAttributes.PNI11,
		VolunteerAttributes.PNI25,
		VolunteerAttributes.RDKB,
		VolunteerAttributes.Roshal,
		VolunteerAttributes.StAlexey,
		VolunteerAttributes.SSSD,
		VolunteerAttributes.CSSV_Kuncevsky,
		VolunteerAttributes.CSSV_Mayak,
	],
	[VolunteerProjectTypes.Other]: [
		VolunteerAttributes.Actions,
		VolunteerAttributes.RescueHangar,
		VolunteerAttributes.Almshouse,
		VolunteerAttributes.AdultsAtHome,
		VolunteerAttributes.MMO,
		VolunteerAttributes.LargeFamily,
		VolunteerAttributes.MO,
		VolunteerAttributes.MusicalTherapy,
		VolunteerAttributes.OnlineHelping,
		VolunteerAttributes.Barbershop,
		VolunteerAttributes.PB,
		VolunteerAttributes.Transportation,
		VolunteerAttributes.HelpForGroups,
		VolunteerAttributes.HelpToCoordinator,
		VolunteerAttributes.WashingRoom,
		VolunteerAttributes.Repairing,
		VolunteerAttributes.Website,
		VolunteerAttributes.SocialDepartment,
		VolunteerAttributes.VolunteerSchool,
		VolunteerAttributes.Lawyers,
	],
};

export const allProjectFieldKeysSet = new Set(
	([] as VolunteerAttributes[]).concat(
		...Object.keys(volunteerProjectFieldKeysByType).map(
			key => volunteerProjectFieldKeysByType[key as VolunteerProjectTypes],
		),
	),
);

export class VolunteerProjects {
	@observable public field: ISchemaField | null = null;
	public form: SchemaForm;

	public constructor(scope: XScopeChildInfo | XScopeCard, private typeSchema: XAppTypeInfo) {
		const fields = scope.attrs.filter(attr => allProjectFieldKeysSet.has(attr.attr));
		const form = new SchemaForm(typeSchema.attrs.filter(attr => fields.find(field => field.attr === attr.id)));
		mapAttrValueToFormField(scope, form);

		this.form = form;
	}

	@action
	public selectProject(attrId: VolunteerAttributes | null) {
		if (attrId === null) {
			this.field = null;
		} else {
			const attr = this.typeSchema.attrs.find(attr => attr.id === attrId);

			if (!attr) {
				throw new Error(`${attrId} not found`);
			}
			this.field = new SchemaField(attr);
			this.field.updateValue("2");
		}
	}

	public getProjectLabel(attrId: VolunteerAttributes) {
		const attr = this.typeSchema.attrs.find(attr => attr.id === attrId);

		if (!attr) {
			throw new Error(`${attrId} not found`);
		}

		return attr.name;
	}
}
