import React from "react";
import { SvgIcon } from "@app-types/models";

export function Pray({ className }: SvgIcon) {
	return (
		<svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
			<g>
				<path
					d="M19.8634 20.1766V14.521C19.8634 14.4298 19.8467 14.3395 19.8141 14.2543L16.7332 6.21471C16.4031 5.35848 15.5646 4.7832 14.6468 4.7832C14.0746 4.7832 13.5521 4.99952 13.1563 5.3543C12.7604 4.99952 12.238 4.7832 11.6658 4.7832C10.748 4.7832 9.90942 5.35848 9.57873 6.21615L6.49846 14.2543C6.46587 14.3395 6.44913 14.4298 6.44913 14.521V20.1766L2.19306 24.48C1.98161 24.6938 1.91936 25.0137 2.03522 25.2911C2.15103 25.5686 2.42225 25.7493 2.72293 25.7493H10.1753C10.4141 25.7493 10.6384 25.6348 10.7786 25.4415C11.8254 23.9981 12.6271 22.3762 13.1563 20.6654C13.6854 22.3762 14.4871 23.9981 15.5339 25.4415C15.6741 25.6348 15.8984 25.7493 16.1372 25.7493H23.5896C23.8903 25.7493 24.1615 25.5686 24.2773 25.2911C24.3932 25.0137 24.3309 24.6938 24.1195 24.48L19.8634 20.1766ZM9.78909 24.2588H4.50812L7.72423 21.007C7.8622 20.8675 7.9396 20.6792 7.9396 20.4829V14.6589L10.97 6.75098C11.08 6.46546 11.3597 6.27368 11.6658 6.27368C12.0767 6.27368 12.411 6.608 12.411 7.01892V12.413C12.1779 12.3303 11.927 12.2853 11.6658 12.2853C10.433 12.2853 9.43008 13.2882 9.43008 14.521V18.2472C9.43008 18.6588 9.76375 18.9924 10.1753 18.9924C10.5869 18.9924 10.9206 18.6588 10.9206 18.2472V14.521C10.9206 14.1101 11.2549 13.7758 11.6658 13.7758C12.0767 13.7758 12.411 14.1101 12.411 14.521V15.7663C12.411 18.79 11.4825 21.7904 9.78909 24.2588ZM16.5235 24.2588C14.8301 21.7904 13.9015 18.79 13.9015 15.7663V14.521C13.9015 14.1101 14.2358 13.7758 14.6468 13.7758C15.0577 13.7758 15.392 14.1101 15.392 14.521V18.2472C15.392 18.6588 15.7257 18.9924 16.1372 18.9924C16.5488 18.9924 16.8825 18.6588 16.8825 18.2472V14.521C16.8825 13.2882 15.8795 12.2853 14.6468 12.2853C14.3855 12.2853 14.1348 12.3307 13.9015 12.4135V7.01892C13.9015 6.608 14.2358 6.27368 14.6468 6.27368C14.9529 6.27368 15.2325 6.46546 15.342 6.74954L18.373 14.6589V20.4829C18.373 20.6792 18.4504 20.8675 18.5883 21.007L21.8044 24.2588H16.5235Z"
					fill="#EE5D5D"
				/>
				<path
					d="M13.9015 2.54822V1.05774C13.9015 0.646168 13.5678 0.3125 13.1563 0.3125C12.7447 0.3125 12.411 0.646168 12.411 1.05774V2.54822C12.411 2.95979 12.7447 3.29346 13.1563 3.29346C13.5678 3.29346 13.9015 2.95984 13.9015 2.54822Z"
					fill="#EE5D5D"
				/>
				<path
					d="M4.21338 9.30469C3.80181 9.30469 3.46814 9.63836 3.46814 10.0499C3.46814 10.4615 3.80181 10.7952 4.21338 10.7952H5.70386C6.11543 10.7952 6.4491 10.4615 6.4491 10.0499C6.4491 9.63836 6.11543 9.30469 5.70386 9.30469H4.21338Z"
					fill="#EE5D5D"
				/>
				<path
					d="M20.6086 10.7952H22.0991C22.5107 10.7952 22.8444 10.4615 22.8444 10.0499C22.8444 9.63836 22.5107 9.30469 22.0991 9.30469H20.6086C20.1971 9.30469 19.8634 9.63836 19.8634 10.0499C19.8634 10.4615 20.1971 10.7952 20.6086 10.7952Z"
					fill="#EE5D5D"
				/>
				<path
					d="M7.36231 5.26034C7.6533 5.55138 8.12518 5.55143 8.41622 5.26034C8.70726 4.9693 8.70726 4.49746 8.41622 4.20642L7.35972 3.14992C7.06873 2.85888 6.59685 2.85888 6.3058 3.14992C6.01476 3.44096 6.01476 3.9128 6.3058 4.20384L7.36231 5.26034Z"
					fill="#EE5D5D"
				/>
				<path
					d="M18.9529 3.14992L17.8964 4.20642C17.6053 4.49746 17.6053 4.9693 17.8964 5.26034C18.1874 5.55133 18.6593 5.55138 18.9503 5.26034L20.0068 4.20384C20.2978 3.9128 20.2978 3.44096 20.0068 3.14992C19.7158 2.85888 19.2439 2.85888 18.9529 3.14992Z"
					fill="#EE5D5D"
				/>
			</g>
		</svg>
	);
}
