import React from "react";
import { observer } from "mobx-react";

import { SuggestItem, yaMapsService } from "@helpers/ya-service";
import { ISchemaForm } from "@app-models/schema-form";
import { SchemaFormRow } from "@app-components/schema-form/schema-form-row";
import { useDebouncedEffect } from "@shared/effects/use-debounce-effect";

import { LocateLink } from "./locate-link";

interface Props {
	form: ISchemaForm;
	id: number;
	cityFieldId: number;
}

function SuggestDropDownItem({ item, onClick }: { item: SuggestItem; onClick: (item: SuggestItem) => void }) {
	function handleClick() {
		onClick(item);
	}

	return (
		<div key={item.value} onClick={handleClick} className="autocomplete-dropdown__item">
			{item.displayName}
		</div>
	);
}

export const StreetFormField = observer(({ form, id, cityFieldId }: Props) => {
	const ref = React.useRef<HTMLInputElement | null>(null);
	const streetField = form.getField(id);
	const cityField = form.getField(cityFieldId);
	const [suggestResult, updateSuggestResult] = React.useState<SuggestItem[]>([]);
	const [selectedValue, setSelectedValue] = React.useState<string>("");

	if (!streetField) {
		return null;
	}

	useDebouncedEffect(
		() => {
			if (selectedValue !== streetField.value) {
				yaMapsService.suggest(cityField!.value, streetField.value).then(res => {
					updateSuggestResult(res);
				});
			}
		},
		200,
		[streetField.value],
	);

	function handleFormFieldChange(e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) {
		streetField!.updateValue(e.currentTarget.value);
	}

	function handleBlur() {
		setTimeout(() => {
			updateSuggestResult([]);
		}, 200);
	}

	function handleSuggestSelect(suggestItem: SuggestItem) {
		const res = suggestItem.value
			.split(", ")
			.filter(x => x !== "Россия" && x.toLowerCase() !== cityField!.value.toLowerCase())
			.join(", ")
			.trim();

		streetField!.updateValue(res);
		setSelectedValue(res);
		ref.current!.focus();
	}

	return (
		<SchemaFormRow field={streetField} className="form__row--autocomplete">
			<input
				type="text"
				ref={ref}
				className="form__input"
				value={streetField.value}
				onChange={handleFormFieldChange}
				onBlur={handleBlur}
			/>

			<LocateLink city={cityField!.value} street={streetField.value} />

			{suggestResult.length > 0 && (
				<div className="autocomplete-container">
					<div className="autocomplete-dropdown">
						{suggestResult.map(item => (
							<SuggestDropDownItem key={item.value} item={item} onClick={handleSuggestSelect} />
						))}
					</div>
				</div>
			)}
		</SchemaFormRow>
	);
});
