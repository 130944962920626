import React, { useContext } from "react";
import { generatePath, RouteComponentProps, withRouter } from "react-router";
import { observer } from "mobx-react";

import { StoreContext } from "../../context";

import { Paths } from "../../routes/paths";
import { ReferenceWrapper } from "./reference-wrapper";

export { ReferenceItem } from "./reference-item";
export { ReferenceItemCreation } from "./reference-item-creation";
export { ReferenceList } from "./reference-list";

export const Reference = withRouter(
	observer(({ history }: RouteComponentProps) => {
		const { referenceStore } = useContext(StoreContext);

		React.useEffect(() => {
			if (referenceStore.scopes.length > 0) {
				history.replace(
					generatePath(Paths.ReferenceList, { referenceId: referenceStore.referenceNavigation.activeId! }),
				);
			}
		}, [referenceStore.scopes]);

		return <ReferenceWrapper />;
	}),
);
