import React from "react";
import { observer } from "mobx-react";
import cn from "classnames";

import { formatDateTime } from "@helpers/datetime";
import { fullName } from "@helpers/user";

import { RequestTask } from "../../stores/request-store";

interface Props {
	entry: RequestTask;
	captions: string[];
	children?: React.ReactNode;
	onClick?: () => void;
	isActive: boolean;
	isNotActive: boolean;
	className?: string;
}

function CellContent({ children }: { children: string }) {
	return (
		<td className={"data-grid__cell"}>
			<span className="call-grid-cell-content" title={children}>
				{children}
			</span>
		</td>
	);
}

export const GridEntry = observer(({ entry, captions, children, onClick, isActive, isNotActive, className }: Props) => {
	function handleClick() {
		onClick?.();
	}

	return (
		<>
			<tr
				className={cn("data-grid__row reference-grid-item", className, {
					"call-center-row-inactive": isNotActive,
					"call-center-row-active": isActive,
				})}
				onClick={handleClick}
			>
				<CellContent>{formatDateTime(entry.start, "YYYY.MM.DD HH:mm")}</CellContent>
				<CellContent>{entry.phone}</CellContent>
				<CellContent>{entry.name}</CellContent>
				<CellContent>{entry.topic}</CellContent>
				<CellContent>{entry.scopeName}</CellContent>
				<CellContent>{entry.message}</CellContent>
				<CellContent>{fullName(entry.initiator)}</CellContent>
				<CellContent>{entry.state}</CellContent>
			</tr>
			{isActive && (
				<tr className="call-center-row-details">
					<td colSpan={captions.length} className="call-details-cell">
						{children}
					</td>
				</tr>
			)}
		</>
	);
});
