import React from "react";
import { SvgIcon } from "@app-types/models";

export default function({ className }: SvgIcon) {
	return (
		<svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M23.8025 3.02902C23.7882 3.01562 20.9691 0.327103 20.9691 0.327103C20.8614 0.223696 20.7499 0.116821 20.5418 0.116821H9.19239C8.86089 0.116821 8.59117 0.386493 8.59117 0.717993V2.56407L4.59109 3.24976C4.27046 3.30601 4.05484 3.61285 4.11039 3.93315L4.3134 5.11684C3.01323 5.55437 1.67837 6.0054 0.38973 6.46829C0.0852295 6.57962 -0.0745674 6.9182 0.0340889 7.22452L2.59745 14.3503C2.64901 14.4937 2.78415 14.583 2.92829 14.583C2.96776 14.583 3.00798 14.5763 3.04726 14.5621C3.22998 14.4964 3.32481 14.295 3.25909 14.1123L0.733651 7.09192C1.94771 6.65724 3.205 6.23181 4.43373 5.81823L5.64175 12.861L7.03178 20.9718C7.08198 21.2581 7.33365 21.4619 7.61771 21.4619C7.64987 21.4619 7.6825 21.4592 7.71521 21.4539L11.48 20.8078L14.4219 20.3035L9.99732 21.8938L6.50818 23.1483L3.79843 15.6114C3.73276 15.4286 3.53143 15.3338 3.34867 15.3995C3.16595 15.4651 3.07112 15.6665 3.13679 15.8492L5.88498 23.4933C5.97287 23.7337 6.20059 23.8831 6.44129 23.8831C6.50781 23.8831 6.57535 23.8717 6.64117 23.8477L10.2351 22.5555L18.3958 19.6224L19.9548 19.3551H23.3987C23.7302 19.3551 24 19.0848 24 18.7526V3.42517C24 3.21474 23.8858 3.1074 23.8025 3.02902ZM22.871 3.11227H21.1118L21.0682 3.11246C20.984 3.11302 20.7868 3.11448 20.7368 3.06479C20.7252 3.05331 20.7117 3.01496 20.7117 2.94376L20.7077 1.04931L22.871 3.11227ZM23.2969 18.6519H9.29429V11.8003C9.29429 11.6062 9.13689 11.4488 8.94273 11.4488C8.74857 11.4488 8.59117 11.6062 8.59117 11.8003V18.7525C8.59117 19.0847 8.86089 19.355 9.19239 19.355H15.7929L11.3612 20.1147L7.70575 20.742L6.33475 12.7421L4.82214 3.92349L8.59112 3.27741V10.1691C8.59112 10.3632 8.74853 10.5206 8.94268 10.5206C9.13684 10.5206 9.29424 10.3632 9.29424 10.1691V0.819899H20.004L20.0086 2.94456C20.0086 3.20209 20.0869 3.4104 20.2414 3.56377C20.4787 3.79942 20.8142 3.81582 21.0225 3.81582C21.0404 3.81582 21.0573 3.81568 21.0732 3.81559L23.2969 3.81545V18.6519Z"
				fill="black"
			/>
			<path
				d="M10.989 5.57568C10.989 5.76984 11.1464 5.92725 11.3406 5.92725H21.3923C21.5864 5.92725 21.7438 5.76984 21.7438 5.57568C21.7438 5.38153 21.5864 5.22412 21.3923 5.22412H11.3406C11.1464 5.22412 10.989 5.38153 10.989 5.57568Z"
				fill="black"
			/>
			<path
				d="M21.3923 7.72174H11.3406C11.1464 7.72174 10.989 7.87915 10.989 8.0733C10.989 8.26746 11.1464 8.42487 11.3406 8.42487H21.3923C21.5864 8.42487 21.7438 8.26746 21.7438 8.0733C21.7438 7.87915 21.5864 7.72174 21.3923 7.72174Z"
				fill="black"
			/>
			<path
				d="M21.3923 10.2193H11.3406C11.1464 10.2193 10.989 10.3767 10.989 10.5709C10.989 10.765 11.1464 10.9224 11.3406 10.9224H21.3923C21.5864 10.9224 21.7438 10.765 21.7438 10.5709C21.7438 10.3767 21.5864 10.2193 21.3923 10.2193Z"
				fill="black"
			/>
			<path
				d="M21.3923 12.7169H11.3406C11.1464 12.7169 10.989 12.8743 10.989 13.0685C10.989 13.2626 11.1464 13.42 11.3406 13.42H21.3923C21.5864 13.42 21.7438 13.2626 21.7438 13.0685C21.7438 12.8743 21.5864 12.7169 21.3923 12.7169Z"
				fill="black"
			/>
			<path
				d="M21.3923 15.2145H11.3406C11.1464 15.2145 10.989 15.3719 10.989 15.5661C10.989 15.7603 11.1464 15.9177 11.3406 15.9177H21.3923C21.5864 15.9177 21.7438 15.7603 21.7438 15.5661C21.7438 15.3719 21.5864 15.2145 21.3923 15.2145Z"
				fill="black"
			/>
		</svg>
	);
}
