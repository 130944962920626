import React from "react";
import { Ward } from "../../../../stores/wards-store";
import { AttrFormRowInfo } from "./base-info-section";
import { WardAttributes } from "../../../../constants/ward-attributes";

interface Props {
	entry: Ward;
}

export const ContactsSection = ({ entry }: Props) => {
	return (
		<>
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.Phone)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.MobilePhone)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.HomePhone)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.AdditionalInfo)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.Email)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.Region)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.Settlement)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.DistrictOfMoscow)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.Subway)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.Address)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.Apartment)} scope={entry.scope} />
		</>
	);
};
