import React, { useContext } from "react";
import { useParams } from "react-router";
import { observer } from "mobx-react";
import cn from "classnames";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { CircleButton } from "@app-components/buttons/circle-button";
import { ArrowBackIcon, EditIcon, Trash2Icon } from "@icons";
import { Button } from "@app-components/buttons/button";

import { StoreContext } from "../../../context";
import { Paths } from "../../../routes/paths";
import { withFetching } from "../../../components/with-fetching";
import { Ward, WardSectionId, WardSections } from "../../../stores/wards-store";
import { DisplayMode } from "../../../stores/wards-store/ward-info";

import {
	BaseInfoSection,
	ChurchnessSection,
	ContactsSection,
	DescriptionSection,
	FamilySection,
	HealthStatusSection,
	ProjectsSection,
} from "./view-sections";
import {
	BaseInfoEditSection,
	ChurchnessEditSection,
	ContactsEditSection,
	DescriptionEditSection,
	FamilyEditSection,
	HealthStatusEditSection,
	ProjectsEditSection,
} from "./edit-sections";
import { LightButton } from "@app-components/buttons/light-button";
import { Overlay } from "@app-components/controls/overlay";
import { UserPhotoUploader } from "../../components/user-photo-uploader";

const WardEntryEditForm = observer(({ item }: { item: Ward }) => {
	const form = item.form!;

	switch (item.currentSectionId) {
		case WardSectionId.BaseInfo:
			return (
				<>
					<div className="scope-card-user-photo">
						<UserPhotoUploader
							fileUrl={item.photoUrl}
							onUpload={item.uploadPhoto}
							onDelete={item.deletePhoto}
						/>
					</div>
					<BaseInfoEditSection form={form} />
				</>
			);
		case WardSectionId.Contacts:
			return <ContactsEditSection form={form} />;
		case WardSectionId.Description:
			return <DescriptionEditSection form={form} />;
		case WardSectionId.Churchness:
			return <ChurchnessEditSection form={form} />;
		case WardSectionId.Projects:
			return <ProjectsEditSection form={form} />;
		case WardSectionId.Family:
			return <FamilyEditSection form={form} />;
		case WardSectionId.HealthStatus:
			return <HealthStatusEditSection form={form} />;
		default:
			return null;
	}
});

const WardEntryContent = observer(({ item }: { item: Ward }) => {
	function handleCancel() {
		item.toggleMode(DisplayMode.View);
	}

	async function handleSave() {
		await item.save();
		item.toggleMode(DisplayMode.View);
	}

	if (item.mode === DisplayMode.Edit) {
		return (
			<>
				<WardEntryEditForm item={item} />
				<div className="ward-edit-actions">
					<Button className="button button--primary" onClick={handleSave}>
						Сохранить
					</Button>
					<LightButton onClick={handleCancel}>Отмена</LightButton>
				</div>
			</>
		);
	}

	switch (item.currentSectionId) {
		case WardSectionId.BaseInfo:
			return <BaseInfoSection entry={item} />;
		case WardSectionId.Contacts:
			return <ContactsSection entry={item} />;
		case WardSectionId.Description:
			return <DescriptionSection entry={item} />;
		case WardSectionId.Churchness:
			return <ChurchnessSection entry={item} />;
		case WardSectionId.Projects:
			return <ProjectsSection entry={item} />;
		case WardSectionId.Family:
			return <FamilySection entry={item} />;
		case WardSectionId.HealthStatus:
			return <HealthStatusSection entry={item} />;
		default:
			return null;
	}
});

interface WardEntryProps extends RouteComponentProps {
	item: Ward;
}

const WardEntry = withRouter(
	observer(({ item, history }: WardEntryProps) => {
		function onBack() {
			history.push(Paths.Wards);
		}

		function editScope() {
			item.toggleMode(DisplayMode.Edit);
		}

		async function deleteScope() {
			await item.deleteScope();
			history.push(Paths.Wards);
		}

		const handleChangeSection = (sectionId: number) => () => {
			item.updateCurrentSection(sectionId);
		};

		return (
			<div className="reference-create-card">
				<div className="reference-create-card__content">
					<div className="reference-create-card__sections sidebar">
						<div className="back-button-wrapper">
							<button className="back-button" onClick={onBack}>
								<span className="back-button__icon">
									<ArrowBackIcon />
								</span>
								<span>Назад</span>
							</button>
						</div>

						{WardSections.map(section => (
							<div
								className={cn("sidebar__link", {
									"sidebar__link--active": section.id === item.currentSectionId,
								})}
								onClick={handleChangeSection(section.id)}
								key={section.id}
							>
								{section.title}
							</div>
						))}
					</div>
					<div className="reference-create-card__section">
						<header className="reference-create-card__title">
							{item.fullName}
							{item.mode === DisplayMode.View ? (
								<CircleButton Icon={EditIcon} onClick={editScope} />
							) : (
								<CircleButton Icon={Trash2Icon} onClick={deleteScope} />
							)}
						</header>
						<WardEntryContent item={item} />
					</div>
				</div>
				{item.fetching && <Overlay global={false} globalWait />}
			</div>
		);
	}),
);

export const WardItemContent = observer(function() {
	const { wardsStore: store } = useContext(StoreContext);

	return <div className="page__content">{store.currentItem != null && <WardEntry item={store.currentItem} />}</div>;
});

const ContentWithFetching = withFetching(WardItemContent);

export const WardItem = observer(function({ children }: { children?: React.ReactNode }) {
	const { wardsStore } = useContext(StoreContext);
	const [initialized, setInitialized] = React.useState(false);
	const { id } = useParams<{ id: string }>();
	const idParam = parseInt(id, 10);

	async function fetch() {
		await wardsStore.fetchWard(idParam);
		setInitialized(true);
	}

	return (
		<ContentWithFetching fetch={fetch} initialized={initialized} path={Paths.Wards}>
			{children}
		</ContentWithFetching>
	);
});
