import { InfiniteDataSource } from "@app-models/data-source";
import { actions } from "@actions";
import { XTaskExtRef } from "@external-types/process/task-ext-ref";
import { AppState } from "../app-state";
import { action, computed, runInAction } from "mobx";
import { fullName } from "../../../../../src/helpers/user";
import { WardAttributes } from "../../constants/ward-attributes";
import { XScopeRef } from "../../../../../src/types/external-data/scope";
import { RequestItem } from "./request-item";
import { ProcessFieldValue } from "../../constants/process-field-value";
import { getScopeExtAttributeValue, getTaskExtAttributeValue } from "../../helpers/attributes";

export class RequestTask {
	public constructor(private taskRef: XTaskExtRef) {}

	@computed
	public get name() {
		const scopeRef = this.taskRef.scope;

		if (scopeRef.attrs) {
			return fullName({
				firstName: getScopeExtAttributeValue(scopeRef, WardAttributes.FirstName),
				lastName: getScopeExtAttributeValue(scopeRef, WardAttributes.LastName),
				middleName: getScopeExtAttributeValue(scopeRef, WardAttributes.MiddleName),
				username: "Не определено",
			});
		}

		return "Не определено";
	}

	@computed
	public get topic() {
		return (
			getTaskExtAttributeValue(this.taskRef, ProcessFieldValue.RequestTopic) ||
			getTaskExtAttributeValue(this.taskRef, ProcessFieldValue.CallTopic)
		);
	}

	@computed
	public get message() {
		return (
			getTaskExtAttributeValue(this.taskRef, ProcessFieldValue.RequestMessage) ||
			getTaskExtAttributeValue(this.taskRef, ProcessFieldValue.AppealMessage)
		);
	}

	public get scopeName() {
		return this.taskRef.scope.name;
	}

	@action
	public updateScope(_scopeRef: XScopeRef) {
		// this.scopeRef = scopeRef;
	}

	public get taskId() {
		return this.taskRef.id;
	}

	public get pid() {
		return this.taskRef.pid;
	}

	public get start() {
		return this.taskRef.create;
	}

	public get initiator() {
		return this.taskRef.initiator;
	}

	public get state() {
		return this.taskRef.state;
	}

	@computed
	public get phone() {
		if (!this.taskRef.scope.attrs) {
			return "Не определено";
		}

		return getScopeExtAttributeValue(this.taskRef.scope, WardAttributes.Phone);
	}
}

export class RequestStore extends InfiniteDataSource<RequestTask> {
	public constructor(private appState: AppState) {
		super();
	}

	protected get session() {
		return this.appState.requests;
	}

	protected async fetch() {
		const result = await actions.getMyTasks(this.session.pagination, this.filter);

		this.updatePageInfo(result);

		return result.list.map(x => new RequestTask(x));
	}

	public get currentItem() {
		return this.session.currentItem;
	}

	public async fetchTask(taskId: number) {
		runInAction(() => {
			this.session.loading = true;
			this.session.currentItem = null;
		});

		const currentItem = new RequestItem(taskId);
		await currentItem.fetch();

		runInAction(() => {
			this.session.loading = false;
			this.session.currentItem = currentItem;
		});
	}
}
