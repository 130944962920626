import { action, computed, observable, runInAction } from "mobx";

import { actions } from "@actions";
import { InfiniteDataSource } from "@shared/models/data-source";
import { XProcessRef, XScopeExtRef, XTaskExtRef } from "@external-types/process";
import { XScopeCard } from "@external-types/scope";
// import { ServerSideEvents } from "@shared/sse";

import { AppState } from "../app-state";
import { getScopeAttributeValue } from "../../../../../src/helpers/scope-attributes";
import { WardAttributes } from "../../constants/ward-attributes";
import { fullName } from "../../../../../src/helpers/user";
import { Scope } from "../../constants/scope";
import { ScopeAppType } from "../../constants/scope-app-type";
import { ProcessSchema } from "../../constants/process-schema";
import { formatDateTime } from "../../../../../src/helpers/datetime";
import { getScopeExtAttributeValue, getTaskExtAttributeValue } from "../../helpers/attributes";
import { ProcessFieldValue } from "../../constants/process-field-value";
import { CallItemHistory } from "./call-item-history";
import { CallItem } from "./call-item";
import { ProcessState } from "../../constants/process-state";

export class IncomingCall {
	@observable private scope: XScopeCard | null = null;

	public constructor(private processRef: XProcessRef) {}

	public get id() {
		return this.processRef.id;
	}

	public get phone() {
		return this.scope ? getScopeAttributeValue(this.scope, WardAttributes.Phone) : "";
	}

	public async fetch() {
		const scope = await actions.getScope(this.processRef.scope.id);

		runInAction(() => {
			this.scope = scope;
		});
	}
}

export class CallTask {
	@observable private scopeRef: XScopeExtRef | XScopeCard;

	public constructor(private taskRef: XTaskExtRef) {
		this.scopeRef = taskRef.scope;
	}

	@computed
	public get name() {
		const scopeRef = this.scopeRef;

		if (scopeRef.attrs) {
			return fullName({
				firstName: getScopeExtAttributeValue(scopeRef, WardAttributes.FirstName),
				lastName: getScopeExtAttributeValue(scopeRef, WardAttributes.LastName),
				middleName: getScopeExtAttributeValue(scopeRef, WardAttributes.MiddleName),
				username: "Не определено",
			});
		}

		return "Не определено";
	}

	@computed
	public get topic() {
		return (
			getTaskExtAttributeValue(this.taskRef, ProcessFieldValue.RequestTopic) ||
			getTaskExtAttributeValue(this.taskRef, ProcessFieldValue.CallTopic)
		);
	}

	@computed
	public get message() {
		return (
			getTaskExtAttributeValue(this.taskRef, ProcessFieldValue.RequestMessage) ||
			getTaskExtAttributeValue(this.taskRef, ProcessFieldValue.AppealMessage)
		);
	}

	public async updateScope(scopeId: number) {
		const scope = await actions.getScope(scopeId);

		runInAction(() => {
			this.scopeRef = scope;
		});
	}

	public get taskId() {
		return this.taskRef.id;
	}

	public get pid() {
		return this.taskRef.pid;
	}

	public get start() {
		return this.taskRef.create;
	}

	public get initiator() {
		return this.taskRef.initiator;
	}

	public get state() {
		return this.taskRef.state;
	}

	@computed
	public get phone() {
		if (!this.taskRef.scope.attrs) {
			return "Не определено";
		}

		return getScopeExtAttributeValue(this.taskRef.scope, WardAttributes.Phone);
	}
}

export abstract class CallCenterStore extends InfiniteDataSource<CallTask> {
	public constructor(protected appState: AppState) {
		super();

		// reaction(
		// 	() => appStore.serverSideEvents,
		// 	(serverSideEvents: ServerSideEvents | null) => {
		// 		if (serverSideEvents !== null) {
		// 			serverSideEvents.onNewTask(this.handleNewCall);
		// 		}
		// 	},
		// );
	}

	protected abstract get session(): any;

	// private handleNewCall = async (callInfo: XProcessRef) => {
	// 	const incomingCall = new IncomingCall(callInfo);
	// 	await incomingCall.fetch();
	//
	// 	runInAction(() => {
	// 		this.session.incomingCall = incomingCall;
	// 	});
	// };

	public get incomingCall() {
		return this.session.incomingCall;
	}

	public get currentItem() {
		return this.session.currentItem;
	}

	@action
	public clearFocus() {
		this.session.currentItem = null;
	}

	@action
	public clearIncomingCall() {
		this.session.incomingCall = null;
	}

	public async createCall() {
		const timeStr = formatDateTime(Date.now());
		const processStartRefs = await actions.getUserTasks();
		const startRef = processStartRefs.find(ref => ref.id === ProcessSchema.MainProcess);

		if (!startRef) {
			throw new Error("Start process ref is not found");
		}

		const scope = await actions.createScope(Scope.Wards, ScopeAppType.Ward, {
			name: `Новый подопечный от ${timeStr}`,
			attrs: [],
		});

		const task = await actions.createScopeProcess(
			scope.id,
			ProcessState.NewCall,
			`Новый звонок от ${timeStr}`,
			Date.now() + startRef.duration * 1000,
		);

		return task.id;
	}

	public async moveToScope(task: CallTask, scopeId: number) {
		const result = await actions.moveProcessToScope(task.pid, scopeId);

		await task.updateScope(result.scope.id);

		if (this.currentItem && this.currentItem.taskId === task.taskId) {
			await this.currentItem.updateScope(scopeId);
		}
	}
}

export class ProcessedCallsStore extends CallCenterStore {
	protected get session() {
		return this.appState.processedCalls;
	}

	protected async fetch() {
		const result = await actions.getScopeProcesses(Scope.Root, this.session.pagination, this.filter);

		this.updatePageInfo(result);

		return result.list.map(x => new CallTask(x));
	}

	public async fetchProcess(processId: number) {
		runInAction(() => {
			this.session.loading = true;
			this.session.currentItem = null;
		});

		const currentItem = new CallItemHistory(processId);
		await currentItem.fetch();

		runInAction(() => {
			this.session.loading = false;
			this.session.currentItem = currentItem;
		});
	}
}

export class UnhandledCallsStore extends CallCenterStore {
	protected get session() {
		return this.appState.unhandledCalls;
	}

	protected async fetch() {
		const result = await actions.getMyTasks(this.session.pagination, this.filter);

		this.updatePageInfo(result);

		return result.list.map(x => new CallTask(x));
	}

	public async fetchTask(taskId: number) {
		runInAction(() => {
			this.session.loading = true;
			this.session.currentItem = null;
		});

		const currentItem = new CallItem(taskId);
		await currentItem.fetch();

		runInAction(() => {
			this.session.loading = false;
			this.session.currentItem = currentItem;
		});
	}
}
