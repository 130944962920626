import { DataSourceSession, Filters } from "@shared/models/data-source";
import { Volunteer } from "../volunteers-store";
import { ScopeAppType } from "../../constants/scope-app-type";

class VolunteersFilters extends Filters {
	protected createQuery() {
		const query = super.createQuery();

		return {
			...query,
			type: ScopeAppType.Volunteer,
		};
	}
}

export class VolunteersSession extends DataSourceSession<Volunteer> {
	public filter: Filters = new VolunteersFilters(true);
}
