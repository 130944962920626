import React from "react";
import { SvgIcon } from "@app-types/models";

export default function({ className }: SvgIcon) {
	return (
		<svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g>
				<path
					d="M22.8 13.6C22.1373 13.6 21.6 14.1373 21.6 14.8V16H8.6596C8.75084 15.8131 8.79883 15.608 8.8 15.4C8.8 14.6268 8.1732 14 7.4 14H4.6C3.8268 14 3.2 14.6268 3.2 15.4C3.20117 15.608 3.24916 15.8131 3.3404 16H2.4V11.6C2.4 10.9373 1.86274 10.4 1.2 10.4C0.53726 10.4 2.17932e-06 10.9373 2.17932e-06 11.6V22.8C-0.00110398 23.3838 0.418997 23.8833 0.994331 23.9822C1.56966 24.0811 2.13246 23.7506 2.3264 23.2H21.6736C21.8675 23.7506 22.4303 24.0811 23.0057 23.9822C23.581 23.8833 24.0011 23.3838 24 22.8V14.8C24 14.1373 23.4627 13.6 22.8 13.6ZM21.6 20.4H7.6C7.37909 20.4 7.2 20.2209 7.2 20V16.8H21.6V20.4ZM4 15.4C4 15.0687 4.26863 14.8 4.6 14.8H7.4C7.73137 14.8 8 15.0687 8 15.4C8 15.7314 7.73137 16 7.4 16H4.6C4.26863 16 4 15.7314 4 15.4ZM1.2 23.2C0.979088 23.2 0.800002 23.0209 0.800002 22.8V11.6C0.800002 11.3791 0.979088 11.2 1.2 11.2C1.42092 11.2 1.6 11.3791 1.6 11.6V22.8C1.6 23.0209 1.42092 23.2 1.2 23.2ZM2.4 16.8H6.4V20C6.4 20.6628 6.93726 21.2 7.6 21.2H21.6V22.4H2.4V16.8ZM23.2 22.8C23.2 23.0209 23.0209 23.2 22.8 23.2C22.5791 23.2 22.4 23.0209 22.4 22.8V14.8C22.4 14.5791 22.5791 14.4 22.8 14.4C23.0209 14.4 23.2 14.5791 23.2 14.8V22.8Z"
					fill="black"
				/>
				<path
					d="M12.0001 12.8C15.5347 12.8 18.4001 9.93462 18.4001 6.4C18.4001 2.86538 15.5347 0 12.0001 0C8.46548 0 5.6001 2.86538 5.6001 6.4C5.60429 9.93289 8.46721 12.7958 12.0001 12.8ZM12.0001 0.8C15.0929 0.8 17.6001 3.30721 17.6001 6.4C17.6001 9.49279 15.0929 12 12.0001 12C8.9073 12 6.4001 9.49279 6.4001 6.4C6.4034 3.30858 8.90867 0.803307 12.0001 0.8Z"
					fill="black"
				/>
				<path
					d="M9.1999 7.99999H10.3999V9.19999C10.3999 9.64182 10.7581 9.99999 11.1999 9.99999H12.7999C13.2417 9.99999 13.5999 9.64182 13.5999 9.19999V7.99999H14.7999C15.2417 7.99999 15.5999 7.64182 15.5999 7.19999V5.59999C15.5999 5.15816 15.2417 4.79999 14.7999 4.79999H13.5999V3.59999C13.5999 3.15816 13.2417 2.79999 12.7999 2.79999H11.1999C10.7581 2.79999 10.3999 3.15816 10.3999 3.59999V4.79999H9.1999C8.75807 4.79999 8.3999 5.15816 8.3999 5.59999V7.19999C8.3999 7.64182 8.75807 7.99999 9.1999 7.99999ZM9.1999 5.59999H10.3999C10.8417 5.59999 11.1999 5.24182 11.1999 4.79999V3.59999H12.7999V4.79999C12.7999 5.24182 13.1581 5.59999 13.5999 5.59999H14.7999V7.19999H13.5999C13.1581 7.19999 12.7999 7.55816 12.7999 7.99999V9.19999H11.1999V7.99999C11.1999 7.55816 10.8417 7.19999 10.3999 7.19999H9.1999V5.59999Z"
					fill="black"
				/>
				<path
					d="M8.4 18.4H17.2C17.4209 18.4 17.6 18.2209 17.6 18C17.6 17.7791 17.4209 17.6 17.2 17.6H8.4C8.17909 17.6 8 17.7791 8 18C8 18.2209 8.17909 18.4 8.4 18.4Z"
					fill="black"
				/>
				<path
					d="M18.7999 18.4C19.0208 18.4 19.1999 18.2209 19.1999 18C19.1999 17.7791 19.0208 17.6 18.7999 17.6C18.579 17.6 18.3999 17.7791 18.3999 18C18.3999 18.2209 18.579 18.4 18.7999 18.4Z"
					fill="black"
				/>
			</g>
		</svg>
	);
}
