import React from "react";
// @ts-ignore
import Files from "react-files";
import cn from "classnames";
import { observer } from "mobx-react";

import { DownloadIcon, Trash2Icon, AvatarIcon } from "@icons";
import { IUploadFile } from "@app-types/models";

interface Props {
	fileUrl: string | null;
	onUpload: (files: IUploadFile[]) => void;
	onDelete: () => void;
}

export const UserPhotoUploader = observer(({ fileUrl = null, onUpload, onDelete }: Props) => {
	const hasFile = fileUrl !== null;
	const styles = !hasFile
		? {}
		: {
				backgroundImage: `url(${fileUrl})`,
				backgroundSize: "cover",
		  };

	return (
		<div className="user-photo-uploader">
			<div className="user-photo-uploader__content" style={styles}>
				{!hasFile && (
					<Files onChange={onUpload} multiple={false} minFileSize={0} clickable>
						<div className="user-photo-uploader-empty">
							<AvatarIcon className="user-photo-uploader-empty__icon" />
						</div>
					</Files>
				)}
			</div>
			<div className="user-photo-uploader__toolbar user-photo-uploader-toolbar">
				{hasFile ? (
					<button type="button" className="user-photo-uploader-button user-photo-uploader-button--disabled">
						<DownloadIcon className="user-photo-uploader-button__icon user-photo-uploader-button__icon--upload" />
					</button>
				) : (
					<div className="user-photo-uploader-button">
						<Files onChange={onUpload} multiple={false} minFileSize={0} clickable>
							<button type="button">
								<DownloadIcon className="user-photo-uploader-button__icon user-photo-uploader-button__icon--upload" />
							</button>
						</Files>
					</div>
				)}

				<button
					type="button"
					className={cn("user-photo-uploader-button", { "user-photo-uploader-button--disabled": !hasFile })}
					onClick={onDelete}
				>
					<Trash2Icon className="user-photo-uploader-button__icon" />
				</button>
			</div>
		</div>
	);
});
