import React from "react";
import fileDownload from "js-file-download";

export function useDownloadExcel(): [boolean, (downloadHandler: () => Promise<Blob>) => Promise<void>] {
	const [downloadInProgress, setDownloadInProgress] = React.useState(false);

	async function handleDownload(downloadHandler: () => Promise<Blob>) {
		try {
			setDownloadInProgress(true);

			const data = await downloadHandler();

			fileDownload(data, "Милосердие.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
		} finally {
			setDownloadInProgress(false);
		}
	}

	return [downloadInProgress, handleDownload];
}
