import { XScopeExtRef, XTaskExtRef } from "@external-types/process/task-ext-ref";
import { XTaskValueInfo } from "@external-types/process";

export function getTaskAttributeValue(attr: XTaskValueInfo) {
	if (attr.type === "enum") {
		const optionsAttr = JSON.parse(attr.options) as { options: Array<{ id: string; name: string }> };
		const option = optionsAttr.options.find(opt => opt.id == attr.value);

		if (option) {
			return option.name;
		}
	}

	return attr.value;
}

export function getScopeExtAttributeValue(scopeRef: XScopeExtRef, attrId: number) {
	const attr = scopeRef.attrs!.find(attr => attr.attr === attrId);

	if (attr) {
		return attr.value;
	}

	return "";
}

export function getTaskExtAttributeValue(taskExtRef: XTaskExtRef, attrId: number) {
	if (taskExtRef.values == null) {
		return "";
	}

	const attr = taskExtRef.values!.find(attr => attr.item === attrId);

	if (attr) {
		return getTaskAttributeValue(attr);
	}

	return "";
}
