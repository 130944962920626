import React, { useContext } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { AppHeader as SharedAppHeader } from "@shared/app";
import Logo from "./logo";
import { CallNotification } from "./call-notification";
import { AppStoreContext } from "../../../../src/app-store-context";
import { Paths } from "../routes/paths";

function LogoPart() {
	return (
		<a href="/" className="header-logo">
			<Logo />
		</a>
	);
}

interface Props extends RouteComponentProps {
	path?: string;
}

const dropdownItems = [
	{ value: 1, label: "Личный кабинет" },
	{ value: 2, label: "Выйти" },
];

export const AppHeader = withRouter(({ path, history }: Props) => {
	const { authStore } = useContext(AppStoreContext);

	function handleDropdownSelected(value: number) {
		if (value === 1) {
			history.replace(Paths.Profile);
			return;
		}

		if (value === 2) {
			authStore.logout().finally(() => {
				history.replace("/");
			});
		}
	}

	return (
		<>
			<SharedAppHeader
				Logo={LogoPart}
				path={path}
				dropdown={{ items: dropdownItems, onSelect: handleDropdownSelected }}
			/>
			<CallNotification />
		</>
	);
});
