import React from "react";

export function GridCellContent({ children }: { children: React.ReactNode }) {
	const title = typeof children === "string" ? children : "";

	return (
		<td className={"data-grid__cell"}>
			<span className="call-grid-cell-content" title={title}>
				{children}
			</span>
		</td>
	);
}
