import React from "react";
import { SvgIcon } from "@app-types/models";

export function Alms({ className }: SvgIcon) {
	return (
		<svg viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
			<circle opacity="0.05" cx="16.5" cy="16.5" r="16.5" fill="#01081B" />
			<g opacity="0.5" clipPath="url(#clip0)">
				<path
					d="M27.3976 15.6364C27.2704 15.4256 27.0421 15.2969 26.796 15.2969C24.7195 15.2969 22.8185 16.3642 21.736 18.1094H13.397C12.4581 18.1094 11.5751 18.475 10.9112 19.1392L6.75266 23.2975C6.47837 23.572 6.47819 24.0173 6.75266 24.2919C8.46031 25.9994 10.2524 27.7783 10.2703 27.7959C10.5448 28.0685 10.9877 28.0678 11.2616 27.7953L13.9286 25.1406H19.8189C21.656 25.1406 23.3267 24.1301 24.1783 22.5034C24.1997 22.4625 26.3294 18.3983 27.4182 16.3272C27.5328 16.1091 27.5251 15.8473 27.3976 15.6364ZM22.9326 21.851C22.3243 23.0128 21.1312 23.7344 19.8189 23.7344H13.6384C13.4523 23.7344 13.274 23.808 13.1423 23.9391L10.7651 26.3054C10.2049 25.7487 9.24107 24.7898 8.24443 23.7944L11.9054 20.1334C12.3039 19.735 12.8336 19.5156 13.397 19.5156H20.9813L20.4416 20.5452C20.3199 20.7776 20.0813 20.9219 19.8189 20.9219H15.0446C14.6562 20.9219 14.3415 21.2366 14.3415 21.625C14.3415 22.0134 14.6562 22.3281 15.0446 22.3281H19.8189C20.6059 22.3281 21.3218 21.8951 21.6873 21.198C21.6873 21.198 22.7686 19.1351 22.7726 19.1271C23.3614 18.0131 24.3663 17.2136 25.5402 16.8784C24.4183 19.0156 22.9506 21.8169 22.9326 21.851Z"
					fill="black"
				/>
				<path
					d="M20.6697 8.2461C20.6697 6.69519 19.4079 5.43359 17.8572 5.43359C16.3063 5.43359 15.0447 6.69519 15.0447 8.2461C15.0447 9.797 16.3063 11.0586 17.8572 11.0586C19.4079 11.0586 20.6697 9.797 20.6697 8.2461ZM17.8572 9.65235C17.0817 9.65235 16.4509 9.02155 16.4509 8.2461C16.4509 7.47064 17.0817 6.83985 17.8572 6.83985C18.6326 6.83985 19.2634 7.47064 19.2634 8.2461C19.2634 9.02155 18.6326 9.65235 17.8572 9.65235Z"
					fill="black"
				/>
				<path
					d="M14.3414 16.6836H21.3727C21.7609 16.6836 22.0758 16.3688 22.0758 15.9805V15.2773C22.0758 12.9512 20.1832 11.0586 17.8571 11.0586C15.5309 11.0586 13.6383 12.9512 13.6383 15.2773V15.9805C13.6383 16.3688 13.9531 16.6836 14.3414 16.6836ZM17.8571 12.4648C19.4078 12.4648 20.6696 13.7264 20.6696 15.2773H15.0446C15.0446 13.7264 16.3062 12.4648 17.8571 12.4648Z"
					fill="black"
				/>
			</g>
			<defs>
				<clipPath id="clip0">
					<rect width="24" height="24" fill="white" transform="translate(5 4)" />
				</clipPath>
			</defs>
		</svg>
	);
}
