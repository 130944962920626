import { AppState } from "./app-state";

import { AppStore, AuthStore } from "@shared/stores";
import { IUserSection } from "@app-types/models";
import { IAppStores } from "@app-types/stores";
import { XUserRole } from "@external-types/user";
import { inRoles } from "@helpers/user";

import { Paths } from "../routes/paths";
import { ReferenceStore } from "./reference-store";
import { Role } from "../constants/role";
import { ProcessedCallsStore, UnhandledCallsStore } from "./call-center-store";
import { RequestStore } from "./request-store";
import { ReportsStore } from "./reports-store";
import { ProcessedRequestsStore } from "./request-store/processed-requests-store";
import { WardsStore } from "./wards-store";
import { VolunteersStore } from "./volunteers-store";

export interface IStores extends IAppStores {
	referenceStore: ReferenceStore;
	processedCallsStore: ProcessedCallsStore;
	unhandledCallsStore: UnhandledCallsStore;
	requestStore: RequestStore;
	reportsStore: ReportsStore;
	processedRequests: ProcessedRequestsStore;
	wardsStore: WardsStore;
	volunteersStore: VolunteersStore;
}

const sections: IUserSection[] = [
	{
		path: Paths.CallCenter,
		title: "Принятые звонки",
		access(roles: XUserRole[]) {
			return inRoles(roles, [Role.Admin, Role.Operator, Role.External]);
		},
	},
	{
		path: Paths.CallCenterUnhandled,
		title: "Необработанные звонки",
		access(roles: XUserRole[]) {
			return inRoles(roles, [Role.Admin, Role.Operator, Role.External]);
		},
	},
	{
		path: Paths.Requests,
		title: "Все просьбы",
		access(roles: XUserRole[]) {
			return inRoles(roles, [Role.Admin, Role.Coordinator1, Role.Coordinator2, Role.External]);
		},
	},
	{
		path: Paths.ActiveRequests,
		title: "Просьбы в работе",
		access(roles: XUserRole[]) {
			return inRoles(roles, [Role.Admin, Role.Coordinator1, Role.Coordinator2, Role.External]);
		},
	},
	{
		path: Paths.Wards,
		title: "Подопечные",
		access(roles: XUserRole[]) {
			return inRoles(roles, [Role.Admin, Role.Coordinator1, Role.Coordinator2, Role.Operator, Role.External]);
		},
	},
	{
		path: Paths.Volunteers,
		title: "Добровольцы",
		access(roles: XUserRole[]) {
			return inRoles(roles, [Role.Admin, Role.Coordinator1, Role.Coordinator2, Role.Operator, Role.External]);
		},
	},
	{
		path: Paths.Reference,
		title: "Справочники",
		access(roles: XUserRole[]) {
			return inRoles(roles, [
				Role.Admin,
				Role.Coordinator1,
				Role.Coordinator2,
				Role.Operator,
				Role.ReferenceAdmin,
			]);
		},
	},
	{
		path: Paths.Reports,
		title: "Отчеты",
		access(roles: XUserRole[]) {
			return inRoles(roles, [Role.Admin, Role.Coordinator1, Role.Coordinator2, Role.Operator, Role.External]);
		},
	},
];

export function createStores() {
	const appState = new AppState();
	const appStore = new AppStore(appState, sections);

	const stores: IStores = {
		appStore,
		authStore: new AuthStore(appStore),
		referenceStore: new ReferenceStore(appState),
		processedCallsStore: new ProcessedCallsStore(appState),
		unhandledCallsStore: new UnhandledCallsStore(appState),
		requestStore: new RequestStore(appState),
		reportsStore: new ReportsStore(appStore),
		processedRequests: new ProcessedRequestsStore(appStore, appState),
		wardsStore: new WardsStore(appState),
		volunteersStore: new VolunteersStore(appState),
	};

	return stores;
}
