import { observable } from "mobx";

import { XHistoryItem, XProcessAdminInfo, XProcessTaskInfo } from "@external-types/process";
import { actions } from "@actions";
import { ProcessState } from "../../../constants/process-state";
import { XUserRef } from "../../../../../../src/types/external-data/user";

export enum RequestHistoryItemType {
	Common = 0,
	NewRequest = 1,
	VolunteersRequired = 2,
	VolunteersExecution = 3,
}

export type IRequestHistoryItem =
	| VolunteersExecutionStepHistoryItem
	| RequestHistoryItem
	| NewRequestStepHistoryItem
	| VolunteersRequiredStepHistoryItem;

export class VolunteersExecutionStepHistoryItem {
	public type = RequestHistoryItemType.VolunteersExecution;

	public constructor(public readonly historyItem: XHistoryItem, public readonly items: XProcessAdminInfo[]) {}

	public get id() {
		return this.historyItem.state.id;
	}

	public get title() {
		return this.historyItem.state.state;
	}
}

export class RequestHistoryItem {
	public type = RequestHistoryItemType.Common;

	public constructor(public readonly historyItem: XHistoryItem) {}

	public get id() {
		return this.historyItem.state.id;
	}

	public get title() {
		return this.historyItem.state.state;
	}
}

export class NewRequestStepHistoryItem {
	public type = RequestHistoryItemType.NewRequest;
	public items: XHistoryItem[] = [];

	public add(taskInfo: XHistoryItem) {
		this.items.push(taskInfo);
	}

	public get id() {
		return this.items.length > 0 ? this.items[0].state.id : 0;
	}

	public get title() {
		return this.items.length > 0 ? this.items[0].state.state : "";
	}
}

export class VolunteersRequiredStepHistoryItem {
	public type = RequestHistoryItemType.VolunteersRequired;
	private readonly itemsMap: Map<number, XHistoryItem[]> = new Map<number, XHistoryItem[]>();
	public readonly executors: XUserRef[] = [];
	public id: number = -1;
	public title: string = "Нужен доброволец";

	public getActiveHistory(activeId: number) {
		return this.itemsMap.get(activeId);
	}

	public add(item: XHistoryItem) {
		if (item.tasks.length === 0) {
			return;
		}

		const executor = item.tasks[0].executor;
		this.id = item.tasks[0].id;
		let history: XHistoryItem[];

		if (!this.itemsMap.has(executor.id)) {
			history = [];
			this.itemsMap.set(executor.id, history);
			this.executors.push(executor);
		} else {
			history = this.itemsMap.get(executor.id)!;
		}

		history.push(item);
	}

	public get hasItems() {
		return this.itemsMap.size > 0;
	}
}

export class RequestHistory {
	@observable public items: IRequestHistoryItem[] = [];

	public static async create(processTaskInfo: XProcessTaskInfo | XProcessAdminInfo) {
		const requestHistory = new RequestHistory(processTaskInfo);

		const history = processTaskInfo.history.filter(item => ![ProcessState.NewCall].includes(item.formID));

		for (const historyItem of history) {
			await requestHistory.push(historyItem);
		}

		return requestHistory;
	}

	private constructor(private readonly processTaskInfo: XProcessTaskInfo | XProcessAdminInfo) {}

	private async push(historyItem: XHistoryItem) {
		switch (historyItem.formID) {
			case ProcessState.VoluteerRequired: {
				let item = this.items.find(item => item.type === RequestHistoryItemType.VolunteersRequired);
				if (!item) {
					item = new VolunteersRequiredStepHistoryItem();
					this.items.push(item);
				}

				(item as VolunteersRequiredStepHistoryItem).add(historyItem);
				break;
			}
			case ProcessState.NewRequest: {
				let item = this.items.find(item => item.type === RequestHistoryItemType.NewRequest);
				if (!item) {
					item = new NewRequestStepHistoryItem();
					this.items.push(item);
				}

				(item as NewRequestStepHistoryItem).add(historyItem);
				break;
			}
			case ProcessState.VoluteerAssigned: {
				let item = this.items.find(item => item.type === RequestHistoryItemType.VolunteersExecution);
				if (!item) {
					const subProcesses = historyItem.state.subprocess
						? await this.fetchSubProcesses(this.processTaskInfo.pid, historyItem.state.id)
						: [];

					item = new VolunteersExecutionStepHistoryItem(historyItem, subProcesses);
					this.items.push(item);
				}
				break;
			}
			default:
				this.items.push(new RequestHistoryItem(historyItem));
		}
	}

	private async fetchSubProcesses(processId: number, taskId: number): Promise<XProcessAdminInfo[]> {
		const { list } = await actions.getSubprocesses(processId, taskId);
		const processes: XProcessAdminInfo[] = [];

		for (const item of list) {
			const task = await actions.getProcessByManager(item.pid);

			processes.push(task);
		}

		return processes;
	}
}
