import { computed, observable } from "mobx";
import { IPagination } from "@app-types/data";

export class Pagination implements IPagination {
	@observable
	public offset = 0;
	@observable
	public pageSize = 10;
	@observable
	public total = 0;

	@computed
	public get currentPage() {
		if (this.offset === 0) {
			return 1;
		}
		return Math.ceil(this.offset / this.pageSize) + 1;
	}

	@computed
	public get pageCount() {
		return Math.ceil(this.total / this.pageSize);
	}
}
