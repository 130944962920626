import React from "react";
import { observer } from "mobx-react";

import { VolunteerAttributes } from "../../../../constants/volunteer-attributes";
import { SchemaFormFieldRow } from "@app-components/schema-form";
import { SchemaForm } from "@app-models/schema-form";
import { UserPhotoUploader } from "../../../components/user-photo-uploader";
import { IVolunteer } from "../../../../stores/volunteers-store/volunteer";

interface Props {
	form: SchemaForm;
	entry: IVolunteer;
}

export const BaseInfoSection = observer(({ form, entry }: Props) => {
	return (
		<div className="scope-card-content__row">
			<div className="scope-card-content__column">
				<div className="scope-card-heading">Личные данные</div>
				<div className="scope-card-user-photo">
					<UserPhotoUploader
						fileUrl={entry.photoUrl}
						onUpload={entry.uploadPhoto}
						onDelete={entry.deletePhoto}
					/>
				</div>

				<div>
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.LastName)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.FirstName)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.MiddleName)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.Birthday)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.Gender)!} />
				</div>
			</div>

			<div className="scope-card-content__column">
				<div className="scope-card-heading">Состояние</div>
				<div>
					{/*<FormFieldRow field={form.getField(VolunteerAttributes.InPrayList)!} />*/}
					{/*<FormFieldRow field={form.getField(VolunteerAttributes.ReasonOfInPrayList)!} />*/}
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.VolunteerStatus)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.Dead)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.DateOfDeath)!} />
				</div>
			</div>
		</div>
	);
});
