import React from "react";

import { SchemaFormFieldRow } from "@app-components/schema-form";
import { SchemaForm } from "@app-models/schema-form";
import { VolunteerAttributes } from "../../../../constants/volunteer-attributes";
import { FieldRating } from "../../components/rating";

interface Props {
	form: SchemaForm;
}

export const CharacteristicsSection = ({ form }: Props) => {
	return (
		<div className="scope-card-content__row">
			<div className="scope-card-content__column">
				<div className="scope-card-heading">Характеристика</div>
				<div>
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.VolunteerPersonalCharacteristics)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.HasAuto)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.HasMedicalExamination)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.TrainingCompleted)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.DateTrainingCompleted)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.CoordinatorComments)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.PriestComments)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.ImportantNotes)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.AdditionalNotes)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.ReasonOfHelping)!} />
				</div>
				<div className="scope-card-heading">Кому и как хочет помогать</div>
				<div>
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.HelpToAdults)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.HelpToChildren)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.HelpRemote)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.HelpByAuto)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.WhenCanHelp)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.MoreThanOncePerWeek)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.OncePerWeek)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.OncePerMonth)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.TwicePerMonth)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.WhenCanHelpComments)!} />
				</div>
			</div>
			<div className="scope-card-content__column">
				<div className="scope-card-heading">Рейтинг</div>
				<div>
					<FieldRating field={form.getField(VolunteerAttributes.HowIsGood)!} />
					<FieldRating field={form.getField(VolunteerAttributes.AlwaysCompletesRequests)!} />
					<FieldRating field={form.getField(VolunteerAttributes.CanWarkHard)!} />
					<FieldRating field={form.getField(VolunteerAttributes.Responsibility)!} />
					<FieldRating field={form.getField(VolunteerAttributes.Diligence)!} />
					<FieldRating field={form.getField(VolunteerAttributes.Mobility)!} />
				</div>
			</div>
		</div>
	);
};
