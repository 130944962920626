import moment from "moment";

moment.locale("ru-RU");

export const DateTimeFormats = {
	Until: "до DD MMM YYYY, HH:mm",
};

export function formatDate(timestamp: number) {
	return moment(timestamp).format("DD.MM.YYYY");
}

export function formatDateTime(timestamp: number, format: string = "DD.MM.YYYY в HH:mm") {
	return moment(timestamp).format(format);
}

export function formatDuration(timestamp: number) {
	return moment().from(moment(timestamp), true);
}

export function duration(from: number, to: number) {
	const start = moment(from);
	const end = moment(to);

	return end.from(start, true);
}

export function isExpired(timestamp: number) {
	return timestamp < Date.now();
}

export function getDefaultExpireTimestamp(duration?: number): number | null {
	if (duration == null) {
		return null;
	}

	const expireDate = new Date(Date.now() + duration * 1000);
	expireDate.setMinutes(Math.round(expireDate.getMinutes() / 30) * 30);
	expireDate.setSeconds(0);
	return expireDate.getTime();
}

function pad(num: number) {
	if (num < 10) {
		return ("0" + num).slice(-2);
	}

	return String(num);
}

export function secondsToHhMm(seconds?: number) {
	if (!seconds) {
		return "00:00";
	}

	let minutes = Math.floor(seconds / 60);
	const hours = Math.floor(minutes / 60);
	minutes = minutes % 60;
	return `${pad(hours)}:${pad(minutes)}`;
}
