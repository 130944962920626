import { Form, IField, IForm, IValidator } from "@app-models/form";
import { XUserProfile } from "@external-types/user";

const required: IValidator = (field: IField) => {
	return [field.value != null && field.value !== "", "Это поле обязательно к заполнению"];
};

const validatePassword: IValidator = (field: IField, form: IForm) => {
	if (form.get("newPassword").value || form.get("newPasswordValidation").value) {
		return required(field, form);
	}

	return [true];
};

const validateNewPasswordRequired: IValidator = (field: IField, form: IForm) => {
	if (form.get("newPasswordValidation").value) {
		return required(field, form);
	}

	return [true];
};

const validateNewPasswordSame: IValidator = (field: IField, form: IForm) => {
	if (form.get("newPasswordValidation").value) {
		return [field.value === form.get("newPasswordValidation").value, "Пароли не совпадают"];
	}

	return [true];
};

const validateNewPasswordValidationRequired: IValidator = (field: IField, form: IForm) => {
	if (form.get("newPassword").value) {
		return required(field, form);
	}

	return [true];
};

const validateNewPasswordValidationSame: IValidator = (field: IField, form: IForm) => {
	if (form.get("newPassword").value) {
		return [field.value === form.get("newPassword").value, "Пароли не совпадают"];
	}

	return [true];
};

export function createProfileFormStore(user: XUserProfile) {
	return new Form({
		fields: [
			{
				name: "username",
				value: user.username,
				label: "Логин",
			},
			{
				name: "password",
				label: "Текущий пароль",
				validators: [validatePassword],
			},
			{
				name: "newPassword",
				label: "Новый пароль",
				validators: [validateNewPasswordRequired, validateNewPasswordSame],
			},
			{
				name: "newPasswordValidation",
				label: "Повторите новый пароль",
				validators: [validateNewPasswordValidationRequired, validateNewPasswordValidationSame],
			},
			{
				name: "lastName",
				label: "Фамилия",
				value: user.lastName,
			},
			{
				name: "firstName",
				label: "Имя",
				value: user.firstName,
			},
			{
				name: "middleName",
				label: "Отчество",
				value: user.middleName,
			},
			{
				name: "phone",
				label: "Телефон",
				value: user.phone,
			},
			{
				name: "email",
				label: "Email",
				value: user.email,
			},
		],
	});
}
