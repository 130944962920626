import { IAppState } from "@app-types/stores";
import { ReferenceSession } from "./session/reference-session";
import { ProcessedCallsSession } from "./session/processed-calls-session";
import { RequestSession } from "./session/request-session";
import { UnhandledCallsSession } from "./session/unhandled-calls-session";
import { ProcessedRequestsSession } from "./session/processed-requests-session";
import { WardsSession } from "./session/wards-session";
import { VolunteersSession } from "./session/volunteers-session";

export class AppState implements IAppState {
	public reference = new ReferenceSession();
	public processedCalls = new ProcessedCallsSession();
	public unhandledCalls = new UnhandledCallsSession();
	public requests = new RequestSession();
	public processedRequests = new ProcessedRequestsSession();
	public wards = new WardsSession();
	public volunteers = new VolunteersSession();

	public clean() {
		this.reference = new ReferenceSession();
		this.processedCalls = new ProcessedCallsSession();
		this.unhandledCalls = new UnhandledCallsSession();
		this.requests = new RequestSession();
		this.processedRequests = new ProcessedRequestsSession();
		this.wards = new WardsSession();
		this.volunteers = new VolunteersSession();
	}

	public init() {
		this.clean();
	}
}
