import { observable, runInAction } from "mobx";

import { actions } from "@actions";
import { XNextTaskRef, XProcessTaskInfo } from "@external-types/process";

import { Task } from "../call-center-store/task";
import { WardInfo } from "../models/ward-info";
import { ProcessState } from "../../constants/process-state";
import { BaseTaskForm } from "../models/base-task-form";
import { XSchemaFormItemInfo } from "@app-types/external-data/schema";
import { RequestHistory } from "./request-history";

class RequestTask extends Task {
	protected createForm(items: XSchemaFormItemInfo[]) {
		super.createForm(items.filter(item => item.id !== 4448));
	}
}

export class RequestItem {
	@observable public task: RequestTask | null = null;
	public ward: WardInfo | null = null;
	public baseTask: BaseTaskForm | null = null;
	@observable public processInfo: XProcessTaskInfo | null = null;
	// @observable public taskReports: XProcessRef[] = [];
	@observable public audioRecordUrl: string | null = null;
	public requestHistory: RequestHistory | null = null;

	public constructor(public readonly taskId: number) {}

	public async fetch() {
		const processTaskInfo = await actions.getTask(this.taskId);
		const scopeRef = processTaskInfo.base ? processTaskInfo.base.scope : processTaskInfo.scope;
		const scope = await actions.getScope(scopeRef.id);
		const task = new RequestTask(processTaskInfo);
		const baseTask = task.history.find(item => item.formID === ProcessState.NewCall) ?? null;

		// let taskReports: XProcessAdminInfo[] = [];
		this.requestHistory = await RequestHistory.create(processTaskInfo);

		//if (this.requestHistory.hasSubProcesses) {
		// await this.requestHistory.fetchSubProcesses();

		// taskReports = this.requestHistory.volunteersExecutionHistory.items;
		// }

		runInAction(() => {
			this.task = task;
			// this.taskReports = taskReports;
			this.processInfo = processTaskInfo;
			this.audioRecordUrl =
				processTaskInfo.links && processTaskInfo.links.length > 0
					? actions.getTaskProcessLinkUrl(processTaskInfo.id, processTaskInfo.links[0].id)
					: null;

			if (baseTask != null) {
				this.baseTask = new BaseTaskForm(processTaskInfo.pid, baseTask);
			}
			this.ward = new WardInfo(scope);
		});
	}

	public get history() {
		return this.task?.history.filter(item => item.formID !== ProcessState.NewCall) ?? [];
	}

	public async runAction(actionId: number, callback?: (nextTaskRef: XNextTaskRef) => void) {
		this.task!.updateActionId(actionId);

		if (this.task!.submitAllowed) {
			await this.task!.runAction(callback);
		}
	}
}
