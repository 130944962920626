import React from "react";
import { Paths } from "../../routes/paths";

interface Props {
	city: string;
	street: string;
}

export function LocateLink({ city, street }: Props) {
	const address = encodeURIComponent(`Россия, ${city}, ${street}`);
	const url = `${Paths.Map}?address=${address}`;

	return (
		<a href={url} target="_blank">
			Посмотреть на карте
		</a>
	);
}
