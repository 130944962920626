import React from "react";
import cn from "classnames";

interface Props {
	on: {
		label: string;
		value: string;
	};
	off: {
		label: string;
		value: string;
	};
	value: string;
	onChange: (value: string) => void;
}

export function Switch({ on, off, value, onChange }: Props) {
	function handleChange(value: string) {
		return () => {
			onChange(value);
		};
	}

	return (
		<div className="switch">
			<div
				className={cn("switch__item", { "switch__item--active": on.value === value })}
				onClick={handleChange(on.value)}
			>
				{on.label}
			</div>
			<div
				className={cn("switch__item", { "switch__item--active": off.value === value })}
				onClick={handleChange(off.value)}
			>
				{off.label}
			</div>
		</div>
	);
}
