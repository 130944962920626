import React from "react";
import { Ward } from "../../../../stores/wards-store";
import { AttrFormRowInfo } from "./base-info-section";
import { WardAttributes } from "../../../../constants/ward-attributes";

interface Props {
	entry: Ward;
}

export const DescriptionSection = ({ entry }: Props) => {
	return (
		<>
			<h3>Что может делать самостоятельно</h3>
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.CanDoSanitary)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.CanDoCook)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.CanDoCleaning)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.CanDoFlatMovement)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.CanDoCityMovement)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.HouseholdSelfService)} scope={entry.scope} />

			<h3>Чья помощь нужна</h3>
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.HelpRequiredVolunteers)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.HelpRequiredNurse)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.HelpRequiredMedicalStaff)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.HelpRequiredPriest)} scope={entry.scope} />

			<h3>Какая помощь необходима</h3>
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.HelpTypeCleaningCook)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.HelpTypeBuyDrugs)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.HelpTypeSanitary)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.HelpTypeChangingDiapers)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.HelpTypeTreatmentOfBedsores)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.HelpTypeTalk)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.HelpTypeAdditional)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.HelpRequiredFrequency)} scope={entry.scope} />

			<h3>Финансовое положение</h3>
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.FinancialSituation)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.FinancialSituationInfo)} scope={entry.scope} />

			<h3>Дополнительная информация</h3>
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.CommonComment)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.AdditionalInfo)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.FullNameofVolunteer)} scope={entry.scope} />
			<AttrFormRowInfo attr={entry.getAttr(WardAttributes.FillingDate)} scope={entry.scope} />
		</>
	);
};
