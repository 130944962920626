import React from "react";
import { observer } from "mobx-react-lite";

import { SchemaForm } from "@app-models/schema-form";
import { SchemaFormFieldRow } from "@app-components/schema-form";
import { DialogPresenter } from "@app-components/dialog";
import { LightButton } from "@app-components/buttons/light-button";
import { CrossIcon } from "@app-components/icons";

import { VolunteerAttributes } from "../../../../constants/volunteer-attributes";
import { VolunteerProjectsDialog } from "../../components/volunteer-projects-dialog";
import { VolunteerProjects } from "../../../../stores/volunteers-store/volunteer-projects";

interface Props {
	form: SchemaForm;
	volunteerProjects: VolunteerProjects | null;
}

export const ProjectsSection = observer(({ form, volunteerProjects }: Props) => {
	function handleAddProject() {
		const presenter = new DialogPresenter();

		presenter.show(
			<VolunteerProjectsDialog onClose={() => presenter.close()} volunteerProjects={volunteerProjects!} />,
			"volunteer-projects-dialog",
		);
	}

	function handleDeleteProject(attrId: number) {
		return function() {
			volunteerProjects!.form.removeField(attrId);
		};
	}

	return (
		<div className="scope-card-content__row">
			<div className="scope-card-content__column">
				<div className="scope-card-heading">Проекты</div>
				{volunteerProjects && (
					<>
						{volunteerProjects.form.fields.map(field => (
							<div key={field.id} className="volunteer-project">
								<div className="volunteer-project__content">
									<div className="volunteer-project__name">{field.label}</div>
									<div className="volunteer-project__actions">
										<span onClick={handleDeleteProject(field.id)} title="Удалить">
											<CrossIcon className="volunteer-project-delete-btn" />
										</span>
									</div>
								</div>
								<div className="volunteer-project__status volunteer-project-status">
									<div className="volunteer-project-status__label">Статус активности</div>
									<div className="volunteer-project-status__value">
										{field.options.find(opt => opt.value === field.value)?.label ?? "Не определен"}
									</div>
								</div>
							</div>
						))}
						<LightButton onClick={handleAddProject}>Добавить проект</LightButton>
					</>
				)}
			</div>
			<div className="scope-card-content__column">
				<div className="scope-card-heading">Постоянные подопечные</div>
				<SchemaFormFieldRow field={form.getField(VolunteerAttributes.RegularWards)!} />
			</div>
		</div>
	);
});
