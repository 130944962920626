import React from "react";
// @ts-ignore
import Files from "react-files";
import { IUploadFile } from "@app-types/models";
import { LightButton } from "../buttons/light-button";
import { DownloadIcon } from "@icons";

interface FileUploadProps {
	onChange?(files: IUploadFile[]): void;
	onError?(error: any, file: IUploadFile): void;
	maxFiles?: number;
	maxFileSize?: number;
}

export const FileUpload = React.forwardRef(
	({ onChange, onError, maxFiles = 10, maxFileSize = 104857600 }: FileUploadProps, ref) => {
		return (
			<Files
				ref={ref}
				className="files-upload__button"
				onChange={onChange}
				onError={onError}
				multiple
				maxFiles={maxFiles}
				maxFileSize={maxFileSize}
				minFileSize={0}
				clickable
			>
				<div style={{ display: "flex", justifyContent: "flex-end" }}>
					<LightButton Icon={<DownloadIcon className="btn-download-icon" />}>Загрузить</LightButton>
				</div>
			</Files>
		);
	},
);
