import React from "react";
import { Loader } from "./loader";

export function InlineLoader({ text }: { text?: string }) {
	return (
		<div className="inline-loader">
			<Loader />
			{text && <div className="inline-loader__text">{text}</div>}
		</div>
	);
}
