import React from "react";
import { SvgIcon } from "@app-types/models";

interface Props extends SvgIcon {
	children?: React.ReactNode;
}

export default function({ className, children }: Props) {
	return (
		<svg viewBox="0 0 22 22" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
			{children}
			<path
				d="M11 1L14.09 7.26L21 8.27L16 13.14L17.18 20.02L11 16.77L4.82 20.02L6 13.14L1 8.27L7.91 7.26L11 1Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
