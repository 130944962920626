interface Ymaps {
	SuggestView: (element: HTMLInputElement) => any;
	suggest: (request: string) => Promise<SuggestItem[]>;
}

declare const ymaps: Ymaps;

interface SuggestItem {
	displayName: string;
	type: string;
	value: string;
	hl: Array<[number, number]>;
}

interface Provider {
	suggest: (request: string, options: any) => Promise<void>;
}

class YaMapsService {
	public createSuggest(element: HTMLInputElement, provider: "yandex#map" | Provider = "yandex#map") {
		// @ts-ignore
		return new ymaps.SuggestView(element, { provider });
	}

	public suggest(city: string, street: string) {
		return ymaps.suggest(`Россия, ${city}, ${street}`);
	}
}

const yaMapsService = new YaMapsService();

export { yaMapsService, SuggestItem };
