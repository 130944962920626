import { action, computed, runInAction } from "mobx";

import { XScopeCard, XScopeChildInfo } from "@external-types/scope";

import { WardAttributes } from "../../constants/ward-attributes";
import { ISchemaForm, SchemaForm } from "@app-models/schema-form";
import { actions } from "../../../../../src/actions";
import { Scope } from "../../constants/scope";
import { ScopeAppType } from "../../constants/scope-app-type";
import { mapAttrValueToFormField } from "../reference-store/reference-scope";
import { saveWardInfo, setWardDefaults } from "../../helpers/ward-helper";

const additionalPhoneFieldIds = [WardAttributes.MobilePhone, WardAttributes.HomePhone, WardAttributes.AdditionalPhone];
const additionalAddressFieldIds = [WardAttributes.Settlement, WardAttributes.Subway];

export class WardForm implements ISchemaForm {
	public form: SchemaForm | null = null;

	public constructor(private scope: XScopeCard | XScopeChildInfo) {}

	public async fetch() {
		const keys = new Set(Object.values(WardAttributes).filter(v => typeof v === "number"));
		const appType = await actions.getScopeType(Scope.Wards, ScopeAppType.Ward);
		const form = new SchemaForm(appType.attrs.filter(attr => keys.has(attr.id)));

		runInAction(() => {
			mapAttrValueToFormField(this.scope, form);
			setWardDefaults(form);
			this.form = form;
		});
	}

	public async save() {
		let scope = this.scope;

		if (this.form!.validate()) {
			await saveWardInfo(this.scope, this.form!);

			scope = await actions.getScope(this.scope.id);

			runInAction(() => {
				this.scope = scope;
			});
		}

		return scope;
	}

	@action
	public replaceScope(scope: XScopeCard | XScopeChildInfo) {
		mapAttrValueToFormField(scope, this.form!);
		this.scope = scope;
	}

	public validate() {
		return this.form!.validate();
	}

	public getField(wardAttr: WardAttributes) {
		return this.form!.getField(wardAttr);
	}

	public get fields() {
		return this.form!.fields;
	}

	public get isValid() {
		return this.form!.isValid;
	}

	@computed
	public get hasAdditionalEmptyPhone() {
		return this.hasAdditionalEmptyFields(additionalPhoneFieldIds);
	}

	@computed
	public get hasAdditionalEmptyAddress() {
		return this.hasAdditionalEmptyFields(additionalAddressFieldIds);
	}

	private hasAdditionalEmptyFields(fieldIds: WardAttributes[]) {
		for (const fieldId of fieldIds) {
			const field = this.getField(fieldId);

			if (field && !field.value) {
				return true;
			}
		}

		return false;
	}
}
