const mbSize = 1024 * 1024;

export const MimeTypes = {
	Doc: "application/msword",
	Docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	Gif: "image/gif",
	Jpeg: "image/jpeg",
	Mov: "video/quicktime",
	Mp4: "video/mp4",
	Ppt: "application/vnd.ms-powerpoint",
	Pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
	Pdf: "application/pdf",
	Png: "image/png",
	OctetStream: "application/octet-stream",
	Txt: "text/plain",
	Xls: "application/vnd.ms-excel",
	Xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	Xml: "text/xml",
	Zip: "application/zip",
};

export function shortType(fileName: string, mimeType: string) {
	switch (mimeType) {
		case MimeTypes.Doc:
		case MimeTypes.Docx:
			return "DOC";
		case MimeTypes.Gif:
			return "GIF";
		case MimeTypes.Jpeg:
			return "JPEG";
		case MimeTypes.Mp4:
			return "MP4";
		case MimeTypes.Ppt:
		case MimeTypes.Pptx:
			return "PPT";
		case MimeTypes.Png:
			return "PNG";
		case MimeTypes.Pdf:
			return "PDF";
		case MimeTypes.Txt:
			return "TXT";
		case MimeTypes.Xls:
		case MimeTypes.Xlsx:
			return "XLS";
		case MimeTypes.Mov:
			return "MOV";
		case MimeTypes.Zip:
			return "ZIP";
		case MimeTypes.Xml:
			return "XML";
		case MimeTypes.OctetStream:
			return fileName.endsWith("rar") ? "RAR" : "DOC";
		default: {
			const ext = fileName.match(/\.[0-9a-z]+$/);
			return ext ? ext[0].substr(1) : "DOC";
		}
	}
}

export function toFixedSize(bytes: number, digits: number = 1): string {
	if (bytes < mbSize) {
		return (bytes / 1024).toFixed(digits) + "Кб";
	}

	return (bytes / mbSize).toFixed(digits) + "Mб";
}
