import React from "react";
import { SvgIcon } from "@app-types/models";

export function Star({ className }: SvgIcon) {
	return (
		<svg viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
			<g>
				<path
					d="M26.9619 10.4026C26.8841 10.1632 26.6954 9.97613 26.4552 9.90051L18.7963 7.48697L14.1341 0.948759C13.988 0.743781 13.7517 0.62207 13.5 0.62207C13.2482 0.62207 13.012 0.743781 12.8659 0.948759L8.20367 7.48702L0.544805 9.90051C0.304653 9.97623 0.115917 10.1632 0.0381338 10.4026C-0.0396492 10.6421 0.0030128 10.9044 0.152831 11.1067L4.9304 17.5611L4.90804 20.0797L4.8591 25.591C4.85689 25.8428 4.97644 26.08 5.18015 26.228C5.38381 26.3761 5.64648 26.4164 5.88515 26.3365L13.5 23.7873L21.1148 26.3366C21.1955 26.3636 21.279 26.3769 21.362 26.3769C21.5245 26.3769 21.6851 26.326 21.8198 26.2281C22.0235 26.0801 22.1431 25.8428 22.1409 25.5911L22.092 20.0798L22.0696 17.5612L26.8472 11.1068C26.997 10.9044 27.0397 10.6421 26.9619 10.4026ZM13.7473 22.2275C13.667 22.2006 13.5836 22.1872 13.5 22.1872C13.4165 22.1872 13.3329 22.2006 13.2527 22.2275L6.42642 24.5127L6.46312 20.3794C7.58604 19.1386 9.1706 18.4322 10.8519 18.4322H16.1481C17.8294 18.4322 19.4139 19.1386 20.5369 20.3794L20.5736 24.5127L13.7473 22.2275ZM10.125 13.9346V13.4599C10.125 11.6208 11.6213 10.1245 13.4604 10.1245H13.5395C15.3787 10.1245 16.875 11.6208 16.875 13.4599V13.9346C16.875 15.5556 15.5562 16.8745 13.9352 16.8745H13.0649C11.4438 16.8745 10.125 15.5556 10.125 13.9346ZM20.6624 16.844C20.5618 16.98 20.5081 17.1451 20.5096 17.3143L20.5182 18.2837C19.5568 17.5899 18.4429 17.1345 17.2622 16.9579C17.989 16.1587 18.4326 15.0975 18.4326 13.9347V13.46C18.4326 10.7619 16.2376 8.56686 13.5395 8.56686H13.4604C10.7623 8.56686 8.56727 10.7619 8.56727 13.46V13.9347C8.56727 15.0975 9.01092 16.1587 9.73771 16.9579C8.55699 17.1346 7.44308 17.5899 6.48168 18.2837L6.49028 17.3143C6.49181 17.1451 6.43812 16.98 6.33751 16.844L2.05475 11.0579L8.92059 8.89434C9.0819 8.8435 9.22244 8.74141 9.32058 8.60367L13.5 2.74246L17.6794 8.60367C17.7776 8.74141 17.9181 8.8435 18.0794 8.89434L24.9453 11.0579L20.6624 16.844Z"
					fill="currentColor"
				/>
			</g>
		</svg>
	);
}
