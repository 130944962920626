declare const ymaps: any;

interface Point {
	id: number;
	x: number;
	y: number;
}

interface IRegion {
	id: number;
	name: string;
	color: string;
	path: Array<number | [number, number]>;
}

type Coord = [number, number];

const Points: Point[] = [
	// Красная площадь
	{ id: 1, x: 37.6206880950927, y: 55.7538047420646 },
	// Манежная площадь
	{ id: 2, x: 37.61403956584881, y: 55.75591189995851 },
	// Политехнический музей
	{ id: 3, x: 37.63001384704464, y: 55.757095607690225 },
	// Покровские ворота
	{ id: 4, x: 37.64594856200929, y: 55.759224670459915 },

	// Соприкосновние ЮАО с ЮЗАО
	{ id: 101, x: 37.62465359200135, y: 55.749458889723066 },
	{ id: 102, x: 37.63908244192488, y: 55.747745696642205 },
	{ id: 103, x: 37.653517083404004, y: 55.728778415777285 },
	{ id: 104, x: 37.658369202777166, y: 55.720100814935016 },
	{ id: 105, x: 37.66361627497882, y: 55.7178851406456 },
	{ id: 106, x: 37.668133786328454, y: 55.70054943178527 },
	{ id: 107, x: 37.71088895367072, y: 55.69104153949685 },
	{ id: 108, x: 37.714008318996164, y: 55.67338666535328 },
	{ id: 109, x: 37.67650088646659, y: 55.668873859712825 },
	{ id: 110, x: 37.67111183709376, y: 55.65476705583549 },
	{ id: 111, x: 37.69218871353945, y: 55.64330515511082 },
	{ id: 112, x: 37.71591807416269, y: 55.63957910483726 },
	{ id: 113, x: 37.74749012421401, y: 55.640094769308654 },
	{ id: 114, x: 37.783900649804536, y: 55.64850740866366 },
	{ id: 115, x: 37.789636852037596, y: 55.621641330871356 },

	// Граница ЮАО
	{ id: 151, x: 37.731853332519556, y: 55.592287325576535 },
	{ id: 152, x: 37.67381729125975, y: 55.571284144302474 },
	{ id: 153, x: 37.597178289842184, y: 55.57582623898076 },
	{ id: 154, x: 37.59145610619087, y: 55.558548566006706 },
	{ id: 155, x: 37.56293163851077, y: 55.54041738369087 },
	{ id: 156, x: 37.517684526130644, y: 55.52599334540257 },
	{ id: 157, x: 37.49819379007, y: 55.53078125946778 },
	{ id: 158, x: 37.49775390484244, y: 55.55051661385248 },

	// Соприкосновние ЮАО и ЮЗАВ
	{ id: 201, x: 37.55981305461972, y: 55.58416566941105 },
	{ id: 202, x: 37.56383548461924, y: 55.64930247378024 },
	{ id: 203, x: 37.603234164844295, y: 55.68723796019018 },
	{ id: 204, x: 37.62192164528876, y: 55.711409098937274 },
	{ id: 205, x: 37.61796380610951, y: 55.729844550164835 },
	{ id: 206, x: 37.6095062235396, y: 55.73878895619738 },
	{ id: 207, x: 37.609508225054306, y: 55.74321385720803 },

	// Граница ЮЗАО
	{ id: 251, x: 37.51331154615546, y: 55.595476223118446 },
	{ id: 252, x: 37.4905680266365, y: 55.59015200679478 },
	{ id: 253, x: 37.50488005141211, y: 55.57291257916845 },
	{ id: 254, x: 37.48633926011862, y: 55.561461675054474 },
	{ id: 255, x: 37.47259629355209, y: 55.54622990021342 },
	{ id: 256, x: 37.44992868812434, y: 55.56475989032548 },
	{ id: 257, x: 37.379464152663225, y: 55.61387065415937 },
	{ id: 258, x: 37.34684111170769, y: 55.633314865027565 },
	{ id: 259, x: 37.31018156861565, y: 55.62754356551192 },
	{ id: 260, x: 37.30535537183587, y: 55.638726617436745 },
	{ id: 261, x: 37.3311510526171, y: 55.64734155780533 },
	{ id: 262, x: 37.34629599035073, y: 55.655190220574674 },
	{ id: 263, x: 37.42781710386496, y: 55.667603533715926 },
	{ id: 264, x: 37.413014600923795, y: 55.69087845496288 },

	// Соприкосновение ЮЗАО и ЗАО
	{ id: 301, x: 37.40223449707026, y: 55.69926688479474 },
	{ id: 302, x: 37.427952060414135, y: 55.66786633327963 },
	{ id: 303, x: 37.46093250246129, y: 55.66789216048434 },
	{ id: 304, x: 37.48509384126739, y: 55.67600479839495 },
	{ id: 305, x: 37.503034134174925, y: 55.68515661227572 },
	{ id: 306, x: 37.547248423677885, y: 55.712006558711124 },
	{ id: 307, x: 37.541134499326816, y: 55.71886248866506 },
	{ id: 308, x: 37.54391326786627, y: 55.72668295735728 },
	{ id: 309, x: 37.568954371229346, y: 55.737149014228194 },
	{ id: 310, x: 37.57585408356159, y: 55.73741338726688 },
	{ id: 311, x: 37.58283002852217, y: 55.73694109751237 },
	{ id: 312, x: 37.600039031154985, y: 55.74695523810871 },
	{ id: 313, x: 37.604248112381036, y: 55.75034834354158 },

	// Граница ЗАО
	{ id: 351, x: 37.38583567103201, y: 55.71263260951002 },
	{ id: 352, x: 37.36978358356107, y: 55.7459022176333 },
	{ id: 353, x: 37.369625804424956, y: 55.783119092852594 },
	{ id: 354, x: 37.387571359437004, y: 55.806258279624444 },

	// Соприкосновение СЗАО и ЗАО
	{ id: 401, x: 37.39190927976531, y: 55.81975562806082 },
	{ id: 402, x: 37.40754206154418, y: 55.82001260186864 },
	{ id: 403, x: 37.42137239886501, y: 55.81055574501925 },
	{ id: 404, x: 37.44602899941005, y: 55.807347999163376 },
	{ id: 405, x: 37.44643730163568, y: 55.780031020456626 },
	{ id: 406, x: 37.463002374238364, y: 55.76695339631846 },
	{ id: 407, x: 37.48394481195338, y: 55.76800282892408 },
	{ id: 408, x: 37.54437145702974, y: 55.76111031434176 },
	{ id: 409, x: 37.547915066263606, y: 55.755902052201535 },
	{ id: 410, x: 37.55643685542915, y: 55.75319071842884 },
	{ id: 411, x: 37.574167079268044, y: 55.752609128234326 },
	{ id: 412, x: 37.58791236276131, y: 55.756633250008576 },
	{ id: 413, x: 37.59900926824869, y: 55.75735953309666 },

	// Граница СЗАО
	{ id: 451, x: 37.381278657348474, y: 55.82283760324913 },
	{ id: 452, x: 37.37717116725588, y: 55.830412312220425 },
	{ id: 453, x: 37.3523050335063, y: 55.84116265584535 },
	{ id: 454, x: 37.331240215958196, y: 55.84566012022249 },
	{ id: 455, x: 37.33639925025073, y: 55.857123928702656 },
	{ id: 456, x: 37.34818461467905, y: 55.86402007719956 },
	{ id: 457, x: 37.36754058043516, y: 55.856434806132725 },
	{ id: 458, x: 37.37839930803211, y: 55.844984990849504 },
	{ id: 459, x: 37.393556786250116, y: 55.851855090324975 },
	{ id: 461, x: 37.40319279947714, y: 55.86524065814136 },
	{ id: 462, x: 37.41326318022935, y: 55.871542141865866 },

	// Соприкосновение СЗАО и САО
	{ id: 501, x: 37.445578994750925, y: 55.88158513150577 },
	{ id: 502, x: 37.47635099466464, y: 55.829959968023104 },
	{ id: 503, x: 37.47288568830063, y: 55.81355847642027 },
	{ id: 504, x: 37.48604046956228, y: 55.80718708744336 },
	{ id: 505, x: 37.50759553519699, y: 55.80247105735511 },
	{ id: 506, x: 37.518822771979735, y: 55.79462494290154 },
	{ id: 507, x: 37.536056650819816, y: 55.79174467815541 },
	{ id: 508, x: 37.54532434864557, y: 55.78743440589014 },
	{ id: 509, x: 37.559045209514224, y: 55.77977987518188 },
	{ id: 510, x: 37.57722465615789, y: 55.774820581161784 },
	{ id: 511, x: 37.59075362565162, y: 55.76552563939407 },
	{ id: 512, x: 37.60126328056269, y: 55.761934172526146 },
	{ id: 513, x: 37.61403956584881, y: 55.75591189995851 },

	// Граница САО
	{ id: 551, x: 37.48312507548835, y: 55.88667537958825 },
	{ id: 552, x: 37.527987057206104, y: 55.905906104370196 },
	{ id: 553, x: 37.5770002746582, y: 55.911147974731755 },
	{ id: 554, x: 37.59103640847778, y: 55.90935576203187 },

	// Соприкосновение САО и СВАО
	{ id: 601, x: 37.64396476646261, y: 55.89712828242319 },
	{ id: 602, x: 37.61562428323613, y: 55.88428397606657 },
	{ id: 603, x: 37.61770540351464, y: 55.87361402386562 },
	{ id: 604, x: 37.63596862520864, y: 55.861713814528095 },
	{ id: 605, x: 37.631426892670014, y: 55.852013330294156 },
	{ id: 606, x: 37.63067311632491, y: 55.842115761129065 },
	{ id: 607, x: 37.61248121473577, y: 55.83594503221266 },
	{ id: 608, x: 37.60668318400658, y: 55.819304885415484 },
	{ id: 609, x: 37.608812027226605, y: 55.81359967359623 },
	{ id: 610, x: 37.62360089699696, y: 55.805500872131866 },
	{ id: 611, x: 37.62883883017323, y: 55.79245018082183 },
	{ id: 612, x: 37.62253445479049, y: 55.79283394029455 },
	{ id: 613, x: 37.62231487314805, y: 55.785383523921105 },
	{ id: 614, x: 37.62256842026936, y: 55.77938434146498 },
	{ id: 615, x: 37.61985689443878, y: 55.77558356260464 },
	{ id: 616, x: 37.62174024156118, y: 55.773586274379575 },
	{ id: 617, x: 37.62457678240676, y: 55.77343424828339 },
	{ id: 618, x: 37.62546857240523, y: 55.770576288650346 },
	{ id: 619, x: 37.62181400565047, y: 55.76709180357863 },
	{ id: 620, x: 37.6198150608961, y: 55.764696174269275 },
	{ id: 621, x: 37.62006013350188, y: 55.76173194753593 },
	{ id: 622, x: 37.62168470685295, y: 55.75762416229195 },

	// Граница СВАО
	{ id: 651, x: 37.673574295043956, y: 55.8950913574896 },
	{ id: 652, x: 37.70223113105294, y: 55.89428213035048 },
	{ id: 653, x: 37.723829689243246, y: 55.88353087451772 },

	// Соприкосновение СВАО и ВАО
	{ id: 701, x: 37.821404015892305, y: 55.83337679324017 },
	{ id: 702, x: 37.78270620050703, y: 55.82444078407765 },
	{ id: 703, x: 37.76093462534501, y: 55.81314434474922 },
	{ id: 704, x: 37.7687071796065, y: 55.80178017671679 },
	{ id: 705, x: 37.7399982931097, y: 55.79461682133721 },
	{ id: 706, x: 37.731247887712215, y: 55.792737580501274 },
	{ id: 707, x: 37.72283864265509, y: 55.78751390624509 },
	{ id: 708, x: 37.715501199997114, y: 55.78767864294544 },
	{ id: 709, x: 37.70333524050894, y: 55.785520840394724 },
	{ id: 710, x: 37.695878969749245, y: 55.782125644995844 },
	{ id: 711, x: 37.684705022144065, y: 55.78211484419465 },
	{ id: 712, x: 37.66847541527627, y: 55.77781637153822 },
	{ id: 713, x: 37.66430882351226, y: 55.77319126971711 },
	{ id: 714, x: 37.6630409191708, y: 55.76981545700645 },
	{ id: 715, x: 37.66448089354529, y: 55.76706135509423 },
	{ id: 716, x: 37.65618865844249, y: 55.763869581928276 },

	// Граница ВАО
	{ id: 751, x: 37.83897761209875, y: 55.82363355850797 },
	{ id: 752, x: 37.843089904785174, y: 55.772484313356244 },

	// Соприкосновение ВАО и ЮВАО
	{ id: 801, x: 37.84033959635531, y: 55.728860992749205 },
	{ id: 802, x: 37.80785081756755, y: 55.733685061520596 },
	{ id: 803, x: 37.75002145960679, y: 55.73186974650551 },
	{ id: 804, x: 37.709412550104986, y: 55.734900854807854 },
	{ id: 805, x: 37.67174511629996, y: 55.73897939039165 },
	{ id: 806, x: 37.67012045243705, y: 55.74699793803434 },
	{ id: 807, x: 37.66484871090077, y: 55.74829822234788 },
	{ id: 808, x: 37.66262053590291, y: 55.74986802207833 },
	{ id: 809, x: 37.65790155948796, y: 55.75039800715458 },
	{ id: 810, x: 37.64524238866331, y: 55.748848507058206 },
	{ id: 811, x: 37.64337599905178, y: 55.750719823007074 },
	{ id: 812, x: 37.64648021697988, y: 55.75194113129624 },
	{ id: 813, x: 37.64648021697988, y: 55.75194113129624 },
	{ id: 814, x: 37.64861764877194, y: 55.75581298089367 },

	// Граница ЮВАО
	{ id: 851, x: 37.839493993779264, y: 55.71854174920602 },
	{ id: 852, x: 37.85782496083466, y: 55.72098774113894 },
	{ id: 853, x: 37.882167656160036, y: 55.71894414431451 },
	{ id: 854, x: 37.918082242181356, y: 55.719070763001895 },
	{ id: 855, x: 37.940717307713975, y: 55.71793767295553 },
	{ id: 856, x: 37.952206501857205, y: 55.713180725577416 },
	{ id: 857, x: 37.94681954000948, y: 55.7096102833375 },
	{ id: 858, x: 37.94413624484882, y: 55.70514292233072 },
	{ id: 859, x: 37.940869559504996, y: 55.6978757760262 },
	{ id: 860, x: 37.91942522193315, y: 55.69946710889157 },
	{ id: 861, x: 37.9138958098001, y: 55.69662725473937 },
	{ id: 862, x: 37.895878032493684, y: 55.702003530761 },
	{ id: 863, x: 37.874562210962864, y: 55.69722769397227 },
	{ id: 864, x: 37.871412684600394, y: 55.69518057490415 },
	{ id: 865, x: 37.869537737939794, y: 55.688368967625024 },
	{ id: 866, x: 37.8634546656015, y: 55.67941533722299 },
	{ id: 867, x: 37.86498906686563, y: 55.67836282115154 },
	{ id: 868, x: 37.863218986623394, y: 55.672920815416155 },
	{ id: 869, x: 37.861888461937426, y: 55.66798051293425 },
	{ id: 870, x: 37.84249042831246, y: 55.66557757469886 },
	{ id: 871, x: 37.838884943338016, y: 55.65662635981695 },
	{ id: 872, x: 37.83188010836287, y: 55.648495240475114 },
];

const Regions: IRegion[] = [
	{ id: 1, name: "ЮАО", color: "#E2FFD188", path: [1, [100, 150], [150, 200], [200, 250]] },
	{ id: 2, name: "ЮЗАО", color: "#FFF3CD88", path: [1, [250, 200], [250, 300], [300, 350], 2] },
	{ id: 3, name: "ЗАО", color: "#D7E7FE88", path: [[350, 300], [350, 400], [400, 450], 2] },
	{ id: 4, name: "СЗАО", color: "#EED8FF88", path: [2, [450, 400], [450, 500], [500, 550]] },
	{ id: 5, name: "САО", color: "#D9FEF688", path: [[550, 500], [550, 600], [600, 650], 1, 2] },
	{ id: 6, name: "СВАО", color: "#FEDADA88", path: [1, [650, 600], [650, 700], [700, 750], 4, 3] },
	{ id: 7, name: "ВАО", color: "#E4DAFF88", path: [4, [750, 700], [750, 800], [800, 850]] },
	{ id: 8, name: "ЮВАО", color: "#FEDAF488", path: [1, 3, 4, [850, 800], [850, 900], [150, 100]] },
];

const pointMap = new Map(Points.map(point => [point.id, point]));

function addCoord(coords: Coord[], id: number) {
	const p = pointMap.get(id);

	if (p) {
		coords.push([p.y, p.x]);
	}
}

function toCoordinates(coords: Coord[], point: number | [number, number]) {
	if (typeof point === "number") {
		addCoord(coords, point);
	}
	if (Array.isArray(point)) {
		const from = point[0];
		const to = point[1];
		if (from < to) {
			for (let id = from; id <= to; id++) {
				addCoord(coords, id);
			}
		} else {
			for (let id = from; id >= to; id--) {
				addCoord(coords, id);
			}
		}
	}
	return coords;
}

function ToPolygon(region: IRegion) {
	const path = region.path.reduce(toCoordinates, []);
	return new ymaps.Polygon(
		[path],
		{
			// Подсказка
			hintContent: region.name,
		},
		{
			// Цвет заливки.
			fillColor: region.color,
		},
	);
}

export function getRegions() {
	return Regions.map(region => ({ region, polygon: ToPolygon(region) }));
}
