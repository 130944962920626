import React, { useContext } from "react";
import { observer } from "mobx-react";

import { StoreContext } from "../../context";
import { Paths } from "../../routes/paths";
import { withFetching } from "../../components/with-fetching";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { WardSectionId, WardSections } from "../../stores/wards-store";

import { ArrowBackIcon } from "../../../../../src/components/icons";
import cn from "classnames";
import { Overlay } from "../../../../../src/components/controls/overlay";
import { EmptyWardInfo } from "../../stores/wards-store/empty-ward-info";
import { Button } from "../../../../../src/components/buttons/button";
import { LightButton } from "../../../../../src/components/buttons/light-button";
import {
	BaseInfoEditSection,
	ChurchnessEditSection,
	ContactsEditSection,
	DescriptionEditSection,
	FamilyEditSection,
	HealthStatusEditSection,
	ProjectsEditSection,
} from "./ward-item/edit-sections";
import { generatePath } from "react-router";
import { UserPhotoUploader } from "../components/user-photo-uploader";

interface WardEntryProps extends RouteComponentProps {
	item: EmptyWardInfo;
}

const WardEntryEditForm = observer(({ item }: { item: EmptyWardInfo }) => {
	const form = item.form!;

	switch (item.currentSectionId) {
		case WardSectionId.BaseInfo:
			return (
				<>
					<div className="scope-card-user-photo">
						<UserPhotoUploader
							fileUrl={item.photoUrl}
							onUpload={item.uploadPhoto}
							onDelete={item.deletePhoto}
						/>
					</div>
					<BaseInfoEditSection form={form} />
				</>
			);

		case WardSectionId.Contacts:
			return <ContactsEditSection form={form} />;
		case WardSectionId.Description:
			return <DescriptionEditSection form={form} />;
		case WardSectionId.Churchness:
			return <ChurchnessEditSection form={form} />;
		case WardSectionId.Projects:
			return <ProjectsEditSection form={form} />;
		case WardSectionId.Family:
			return <FamilyEditSection form={form} />;
		case WardSectionId.HealthStatus:
			return <HealthStatusEditSection form={form} />;
		default:
			return null;
	}
});

const WardEntryContent = withRouter(
	observer(({ item, history }: WardEntryProps) => {
		function handleCancel() {
			history.push(Paths.Wards);
		}

		async function handleSave() {
			const scope = await item.save();
			if (scope) {
				history.push(generatePath(Paths.WardItem, { id: scope.id }));
			}
		}

		return (
			<>
				<WardEntryEditForm item={item} />
				<div className="ward-edit-actions">
					<Button className="button button--primary" onClick={handleSave}>
						Сохранить
					</Button>
					<LightButton onClick={handleCancel}>Отмена</LightButton>
				</div>
			</>
		);
	}),
);

const WardEntry = withRouter(
	observer(({ item, history }: WardEntryProps) => {
		function onBack() {
			history.push(Paths.Wards);
		}

		const handleChangeSection = (sectionId: number) => () => {
			item.updateCurrentSection(sectionId);
		};

		return (
			<div className="reference-create-card">
				<div className="reference-create-card__content">
					<div className="reference-create-card__sections sidebar">
						<div className="back-button-wrapper">
							<button className="back-button" onClick={onBack}>
								<span className="back-button__icon">
									<ArrowBackIcon />
								</span>
								<span>Назад</span>
							</button>
						</div>

						{WardSections.map(section => (
							<div
								className={cn("sidebar__link", {
									"sidebar__link--active": section.id === item.currentSectionId,
								})}
								onClick={handleChangeSection(section.id)}
								key={section.id}
							>
								{section.title}
							</div>
						))}
					</div>
					<div className="reference-create-card__section">
						<header className="reference-create-card__title">Новый подопечный</header>
						<WardEntryContent item={item} />
					</div>
				</div>
				{item.fetching && <Overlay global={false} globalWait />}
			</div>
		);
	}),
);

export const WardItemContent = observer(function() {
	const { wardsStore: store } = useContext(StoreContext);

	return <div className="page__content">{store.emptyWard !== null && <WardEntry item={store.emptyWard} />}</div>;
});

const ContentWithFetching = withFetching(WardItemContent);

export const WardItemCreate = observer(function({ children }: { children?: React.ReactNode }) {
	const { wardsStore } = useContext(StoreContext);
	const [initialized, setInitialized] = React.useState(false);

	async function fetch() {
		await wardsStore.fetchEmptyWard();
		setInitialized(true);
	}

	return (
		<ContentWithFetching fetch={fetch} initialized={initialized} path={Paths.Wards}>
			{children}
		</ContentWithFetching>
	);
});
