import { IDataSource, IDataSourceSession } from "@app-types/data";
import { action, computed } from "mobx";
import { XPaginationData } from "@external-types/lists/pagination-data";

export abstract class InfiniteDataSource<TItem> implements IDataSource<TItem> {
	protected abstract get session(): IDataSourceSession<TItem>;

	public get loading() {
		return this.session.loading;
	}

	public get loadingNext() {
		return this.session.loadingNext;
	}

	public get pagination() {
		return this.session.pagination;
	}

	public get filter() {
		return this.session.filter;
	}

	@action
	public applyFilter(filterId: string, value: any) {
		this.filter.apply(filterId, value);

		return this.fetchPage();
	}

	@computed
	public get hasMore() {
		return this.pagination.currentPage < this.pagination.pageCount;
	}

	public get items() {
		return this.session.items;
	}

	protected abstract fetch(): Promise<TItem[]>;

	public async fetchNextPage(): Promise<any> {
		try {
			if (this.loading || this.loadingNext) {
				return;
			}

			this.setLoadingNext(true);
			this.updatePagination(this.pagination.currentPage + 1);

			const items = await this.fetch();
			this.updateItems(this.session.items.concat(items));
		} finally {
			this.setLoadingNext(false);
		}
	}

	public async fetchPage(pageNumber: number = 1, pageSize: number | null = null): Promise<any> {
		try {
			if (this.loading) {
				return;
			}

			this.setLoading(true);
			this.updatePagination(pageNumber, pageSize);

			const items = await this.fetch();
			this.updateItems(items);
		} finally {
			this.setLoading(false);
		}
	}

	@action
	public onPageChange(pageNumber: number, pageSize: number): Promise<any> {
		return this.fetchPage(pageNumber, pageSize);
	}

	@action
	public updatePageInfo(pageInfo: XPaginationData<any, any, any>) {
		this.pagination.offset = pageInfo.pagination.offset;
		this.pagination.pageSize = pageInfo.pagination.pagesize;
		this.pagination.total = pageInfo.pagination.total;

		this.filter.update(pageInfo.filters, pageInfo.available);
	}

	@action
	private updateItems(items: TItem[]) {
		this.session.items = items;
	}

	@action
	private updatePagination(pageNumber: number, pageSize: number | null = null) {
		if (pageSize !== null) {
			this.pagination.pageSize = pageSize;
		}

		this.pagination.offset = (pageNumber - 1) * this.pagination.pageSize;
	}

	@action
	private setLoading(loading: boolean) {
		this.session.loading = loading;
	}

	@action
	private setLoadingNext(loading: boolean) {
		this.session.loadingNext = loading;
	}
}
