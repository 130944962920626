import fileDownload from "js-file-download";
import { actions } from "@actions";
import { XFileRef } from "@external-types/file";

export async function downloadScopeFile(scopeId: number, file: XFileRef) {
	const data = await actions.getScopeFileContent(scopeId, file.id);

	fileDownload(data, file.filename, file.mimetype);
}

export async function downloadTaskFile(taskId: number, file: XFileRef) {
	const data = await actions.getTaskFileContent(taskId, file.id);

	fileDownload(data, file.filename, file.mimetype);
}
