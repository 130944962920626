import React from "react";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import { XProcessRef } from "@external-types/process";
import { isExpired } from "@helpers/datetime";
import { TaskItemAction } from "./task-item-action";
import { TaskActions } from "./task-actions";
import { CallItem } from "../../../stores/call-center-store/call-item";

export const TaskState = observer(({ className, task }: { className?: string; task: XProcessRef }) => {
	return (
		<div
			className={cn(className, "task-state", {
				"task-state--expired": isExpired(task.expire),
				"task-state--completed": task.is_finish,
			})}
		>
			{task.state}
		</div>
	);
});

interface Props {
	item: CallItem;
}

export const TaskContent = observer(({ item }: Props) => {
	const task = item.task;

	return <div>{task !== null && <TaskItemAction item={task} />}</div>;
});

export const Task = observer(function({ item }: Props) {
	if (!item) {
		return <div>Ничего не загружено</div>;
	}

	return (
		<>
			<TaskContent item={item} />
			<TaskActions item={item} />
		</>
	);
});
