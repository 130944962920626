import React from "react";
import { OptionItem } from "@app-types/models";
import { InfiniteDataList } from "@app-components/infinite-data-list";
import { IDataSource } from "@app-types/data";

interface Props<T> {
	dataSource: IDataSource<OptionItem<T>>;
	selected?: T[];
	onChange?(selected: T[]): void;
	className?: string;
	single?: boolean;
}

export function CheckBoxListWithPaging<T extends string | number>({
	dataSource,
	selected = [],
	onChange,
	className,
	single,
}: Props<T>) {
	function handleChange(item: OptionItem<T>) {
		return function(e: React.FormEvent<HTMLInputElement>) {
			if (onChange) {
				if (single) {
					if (e.currentTarget.checked) {
						onChange([item.value]);
					} else {
						onChange(selected.filter(x => x !== item.value));
					}
				} else {
					if (e.currentTarget.checked) {
						onChange(selected.concat(item.value));
					} else {
						onChange(selected.filter(x => x !== item.value));
					}
				}
			}
		};
	}

	return (
		<div className={className}>
			<InfiniteDataList
				store={dataSource}
				EntryComponent={({ entry }) => {
					return (
						<tr>
							<td>
								<label className="form-checkbox" key={String(entry.value)}>
									{entry.label}
									<input
										type="checkbox"
										onChange={handleChange(entry)}
										checked={selected.includes(entry.value)}
									/>
									<span className="form-checkbox__check" />
								</label>
							</td>
						</tr>
					);
				}}
				createKey={x => x.value}
				useInfiniteFetch={false}
			/>
		</div>
	);
}
