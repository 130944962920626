import { computed, observable, runInAction } from "mobx";

import { actions } from "@actions";
import { XScopeCard, XScopeChildInfo } from "@external-types/scope";
import { ISchemaForm } from "@app-models/schema-form";

import { ReferenceRootSection } from "./reference-root-section";
import { ReferenceChildSection } from "./reference-child-section";
import { IReferenceScopeSection, NameFieldId } from "./types";

export function mapAttrValueToFormField(scope: XScopeCard | XScopeChildInfo, form: ISchemaForm) {
	form.fields.forEach(field => {
		const attr = scope.attrs.find(attr => attr.attr === field.id);

		if (attr) {
			field.value = attr.value;
		} else {
			field.reset();
		}
	});

	const field = form.fields.find(field => field.id === NameFieldId);

	if (field != null) {
		field.value = scope.name;
	}
}

export function mapAttrToScopeAttrId(
	attrs: Array<{ attr: number; value: string }>,
	scope: XScopeCard | XScopeChildInfo,
) {
	return attrs.map(attr => {
		const scopeAttr = scope.attrs.find(scopeAttr => scopeAttr.attr === attr.attr);

		return {
			...attr,
			...(scopeAttr != null && { id: scopeAttr.id }),
		};
	});
}

export class ReferenceScope {
	@observable public activeSectionId: number = 0;
	@observable.ref public sections: IReferenceScopeSection[] = [];
	@observable public fetched: boolean = false;
	private scope: XScopeCard | null = null;

	public constructor(public readonly scopeId: number) {}

	public get name() {
		return this.scope?.name ?? "";
	}

	public async fetch() {
		const scope = await actions.getScope(this.scopeId);
		const rootType = await actions.getTypeSchema(scope.type.id);
		const rootSection = new ReferenceRootSection(rootType, null, scope);
		const children = await actions.getScopeChildren(this.scopeId);

		await rootSection.fetch();

		this.scope = scope;

		runInAction(() => {
			const sections: IReferenceScopeSection[] = [rootSection];

			this.sections = sections.concat(
				rootType.types.map(appTypeRef => {
					const child = children.find(child => child.type.id === appTypeRef.id);

					return new ReferenceChildSection(rootSection, appTypeRef, child ?? null);
				}),
			);
			this.activeSectionId = rootSection.id;
			this.fetched = true;
		});
	}

	@computed
	public get activeSection() {
		return this.findSectionById(this.activeSectionId);
	}

	public updateActiveSectionId = async (sectionId: number) => {
		const activeSection = this.activeSection;

		if (activeSection !== null && activeSection.validate()) {
			await activeSection.update();

			const nextActiveSection = this.findSectionById(sectionId);

			if (nextActiveSection !== null && !nextActiveSection.fetched) {
				await nextActiveSection.fetch();
			}
			runInAction(() => {
				this.activeSectionId = sectionId;
			});
		}
	};

	private findSectionById(sectionId: number) {
		const activeSection = this.sections.find(section => section.id === sectionId);

		return activeSection ?? null;
	}
}
