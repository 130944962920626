import { action, computed, observable, reaction, runInAction } from "mobx";
import { actions } from "@actions";

import { getDefaultExpireTimestamp, isExpired } from "@helpers/datetime";
import { XUserRef } from "@external-types/user";
import { XScopeCard } from "@external-types/scope";
import {
	XActionType,
	XHistoryItem,
	XProcessRef,
	XProcessTaskInfo,
	XTaskAction,
	XTaskValueInfo,
} from "@external-types/process";
import { XSchemaFormItemInfo } from "@external-types/schema";
import { XSubprocessType } from "@external-types/schema/schema-form-info";
import { XNextTaskRef } from "@external-types/process/next-task-ref";
import { SchemaFieldType, SchemaForm } from "@app-models/schema-form";
import { XAppAttributeRef } from "../../../../../src/types/external-data/type";
import { ProcessFieldValue } from "../../constants/process-field-value";
import { DataSourceSession, Filters, InfiniteDataSource } from "../../../../../src/models/data-source";
import { OptionItem } from "../../../../../src/types/models";
import { IDataSource, IDataSourceSession } from "../../../../../src/types/data";

export interface ITask {
	actionDeadlineTimestamp: number | null;
	actionExecutors: XUserRef[];
	actionIsExecuting: boolean;
	actionOptions: XTaskAction[];
	actions: XTaskAction[];
	scopeDataSource: IDataSource<OptionItem<number>>;
	form: TaskForm;
	selectedActionScopes: number[];
	selectedExecutors: number[];
	submitAllowed: boolean;
	actionId: number;
	pid: number;

	isActionHasExecutors(action: XTaskAction): boolean;
	isActionSubprocessRequired(actionId: number): boolean;
	runAction(callback?: (nextTaskRef: XNextTaskRef) => void): Promise<any>;
	updateActionId(actionId: number): void;
	updateActionScopes(actionScopes: number[]): void;
	updateDeadlineTimestamp(timestamp: number): void;
	updateExecutors(executors: number[]): void;
}

export enum CallTaskFormType {
	None = 0,
	SimpleCall = 1,
	LawyerCall = 2,
	Request = 3,
	Transportation = 4,
	VolunteerRegion = 5,
}

const lawyerOptionId = "96";
const transportationOptionId = "4";
const volunteerRegionOptionId = "20";
const callOptionId = "1";
const requestOptionId = "2";

const SimpleCallFields = [
	ProcessFieldValue.CallType,
	ProcessFieldValue.CallTopic,
	ProcessFieldValue.AppealMessage,
	ProcessFieldValue.AnswerMessage,
];

const RequestFields = SimpleCallFields.concat([
	ProcessFieldValue.RequestTopic,
	ProcessFieldValue.HasRelatedRequest,
	ProcessFieldValue.UrgentRequest,
]);

const VolunteerRegionFields = RequestFields.concat([ProcessFieldValue.Eparchy]);

const LawyerCallFields = SimpleCallFields.concat([
	ProcessFieldValue.ChooseLawyer,
	ProcessFieldValue.LawyerDate,
	ProcessFieldValue.LawyerTime,
]);

const TransportationRequestFields = SimpleCallFields.concat([
	ProcessFieldValue.RequestTopic,
	ProcessFieldValue.AddressFrom,
	ProcessFieldValue.AddressTo,
	ProcessFieldValue.Date,
	ProcessFieldValue.Time,
]);

export class TaskForm extends SchemaForm {
	public constructor(items: Array<XSchemaFormItemInfo | XAppAttributeRef | XTaskValueInfo>) {
		super(items);

		const typeField = this.getField(ProcessFieldValue.CallType);

		if (typeField && !typeField.value) {
			typeField.value = typeField.options[0].value;
		}
	}

	@computed
	public get type() {
		const typeField = this.getField(ProcessFieldValue.CallType);

		if (typeField) {
			if (typeField.value === callOptionId) {
				// Звонок
				const topicField = this.getField(ProcessFieldValue.CallTopic);

				if (topicField && topicField.value === lawyerOptionId) {
					return CallTaskFormType.LawyerCall;
				}

				return CallTaskFormType.SimpleCall;
			} else if (typeField.value === requestOptionId) {
				const topicField = this.getField(ProcessFieldValue.RequestTopic);

				if (topicField && topicField.value === transportationOptionId) {
					return CallTaskFormType.Transportation;
				}

				const callTopicField = this.getField(ProcessFieldValue.CallTopic);

				if (callTopicField && callTopicField.value === volunteerRegionOptionId) {
					return CallTaskFormType.VolunteerRegion;
				}

				// Просьба
				return CallTaskFormType.Request;
			}

			return CallTaskFormType.None;
		}
	}

	@computed
	public get visibleFields() {
		switch (this.type) {
			case CallTaskFormType.LawyerCall:
				return LawyerCallFields;
			case CallTaskFormType.Request:
				return RequestFields;
			case CallTaskFormType.Transportation:
				return TransportationRequestFields;
			case CallTaskFormType.SimpleCall:
				return SimpleCallFields;
			case CallTaskFormType.VolunteerRegion:
				return VolunteerRegionFields;
			default:
				return this.fields.map(f => f.id);
		}
	}
}

class TaskScopeDataSourceSession extends DataSourceSession<OptionItem<number>> {
	public filter = new Filters(true);
}

class ScopeDataSource extends InfiniteDataSource<OptionItem<number>> {
	protected session: IDataSourceSession<OptionItem<number>>;
	@observable public taskId: number;
	@observable public actionId: number | null = null;

	public constructor(taskId: number) {
		super();

		this.taskId = taskId;
		this.session = new TaskScopeDataSourceSession();
	}

	protected async fetch(): Promise<Array<OptionItem<number>>> {
		if (this.actionId) {
			const pageInfo = await actions.getTaskScopes(this.taskId, this.actionId, this.pagination, this.filter);

			this.updatePageInfo(pageInfo);

			return pageInfo.list.map(x => ({ label: x.name, value: x.id }));
		}

		return [];
	}

	public fetchByAction(actionId: number) {
		this.actionId = actionId;
		this.fetchPage();
	}

	@action
	public clear() {
		this.session.items = [];
	}
}

export class Task implements ITask {
	public id!: number;
	public name!: string;
	public schema!: string;
	public expire!: number;
	public initiator!: XUserRef;
	public completed!: boolean;
	public expired!: boolean;
	public scope!: XScopeCard;
	public pid!: number;

	@observable public state!: string;

	@observable.ref public actions: XTaskAction[] = [];
	@observable.ref public actionOptions: XTaskAction[] = [];
	@observable.ref public history: XHistoryItem[] = [];
	@observable.ref public form!: TaskForm;
	@observable public actionId: number = -1;
	@observable public isInfoVisible: boolean = false;
	public scopeDataSource: ScopeDataSource;

	@observable.ref public actionExecutors: XUserRef[] = [];
	@observable public actionDeadlineTimestamp: number | null = null;
	@observable public selectedExecutors: number[] = [];
	@observable public actionIsExecuting: boolean = false;
	@observable public selectedActionScopes: number[] = [];

	public constructor(taskInfo: XProcessTaskInfo, private mergeBaseHistory = true) {
		this.scopeDataSource = new ScopeDataSource(taskInfo.id);
		this.updateByTaskInfo(taskInfo);
		reaction(
			() => this.actionId,
			(actionId: number) => {
				if (actionId === -1) {
					this.actionExecutors = [];
					this.scopeDataSource.clear();
				} else {
					const action = this.actions.find(x => x.actionID === actionId)!;

					if (this.isActionHasExecutors(action)) {
						actions.getAllTaskActionExecutors(this.id, actionId).then(result => {
							runInAction(() => {
								this.actionExecutors = result;
							});
						});
						this.actionDeadlineTimestamp = getDefaultExpireTimestamp(action.duration);
					} else {
						this.actionExecutors = [];
					}

					if (this.isActionSubprocessRequired(actionId)) {
						actions.getTaskScopes(this.id, actionId).then(() => {
							runInAction(() => {
								this.scopeDataSource.fetchByAction(actionId);
							});
						});
					} else {
						this.scopeDataSource.clear();
					}
				}

				this.selectedExecutors = [];
			},
		);
	}

	@action
	public updateExecutors(executors: number[]) {
		this.selectedExecutors = executors;
	}

	@action
	public updateActionScopes(actionScopes: number[]) {
		this.selectedActionScopes = actionScopes;
	}

	@action
	public async toggleInfoVisible() {
		await this.fetchScope();

		runInAction(() => {
			this.isInfoVisible = !this.isInfoVisible;
		});
	}

	@action
	public updateDeadlineTimestamp(timestamp: number) {
		this.actionDeadlineTimestamp = timestamp;
	}

	@action
	public updateActionIsExecuting(isExecuting: boolean) {
		this.actionIsExecuting = isExecuting;
	}

	public isActionHasExecutors(action: XTaskAction) {
		return action.type === XActionType.Assigned || action.type === XActionType.Parallel;
	}

	public isActionSubprocessRequired(actionId: number) {
		const action = this.actions.find(action => action.actionID === actionId);

		if (action != null) {
			return action.type === XActionType.Subprocess && action.subprocess.type !== XSubprocessType.All;
		}

		return false;
	}

	@action
	private updateByProcessRef(processRef: XProcessRef) {
		const expired = isExpired(processRef.expire);

		this.id = processRef.id;
		this.pid = processRef.pid;
		this.name = processRef.name;
		this.schema = processRef.schema;
		this.state = processRef.state;
		this.expire = processRef.expire;
		this.completed = processRef.is_finish && !expired;
		this.expired = !processRef.is_finish && expired;
		this.initiator = processRef.initiator;
		this.scope = processRef.scope as XScopeCard;
	}

	private async fetchScope() {
		const scope = await actions.getScope(this.scope.id);

		runInAction(() => {
			this.scope = scope;
		});
	}

	public async substitute(id: number) {
		runInAction(() => {
			this.id = id;
			this.actionId = -1;
		});

		this.fetch();
	}

	public async runAction(callback?: (nextTaskRef: XNextTaskRef) => void) {
		if (this.form.validate()) {
			const nextTaskRef = await this.runActionInternal();

			if (callback) {
				callback(nextTaskRef);
			}
		}
	}

	@action
	public updateByTaskInfo(taskInfo: XProcessTaskInfo) {
		this.updateByProcessRef(taskInfo);
		this.actions = taskInfo.actions;
		this.actionOptions = taskInfo.actions;

		this.createForm(taskInfo.items);
		this.history = taskInfo.history;

		if (this.mergeBaseHistory) {
			const baseHistory = taskInfo.base ? taskInfo.base.history : [];
			this.history = this.history.concat(baseHistory);
		}
	}

	public async fetch() {
		const taskInfo = await actions.getTask(this.id);

		this.updateByTaskInfo(taskInfo);
	}

	private async runActionInternal(): Promise<XNextTaskRef> {
		const action = this.actions.find(x => x.actionID === this.actionId)!;
		const hasExecutors = this.isActionHasExecutors(action);
		const subprocessRequired = this.isActionSubprocessRequired(this.actionId);

		const data = {
			items: this.form.fields
				.filter(f => f.type !== SchemaFieldType.Label)
				.map(x => ({ id: x.id, value: x.value || undefined, files: x.files })),
			accounts: hasExecutors ? this.selectedExecutors : undefined,
			scopes: subprocessRequired ? this.selectedActionScopes : undefined,
			expire: this.actionDeadlineTimestamp != null ? this.actionDeadlineTimestamp : undefined,
			// TODO: should be selected on UI
			subform:
				subprocessRequired && action.subprocess.forms.length > 0 ? action.subprocess.forms[0].id : undefined,
		};

		try {
			this.updateActionIsExecuting(true);
			return await actions.runTaskAction(this.id, this.actionId, data);
		} finally {
			this.updateActionIsExecuting(false);
		}
	}

	@action
	public updateActionId(actionId: number) {
		this.actionId = actionId;
		// this.detailsVisible = false;
	}

	@computed
	public get submitAllowed() {
		const action = this.actions.find(x => x.actionID === this.actionId);

		if (action) {
			if (action.type === XActionType.Parallel && this.selectedExecutors.length !== action.parallel.count) {
				return false;
			}
			if (action.type === XActionType.Assigned && this.selectedExecutors.length === 0) {
				return false;
			}
			if (
				action.type === XActionType.Subprocess &&
				action.subprocess.type !== XSubprocessType.All &&
				this.selectedActionScopes.length === 0
			) {
				return false;
			}
		}
		return true;
	}

	@action
	protected createForm(items: XSchemaFormItemInfo[]) {
		this.form = new TaskForm(items);

		this.form.subscribeChange(data => {
			if (data.id === ProcessFieldValue.CallType) {
				this.updateActionId(-1);
			}
		});
	}
}
