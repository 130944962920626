import React, { useContext } from "react";
import { generatePath, RouteComponentProps, useParams, withRouter } from "react-router";
import { observer } from "mobx-react";
import cn from "classnames";

import { ArrowBackIcon, PlusIcon } from "@icons";
import { Overlay } from "@app-components/controls/overlay";
import { SchemaForm } from "@app-components/schema-form";
import { Button } from "@app-components/buttons/button";

import { StoreContext } from "../../context";
import { Paths } from "../../routes/paths";
import { NewReferenceScope } from "../../stores/reference-store";
import { ReferenceWrapper } from "./reference-wrapper";
import { CircleButton } from "../../../../../src/components/buttons/circle-button";
import { ReferenceCollection } from "./reference-item";

export const ReferenceItemCreationContent = observer(
	({ typeSchema, onBack, scopeName }: { typeSchema: NewReferenceScope; onBack: () => void; scopeName: string }) => {
		async function handleSave() {
			const activeSection = typeSchema.activeSection;

			if (activeSection && activeSection.validate()) {
				await activeSection.update();

				onBack();
			}
		}

		function handleAppTypeChange(appTypeId: number) {
			return () => {
				typeSchema.updateActiveSectionId(appTypeId);
			};
		}

		if (typeSchema.sections.length === 0) {
			return (
				<div className="reference-create-card">
					<div className="reference-create-card__content">
						<div className="reference-create-card__sections sidebar">
							<div className="back-button-wrapper">
								<button className="back-button" onClick={onBack}>
									<span className="back-button__icon">
										<ArrowBackIcon />
									</span>
									<span>Назад</span>
								</button>
							</div>

							<div className="sidebar__link sidebar__link--active">Основная информация</div>
						</div>
						<div className="reference-create-card__section">
							<header className="reference-create-card__title">
								Добавление в справочник {scopeName}
							</header>
							Нет типов данных
							<div className="reference-create-card__navigation">
								<Button className="button button--secondary" onClick={onBack}>
									Отмена
								</Button>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className="reference-create-card">
				<div className="reference-create-card__content">
					<div className="reference-create-card__sections sidebar">
						<div className="back-button-wrapper">
							<button className="back-button" onClick={onBack}>
								<span className="back-button__icon">
									<ArrowBackIcon />
								</span>
								<span>Назад</span>
							</button>
						</div>

						{typeSchema.sections.map(x => (
							<div
								key={x.id}
								className={cn("sidebar__link", {
									"sidebar__link--active": x.id === typeSchema.activeSectionId,
								})}
								onClick={handleAppTypeChange(x.id)}
							>
								{x.name}
							</div>
						))}
					</div>
					<div className="reference-create-card__section">
						<header className="reference-create-card__title">Добавление в справочник {scopeName}</header>
						{typeSchema.activeSection && (
							<>
								{typeSchema.activeSection.form && <SchemaForm form={typeSchema.activeSection.form} />}
								{typeSchema.activeSection.children !== null && (
									<>
										<CircleButton onClick={typeSchema.activeSection.children.add} Icon={PlusIcon} />
										<ReferenceCollection collection={typeSchema.activeSection.children} />
									</>
								)}
							</>
						)}
						<div className="reference-create-card__navigation">
							<Button className="button button--primary" onClick={handleSave}>
								Добавить
							</Button>

							<Button className="button button--secondary" onClick={onBack}>
								Отмена
							</Button>
						</div>
					</div>
				</div>
			</div>
		);
	},
);

interface Props extends RouteComponentProps {}

export const ReferenceItemCreation = withRouter(
	observer(({ history }: Props) => {
		const { referenceId } = useParams<{ referenceId: string }>();
		const { referenceStore } = useContext(StoreContext);

		const activeScope = referenceStore.activeScope;

		React.useEffect(() => {
			if (activeScope && !activeScope?.typeSchema.fetched) {
				activeScope.typeSchema.fetch();
			}
		}, [activeScope, activeScope?.typeSchema.fetched]);

		function handleBack() {
			history.push(generatePath(Paths.ReferenceList, { referenceId }));
		}

		return (
			<ReferenceWrapper>
				<div className="page__content">
					{activeScope?.typeSchema.fetched === true ? (
						<ReferenceItemCreationContent
							onBack={handleBack}
							typeSchema={activeScope.typeSchema}
							scopeName={referenceStore.activeScope?.name ?? ""}
						/>
					) : (
						<Overlay global={false} globalWait />
					)}
				</div>
			</ReferenceWrapper>
		);
	}),
);
