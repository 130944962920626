import React from "react";
import { SchemaFormFieldRow } from "@app-components/schema-form";
import { SchemaForm } from "@app-models/schema-form";

import { WardAttributes } from "../../../../constants/ward-attributes";

interface Props {
	form: SchemaForm;
}

export const ChurchnessEditSection = ({ form }: Props) => {
	return (
		<>
			<SchemaFormFieldRow field={form.getField(WardAttributes.Religion)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.OtherReligion)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.NameInChristening)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.AngelDate)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.ParishChurch)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.PriestRequired)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.ChurchinessInfo)!} />
		</>
	);
};
