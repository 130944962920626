import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { RouteComponentProps, withRouter } from "react-router";
import { bem } from "@helpers/bem";
import { AppStoreContext } from "@shared/app-store-context";

interface Props extends RouteComponentProps {
	path?: string;
}

export const AppHeaderMenu = withRouter(
	observer(function({ history, path }: Props) {
		const { appStore } = useContext(AppStoreContext);
		const sections = appStore.userSections;

		function handleClick(path: string) {
			return function() {
				history.push(path);
			};
		}

		const cnb = bem("header-menu");
		const activePath = path ?? history.location.pathname;

		return (
			<div className={cnb.classNames()}>
				{sections!.map(section => (
					<div
						key={section.path}
						className={cnb.em("item", { active: activePath === section.path })}
						onClick={handleClick(section.path)}
					>
						{section.title}
					</div>
				))}
			</div>
		);
	}),
);
