import React from "react";
import cn from "classnames";
import { TabGroup } from "@app-models/tabbed-control";

export function TabGroupItem({
	tab,
	onClick,
	activeKey,
}: {
	tab: TabGroup;
	activeKey: string | number;
	onClick: (key: string | number) => void;
}) {
	if (!tab.groupName) {
		return null;
	}

	function handleClick() {
		if (!tab.disabled) {
			onClick(tab.key);
		}
	}

	const subTabs = (tab.items as TabGroup[]).filter(sub => sub.groupName);

	return (
		<>
			<div
				key={tab.key}
				onClick={handleClick}
				className={cn("org-panel-tab", {
					"org-panel-tab--active": tab.key === activeKey,
					"org-panel-tab--disabled": tab.disabled,
				})}
			>
				{tab.groupName}
			</div>

			{subTabs.length > 0 && (
				<div className="org-panel-sub-tabs">
					{subTabs.map(tab => (
						<TabGroupItem key={tab.key} tab={tab} onClick={onClick} activeKey={activeKey} />
					))}
				</div>
			)}
		</>
	);
}
