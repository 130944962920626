import React, { useContext } from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import { generatePath, RouteComponentProps, withRouter } from "react-router";

import { InfiniteDataList } from "@app-components/infinite-data-list";
import { Button } from "@app-components/buttons/button";
import { FilterToolbar } from "@app-components/filters/filter-toolbar";
import { IconButton } from "@app-components/buttons/icon-button";
import { FilterIcon } from "@app-components/icons";
import { Overlay } from "@app-components/controls/overlay";
import { useDownloadExcel } from "@shared/hooks/use-download-excel";
import { actions } from "@actions";

import { PageHeader } from "../../components/page-header";
import { withFetching } from "../../components/with-fetching";
import { StoreContext } from "../../context";
import { Paths } from "../../routes/paths";
import { Volunteer } from "../../stores/volunteers-store";
import { VolunteerItem } from "./volunteer-item";
import { VolunteerCreate } from "./volunteer-create";
import { VolunteerBaseDetails } from "./volunteer-base-details";
import { StatusIcon } from "./components/status-icon";
import { FilterSidebar } from "../../components/filter-sidebar";
import { Scope } from "../../constants/scope";

export { VolunteerItem, VolunteerCreate };

interface EntryComponentProps extends RouteComponentProps {
	entry: Volunteer;
}

const captions = ["ФИО", "Проект", "Телефон", "Email", "Округ, метро", "Статус"];

interface EntryComponentProps extends RouteComponentProps {
	entry: Volunteer;
	isActive: boolean;
	isNotActive: boolean;
	onClick: (entryId: number) => void;
	children?: React.ReactNode;
}

const GridEntry = withRouter(
	observer(({ entry, isActive, isNotActive, children, onClick }: EntryComponentProps) => {
		function handleClick() {
			onClick(entry.id);
		}

		return (
			<>
				<tr
					className={cn("data-grid__row ward-row", {
						"ward-row--inactive": isNotActive,
						"ward-row--active": isActive,
					})}
					onClick={handleClick}
				>
					<td className={"data-grid__cell"}>
						<span className="call-grid-cell-content" title={entry.fullName}>
							{entry.fullName}
						</span>
					</td>
					<td className="data-grid__cell">
						<span className="call-grid-cell-content" title={entry.projects}>
							{entry.projects}
						</span>
					</td>
					<td className="data-grid__cell">
						<span className="call-grid-cell-content" title={entry.phone}>
							{entry.phone}
						</span>
					</td>
					<td className="data-grid__cell">
						<span className="call-grid-cell-content" title={entry.email}>
							{entry.email}
						</span>
					</td>
					<td className="data-grid__cell">
						<span className="call-grid-cell-content" title={entry.location}>
							{entry.location}
						</span>
					</td>
					<td className="data-grid__cell">
						<span className="call-grid-cell-content" title={entry.status}>
							<span className="status-content">
								<StatusIcon status={entry.status} />
								{entry.status}
							</span>
						</span>
					</td>
				</tr>
				{isActive && (
					<tr className="call-center-row-details">
						<td colSpan={captions.length} className="ward-details-cell">
							{children}
						</td>
					</tr>
				)}
			</>
		);
	}),
);

const GridEntryWithStore = observer(({ entry }: { entry: Volunteer }) => {
	const { volunteersStore } = useContext(StoreContext);

	function handleClick(entryId: number) {
		volunteersStore.toggleSelect(entryId);
	}

	const isActive = entry.id === volunteersStore.selectedItemId;
	const isNotActive = volunteersStore.selectedItemId !== -1 && !isActive;

	return (
		<GridEntry entry={entry} isActive={isActive} isNotActive={isNotActive} onClick={handleClick}>
			<VolunteerBaseDetails entry={entry} />
		</GridEntry>
	);
});

function Header() {
	return (
		<thead>
			<tr className="data-grid__header">
				{captions.map((caption, index) => (
					<th
						className={cn("data-grid__header-cell", {
							"data-grid__header-cell--10": index === captions.length - 1,
						})}
						key={index}
					>
						{caption}
					</th>
				))}
			</tr>
		</thead>
	);
}

function VolunteersContent() {
	const { volunteersStore: store } = useContext(StoreContext);
	const [sidebarFilterVisible, setSidebarFilterVisibility] = React.useState(false);
	const [downloadInProgress, download] = useDownloadExcel();

	function handleSidebarFilter() {
		setSidebarFilterVisibility(!sidebarFilterVisible);
	}

	function handleCreate() {
		window.open(generatePath(Paths.VolunteerCreate), "_blank");
	}

	function handleFilterApply(filterId: string, value: any) {
		store.applyFilter(filterId, value);
	}

	function handleDownload() {
		download(() => actions.getScopeExportContent(Scope.ProjectsAndVolunteers));
	}

	return (
		<>
			<div className="page__content">
				<PageHeader title="Добровольцы">
					<div className="filter-toolbar">
						<FilterToolbar
							filters={store.filter}
							onApply={handleFilterApply}
							hiddenFilters={["type", "orderBy", "attr", "value"]}
						/>
					</div>
					<div className="page__actions">
						<button onClick={handleDownload} type="button" className="button button--primary">
							Выгрузить список
						</button>
						<Button className="button button--primary" onClick={handleCreate}>
							Добавить
						</Button>
					</div>
					<div className="filter-sidebar-action">
						<IconButton
							Icon={FilterIcon}
							onClick={handleSidebarFilter}
							iconClassName={cn("filter-sidebar-icon", {
								"filter-sidebar-icon--active": sidebarFilterVisible,
							})}
						/>
					</div>
				</PageHeader>
				<InfiniteDataList
					store={store}
					EntryComponent={GridEntryWithStore}
					createKey={entry => entry.id}
					captions={captions}
					pagerPosition="top"
					Header={<Header />}
				/>
			</div>
			{sidebarFilterVisible && <FilterSidebar filters={store.filter} onApply={handleFilterApply} />}
			{downloadInProgress && <Overlay global={false} />}
		</>
	);
}

const ContentWithFetching = withFetching(VolunteersContent);

export const Volunteers = observer(function() {
	const { volunteersStore } = useContext(StoreContext);
	const [initialized, setInitialized] = React.useState(false);

	async function fetch() {
		await volunteersStore.fetchPage(1, 50);
		setInitialized(true);
	}

	return <ContentWithFetching fetch={fetch} initialized={initialized} path={Paths.Volunteers} />;
});
