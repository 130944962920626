import React from "react";
import { observer } from "mobx-react";

import { ProcessedRequestItem } from "../../stores/request-store/processed-request-item";
import { HelpRequestInfo } from "../components/help-request-info";
import { CallHistory } from "../components/call-history";
import { HelpRequestHistory } from "../components/help-request-history";

interface Props {
	details: ProcessedRequestItem;
}

export const ProcessedRequestDetails = observer(({ details }: Props) => {
	return (
		<div className="call-details">
			<HelpRequestInfo
				wardInfo={details.ward!}
				baseTask={details.baseTask}
				processId={details.processId}
				editable
				audioRecordUrl={details.audioRecordUrl}
				showProcessMoveButton
				onDelete={details.delete}
			/>
			<HelpRequestHistory requestItem={details} />
			{details.scopeProcesses.length > 0 && <CallHistory scopeProcesses={details.scopeProcesses} />}
		</div>
	);
});
