import React from "react";
import { AddUserIcon, BlockUserIcon, DollarIcon, MortarBoardIcon, PrayIcon, StarIcon, UserIcon } from "./icons";

export function StatusIcon({ status }: { status: string }) {
	switch (status) {
		case "новый":
			return <AddUserIcon className="status-icon status-icon--new" />;
		case "в школе добровольцев":
			return <MortarBoardIcon className="status-icon status-icon--school" />;
		case "участник":
			return <UserIcon className="status-icon status-icon--participant" />;
		case "активный":
			return <StarIcon className="status-icon status-icon--active" />;
		case "финансовая помощь":
			return <DollarIcon className="status-icon status-icon--finance" />;
		case "неактивный":
			return <BlockUserIcon className="status-icon status-icon--inactive" />;
		case "в списке для молитв":
			return <PrayIcon className="status-icon status-icon--pray" />;
		default:
			return null;
	}
}