import * as React from "react";
import { ExpandButton } from "../buttons/expand-button";
import cn from "classnames";
import { OptionItem } from "@app-types/models";

interface Props<T> {
	items: Array<OptionItem<T | null>>;
	value: T | null;
	className?: string;
	disabled?: boolean;
	onChange?(value: T | null): void;
	Component?: React.FC;
}

interface State {
	expanded: boolean;
}

export class Dropdown<T> extends React.Component<Props<T>, State> {
	public state: State = {
		expanded: false,
	};

	private toggle = () => {
		if (this.props.disabled) {
			return;
		}

		this.setState(prevState => ({
			expanded: !prevState.expanded,
		}));
	};

	private handleSelect = (item: OptionItem<T | null>) => () => {
		this.setState({
			expanded: false,
		});

		if (this.props.onChange) {
			this.props.onChange(item.value);
		}
	};

	private get selectedText() {
		const { items, value } = this.props;
		const itemByValue = items.find(item => item.value === value);

		if (itemByValue) {
			return itemByValue.label;
		}

		if (items.length > 0) {
			return "Не выбрано";
		}

		return "";
	}

	public handleClickOutside = () => {
		this.setState({
			expanded: false,
		});
	};

	public itemKey(item: OptionItem<T | null>): string {
		return String(item.value);
	}

	public render() {
		const { expanded } = this.state;
		const { items, value, disabled, className, Component } = this.props;

		const Cmp = Component ? Component : () => <div className="dropdown__value">{this.selectedText}</div>;

		return (
			<div className={cn("dropdown", className, { "dropdown--disabled": disabled })}>
				<div className="dropdown__selection" onClick={this.toggle}>
					<Cmp />
					<ExpandButton className="dropdown__caret" />
				</div>
				{expanded && (
					<div className="dropdown__content">
						{items
							.filter(item => item.value !== value && !item.disabled)
							.map(item => (
								<div
									key={this.itemKey(item)}
									onClick={this.handleSelect(item)}
									className="dropdown__content-item"
								>
									{item.label}
								</div>
							))}
					</div>
				)}
			</div>
		);
	}
}
