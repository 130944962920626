import React from "react";
import { observer } from "mobx-react-lite";
import cn from "classnames";

import { ISchemaField } from "@app-models/schema-form";

interface Props {
	field: ISchemaField;
	children: React.ReactNode;
	className?: string;
}

export const SchemaFormRow = observer(({ field, children, className }: Props) => {
	return (
		<div
			className={cn("form__row", `form__row--${field.type}`, { "form__row--invalid": !field.isValid }, className)}
		>
			<label className={cn("form__label", { "form__label--required": field.required })}>{field.label}</label>
			{children}
			{field.validationMessage !== "" && <div className="form__error-message">{field.validationMessage}</div>}
		</div>
	);
});
