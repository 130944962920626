import { observable, runInAction } from "mobx";
import { actions } from "@actions";

import { SchemaForm } from "@app-models/schema-form";
import { XAppTypeInfo, XAppTypeRef } from "@external-types/type";
import { XScopeCard, XScopeChildInfo } from "@external-types/scope";
import { mapAttrToScopeAttrId, mapAttrValueToFormField } from "./reference-scope";
import { SubReferenceCollection } from "./sub-reference-collection";
import { ReferenceRootSection } from "./reference-root-section";
import { IReferenceScopeSection } from "./types";

export class ReferenceChildSection implements IReferenceScopeSection {
	public fetched: boolean = false;
	@observable public form: SchemaForm | null = null;
	private appType: XAppTypeInfo | null = null;
	@observable public children: SubReferenceCollection | null = null;
	@observable private appSubTypes: XAppTypeInfo[] = [];

	public constructor(
		private parentSection: ReferenceRootSection,
		private appTypeRef: XAppTypeRef,
		private scope: XScopeCard | XScopeChildInfo | null = null,
	) {}

	public get id() {
		return this.appTypeRef.id;
	}

	public get scopeId() {
		return this.scope?.id ?? null;
	}

	public get name() {
		return this.appTypeRef.name;
	}

	public async fetch() {
		const appType = await actions.getTypeSchema(this.appTypeRef.id);

		this.appType = appType;
		this.form = new SchemaForm(appType.attrs);

		for (const subAppType of appType.types) {
			const subAppTypeInfo = await actions.getTypeSchema(subAppType.id);
			this.appSubTypes.push(subAppTypeInfo);
		}

		if (this.scope !== null) {
			mapAttrValueToFormField(this.scope, this.form);
		}

		if (appType.types.length > 0) {
			let children: XScopeChildInfo[] = [];
			let scopeId: number | null = null;

			if (this.scope !== null) {
				scopeId = this.scope.id;
				children = await actions.getScopeChildren(scopeId);
			}

			runInAction(() => {
				this.children = new SubReferenceCollection(this, this.appSubTypes, children);
			});
		}

		this.fetched = true;
	}

	public async update() {
		if (!this.form) {
			return true;
		}

		if (!this.validate()) {
			return false;
		}

		const attrs = this.form.values.map(x => ({ attr: x.id, value: x.value }));
		const name = this.appType!.name;

		if (this.scope === null) {
			const parentScopeId = this.parentSection.scopeId;

			if (parentScopeId === null) {
				throw new Error("parentScopeId is not defined");
			}

			const scopeRef = await actions.createScope(parentScopeId, this.appType!.id, {
				name,
				attrs,
			});

			this.scope = await actions.getScope(scopeRef.id);
		} else {
			this.scope = await actions.updateScope(this.scope.id, {
				name,
				attrs: mapAttrToScopeAttrId(attrs, this.scope),
			});

			if (this.children) {
				this.children.submitActiveScope();
			}
		}

		return true;
	}

	public validate() {
		let result = this.form?.validate() ?? true;

		if (result && this.children !== null) {
			result = this.children.validate();
		}
		return result;
	}
}