import React from "react";
import { SvgIcon } from "@app-types/models";
import { bem } from "@helpers/bem";

const cnb = bem("timeline");

export default function({ className }: SvgIcon) {
	return (
		<svg viewBox="0 0 17 79" fill="none" xmlns="http://www.w3.org/2000/svg" className={cnb.classNames(className)}>
			<path
				className={cnb.em("midpoint")}
				d="M8.5 43C10.433 43 12 41.433 12 39.5C12 37.567 10.433 36 8.5 36C6.567 36 5 37.567 5 39.5C5 41.433 6.567 43 8.5 43Z"
				fill="#00A8FF"
			/>
			<path
				className={cnb.em("point")}
				d="M8.5 46C12.0899 46 15 43.0899 15 39.5C15 35.9101 12.0899 33 8.5 33C4.91015 33 2 35.9101 2 39.5C2 43.0899 4.91015 46 8.5 46Z"
				stroke="#0CA7F6"
				strokeWidth="3"
			/>
			<rect className={cnb.em("next")} opacity="0.2" x="7" width="3" height="30" rx="1.5" fill="#01081B" />
			<rect className={cnb.em("prev")} x="7" y="49" width="3" height="30" rx="1.5" fill="#0CA7F6" />
		</svg>
	);
}
