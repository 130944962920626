import React from "react";

import { VolunteerAttributes } from "../../../../constants/volunteer-attributes";
import { SchemaFormFieldRow } from "@app-components/schema-form";
import { SchemaForm } from "@app-models/schema-form";

interface Props {
	form: SchemaForm;
}

export const ContactsSection = ({ form }: Props) => {
	return (
		<div className="scope-card-content__row">
			<div className="scope-card-content__column">
				<div className="scope-card-heading">Контакты</div>
				<div>
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.MobilePhone)!} />

					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.HomePhone)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.AdditionalPhone)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.ContactPhones)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.Email)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.Messengers)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.PreferredCommunicationWay)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.ReceiveEmail)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.ReceiveWhatsapp)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.Region)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.Settlement)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.DistrictOfMoscow)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.Subway)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.Address)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.Apartment)!} />
				</div>
			</div>
		</div>
	);
};
