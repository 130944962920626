import React from "react";

import { XTaskValueInfo } from "@external-types/process";
import { XShemaFormItemType } from "@external-types/schema";
import { TaskFileItem } from "@app-components/files/task-file-item";
import { breakByLine } from "@helpers/text";

export function TaskFieldValue({ field, taskId }: { field: XTaskValueInfo; taskId: number }) {
	if (field.type === XShemaFormItemType.Media) {
		return (
			<div className="form__row">
				<div className="form__label">{field.name}</div>

				<div className="form__value">
					<div className="task-file-list">
						{field.files.map(f => (
							<TaskFileItem file={f} taskId={taskId} key={f.id} className="task-file-list__item" />
						))}
					</div>
				</div>
			</div>
		);
	}

	const val =
		field.type === XShemaFormItemType.Enum
			? JSON.parse(field.options).options.find((x: { id: number }) => x.id === parseInt(field.value, 10))!.name
			: field.value;

	return (
		<div className="form__row">
			<div className="form__label">{field.name}</div>
			<div className="form__value">
				{breakByLine(val).map((x, index) => (
					<React.Fragment key={index}>
						{x}
						<br />
					</React.Fragment>
				))}
			</div>
		</div>
	);
}
