import React, { useContext } from "react";
import { useParams } from "react-router";
import { observer } from "mobx-react";

import { StoreContext } from "../../context";
import { Paths } from "../../routes/paths";
import { withFetching } from "../../components/with-fetching";
import { IncomingCallDetails } from "./components/incoming-call-details";

export const CallCenterItemContent = observer(function() {
	const { unhandledCallsStore: store } = useContext(StoreContext);

	React.useEffect(() => {
		if (store.currentItem) {
			document.title = store.currentItem.title;
		}
	}, [store.currentItem]);

	function handleAttach(scopeId: number) {
		return store.currentItem!.attachToScope(scopeId);
	}

	return (
		<div className="page__content">
			{store.currentItem != null && <IncomingCallDetails onAttach={handleAttach} details={store.currentItem} />}
		</div>
	);
});

const ContentWithFetching = withFetching(CallCenterItemContent);

export const CallCenterItem = observer(function({ children }: { children?: React.ReactNode }) {
	const { unhandledCallsStore } = useContext(StoreContext);
	const [initialized, setInitialized] = React.useState(false);
	const { callId } = useParams<{ callId: string }>();
	const callIdParam = parseInt(callId, 10);

	async function fetch() {
		await unhandledCallsStore.fetchTask(callIdParam);
		setInitialized(true);
	}

	return (
		<ContentWithFetching fetch={fetch} initialized={initialized} path={Paths.CallCenter}>
			{children}
		</ContentWithFetching>
	);
});
