import React, { useContext, useState } from "react";
import { generatePath, RouteComponentProps, withRouter } from "react-router";
import { observer } from "mobx-react";
import cn from "classnames";

import { InfiniteDataList } from "@app-components/infinite-data-list";

import { ReferenceList } from "../../stores/reference-store";
import { Paths } from "../../routes/paths";
import { StoreContext } from "../../context";
import { getScopeAttributeValue, mapAttributes } from "../../../../../src/helpers/scope-attributes";
import { ReferenceScopeItem, ReferenceScopeItemSection } from "../../stores/reference-store/reference-scope-item";
import { InlineLoader } from "../../../../../src/components/controls/inline-loader";
import { Pagination } from "../../../../../src/components/pagination";
import { LabelValueRow } from "../../../../../src/components/panel";
import { EditIcon } from "../../../../../src/components/icons";
import { CircleButton } from "../../../../../src/components/buttons/circle-button";

interface Props extends RouteComponentProps {
	entry: ReferenceScopeItem;
}

const ContentSectionCollection = observer(({ section }: { section: ReferenceScopeItemSection }) => {
	const count = section.children.length;

	function onPageChange(current: number) {
		section.updateIndex(current - 1);
	}

	if (section.children.length === 0) {
		return <div className="reference-collection__empty">Информация отсутствует</div>;
	}

	const activePage = section.activePage;

	return (
		<div>
			<Pagination currentPage={section.pageIndex + 1} total={count} pageSize={1} onChange={onPageChange} />
			{mapAttributes(activePage.attrs, activePage.type).map(x => (
				<LabelValueRow key={x.key} label={x.label} value={x.value} />
			))}
		</div>
	);
});

export const EntryContentSection = observer(({ section }: { section: ReferenceScopeItemSection }) => {
	React.useEffect(() => {
		if (!section.fetched) {
			section.fetch();
		}
	}, [section.id]);

	if (!section.fetched) {
		return <InlineLoader />;
	}

	return (
		<>
			{section.children.length > 0 && <ContentSectionCollection section={section} />}
			{mapAttributes(section.attrs, section.type).map(x => (
				<LabelValueRow key={x.key} label={x.label} value={x.value} />
			))}
		</>
	);
});

const EntryContent = withRouter(
	observer(({ entry, history }: Props) => {
		const { referenceStore } = useContext(StoreContext);

		React.useEffect(() => {
			if (!entry.fetched) {
				entry.fetch();
			}
		}, []);

		function handleClick() {
			history.push(
				generatePath(Paths.ReferenceItem, {
					referenceId: referenceStore.activeScope!.id,
					itemId: entry.scope.id,
				}),
			);
		}

		function handleSectionChange(id: number) {
			return () => {
				entry.updateSection(id);
			};
		}

		if (!entry.fetched) {
			return <InlineLoader />;
		}

		return (
			<div className="reference-create-card">
				<div className="reference-create-card__content">
					<div className="reference-create-card__sections sidebar">
						{entry.children.map(x => (
							<div
								key={x.id}
								className={cn("sidebar__link", {
									"sidebar__link--active": x.id === entry.activeSectionId,
								})}
								onClick={handleSectionChange(x.id)}
							>
								{x.title}
							</div>
						))}
					</div>
					<div className="reference-create-card__section">
						{entry.activeSection && (
							<div className="reference-card-section-info">
								<EntryContentSection section={entry.activeSection} />
								{entry.activeSection.fetched && (
									<CircleButton
										onClick={handleClick}
										Icon={EditIcon}
										className="reference-card-edit-action"
									/>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}),
);

const EntryComponent = observer(({ entry }: { entry: ReferenceScopeItem }) => {
	const [expanded, setExpanded] = useState(false);
	const { referenceStore } = useContext(StoreContext);

	function handleClick() {
		setExpanded(expanded => !expanded);
	}

	const scope = referenceStore.activeScope!;

	return (
		<>
			<tr className="data-grid__row reference-grid-item" onClick={handleClick}>
				{scope.columns.map((column, index) => {
					let value: React.ReactNode = "";

					if (column.source === "@name") {
						value = entry.scope.name;
					} else if (Array.isArray(column.source)) {
						const vals = column.source
							.map(sourceAttr => getScopeAttributeValue(entry.scope, sourceAttr))
							.filter(str => str !== "");

						if (vals.length > 0) {
							value =
								column.type === "first"
									? vals[0]
									: vals.map((x, index) => (
											<React.Fragment key={index}>
												{x}
												<br />
											</React.Fragment>
									  ));
						}
					} else {
						value = getScopeAttributeValue(entry.scope, column.source);
					}

					return (
						<td
							key={index}
							className={cn("data-grid__cell", {
								"data-grid__cell--strong": index === 0,
								"data-grid__cell--30": index === 0,
								[`data-grid__cell--${column.width}`]: column.width != null,
							})}
						>
							{value}
						</td>
					);
				})}
			</tr>
			{expanded && (
				<tr>
					<td colSpan={scope.columns.length}>
						<EntryContent entry={entry} />
					</td>
				</tr>
			)}
		</>
	);
});

function EmptyResult() {
	return (
		<div className="data-grid-empty-result">
			<div className="data-grid-empty-result__term">По запросу ничего не найдено...</div>
			<div className="data-grid-empty-result__hint">
				Проверьте, нет ли опечаток или попробуйте изменить запрос
			</div>
		</div>
	);
}

export const ReferenceDataGrid = observer(({ store }: { store: ReferenceList }) => {
	React.useEffect(() => {
		store.fetchPage();
	}, [store]);

	const captions = store.columns.map(column => column.caption);

	if (!store.loading && store.items.length === 0) {
		return <EmptyResult />;
	}

	return (
		<InfiniteDataList
			store={store}
			EntryComponent={EntryComponent}
			createKey={entry => entry.scope.id}
			captions={captions}
			pagerPosition="top"
		/>
	);
});
