import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
// @ts-ignore
import Files from "react-files";

import { formatDate } from "@helpers/datetime";
import { StoreContext } from "../../context";
import { PageHeader } from "../../components/page-header";
import { CircleButton } from "@app-components/buttons/circle-button";
import { Download2Icon, Trash2Icon } from "@icons";
import { downloadScopeFile } from "@helpers/downloader";
import { fileIcon } from "@app-components/files/file-icon";
import { XFileRef } from "@external-types/file";
import { Button } from "@app-components/buttons/button";
import { SearchTextbox } from "@app-components/search-textbox";
import { IUploadFile } from "../../../../../src/types/models";
import { Overlay } from "../../../../../src/components/controls/overlay";

interface Props {
	file: XFileRef;
	scopeId: number;
	onDelete: (file: XFileRef) => void;
}

function ScopeFileRow({ file, scopeId, onDelete }: Props) {
	const Icon = fileIcon(file.filename, file.mimetype);

	function handleDownload() {
		downloadScopeFile(scopeId, file);
	}

	function handleDelete() {
		onDelete(file);
	}

	return (
		<tr className="data-grid__row">
			<td className="data-grid__cell data-grid__cell--strong data-grid__cell--50">
				<div className="data-grid-cell-flexed">
					<Icon className="data-grid-file-icon" />
					{file.filename}
				</div>
			</td>
			<td className="data-grid__cell data-grid__cell--10">{formatDate(file.uploaded)}</td>
			<td className="data-grid__cell data-grid__cell--10">
				<div className="data-grid-actions-cell">
					<CircleButton
						Icon={Download2Icon}
						className="circle-button--invert"
						title="Скачать"
						onClick={handleDownload}
					/>
					<CircleButton
						Icon={Trash2Icon}
						className="circle-button--invert"
						title="Удалить"
						onClick={handleDelete}
					/>
				</div>
			</td>
		</tr>
	);
}

function EmptyResult({ term }: { term: string }) {
	return (
		<div className="data-grid-empty-result">
			<div className="data-grid-empty-result__term">По запросу «{term}» ничего не найдено...</div>
			<div className="data-grid-empty-result__hint">
				Проверьте, нет ли опечаток или попробуйте изменить запрос
			</div>
		</div>
	);
}

function UploadButton({ onUpload }: { onUpload: (files: IUploadFile[]) => void }) {
	return (
		<Files
			className="files-upload__button"
			onChange={onUpload}
			// onError={onError}
			multiple
			// maxFiles={maxFiles}
			// maxFileSize={maxFileSize}
			minFileSize={0}
			clickable
		>
			<Button className="button button--primary" onClick={() => {}}>
				Загрузить
			</Button>
		</Files>
	);
}

export const ReferenceFileList = observer(() => {
	const { referenceStore } = useContext(StoreContext);
	const [term, setTerm] = useState("");
	const [operationInProgress, setOperationInProgress] = useState(false);
	const activeScope = referenceStore.activeScope!;

	async function handleDelete(file: XFileRef) {
		setOperationInProgress(true);

		await activeScope.deleteFile(file);

		setOperationInProgress(false);
	}

	async function handleUpload(files: IUploadFile[]) {
		if (files.length > 0) {
			setOperationInProgress(true);

			await activeScope.uploadFiles(files);

			setOperationInProgress(false);
		}
	}

	function handleFilterByTerm(value: string) {
		setTerm(value);
	}

	let files = activeScope.files;

	if (term) {
		files = activeScope.files.filter(file => file.filename.toLowerCase().includes(term.toLowerCase()));
	}

	return (
		<>
			<PageHeader title={activeScope.name}>
				<div className="filter-toolbar">
					<div className="content-toolbar__actions">
						<SearchTextbox
							onChange={handleFilterByTerm}
							value=""
							className="filter-search-input"
							delay={100}
						/>
						<UploadButton onUpload={handleUpload} />
					</div>
				</div>
			</PageHeader>

			{files.length === 0 && term !== "" && <EmptyResult term={term} />}

			{files.length > 0 && (
				<table className="data-grid">
					<thead>
						<tr className="data-grid__header">
							<td className="data-grid__header-cell">Название</td>
							<td className="data-grid__header-cell">Дата загрузки</td>
							<td />
						</tr>
					</thead>
					<tbody>
						{files.map(x => (
							<ScopeFileRow scopeId={activeScope.id} file={x} key={x.id} onDelete={handleDelete} />
						))}
					</tbody>
				</table>
			)}

			{operationInProgress && <Overlay global={false} globalWait />}
		</>
	);
});
