import { action, observable } from "mobx";
import { OptionItem } from "@app-types/models";
import { IFilterItem, IFilters } from "@app-types/data";
import { FilterType } from "@app-constants/filter-type";
import { XAppAttributeRef } from "@external-types/type";
import { XShemaFormItemType } from "@external-types/schema";

function getLabel(filterName: string) {
	switch (filterName) {
		case "schema":
			return "Все типы";
		case "all":
			return "Завершенные";
		case "tree":
			return "Все";
		case "state":
			return "Все статусы";
		case "type":
			return "Все типы";
		case "attr":
			return "Поле";
		default:
			return filterName;
	}
}

export class FilterItem implements IFilterItem {
	public type: FilterType = FilterType.None;
	public options: Array<OptionItem<any>>;
	public name: string;
	public label: string;
	@observable public value?: string | number | boolean;

	public constructor({
		type,
		options = [],
		name,
		label = "",
		value,
	}: {
		type: FilterType;
		options?: Array<OptionItem<any>>;
		name: string;
		label?: string;
		value?: string | number | boolean;
	}) {
		this.type = type;
		this.options = options;
		this.name = name;
		this.label = label;
		this.value = value;
	}

	public isFulfilled() {
		return this.value != null && this.value !== "";
	}
}

class AttrValueFilterItem implements IFilterItem {
	public type: FilterType = FilterType.None;
	public options: Array<OptionItem<any>> = [];
	public name: string = "";
	public label: string = "";
	public attr: number;
	@observable public value?: string;

	public constructor(attrRef: XAppAttributeRef, value?: string) {
		switch (attrRef.type) {
			case XShemaFormItemType.Enum:
				this.type = FilterType.Select;
				this.options = JSON.parse(attrRef.options).map(({ code, text }: any) => ({ label: text, value: code }));
				break;
			default:
				this.type = FilterType.Search;
				break;
		}

		this.attr = attrRef.id;
		this.label = "Поиск по полю...";
		this.name = "value";
		this.value = value;
	}

	public isFulfilled(items: IFilterItem[]) {
		const attrFilter = items.find(item => item.name === "attr");

		return attrFilter != null && attrFilter.value === this.attr;
	}
}

export class Filters implements IFilters {
	@observable public items: IFilterItem[] = [];
	protected excludedFilters = new Set<string>();

	public constructor(private searchFilter: boolean = false, private stateNameFilter: boolean = false) {}

	@action
	public update(applied: any, available: any) {
		this.items = Object.keys(available)
			.filter(key => !this.excludedFilters.has(key))
			.map(key => {
				if (key === "value") {
					return new AttrValueFilterItem(available[key], applied[key]);
				}

				const label = getLabel(key);
				return new FilterItem({
					label,
					type: FilterType.Select,
					options: [{ label, value: "" }].concat(
						available[key].map((x: any) => ({ label: x.name, value: x.id })),
					),
					name: key,
				});
			});

		if (this.stateNameFilter) {
			const stateNameFilter = new FilterItem({
				label: "Все статусы",
				name: "stateName",
				options: [
					{ label: "Все статусы", value: "" },
					{ label: "Отправлено исполнителю", value: "Отправлено исполнителю" },
					{ label: "Сбор данных", value: "Сбор данных" },
					{ label: "Возвращено на доработку", value: "Возвращено на доработку" },
					{ label: "Запрос на отмену контрольной точки", value: "Запрос на отмену контрольной точки" },
					{ label: "Валидация данных", value: "Валидация данных" },
					{ label: "Завершено", value: "Завершено" },
					{ label: "Отменено", value: "Отменено" },
				],
				type: FilterType.Select,
			});

			this.items.push(stateNameFilter);
		}

		Object.keys(applied)
			.filter(key => !this.excludedFilters.has(key))
			.forEach(key => {
				if (key === "status" || key === "search" || key === "value") {
					return;
				}

				let filterItem = this.items.find(x => x.name === key);
				if (!filterItem) {
					if (key === "type") {
						filterItem = new FilterItem({
							label: "Все",
							type: FilterType.Select,
							options: [
								{ label: "Все", value: "all" },
								{ label: "Дочерние", value: "child" },
								{ label: "Корневые", value: "root" },
							],
							name: key,
						});
						this.items.push(filterItem);
					} else {
						filterItem = new FilterItem({
							label: getLabel(key),
							type: FilterType.YesNo,
							name: key,
						});

						this.items.push(filterItem);
					}
				}

				filterItem.value = applied[key];
			});

		if (this.searchFilter) {
			const searchFilter = new FilterItem({
				type: FilterType.Search,
				name: "search",
			});

			if (applied.search) {
				searchFilter.value = applied.search;
			}

			this.items.push(searchFilter);
		}
	}

	public get query() {
		return this.createQuery();
	}

	@action
	public apply(filterName: string, value: any) {
		const filterItem = this.items.find(x => x.name === filterName);

		if (filterItem) {
			filterItem.value = value;
		}
	}

	protected createQuery(): Record<string, string | number | boolean | number[]> {
		return Object.assign(
			{},
			...this.items.filter(x => x.isFulfilled(this.items)).map(x => ({ [x.name]: x.value })),
		);
	}
}
