import { action, observable, runInAction } from "mobx";

import { actions } from "@actions";
import { XNextTaskRef } from "@external-types/process";
import { XScopeCard } from "@external-types/scope";
import { Filters } from "@app-models/data-source";
import { getScopeAttributeValue } from "@helpers/scope-attributes";
import { XTaskExtRef } from "@external-types/process";

import { Task } from "./task";
import { WardAttributes } from "../../constants/ward-attributes";
import { ProcessFieldValue } from "../../constants/process-field-value";
import { WardForm } from "../models/ward-form";
import { BaseTaskForm } from "../models/base-task-form";
import { getWardFullName } from "../../helpers/ward-helper";
import { getCallDestinationNumber } from "../../helpers/process-helper";

export class ProcessHistoryFilters extends Filters {
	protected createQuery() {
		const query = super.createQuery();

		return {
			...query,
			vid: [
				ProcessFieldValue.RequestMessage,
				ProcessFieldValue.RequestTopic,
				ProcessFieldValue.CallTopic,
				ProcessFieldValue.AppealMessage,
			],
			pvid: [
				ProcessFieldValue.RequestMessage,
				ProcessFieldValue.RequestTopic,
				ProcessFieldValue.CallTopic,
				ProcessFieldValue.AppealMessage,
			],
			tree: true,
			all: true,
			type: "root",
			orderBy: "date_desc",
		};
	}
}

export class CallItem {
	@observable public task: Task | null = null;
	@observable public scopeProcesses: XTaskExtRef[] = [];
	@observable public inProgress: boolean = false;
	@observable public history: BaseTaskForm[] = [];
	@observable public audioRecordUrl: string | null = null;

	public additionalInfo: { destinationPhoneNumber: string | null } | null = null;
	public form: WardForm | null = null;
	private filters: Filters = new ProcessHistoryFilters();
	private scope: XScopeCard | null = null;

	public constructor(private taskId: number) {}

	public get title() {
		return getWardFullName(this.scope!, getScopeAttributeValue(this.scope!, WardAttributes.Phone));
	}

	public async fetch() {
		const task = await actions.getTask(this.taskId);
		const scope = await actions.getScope(task.scope.id);
		//const scopeProcesses = await actions.getScopeProcesses(task.scope.id, undefined, this.filters);
		const form = new WardForm(scope);
		const audioRecordUrl =
			task.links && task.links.length > 0 ? actions.getTaskProcessLinkUrl(this.taskId, task.links[0].id) : null;

		await form.fetch();

		runInAction(() => {
			this.task = new Task(task);
			//this.scopeProcesses = scopeProcesses.list;
			this.form = form;
			this.additionalInfo = {
				destinationPhoneNumber: getCallDestinationNumber(task.history),
			};
			this.scope = scope;
			this.audioRecordUrl = audioRecordUrl;
		});

		this.updateHistory();
	}

	public async updateTask(taskId: number) {
		const task = await actions.getTask(taskId);

		runInAction(() => {
			this.taskId = taskId;
			this.task = new Task(task);
		});

		this.updateHistory();
	}

	public async attachToScope(scopeId: number) {
		try {
			this.setInProgress(true);
			const result = await actions.moveProcessToScope(this.task!.pid, scopeId);

			await this.updateScope(result.scope.id);
		} finally {
			this.setInProgress(false);
		}
	}

	public async updateWardForm() {
		try {
			this.setInProgress(true);

			await this.form!.save();
		} finally {
			this.setInProgress(false);
		}
	}

	public async runAction(actionId: number, callback?: (nextTaskRef: XNextTaskRef) => void) {
		if (this.form!.validate()) {
			try {
				await this.updateWardForm();

				this.setInProgress(true);

				this.task!.updateActionId(actionId);

				if (this.task!.submitAllowed) {
					await this.task!.runAction(callback);
				}
			} finally {
				this.setInProgress(false);
			}
		}
	}

	@action
	private setInProgress(inProgress: boolean) {
		this.inProgress = inProgress;
	}

	@action
	private updateHistory() {
		if (this.task && this.task.history) {
			this.history = this.task.history
				.filter(h => h.tasks.some(t => t.values.length > 0))
				.map(h => new BaseTaskForm(this.task!.pid, h));
		}
	}

	public async updateScope(scopeId: number) {
		const scope = await actions.getScope(scopeId);
		const scopeProcesses = await actions.getScopeProcesses(scopeId, undefined, this.filters);

		this.form!.replaceScope(scope);

		runInAction(() => {
			this.scope = scope;
			this.scopeProcesses = scopeProcesses.list;
		});
	}
}
