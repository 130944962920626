import React from "react";
import { observer } from "mobx-react-lite";

import { IUploadFile } from "@app-types/models";
import { ISchemaField, SchemaFieldType } from "@app-models/schema-form";

import { UploadFiles } from "../files/upload-files";
import { SchemaFormField } from "./schema-form-field";
import { SchemaFormRow } from "./schema-form-row";

interface Props {
	field: ISchemaField;
	children?: React.ReactNode;
}

export const SchemaFormFieldRow = observer(({ field, children }: Props) => {
	function handleFormFieldFilesChange(files: IUploadFile[]) {
		field.updateFiles(files);
	}

	return (
		<SchemaFormRow field={field}>
			{field.type === SchemaFieldType.Media ? (
				<UploadFiles onChange={handleFormFieldFilesChange} maxFiles={50} files={field.files} />
			) : (
				<SchemaFormField field={field} />
			)}
			{children}
		</SchemaFormRow>
	);
});
