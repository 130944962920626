import { action, observable, runInAction } from "mobx";

import { InfiniteDataSource } from "@app-models/data-source";
import { actions } from "@actions";

import { AppState } from "../app-state";
import { Scope } from "../../constants/scope";
import { Ward } from "./ward-info";
import { WardSectionId, WardSections } from "./ward-sections";
import { EmptyWardInfo } from "./empty-ward-info";

export { Ward, WardSectionId, WardSections };

export class WardsStore extends InfiniteDataSource<Ward> {
	@observable public selectedItemId: number = -1;
	@observable public currentItem: Ward | null = null;
	@observable public emptyWard: EmptyWardInfo | null = null;

	public constructor(private appState: AppState) {
		super();
	}

	protected get session() {
		return this.appState.wards;
	}

	protected async fetch() {
		const result = await actions.getScopeChildrenPaged(Scope.Wards, this.session.pagination, this.filter);

		this.updatePageInfo(result);

		return result.list.map(child => new Ward(child));
	}

	@action
	public toggleSelect(selectedItemId: number) {
		this.selectedItemId = this.selectedItemId === selectedItemId ? -1 : selectedItemId;
	}

	public async fetchWard(scopeId: number) {
		const scope = await actions.getScope(scopeId);

		runInAction(() => {
			this.currentItem = new Ward(scope);
		});
	}

	public async fetchEmptyWard() {
		const emptyWard = new EmptyWardInfo();
		await emptyWard.fetch();

		runInAction(() => {
			this.emptyWard = emptyWard;
		});
	}
}
