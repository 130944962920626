import React from "react";

export class NotFound extends React.Component {
	public render() {
		return (
			<div className="not-found-page">
				Страница не существует
				<a href="/" className="not-found-page__home-link">
					Вернуться на главную
				</a>
			</div>
		);
	}
}
