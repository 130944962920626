import React from "react";
import cn from "classnames";

import { Button } from "@app-components/buttons/button";
import { LightButton } from "@app-components/buttons/light-button";

export const DeleteConfirmationDialog = ({ onSubmit, onClose }: { onSubmit: () => void; onClose: () => void }) => {
	return (
		<div className="move-process-dialog">
			<div className="dialog-instance__header">Удаление звонка/просьбы</div>
			<p>
				Если Вы удалите запись о звонке/просьбе, все данные по ней будут удалены из базы, включая запись звонка,
				текст обращения, комментарии координаторов и добровольцев.
			</p>
			<p>При этом данные о подопечном (ФИО, телефон, адрес и тп), останутся в базе в карточке подопечного.</p>
			<div className="dialog-instance__actions">
				<Button className={cn("button button--primary")} onClick={onSubmit}>
					Удалить
				</Button>
				<LightButton onClick={onClose}>Отмена</LightButton>
			</div>
		</div>
	);
};
