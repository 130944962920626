import React, { useContext } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { fullName } from "@helpers/user";
import { Dropdown } from "../components/dropdown";
import { ProfileAvatar } from "../components/controls/profile-avatar";
import { AppStoreContext } from "../app-store-context";
import { AppHeaderMenu } from "./app-header-menu";

const defaultDropdownItems = [{ value: 1, label: "Выйти" }];

interface Props extends RouteComponentProps {
	Logo: React.FunctionComponent;
	path?: string;
	dropdown?: {
		items: Array<{ value: number; label: string }>;
		onSelect: (value: number) => void;
	};
}

export const AppHeader = withRouter(
	observer(function({ history, path, dropdown, Logo }: Props) {
		const { authStore, appStore } = useContext(AppStoreContext);
		const user = appStore.authUser;

		function handleMenuSelect(value: number) {
			if (value === 1) {
				authStore.logout().finally(() => {
					history.replace("/");
				});
			}
		}

		if (!user) {
			return null;
		}

		const { roles } = user;
		const position = user.position ?? (roles.length > 0 ? roles[0].name : "");

		const Component = () => (
			<>
				<ProfileAvatar user={appStore.authUser} />
				<div>
					<div className="header-profile__fullname">{fullName(user!)}</div>
					<div className="header-profile__position">{position}</div>
				</div>
			</>
		);

		return (
			<>
				<div className="app__header header">
					<Logo />
					<AppHeaderMenu path={path} />

					<Dropdown
						items={dropdown?.items ?? defaultDropdownItems}
						value={""}
						Component={Component}
						className="header-profile dropdown--right-align"
						onChange={dropdown?.onSelect ?? handleMenuSelect}
					/>
				</div>
				<div className="header-placeholder" />
			</>
		);
	}),
);
