import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { RouteComponentProps, withRouter } from "react-router";
import { Button } from "@app-components/buttons/button";
import { yaMapsService } from "@helpers/ya-service";
import { Textbox } from "@app-ui";
import { getRegions } from "./regions";

declare const ymaps: any;

function YaMap({ geoCode }: { geoCode: any }) {
	const [map, setMap] = React.useState<any>();
	const containerMap = React.useRef<HTMLDivElement>(null);
	const placemark = React.useRef();

	React.useLayoutEffect(() => {
		ymaps.ready(() => {
			const yaMap = new ymaps.Map(containerMap.current!, {
				center: [55.76, 37.64], // Москва
				zoom: 18,
				controls: {},
			});

			const regions = getRegions();
			regions.forEach(region => yaMap.geoObjects.add(region.polygon));

			setMap(yaMap);

			return () => {
				yaMap.destroy();
			};
		});
	}, []);

	React.useLayoutEffect(() => {
		if (geoCode && map) {
			const coornidates = geoCode.geometry.getCoordinates();
			const shortAddress = [geoCode.getThoroughfare(), geoCode.getPremiseNumber(), geoCode.getPremise()].join(
				" ",
			);

			if (placemark.current) {
				map.geoObjects.remove(placemark.current);
			}
			placemark.current = new ymaps.Placemark(coornidates, {
				iconCaption: shortAddress,
				balloonContent: shortAddress,
			});

			map.geoObjects.add(placemark.current);
			map.setCenter(coornidates);
		}
	}, [geoCode, map]);

	return <div className="map" ref={containerMap} />;
}

export const Map = withRouter(
	observer(function({ location }: RouteComponentProps) {
		const query = new URLSearchParams(location.search);
		const searchRef = React.createRef<HTMLInputElement>();
		const [address, setAddress] = React.useState(query.get("address") ?? "");
		const [error, setError] = React.useState("");
		const [geo, setGeo] = React.useState(null);

		useEffect(() => {
			ymaps.ready(() => {
				geocode();
			});
		}, []);

		useEffect(() => {
			if (error) {
				const suggestView = yaMapsService.createSuggest(searchRef.current!);

				suggestView.events.add("select", (item: any) => {
					setAddress(item.originalEvent.item.value);
				});

				return () => {
					suggestView.destroy();
				};
			}
		}, [error]);

		async function geocode() {
			const res: any = await ymaps.geocode(address);
			const obj = res.geoObjects.get(0);

			if (!obj) {
				setError("Адрес не найден. Уточните адрес.");
				setGeo(null);
				return;
			}

			// Об оценке точности ответа геокодера можно прочитать тут: https://tech.yandex.ru/maps/doc/geocoder/desc/reference/precision-docpage/
			switch (obj.properties.get("metaDataProperty.GeocoderMetaData.precision")) {
				case "exact":
					break;
				case "number":
				case "near":
				case "range":
					setError("Неточный адрес. Уточните адрес.");
					setGeo(null);
					return;
				case "street":
					setError("Неточный адрес. Уточните адрес.");
					setGeo(null);
					return;
				case "other":
				default:
					setError("Неточный адрес. Уточните адрес.");
					setGeo(null);
					return;
			}

			setGeo(obj);
			setError("");
		}

		function handleChange(value: string) {
			setAddress(value);
		}

		function handleSearch() {
			geocode();
		}

		return (
			<>
				<div className="app__content map-container">
					{error && (
						<div className="map-error">
							<div className="map-error-panel">
								<div>
									<Textbox value={address} ref={searchRef} onChange={handleChange} />
									<div className="map-error__message">{error}</div>
								</div>
								<div className="map-error-panel__action">
									<Button className="button button--primary" onClick={handleSearch}>
										Найти
									</Button>
								</div>
							</div>
						</div>
					)}
					{geo && <YaMap geoCode={geo} />}
				</div>
			</>
		);
	}),
);
