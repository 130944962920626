import { observable } from "mobx";
import { DataSourceSession, Filters } from "@shared/models/data-source";
import { CallTask, IncomingCall } from "../call-center-store";
import { WardAttributes } from "../../constants/ward-attributes";
import { ProcessFieldValue } from "../../constants/process-field-value";
import { CallItemHistory } from "../call-center-store/call-item-history";
import { ProcessSchema } from "../../constants/process-schema";

class ProcessedCallsFilters extends Filters {
	protected createQuery() {
		const query = super.createQuery();

		return {
			...query,
			schema: ProcessSchema.MainProcess,
			aid: [WardAttributes.Phone, WardAttributes.FirstName, WardAttributes.LastName, WardAttributes.MiddleName],
			paid: [WardAttributes.Phone, WardAttributes.FirstName, WardAttributes.LastName, WardAttributes.MiddleName],
			vid: [
				ProcessFieldValue.RequestMessage,
				ProcessFieldValue.RequestTopic,
				ProcessFieldValue.CallTopic,
				ProcessFieldValue.AppealMessage,
			],
			pvid: [
				ProcessFieldValue.RequestMessage,
				ProcessFieldValue.RequestTopic,
				ProcessFieldValue.CallTopic,
				ProcessFieldValue.AppealMessage,
			],
			tree: true,
			all: true,
			type: "root",
			orderBy: "date_desc",
		};
	}
}

export class ProcessedCallsSession extends DataSourceSession<CallTask> {
	public filter: Filters = new ProcessedCallsFilters(true);
	@observable public currentItem: CallItemHistory | null = null;
	@observable public incomingCall: IncomingCall | null = null;
}
