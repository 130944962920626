import { observer } from "mobx-react";
import React, { useContext } from "react";

import { FilterToolbar } from "@app-components/filters/filter-toolbar";
import { Button } from "@app-components/buttons/button";
import { InfiniteDataList } from "@app-components/infinite-data-list";

import { StoreContext } from "../../context";
import { generatePath } from "react-router";
import { Paths } from "../../routes/paths";
import { Page } from "../../components/page";
import { PageHeader } from "../../components/page-header";
import { EmptyCalls } from "./components/empty-calls";
import { CallTask } from "../../stores/call-center-store";
import { GridEntry } from "./components/grid-entry";
import { ProcessedCallDetails } from "./components/processed-call-details";
import { Header } from "./components/header";

const captions = ["Время", "Телефон", "Подопечный", "Тема", "Текст", "Кто добавил", "Статус"];

const GridEntryWithStore = observer(({ entry }: { entry: CallTask }) => {
	const { processedCallsStore: store } = useContext(StoreContext);
	const isActive = store.currentItem ? store.currentItem.processId === entry.pid : false;
	const isNotActive = store.currentItem ? !isActive : false;

	function handleClick() {
		if (store.currentItem === null || store.currentItem.processId !== entry.pid) {
			store.fetchProcess(entry.pid);
		} else {
			store.clearFocus();
		}
	}

	function handleAttach(scopeId: number) {
		return store.moveToScope(entry, scopeId);
	}

	return (
		<GridEntry
			entry={entry}
			captions={captions}
			onClick={handleClick}
			isActive={isActive}
			isNotActive={isNotActive}
			className="call-grid-item"
		>
			{store.currentItem != null && <ProcessedCallDetails details={store.currentItem} onAttach={handleAttach} />}
		</GridEntry>
	);
});

export const ProcessedCalls = observer(function() {
	const { processedCallsStore: store } = useContext(StoreContext);

	React.useEffect(() => {
		store.fetchPage(1, 50);
	}, [store]);

	const handleOpenNotification = (callId: number) => () => {
		window.open(generatePath(Paths.CallCenterItem, { callId }), "_blank");
		store.clearIncomingCall();
	};

	function handleFilterApply(filterId: string, value: any) {
		store.applyFilter(filterId, value);
	}

	async function handleCreate() {
		const callId = await store.createCall();

		window.open(generatePath(Paths.CallCenterItem, { callId }), "_blank");
	}

	return (
		<Page path={Paths.CallCenter}>
			<div className="page__content">
				{store.incomingCall != null && (
					<div className="incoming-call-notification" onClick={handleOpenNotification(store.incomingCall.id)}>
						<div className="incoming-call-notification__header">Поступил звонок</div>
						<div className="incoming-call-notification__row">
							<div className="incoming-call-notification__label">Звонящий</div>
							<div className="incoming-call-notification__value">{store.incomingCall.phone}</div>
						</div>
						{/*<div className="incoming-call-notification__row">*/}
						{/*	<div className="incoming-call-notification__label">Номер обращения</div>*/}
						{/*	<div className="incoming-call-notification__value">444333334234</div>*/}
						{/*</div>*/}
					</div>
				)}

				<PageHeader title="Принятые звонки">
					<div className="filter-toolbar">
						<FilterToolbar
							filters={store.filter}
							onApply={handleFilterApply}
							hiddenFilters={["type", "schema", "orderBy", "all", "tree"]}
						/>
					</div>

					<div>
						<Button className="button button--primary" onClick={handleCreate}>
							Добавить
						</Button>
					</div>
				</PageHeader>

				{!store.loading && store.items.length === 0 ? (
					<EmptyCalls />
				) : (
					<InfiniteDataList
						store={store}
						EntryComponent={GridEntryWithStore}
						Header={<Header />}
						createKey={entry => entry.taskId}
						captions={captions}
						pagerPosition="top"
					/>
				)}
			</div>
		</Page>
	);
});
