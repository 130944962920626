import React from "react";
import { observer } from "mobx-react-lite";
import MaskedInput, { conformToMask } from "react-text-mask";

import { Switch } from "@app-components/switch";
import { SchemaFieldType, ISchemaField } from "@app-models/schema-form";
import { Select } from "@app-ui";

export const SchemaFormField = observer(({ field }: { field: ISchemaField }) => {
	function handleFormFieldChange(e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) {
		field.updateValue(e.currentTarget.value);
	}

	function handleSelect(value: string) {
		field.updateValue(value);
	}

	if (field.type === SchemaFieldType.Switch) {
		return <Switch on={field.options[0]} off={field.options[1]} value={field.value} onChange={handleSelect} />;
	}

	if (field.type === SchemaFieldType.Select) {
		return <Select items={field.options} onChange={handleSelect} value={field.value} />;
	}

	if (field.type === SchemaFieldType.Label) {
		return <div>{field.value}</div>;
	}

	if (field.type === SchemaFieldType.Text) {
		return (
			<textarea
				className="form__input form__input--textarea"
				rows={3}
				value={field.value}
				onChange={handleFormFieldChange}
			/>
		);
	}

	if (field.type === SchemaFieldType.Masked) {
		return (
			<MaskedInput
				className="form__input"
				mask={field.mask}
				value={field.value}
				onChange={handleFormFieldChange}
				placeholder={conformToMask("", field.mask, {}).conformedValue}
			/>
		);
	}

	return <input type="text" className="form__input" value={field.value} onChange={handleFormFieldChange} />;
});
