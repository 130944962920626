import React from "react";
import cn from "classnames";

const captions = ["Время", "Телефон", "Подопечный", "Тема", "Раздел", "Текст", "Кто добавил", "Статус"];

export function Header() {
	return (
		<thead>
			<tr className="data-grid__header">
				{captions.map((caption, index) => (
					<th
						className={cn(
							"data-grid__header-cell",
							{ "data-grid__header-cell--10": index !== 5 && index > 1 },
							{ "data-grid__header-cell--12": index <= 1 },
							{ "data-grid__header-cell--30": index === 5 },
						)}
						key={index}
					>
						{caption}
					</th>
				))}
			</tr>
		</thead>
	);
}
