import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { inRoles } from "@helpers/user";
import { AppStoreContext } from "@shared/app-store-context";
import { Paths } from "./paths";

interface Props {
	path: string;
	component: React.ComponentType;
	exact?: boolean;
	roles?: number[];
	loginPath?: string;
	homePath?: string;
}

export const PrivateRoute = observer(function(props: Props) {
	const { appStore } = useContext(AppStoreContext);
	const { roles } = props;
	const { loginPath = Paths.Login, homePath = Paths.Home, ...restProps } = props;

	if (!appStore.authUser) {
		return <Redirect to={Paths.Login} />;
	}

	if (!roles || roles.length === 0 || inRoles(appStore.authUser.roles, roles)) {
		return <Route {...restProps} />;
	}

	return <Redirect to={Paths.Home} />;
});
