import React from "react";
import cn from "classnames";

export function PanelSection({
	title,
	children,
	padding = true,
	useBottomBorder = false,
}: {
	title?: string;
	children?: React.ReactNode;
	padding?: boolean;
	useBottomBorder?: boolean;
}) {
	return (
		<>
			{title && <div className="org-panel-info__section-title">{title}</div>}

			{children && (
				<div
					className={cn("org-panel-info__section-content", {
						"org-panel-info__section-content--paddingless": !padding,
						"org-panel-info__section-content--use-bottom-border": useBottomBorder,
					})}
				>
					{children}
				</div>
			)}
		</>
	);
}
