import React from "react";
import { observer } from "mobx-react";
import cn from "classnames";

import { formatDateTime } from "@helpers/datetime";
import { fullName } from "@helpers/user";

import { CallTask } from "../../../stores/call-center-store";
import { GridCellContent } from "@app-components/infinite-data-list";

interface Props {
	entry: CallTask;
	captions: string[];
	children?: React.ReactNode;
	onClick?: () => void;
	isActive: boolean;
	isNotActive: boolean;
	className?: string;
}

export const GridEntry = observer(({ entry, captions, children, onClick, isActive, isNotActive, className }: Props) => {
	function handleClick() {
		onClick?.();
	}

	return (
		<>
			<tr
				className={cn("data-grid__row reference-grid-item", className, {
					"call-center-row-inactive": isNotActive,
					"call-center-row-active": isActive,
				})}
				onClick={handleClick}
			>
				<GridCellContent>{formatDateTime(entry.start, "YYYY.MM.DD HH:mm")}</GridCellContent>
				<GridCellContent>{entry.phone}</GridCellContent>
				<GridCellContent>{entry.name}</GridCellContent>
				<GridCellContent>{entry.topic}</GridCellContent>
				<GridCellContent>{entry.message}</GridCellContent>
				<GridCellContent>{fullName(entry.initiator)}</GridCellContent>
				<GridCellContent>{entry.state}</GridCellContent>
			</tr>
			{isActive && (
				<tr className="call-center-row-details">
					<td colSpan={captions.length} className="call-details-cell">
						{children}
					</td>
				</tr>
			)}
		</>
	);
});
