import React, { useContext } from "react";
import { generatePath, RouteComponentProps, useParams, withRouter } from "react-router";
import { observer } from "mobx-react";
import cn from "classnames";

import { ArrowBackIcon, PlusIcon, Trash2Icon } from "@icons";
import { Button } from "@app-components/buttons/button";
import { SchemaForm } from "@app-components/schema-form";
import { Overlay } from "@app-components/controls/overlay";
import { Pagination } from "@app-components/pagination";

import { StoreContext } from "../../context";
import { Paths } from "../../routes/paths";
import { ReferenceWrapper } from "./reference-wrapper";
import { SubReferenceCollection } from "../../stores/reference-store";
import { CircleButton } from "../../../../../src/components/buttons/circle-button";

interface Props extends RouteComponentProps {}

export const ReferenceCollection = observer(({ collection }: { collection: SubReferenceCollection }) => {
	const count = collection.children.length;

	function onPageChange(current: number) {
		collection.updateIndex(current - 1);
	}

	function handleAdd(e: React.FormEvent<HTMLAnchorElement>) {
		e.preventDefault();

		collection.add();
	}

	if (collection.index === -1) {
		return (
			<div className="reference-collection__empty">
				Информация еще не заполнена. Вы можете{" "}
				<a href="javascript:void(0);" onClick={handleAdd}>
					добавить
				</a>{" "}
				новый элемент.
			</div>
		);
	}

	const activeState = collection.children[collection.index];

	return (
		<div>
			<Pagination currentPage={collection.index + 1} total={count} pageSize={1} onChange={onPageChange} />
			<SchemaForm form={activeState.form} />
		</div>
	);
});

const ReferenceItemContent = observer(({ onBack }: { onBack: () => void }) => {
	const { referenceStore } = useContext(StoreContext);
	const scope = referenceStore.currentScope!;

	async function handleSave() {
		const activeSection = scope.activeSection;

		if (activeSection && activeSection.validate()) {
			await activeSection.update();

			onBack();
		}
	}

	function handleAppTypeChange(appTypeId: number) {
		return () => {
			scope.updateActiveSectionId(appTypeId);
		};
	}

	async function deleteScope() {
		await referenceStore.deleteScope(scope.scopeId);

		onBack();
	}

	const hasData = scope.sections.length > 0;

	return (
		<div className="reference-create-card">
			<div className="reference-create-card__content">
				<div className="reference-create-card__sections sidebar">
					<div className="back-button-wrapper">
						<button className="back-button" onClick={onBack}>
							<span className="back-button__icon">
								<ArrowBackIcon />
							</span>
							<span>Назад</span>
						</button>
					</div>

					{hasData ? (
						scope.sections.map(x => (
							<div
								key={x.id}
								className={cn("sidebar__link", {
									"sidebar__link--active": x.id === scope.activeSectionId,
								})}
								onClick={handleAppTypeChange(x.id)}
							>
								{x.name}
							</div>
						))
					) : (
						<div className="sidebar__link sidebar__link--active">Основная информация</div>
					)}
				</div>
				<div className="reference-create-card__section">
					<header className="reference-create-card__title">
						Редактирование: {scope.name}
						<CircleButton Icon={Trash2Icon} onClick={deleteScope} />
					</header>
					{hasData ? (
						scope.activeSection && (
							<>
								{scope.activeSection.form && <SchemaForm form={scope.activeSection.form} />}
								{scope.activeSection.children !== null && (
									<div className="reference-collection">
										<div className="reference-collection__toolbar">
											<CircleButton onClick={scope.activeSection.children.add} Icon={PlusIcon} />
										</div>
										<div className="reference-collection__content">
											<ReferenceCollection collection={scope.activeSection.children} />
										</div>
									</div>
								)}
							</>
						)
					) : (
						<>Нет типов данных</>
					)}

					<div className="reference-create-card__navigation">
						{hasData && (
							<Button className="button button--primary" onClick={handleSave}>
								Сохранить
							</Button>
						)}

						<Button className="button button--secondary" onClick={onBack}>
							Отмена
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
});

export const ReferenceItem = withRouter(
	observer(({ history }: Props) => {
		const { referenceId, itemId } = useParams<{ referenceId: string; itemId: string }>();
		const itemIdParam = parseInt(itemId, 10);
		const { referenceStore } = useContext(StoreContext);
		const [fetched, setFetched] = React.useState(false);

		React.useEffect(() => {
			setFetched(false);

			referenceStore.fetchItem(itemIdParam).then(() => setFetched(true));
		}, [itemId]);

		function handleBack() {
			history.push(generatePath(Paths.ReferenceList, { referenceId }));
		}

		return (
			<ReferenceWrapper>
				<div className="page__content">
					{fetched &&
						referenceStore.currentScope !== null &&
						referenceStore.currentScope.scopeId === itemIdParam && (
							<ReferenceItemContent onBack={handleBack} />
						)}
					{!fetched && <Overlay global={false} globalWait />}
				</div>
			</ReferenceWrapper>
		);
	}),
);
