import React from "react";
import { SchemaFormFieldRow } from "@app-components/schema-form";
import { SchemaForm } from "@app-models/schema-form";

import { WardAttributes } from "../../../../constants/ward-attributes";

interface Props {
	form: SchemaForm;
}

export const ProjectsEditSection = ({ form }: Props) => {
	return (
		<>
			<SchemaFormFieldRow field={form.getField(WardAttributes.RegularVolunteers)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.Projects)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.PB_s)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.TargetedAssistanceSao)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.TargetedAssistanceSvao)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.TargetedAssistanceVao)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.TargetedAssistanceUvao)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.TargetedAssistanceUao)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.TargetedAssistanceUzao)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.TargetedAssistanceZao)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.TargetedAssistanceSzao)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.MMO)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.MO)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.InquiryOffice)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.Patronage)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.TransportationServices)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.WashingRoom)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.WashingRoomOurs)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.HouseForMother)!} />
			<SchemaFormFieldRow field={form.getField(WardAttributes.OtherProject)!} />
		</>
	);
};
