import React from "react";
import { SvgIcon } from "@app-types/models";

export default function({ className }: SvgIcon) {
	return (
		<svg className={className} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M13.4246 26.9587H7.79548C7.53642 26.9587 7.32642 27.1687 7.32642 27.4278C7.32642 27.6869 7.53642 27.8969 7.79548 27.8969H13.4246C13.6836 27.8969 13.8936 27.6869 13.8936 27.4278C13.8936 27.1687 13.6836 26.9587 13.4246 26.9587Z"
				fill="black"
			/>
			<path
				d="M13.4246 28.6177H7.79548C7.53642 28.6177 7.32642 28.8277 7.32642 29.0868C7.32642 29.3459 7.53642 29.5559 7.79548 29.5559H13.4246C13.6836 29.5559 13.8936 29.3459 13.8936 29.0868C13.8936 28.8277 13.6836 28.6177 13.4246 28.6177Z"
				fill="black"
			/>
			<path
				d="M15.4623 9.50048L14.8712 9.00274C14.0761 8.33324 13.4929 7.46974 13.167 6.51024C13.1626 6.49393 13.1574 6.47793 13.1514 6.46236C12.966 5.89987 12.8684 5.30537 12.8684 4.69799V4.10524C13.1176 3.96768 13.2869 3.70237 13.2869 3.39812V0.807249C13.2869 0.362124 12.9248 0 12.4796 0H8.74045C8.29532 0 7.9332 0.362124 7.9332 0.807249V3.39812C7.9332 3.70237 8.10251 3.96768 8.35176 4.10524V4.69799C8.35176 5.3053 8.25407 5.89987 8.0687 6.46236C8.06264 6.47793 8.05751 6.49393 8.05314 6.51024C7.72732 7.46968 7.14407 8.33324 6.34895 9.00274L5.75783 9.50048C5.39164 9.8088 5.18164 10.2602 5.18164 10.7388V30.3575C5.18164 31.2631 5.91839 31.9999 6.82401 31.9999H14.3961C15.3017 31.9999 16.0384 31.2631 16.0384 30.3575V10.7388C16.0384 10.2602 15.8285 9.8088 15.4623 9.50048ZM12.3487 0.938186V3.26718H8.87138V0.938186H12.3487ZM12.0951 6.16268H9.12495C9.23345 5.68699 9.28995 5.19618 9.28995 4.69793V4.20537H11.9302V4.69793C11.9302 5.19618 11.9866 5.68699 12.0951 6.16268ZM15.1003 30.3575C15.1003 30.7458 14.7844 31.0618 14.3961 31.0618H6.82401C6.4357 31.0618 6.11983 30.7458 6.11983 30.3575V20.9617L15.1003 25.1184V30.3575ZM15.1003 24.0847L6.11983 19.9279V19.3177L15.1003 23.5187V24.0847ZM15.1003 22.483L6.11983 18.2819V15.8047L15.1003 20.0058V22.483ZM15.1003 17.2009L9.67763 14.6909C9.44251 14.582 9.1637 14.6845 9.05488 14.9195C8.94601 15.1547 9.04845 15.4335 9.28351 15.5423L15.1002 18.2347V18.97L6.11983 14.769V14.078L7.27064 14.6107C7.50576 14.7195 7.78457 14.6171 7.89339 14.382C8.00226 14.1469 7.89982 13.8681 7.66476 13.7593L6.11983 13.0442V10.7389C6.11983 10.5376 6.20814 10.3478 6.36208 10.2182L6.95326 9.72036C7.80039 9.00699 8.44101 8.10467 8.83545 7.10093H12.3847C12.7791 8.10474 13.4197 9.00705 14.2669 9.72036L14.8581 10.2182C15.012 10.3478 15.1003 10.5375 15.1003 10.7388V17.2009Z"
				fill="black"
			/>
			<path
				d="M26.5801 22.2263C26.4084 22.0389 26.1642 21.9314 25.9101 21.9314H18.6796C18.4255 21.9314 18.1813 22.0389 18.0097 22.2263C17.8381 22.4137 17.7525 22.6663 17.7747 22.9196L18.482 30.9429C18.5185 31.3573 18.7645 31.7015 19.1112 31.8762C19.2688 31.9556 19.4473 32 19.6363 32H24.9533C25.1424 32 25.3208 31.9556 25.4784 31.8762C25.8251 31.7015 26.0711 31.3573 26.1076 30.9429L26.8149 22.9196C26.8373 22.6664 26.7517 22.4137 26.5801 22.2263ZM25.1732 30.8604C25.1643 30.9608 25.0909 31.0396 24.9955 31.0578C24.9819 31.0604 24.9678 31.0618 24.9534 31.0618H19.6363C19.622 31.0618 19.6078 31.0604 19.5942 31.0578C19.4988 31.0397 19.4254 30.9609 19.4165 30.8604L19.3756 30.3963H25.214L25.1732 30.8604ZM25.7196 24.6625H22.2949C22.0358 24.6625 21.8258 24.8725 21.8258 25.1316C21.8258 25.3906 22.0358 25.6006 22.2949 25.6006H25.6369L25.2971 29.4554C25.2879 29.4562 25.2796 29.4581 25.2698 29.4581H19.32C19.3102 29.4581 19.3018 29.4563 19.2927 29.4554L18.9529 25.6006H20.0778C20.3369 25.6006 20.5469 25.3906 20.5469 25.1316C20.5469 24.8725 20.3369 24.6625 20.0778 24.6625H18.8701L18.7121 22.8696H25.8775L25.7196 24.6625Z"
				fill="black"
			/>
		</svg>
	);
}
