import React from "react";
import Rating from "react-rating";

import { ISchemaField } from "@app-models/schema-form";
import { Star2Icon } from "@icons";
import { XScopeAttrRef } from "@external-types/scope";
import { observer } from "mobx-react";

interface FieldRatingProps {
	field: ISchemaField;
}

interface RatingProps {
	label: string;
	value: number;
	children?: React.ReactNode;
	onChange?: (value: number) => void;
}

export function SimpleRating({ label, value, onChange, children }: RatingProps) {
	return (
		<div className="form__row">
			<div className="form__label">{label}</div>
			<div className="form__value rating-form-value">
				<Rating
					start={0}
					stop={5}
					initialRating={value}
					onChange={onChange}
					emptySymbol={<Star2Icon className="rating-empty-star" />}
					placeholderSymbol={<Star2Icon className="rating-placeholder-star" />}
					placeholderRating={value}
					readonly={!onChange}
					className="rating-container"
					fullSymbol={
						<Star2Icon className="rating-full-star">
							<linearGradient
								id="full-star-gradient"
								gradientUnits="userSpaceOnUse"
								x1="93.12%"
								y1="-5.36%"
								x2="6.88%"
								y2="105.36%"
							>
								<stop stopColor="#FFC93E" />
								<stop offset=".688" stopColor="#FEF296" />
								<stop offset="1.132" stopColor="#FEFBA8" />
							</linearGradient>
						</Star2Icon>
					}
				/>
				{children}
			</div>
		</div>
	);
}

export function AttrRating({ attr }: { attr?: XScopeAttrRef }) {
	if (!attr) {
		return null;
	}

	const value = parseInt(attr.value);

	return <SimpleRating label={attr.name} value={Number.isFinite(value) ? value - 1 : 0} />;
}

export const FieldRating = observer(({ field }: FieldRatingProps) => {
	let parsedValue: number = Number.parseInt(field.value);

	parsedValue = Number.isFinite(parsedValue) ? parsedValue - 1 : 0;

	function handleOnChange(value: number) {
		field.updateValue(String(value + 1));
	}

	function handleReset() {
		field.updateValue("1");
	}

	return (
		<div>
			<SimpleRating onChange={handleOnChange} label={field.label} value={parsedValue}>
				<span onClick={handleReset} className="rating-reset-link">
					Сброс
				</span>
			</SimpleRating>
		</div>
	);
});
