import { IUserNames } from "@app-types/models";
import { XUserRole } from "@external-types/user";

export function fullName(user: IUserNames) {
	return `${user.lastName || ""} ${user.firstName || ""} ${user.middleName || ""}`.trim() || user.username;
}

export function inRoles(scopeRoles: XUserRole[], roles: number[]) {
	return roles.some(role => inRole(scopeRoles, role));
}

export function inRole(scopeRoles: XUserRole[], role: number) {
	return scopeRoles.find(scopeRole => scopeRole.role_id === role) != null;
}
