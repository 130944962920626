import React from "react";
import cn from "classnames";

import { AttributeEntry } from "@helpers/scope-attributes";
import { breakByLine } from "@helpers/text";

interface Props {
	className?: string;
	label?: string;
	value?: string | null;
	vertical?: boolean;
	labelClassName?: string;
	attr?: AttributeEntry | null;
}

export function LabelValueRow({ label, value, attr, className, labelClassName, vertical = false }: Props) {
	if (attr) {
		label = attr.label;
		value = attr.value;
	}

	if (value) {
		return (
			<div className={cn("org-panel-data-row", className, { "org-panel-data-row--vertical": vertical })}>
				<div className={cn("org-panel-data-row__label", labelClassName)}>{label}</div>
				<div className="org-panel-data-row__value">
					{breakByLine(value).map((x, index) => (
						<React.Fragment key={index}>
							{x}
							<br />
						</React.Fragment>
					))}
				</div>
			</div>
		);
	}

	return null;
}
