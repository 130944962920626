import React from "react";
import { SvgIcon } from "@app-types/models";

export default function({ className }: SvgIcon) {
	return (
		<svg viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
			<g>
				<path d="M18.25 11H5.75C5.474 11 5.25 11.2235 5.25 11.5C5.25 11.7765 5.474 12 5.75 12H18.25C18.526 12 18.75 11.7765 18.75 11.5C18.75 11.2235 18.526 11 18.25 11Z" />
				<path d="M5.75 8H10.75C11.026 8 11.25 7.7765 11.25 7.5C11.25 7.2235 11.026 7 10.75 7H5.75C5.474 7 5.25 7.2235 5.25 7.5C5.25 7.7765 5.474 8 5.75 8Z" />
				<path d="M18.25 15H5.75C5.474 15 5.25 15.2235 5.25 15.5C5.25 15.7765 5.474 16 5.75 16H18.25C18.526 16 18.75 15.7765 18.75 15.5C18.75 15.2235 18.526 15 18.25 15Z" />
				<path d="M18.25 19H5.75C5.474 19 5.25 19.2235 5.25 19.5C5.25 19.7765 5.474 20 5.75 20H18.25C18.526 20 18.75 19.7765 18.75 19.5C18.75 19.2235 18.526 19 18.25 19Z" />
				<path d="M18.25 23H5.75C5.474 23 5.25 23.2235 5.25 23.5C5.25 23.7765 5.474 24 5.75 24H18.25C18.526 24 18.75 23.7765 18.75 23.5C18.75 23.2235 18.526 23 18.25 23Z" />
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M16.457 0H0.25V30H23.75V7.293L16.457 0ZM16.75 1.707L22.043 7H16.75V1.707ZM1.25 1V29H22.75V8H15.75V1H1.25Z"
				/>
			</g>
		</svg>
	);
}
