import { XSchemaFormItemInfo } from "@external-types/schema";
import { SchemaFieldType, ISchemaField, SchemaField } from "./schema-field";
import { XAppAttributeRef } from "@external-types/type";
import { XTaskValueInfo } from "@external-types/process";
import { action, observable } from "mobx";

export interface ISchemaForm {
	fields: ISchemaField[];
	isValid: boolean;
	getField(fieldId: number): ISchemaField | undefined;
	validate(): boolean;
}

export class SchemaForm implements ISchemaForm {
	@observable public fields: ISchemaField[];
	private fieldById: Map<number, ISchemaField> = new Map<number, ISchemaField>();

	public constructor(items: Array<XSchemaFormItemInfo | XAppAttributeRef | XTaskValueInfo>) {
		this.fields = items.map(item => new SchemaField(item));
		this.fieldById = new Map(this.fields.map(item => [item.id, item]));
	}

	public getField(fieldId: number) {
		return this.fieldById.get(fieldId);
	}

	@action
	public addField(field: ISchemaField) {
		const existingField = this.fields.find(f => f.id === field.id);

		if (existingField != null) {
			throw new Error(`Field ${field.id} (${field.label ?? "?"}) exists already`);
		}

		this.fieldById.set(field.id, field);
		this.fields.push(field);
	}

	@action
	public removeField(fieldId: number) {
		this.fields = this.fields.filter(f => f.id !== fieldId);
		this.fieldById.delete(fieldId);
	}

	public validate() {
		this.fields.forEach(field => field.validate());

		return this.isValid;
	}

	public get isValid() {
		return this.fields.every(field => field.isValid);
	}

	public subscribeChange(callback: (data: { id: number; value: string }) => void) {
		this.fields.forEach(field => {
			field.subscribeChange(callback);
		});
	}

	public get values() {
		return this.fields.filter(
			field => (field.type !== SchemaFieldType.Select && field.type !== SchemaFieldType.Switch) || field.value,
		);
	}
}
