import React from "react";
import { observer } from "mobx-react-lite";
import cn from "classnames";

import { IField } from "@app-models/form";

interface Props {
	field: IField;
	children: React.ReactNode;
	className?: string;
	required?: boolean;
}

export const FormRow = observer(({ field, children, className, required }: Props) => {
	return (
		<div className={cn("form__row", { "form__row--invalid": !field.isValid }, className)}>
			<label className={cn("form__label", { "form__label--required": required })}>{field.label}</label>
			{children}
			{field.validationMessage && <div className="form__error-message">{field.validationMessage}</div>}
		</div>
	);
});
