import React from "react";

import { VolunteerAttributes } from "../../../../constants/volunteer-attributes";
import { SchemaFormFieldRow } from "@app-components/schema-form";
import { SchemaForm } from "@app-models/schema-form";

interface Props {
	form: SchemaForm;
}

export const WorkAndFamilySection = ({ form }: Props) => {
	return (
		<div className="scope-card-content__row">
			<div className="scope-card-content__column">
				<div className="scope-card-heading">Семья и работа</div>
				<div>
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.MaritalStatus)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.SpouseFullName)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.ChildrenInfo)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.Education)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.Profession)!} />
					<SchemaFormFieldRow field={form.getField(VolunteerAttributes.WorkPlace)!} />
				</div>
			</div>
		</div>
	);
};
