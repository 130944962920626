import { SchemaForm } from "@app-models/schema-form";
import { VolunteerAttributes } from "../constants/volunteer-attributes";
import { XScopeCard, XScopeChildInfo } from "../../../../src/types/external-data/scope";
import { actions } from "../../../../src/actions";
import { mapAttrToScopeAttrId } from "../stores/reference-store/reference-scope";

export function setVolunteerDefaults(form: SchemaForm) {
	const statusField = form.getField(VolunteerAttributes.VolunteerStatus);

	if (statusField && !statusField.value) {
		statusField.value = statusField.options[0].value;
	}
}

export function getVolunteerScopeName(form: SchemaForm) {
	return [
		form.getField(VolunteerAttributes.LastName),
		form.getField(VolunteerAttributes.FirstName),
		form.getField(VolunteerAttributes.MiddleName),
	]
		.filter(field => field != null && field.value != null && field.value.trim() !== "")
		.map(field => field!.value.trim())
		.join(" ")
		.trim()
		.substr(0, 400);
}

export async function saveVolunteerInfo(scope: XScopeCard | XScopeChildInfo, form: SchemaForm) {
	const attrs = form.values.map(x => ({ attr: x.id, value: x.value }));

	const name = getVolunteerScopeName(form) || scope.name;

	await actions.updateScope(scope.id, {
		name,
		attrs: mapAttrToScopeAttrId(attrs, scope),
	});
}
