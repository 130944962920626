import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { configure } from "mobx";

export { AppHeader } from "./app-header";
import { AppStoreContext } from "../app-store-context";
import { IAppStores } from "@app-types/stores";
import { App } from "./app";

configure({ enforceActions: "observed" });

export function createApp(stores: IAppStores, Routes: React.FunctionComponent) {
	if (document.getElementById("app")) {
		render(
			<AppStoreContext.Provider value={stores}>
				<App>
					<BrowserRouter>
						<Routes />
					</BrowserRouter>
				</App>
			</AppStoreContext.Provider>,
			document.getElementById("app"),
		);
	}
}
