import React from "react";
import { PageHeader } from "../components/page-header";
import Logo from "../components/logo";

export const Policy = function() {
	return (
		<>
			<div className="app__header header">
				<a href="/" className="header-logo">
					<Logo />
				</a>
			</div>
			<div className="header-placeholder" />
			<div className="page app__content">
				<div className="page__content policy-page">
					<PageHeader title="Политика конфиденциальности" />
					<div className="para">
						ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ <br />в редакции от 01 июля 2021 года
					</div>
					<div className="para">
						Компания, РОО «Милосердие», ОГРН 1057700014679, зарегистрированная в Российской Федерации по
						адресу г. Москва, Ленинский проспект, д.8, корп.12 публикует настоящее соглашение о
						конфиденциальности, являющееся публичным соглашением в адрес юридических и физических лиц (далее
						– Соглашение), полным и безоговорочным принятием условий которого считается переход по ссылке,
						указанной в электронном сообщении, полученном при прохождении процедуры регистрации, для
						активации Аккаунта (Владельца учетной записи) или для регистрации пользовательской учетной
						записи по приглашению Владельца Аккаунта в Платформе Miloserdie.me, расположенном по адресу{" "}
						<a href="https://miloserdie.me/">https://miloserdie.me/</a> (далее – Сервис).
					</div>
					<div className="para">
						<strong>1. Предмет соглашения</strong>
					</div>
					<div className="para">
						1.1. Предметом настоящего Соглашения является определение прав и обязанностей Сторон,
						возникающих вследствие использования Сервиса, в отношении Конфиденциальной Информации, раскрытой
						любой Стороной другой Стороне в связи с исполнением сторонами обязательств по предоставлению и
						использованию услуг Сервиса, а также по обработке информации, включая, помимо прочего, сбор,
						использование, хранение и безопасность предоставленной Сторонами и/или собранной у Сторон
						информации.
						<br />
						1.2. Сторона, передающая Конфиденциальную Информацию, является Раскрывающей Стороной. Сторона,
						принимающая Конфиденциальную информацию, является Получающей Стороной.
						<br />
						1.3. Каждая из Сторон понимает и признает, что Конфиденциальная Информация была разработана или
						получена Сторонами путем вложения значительных усилий, и, что Конфиденциальная Информация - это
						ценное и уникальное достояние Сторон, которое обеспечивает их значимым конкурентным
						преимуществом. Каждая из Сторон соглашается применять все доступные меры по охране
						Конфиденциальной Информации другой Стороны, применяемые ей для охраны своей собственной
						Конфиденциальной Информации.
					</div>
					<div className="para">
						<strong>2. Конфиденциальная информация</strong>
					</div>
					<div className="para">
						2.1. Для условий настоящего Соглашения Конфиденциальной Информацией является любая информация
						или материал, целенаправленно или случайно полученный Получающей стороной от Раскрывающей
						Стороны в письменном или электронном виде в ходе использования Сервиса.
						<br />
						2.2. К Конфиденциальной Информации, в том числе, относится:
						<br />
						<ul>
							<li>
								любая техническая информация, в том числе обо всех выпущенных и не выпущенных Сторонами
								продуктах (исходный код, проектная документация, технические требования, цели,
								технические решения, алгоритмы, интерфейсы, и пр.);
							</li>
							<li>
								любая информация о маркетинге и продвижении продуктов, о политике Сторон на рынке, о
								планах Сторон;
							</li>
							<li>
								информация о Сторонах (обороты, объемы продаж, состояние склада, заключенные и не
								заключенные контракты, клиенты Сторон и пр.);
							</li>
							<li>
								информация, содержащаяся в переписке сотрудников компаний Сторон во время действия
								настоящего Соглашения, вне зависимости от используемых сотрудниками адресов электронной
								почты;
							</li>
							<li>
								информация, содержащаяся в переписке сотрудников компаний Сторон и их клиентов,
								осуществляющейся посредством Сервиса.
							</li>
						</ul>
						2.3. Несмотря на любые другие положения, информация не рассматривается как Конфиденциальная и
						соответственно Получающая Сторона не подпадает под обязательство о конфиденциальности в
						отношении такой информации, если информация удовлетворяет одной из следующих характеристик:
						<br />
						<ul>
							<li>
								информация представлена Получающей Стороне с письменным указанием на то, что она не
								является конфиденциальной;
							</li>
							<li>
								информация получена законным путем от третьего лица не связанного, насколько известно
								Сторонам, обязательствами о конфиденциальности в отношении такой информации;
							</li>
							<li>
								информация, самостоятельно полученная Стороной при осуществлении исследований,
								систематических наблюдений или иной деятельности;
							</li>
							<li>
								информация и материалы, выпущенные в свет и доступные для всеобщего сведения, в том
								числе опубликованные в печати и/или размещенные в Интернете рекламно-информационные
								материалы и научные статьи;
							</li>
							<li>
								информация, которая является или становится публично известной в результате
								неправильного, небрежного или намеренного действия Раскрывающей Стороны;
							</li>
							<li>
								информация независимо разработана Получающей Стороной, при условии, что лицо или лица,
								разработавшие ее, не имели доступа к конфиденциальной или являющейся секретом фирмы
								информации;
							</li>
							<li>
								информация является или становится известной неопределенному кругу лиц не по вине Сторон
								настоящего Соглашения;
							</li>
							<li>
								информация находится в собственности Стороны (Сторон) и в отношении которой не имеется
								никаких обязательств по режиму конфиденциальности;
							</li>
							<li>
								информация, которая в соответствии с действующим законодательством страны, в котором
								зарегистрирована раскрывающая сторона, не может являться коммерческой, служебной или
								иной тайной.
							</li>
						</ul>
					</div>
					<div className="para">
						<strong>3. Информация, собираемая сервисом</strong>
					</div>
					<div className="para">
						Получающая сторона собирает различные типы информации в процессе работы с Сервисом: •
						Информация, которую Раскрывающая Сторона самостоятельно предоставляет Сервису при осуществлении
						действий, направленных на регистрацию в Сервисе либо в рамках его использования Информация
						предоставляется путем заполнения Раскрывающей Стороной соответствующих форм, при этом объем
						предоставления информации при заполнении не обязательных полей указанных форм определяется
						Раскрывающей Стороной самостоятельно. Примеры таких данных: адреса электронной почты; логин
						пользователя, название организации. Информация, собираемая клиентами Это любая информация
						загруженная или внесенная Раскрывающей стороной в Сервис. Сервис не имеет прямого отношения к
						лицам, чьи персональные данные могут быть загружены в рамках работы клиентских аккаунтов. Каждый
						клиент Сервиса несет собственную ответственность за предоставление уведомления пользователям и
						третьим лицам в отношении целей, для которых клиент Сервиса собирает их персональные данные и
						как эти персональные данные в дальнейшем им обрабатываются. Автоматически собираемая информация
						Когда Раскрывающая сторона использует Сервис, Сервис может автоматически собирать
						пользовательские данные: сведения о местоположении; тип и версия ОС; тип и версия браузера; тип
						устройства и разрешение его экрана; источник откуда пришел на сайт пользователь; с какого сайта
						или по какой рекламе; язык ОС и Браузера; какие страницы открывает и на какие функциями
						пользуется пользователь; ip-адрес.
					</div>
					<div className="para">
						<strong>4. Цели сбора и обработки информации</strong>
					</div>
					<div className="para">
						Сбор, хранение и обработка указанной в разделе 3 настоящего Соглашения о конфиденциальности
						информации осуществляется исключительно в следующих целях:
					</div>
					<div className="para">
						4.1. Для идентификации Раскрывающей Стороны при пользовании Сервисом, <br />
						4.2. Для учета посещений (в том числе с целью предоставления услуг Раскрывающей Стороне).
						<br />
						4.3. Для осуществления взаимодействия с Раскрывающей Стороной в рамках заключенных с Сервисом
						договоров и/или соглашений.
						<br />
						4.4. Для проведения статистических и иных исследований на основе обезличенных данных.
						<br />
						4.5. Переданные данные обрабатываются как без использования средств автоматизации, так и с их
						использованием.
						<br />
					</div>
					<div className="para">
						<strong>5. Условия обработки информации пользователя и её передачи третьим лицам</strong>
					</div>
					<div className="para">
						5.1. Хранение (в том числе изменение и удаление) информации Раскрывающей стороны осуществляется
						в соответствии с функциональным назначением Сервиса, технической документацией к нему, а также
						договором на предоставление услуг Сервиса.
						<br />
						5.2. В ходе обработки с данными будут совершены следующие действия: сбор; запись;
						систематизация; накопление; хранение; уточнение (обновление, изменение); извлечение;
						использование; передача (распространение, предоставление, доступ); блокирование; удаление;
						уничтожение.
						<br />
						5.3. Раскрытие информации третьим лицам осуществляется следующих случаях, когда:
						<br />
						5.3.1. Пользователь заблаговременно выразил свое согласие на такое раскрытие.
						<br />
						5.3.2. Передача необходима для защиты прав и законных интересов Сервиса или третьих лиц в
						случаях, когда Раскрывающая Сторона нарушает авторские права Сервиса и/или соответствующий
						договор об использовании Сервиса.
						<br />
					</div>
					<div className="para">
						<strong>6. Защита информации</strong>
					</div>
					<div className="para">
						Сервис принимает необходимые правовые, организационные и технические меры или обеспечивает их
						принятие для защиты информации от неправомерного или случайного доступа к ней, уничтожения,
						изменения, блокирования, копирования, предоставления, распространения информации, а также от
						иных неправомерных действий в отношении информации.
					</div>

					<div className="para">
						<strong>7. Обязательства сторон</strong>
					</div>
					<div className="para">
						7.1. В течение всего срока действия Соглашения Стороны обязуются не разглашать ставшую им
						известной Конфиденциальную Информацию без письменного согласия Раскрывающей Стороны.
						<br />
						7.2. Стороны обязуются предпринимать в отношении известной Конфиденциальной Информации меры по
						недопущению ее разглашения и охране конфиденциальности этой информации, хранить и обрабатывать
						такую информацию в соответствии с установленным каждой из Сторон порядком по сохранению
						Конфиденциальной Информации.
						<br />
						7.3. Получающая Сторона обязуется предоставлять доступ к Конфиденциальной Информации только
						работникам, которым такой доступ необходим в связи с выполнением задач по обеспечению
						использования Сервиса Раскрывающей Стороной.
						<br />
						7.4. Получающая Сторона надлежащим образом в соответствие с действующим законодательством
						образом оформляет доступ своих работников к Конфиденциальной Информации и создает необходимые
						условия для соблюдения условий настоящего Соглашения.
						<br />
					</div>

					<div className="para">
						<strong>8. Использование Конфиденциальной Информации</strong>
					</div>
					<div className="para">
						8.1. Стороны обязуются использовать Конфиденциальную Информацию только в целях исполнения
						обязательств по предоставлению и пользованию услугами Сервиса.
						<br />
						8.2. Вся Конфиденциальная Информация, предоставленная в силу настоящего Соглашения, включая ее
						копии, должна быть уничтожена в течение 60 (шестидесяти) дней со дня удаления Раскрывающей
						стороной своего аккаунта в Сервисе.
						<br />
					</div>
					<div className="para">
						<strong>9. Специальные условия</strong>
					</div>
					<div className="para">
						9.1. Обязательства по соблюдению конфиденциальности, предусмотренные настоящим Соглашением, не
						затрагивают случаи предоставления Конфиденциальной Информации в установленных законодательством
						случаях по требованию органа государственной власти, иного государственного органа, органа
						местного самоуправления или судов, прокуратуры, органов предварительного следствия, органов
						дознания. При этом Получающая Сторона должна незамедлительно известить Раскрывающую сторону о
						получении такого требования и о необходимости раскрытия Конфиденциальной Информации, если это не
						противоречит действующему законодательству страны, в котором зарегистрирована раскрывающая
						сторона.
					</div>
					<div className="para">
						<strong>10. Применимое право и разрешение споров</strong>
					</div>
					<div className="para">
						10.1. Данное Соглашение регулируется и рассматривается в соответствии с действующим
						законодательством страны, в котором зарегистрирована раскрывающая сторона.
						<br />
						10.2. Все споры, возникающие из или в связи с настоящим Соглашением, а также его толкованием,
						подлежат дружественному урегулированию путем переговоров между Сторонами.
						<br />
						10.3. Если урегулирование не будет достигнуто Сторонами путем переговоров в течение 30
						календарных дней от начала этих переговоров, то спор будет передан на разрешение в суд общей
						юрисдикции по месту нахождения Получающей Стороны.
						<br />
					</div>
					<div className="para">
						<strong>11. Срок действия соглашения</strong>
					</div>
					<div className="para">
						11.1. Настоящее Соглашение вступает в силу с момента его одобрения Стороной в момент регистрации
						в Сервисе и действует в течение трех лет после прекращения пользования Сервисом.
					</div>
					<div className="para">
						<strong>12. Прочие условия</strong>
					</div>
					<div className="para">
						12.1. Форма и метод информационного обмена в рамках настоящего Соглашения должны быть определены
						Сторонами при условии, что все действия должны совершаться, руководствуясь честной деловой
						практикой.
						<br />
						12.2. Если любое из условий настоящего Соглашения будет признано судом или любым другим
						компетентным органом недействительным, незаконным или не имеющим силу, то это никоим образом не
						повлияет на действительность, законность и действие остальных условий настоящего Соглашения. При
						этом Стороны предпримут все усилия для пересмотра признанных недействительными условий
						настоящего Соглашения с той целью, чтобы они, являясь действительными в новой редакции,
						наилучшим образом соответствовали намерениям, выраженными в них и прочих условиях настоящего
						Соглашения.
						<br />
						12.3. Ни одна из Сторон не имеет права передать свои права и обязанности полностью или частично,
						вытекающие из настоящего Соглашения, третьему лицу без предварительного письменного разрешения
						другой Стороны. В случае изменения организационно-правовой формы собственности Стороны или ее
						собственника, ее права и обязанности автоматически переходят к преемнику.
						<br />
					</div>
				</div>
			</div>
		</>
	);
};
