import { actions } from "@actions";
import { InfiniteDataSource } from "@app-models/data-source";
import { action, observable, runInAction } from "mobx";

import { AppState } from "../app-state";
import { Scope } from "../../constants/scope";
import { Volunteer } from "./volunteer";
import { EmptyVolunteer } from "./empty-volunteer";

export { Volunteer };

export class VolunteersStore extends InfiniteDataSource<Volunteer> {
	@observable public currentItem: Volunteer | null = null;
	@observable public selectedItemId: number = -1;
	@observable public emptyVolunteer: EmptyVolunteer | null = null;

	public constructor(private appState: AppState) {
		super();
	}

	protected get session() {
		return this.appState.volunteers;
	}

	protected async fetch() {
		const result = await actions.getScopeChildrenPaged(
			Scope.ProjectsAndVolunteers,
			this.session.pagination,
			this.session.filter,
		);

		this.updatePageInfo(result);

		return result.list.map(child => new Volunteer(child));
	}

	public async fetchVolunteer(scopeId: number) {
		const scope = await actions.getScope(scopeId);

		runInAction(() => {
			this.currentItem = new Volunteer(scope);
		});
	}

	@action
	public toggleSelect(selectedItemId: number) {
		this.selectedItemId = this.selectedItemId === selectedItemId ? -1 : selectedItemId;
	}

	public async fetchEmptyVolunteer() {
		const emptyVolunteer = new EmptyVolunteer();
		await emptyVolunteer.fetch();

		runInAction(() => {
			this.emptyVolunteer = emptyVolunteer;
		});
	}
}
