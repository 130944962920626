import React, { forwardRef, Ref } from "react";
import cn from "classnames";

interface Props {
	value?: string;
	placeholder?: string;
	Icon?: React.ReactNode;
	className?: string;
	onChange?: (value: string) => void;
}

export const Textbox = forwardRef(
	({ value, placeholder, Icon, onChange, className }: Props, ref: Ref<HTMLInputElement>) => {
		function handleChange(e: React.FormEvent<HTMLInputElement>) {
			if (onChange) {
				onChange(e.currentTarget.value);
			}
		}

		const hasIcon = Icon != null;

		return (
			<div className={cn("ui-kit-textbox", className)}>
				<input
					ref={ref}
					type="text"
					className={cn("ui-kit-textbox__input", { "ui-kit-textbox__input--icon": hasIcon })}
					value={value}
					placeholder={placeholder}
					onChange={handleChange}
				/>
				{hasIcon && <div className="ui-kit-textbox__icon">{Icon}</div>}
			</div>
		);
	},
);
