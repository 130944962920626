import React from "react";

export function useDebouncedEffect(callback: () => void, delay: number, deps: any[] = []) {
	const firstUpdate = React.useRef(true);
	React.useEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}
		const handler = setTimeout(() => {
			callback();
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [delay, ...deps]);
}
