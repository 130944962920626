import React from "react";

interface Props {
	fileUrl: string;
}

export function UserPhoto({ fileUrl }: Props) {
	const styles = !fileUrl
		? {}
		: {
				backgroundImage: `url(${fileUrl})`,
				backgroundSize: "cover",
		  };

	return (
		<div className="user-photo">
			<div className="user-photo__content" style={styles} />
		</div>
	);
}
