import React from "react";
import { generatePath } from "react-router";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { XScopeAttrRef, XScopeChildInfo } from "@external-types/scope";
import { getAttributeValue } from "@helpers/scope-attributes";
import { LightButton } from "@app-components/buttons/light-button";

import { Paths } from "../../routes/paths";
import { Volunteer } from "../../stores/volunteers-store";
import { VolunteerAttributes } from "../../constants/volunteer-attributes";
import { allProjectFieldKeysSet } from "../../stores/volunteers-store/volunteer-projects";

interface Props extends RouteComponentProps {
	entry: Volunteer;
}

function AttrFormRowInfo({ attr, label, scope }: { attr?: XScopeAttrRef; label?: string; scope: XScopeChildInfo }) {
	if (!attr || !attr.value) {
		return null;
	}

	return (
		<div className="form__row">
			<div className="form__label">{label ?? attr.name}</div>
			<div className="form__value">{getAttributeValue(attr, scope.type)}</div>
		</div>
	);
}

function FormRowInfo({ label, value }: { label: string; value: string }) {
	return (
		<div className="form__row">
			<div className="form__label">{label}</div>
			<div className="form__value">{value}</div>
		</div>
	);
}

export const VolunteerBaseDetails = withRouter(({ entry, history }: Props) => {
	function handleClick() {
		history.push(generatePath(Paths.Volunteer, { id: entry.id }));
	}

	const projects = entry.scope.attrs.filter(attr => allProjectFieldKeysSet.has(attr.attr));

	return (
		<div className="ward-base-details">
			<div className="ward-base-details__content">
				<div className="ward-base-details__column">
					<div className="ward-base-details__header">Доброволец</div>
					<FormRowInfo label="Доброволец" value={entry.fullName} />
					<AttrFormRowInfo attr={entry.getAttr(VolunteerAttributes.Birthday)} scope={entry.scope} />
					{/*<AttrFormRowInfo attr={entry.getAttr(VolunteerAttributes.Age)} scope={entry.scope} />*/}
					<AttrFormRowInfo attr={entry.getAttr(VolunteerAttributes.Gender)} scope={entry.scope} />
				</div>
				<div className="ward-base-details__column">
					<div className="ward-base-details__header">Контакты</div>
					<AttrFormRowInfo attr={entry.getAttr(VolunteerAttributes.MobilePhone)} scope={entry.scope} />
					<AttrFormRowInfo attr={entry.getAttr(VolunteerAttributes.HomePhone)} scope={entry.scope} />
					<AttrFormRowInfo attr={entry.getAttr(VolunteerAttributes.AdditionalPhone)} scope={entry.scope} />
					<AttrFormRowInfo attr={entry.getAttr(VolunteerAttributes.Email)} scope={entry.scope} />
					<AttrFormRowInfo attr={entry.getAttr(VolunteerAttributes.Region)} scope={entry.scope} />
					<AttrFormRowInfo attr={entry.getAttr(VolunteerAttributes.Settlement)} scope={entry.scope} />
				</div>
				<div className="ward-base-details__column">
					<div className="ward-base-details__header">Состояние</div>
					<AttrFormRowInfo attr={entry.getAttr(VolunteerAttributes.VolunteerStatus)} scope={entry.scope} />
					{/*<AttrFormRowInfo attr={entry.getAttr(VolunteerAttributes.Rating)} scope={entry.scope} />*/}
					{/*<AttrFormRowInfo attr={entry.getAttr(VolunteerAttributes.Projects)} scope={entry.scope} />*/}
					{/*<AttrFormRowInfo attr={entry.getAttr(VolunteerAttributes.InPrayList)} scope={entry.scope} />*/}
					{/*<AttrFormRowInfo attr={entry.getAttr(VolunteerAttributes.ReasonOfInPrayList)} scope={entry.scope} />*/}
					<AttrFormRowInfo attr={entry.getAttr(VolunteerAttributes.Dead)} scope={entry.scope} />
					<AttrFormRowInfo attr={entry.getAttr(VolunteerAttributes.DateOfDeath)} scope={entry.scope} />
					<AttrFormRowInfo
						attr={entry.getAttr(VolunteerAttributes.CoordinatorComments)}
						scope={entry.scope}
					/>

					{projects.length > 0 && (
						<>
							<div className="ward-base-details__header">Проекты</div>
							<ul className="volunteer-projects-list">
								{entry.scope.attrs
									.filter(attr => allProjectFieldKeysSet.has(attr.attr))
									.map(attr => (
										<li key={attr.id}>{attr.name}</li>
									))}
							</ul>
						</>
					)}
				</div>
			</div>
			<div className="ward-base-details__actions">
				<LightButton onClick={handleClick}>Подробнее</LightButton>
			</div>
		</div>
	);
});
