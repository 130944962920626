import React from "react";
import { observer } from "mobx-react-lite";
import { Button } from "@app-components/buttons/button";
import { CallItem } from "../../../stores/call-center-store/call-item";
import { Paths } from "../../../routes/paths";
// import { CallTaskFormType } from "../../../stores/call-center-store/task";
// import { ProcessActions } from "../../../constants/process";

interface Props {
	item: CallItem;
}

export const TaskActions = observer(function({ item }: Props) {
	function handleChange(actionId: number) {
		return function() {
			item.runAction(actionId, nextTaskRef => {
				if (nextTaskRef.next_id) {
					item.updateTask(nextTaskRef.next_id);
				} else {
					document.location.replace(Paths.CallCenter);
				}
			});
		};
	}

	const task = item.task!;

	return (
		<div className="task-form-actions">
			<div className="task-form-actions__intermediate" />
			<div className="task-form-actions__finish">
				{task.actions
					// .filter(
					// 	action =>
					// 		task.form.type === CallTaskFormType.Request ||
					// 		action.actionID !== ProcessActions.SendToCoordinator,
					// )
					.map(actionItem => (
						<Button
							className="button button--primary"
							key={actionItem.actionID}
							onClick={handleChange(actionItem.actionID)}
							// disabled={!task.submitAllowed}
						>
							{actionItem.action}
						</Button>
					))}
			</div>
		</div>
	);
});
