import React from "react";
import { SvgIcon } from "@app-types/models";

export default function({ className }: SvgIcon) {
	return (
		<svg className={className} viewBox="0 0 113 113" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle opacity="0.2" cx="56.5" cy="56.5" r="56.5" fill="#9B9B9B" />
			<g opacity="0.3">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M82.6395 57.4738C82.6395 71.365 71.3655 82.6391 57.4743 82.6391C43.5831 82.6391 32.3091 71.365 32.3091 57.4738C32.3091 43.5826 43.5831 32.3086 57.4743 32.3086C71.3655 32.3086 82.6395 43.5826 82.6395 57.4738ZM49.9249 63.136C51.6613 63.136 53.0706 61.7267 53.0706 59.9903C53.0706 58.2539 51.6613 56.8447 49.9249 56.8447C48.1885 56.8447 46.7792 58.2539 46.7792 59.9903C46.7792 61.7267 48.1885 63.136 49.9249 63.136ZM61.8784 59.9903C61.8784 58.2539 63.2876 56.8447 65.024 56.8447C66.7604 56.8447 68.1697 58.2539 68.1697 59.9903C68.1697 61.7267 66.7604 63.136 65.024 63.136C63.2876 63.136 61.8784 61.7267 61.8784 59.9903ZM71.3154 47.4077C64.0175 47.4077 57.6255 43.482 54.1024 37.6436C55.1845 37.4675 56.3169 37.3416 57.4745 37.3416C64.7724 37.3416 71.1644 41.2674 74.6875 47.1058C73.6054 47.2819 72.473 47.4077 71.3154 47.4077ZM38.3992 51.107C42.7025 48.666 46.0243 44.6899 47.6097 39.9337C43.3064 42.3747 39.9846 46.3508 38.3992 51.107ZM76.7762 51.8368C77.3047 53.6236 77.6066 55.511 77.6066 57.4739C77.6066 68.5717 68.5723 77.606 57.4745 77.606C46.3766 77.606 37.3423 68.5717 37.3423 57.4739C37.3423 57.4309 37.3452 57.388 37.3481 57.3461C37.3536 57.2651 37.3589 57.1879 37.3423 57.1215C43.8852 54.6554 49.1448 49.5971 51.7871 43.1548C56.4175 48.817 63.4386 52.4408 71.3153 52.4408C73.2027 52.4408 75.0146 52.2143 76.7762 51.8368Z"
					fill="#01081B"
				/>
			</g>
		</svg>
	);
}
